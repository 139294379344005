import { Box, CardMedia, CircularProgress, Menu, styled } from '@mui/material'

export const MessageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    color: theme.palette.text.primary,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    margin: '17px 0',

    '&.wrapper__message-own': {
        justifyContent: 'flex-end',
    },

    '& .companion-avatar': {
        border: `2px solid ${theme.palette.primary.contrastText}`,
        boxShadow: '0px 9px 12px -6px rgba(127, 154, 159, 0.44)',
        width: '48px',
        height: '48px',
        marginRight: '12px',
    },
}))

export const ReplyWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.violet.main,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 4px 11px rgba(84, 102, 76, 0.1)',
    borderRadius: '12px 0px 12px 12px',
    border: '2px solid transparent',
    maxWidth: '80%',

    '&.wrapper__message-edit': {
        border: '2px solid rgba(43, 48, 115, 0.6)',
    },

    '& .reply-msg': {
        borderLeft: '3px solid #62735b',

        '& .user_nickname': {
            color: '#62735b',
        },
    },
}))

export const OwnMessageWrapper = styled(Box)(() => ({
    position: 'relative',
    minWidth: '330px',
}))

export const CompanionMessageWrapper = styled(Box)`
  position: relative;
  display: flex;
`

export const AdminMessageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: '400',
    fontSize: '14px',
    color: theme.palette.violet.main,
    margin: '12px 0',
}))

export const CompanionReplyWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.violet.main,
    boxShadow: '0px 4px 11px rgba(64, 78, 89, 0.1)',
    borderRadius: '0px 16px 16px 16px',
    maxWidth: '84%',

    '& .reply-msg': {
        borderLeft: '3px solid rgba(43, 48, 115, 0.8)',

        '& .user_nickname': {
            color: 'rgba(43, 48, 115, 0.8)',
        },
    },
}))

export const CompanionMessageContent = styled(Box)(({ theme }) => ({
    padding: '14px 25px',
    minWidth: '330px',
    maxWidth: '800px',
    wordBreak: 'break-all',

    '& .user_nickname': {
        fontWeight: 700,
        fontSize: '14px',
        marginBottom: '6px',
        color: theme.palette.violet.main,
        paddingRight: '90px',
    },

    '& .user_message': {
        wordBreak: 'break-word',
        opacity: '0.8',
    },

    '&.reply-msg': {
        marginTop: '10px',
        marginLeft: '25px',
        marginRight: '25px',
        paddingRight: 0,
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0',
    },
}))

export const SeparatorWrapper = styled(Box)(({ theme }) => ({
    color: theme.palette.text.primary,
    opacity: '0.5',
    fontWeight: 700,
    fontSize: '12px',
}))

export const MessageInfo = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '16px',
    top: '11px',
    color: '#ffffff75',

    '&.relative': {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
    },

    '.MuiIconButton-root': {
        color: '#ffffff75',
        padding: 0,
    },

    '&.message__info-companion': {
        top: '13px',
        color: theme.palette.violet.main,
    },

    '& .message-status': {
        width: '16px',
        height: '16px',

        '& .MuiSvgIcon-root': {
            width: '100%',
            height: '100%',
        },
    },
}))

export const MessageText = styled(Box)`
  word-break: break-word;
  padding: 12px 20px;
`

export const MessageSpinner = styled(CircularProgress)`
  width: inherit !important;
  height: inherit !important;
  color: inherit !important;
`

export const MessageDateAndSettings = styled(Box)`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  &.edited-msg {
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const MessageMedia = styled(CardMedia)`
  border-radius: 14px;
` as typeof CardMedia

export const MessageMenuWrapper = styled(Menu)(({ theme }) => ({
    '&.criteria-menu': {
        top: -13,
        '.MuiPaper-root': {
            width: 390,
            overflow: 'scroll',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginLeft: 20,

            '.MuiMenu-list':{
                padding: 0,
            },

            ':before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 17,
                width: 28,
                height: 28,
                backgroundColor: theme.palette.primary.contrastText,
                transform: 'translateX(-49%) rotate(45deg)',
            },
        },
    },

    '& .MuiPaper-root': {
        width: '160px',
        boxShadow: '0px 4px 16px rgba(63, 75, 84, 0.1)',
        borderRadius: '6px',
        color: '#818285',
    },

    '&.upload-file_wrapper': {
        '& .MuiMenuItem-root': {
            '&.download-btn': {
                color: theme.palette.text.primary,
                opacity: 0.6,
            },

            '&.edit-btn': {
                color: theme.palette.green.contrastText,
                opacity: 1,

                '& path': {
                    fill: theme.palette.green.contrastText,
                },
            },
        },
    },

    '& .MuiMenuItem-root': {
        fontWeight: '500',
        fontSize: '14px',
        color: '#2d2e34',
        padding: '8px 28px',
        opacity: 0.6,

        '&.edit-btn': {
            color: theme.palette.text.primary,
            opacity: 0.7,
        },

        '&.delete-message': {
            color: '#e15555',
            opacity: 1,
        },

        '&.complete-btn': {
            color: theme.palette.purple.main,
            opacity: 1,
        },

        '&.cancel-btn, &.archive-btn': {
            color: '#FC7960',
            opacity: 1,
        },

        '&.unarchive-btn': {
            color: theme.palette.green.contrastText,
            opacity: 1,
        },

        '& .MuiButtonBase-root': {
            padding: 0,
            marginRight: '13px',
            color: 'inherit',
        },
    },
}))
