import React from 'react'
import classNames from 'classnames'
import { Box } from '@mui/material'

import CompanionMessage from './CompanionMessage'
import { cropName, getSenderNickname } from '@/utils'

import { ICompanionReplyMessage } from '../interfaces'

import { CompanionMessageContent, MessageMedia } from '../styles'

const CompanionReplyMessage: React.FC<ICompanionReplyMessage> = ({
    companionMessage,
    isReplyMessage,
    parentMessage,
    coverUrl,
    myOwnId,
    sender,
    patientNickname,
}) => {
    const { message: chatMessage, messageId, createdAt, updatedAt, url } = companionMessage

    const senderNickname =
        parentMessage?._sender.userId === myOwnId
            ? parentMessage?._sender.nickname
            : getSenderNickname(sender, patientNickname)

    const replyMessageContent: string | JSX.Element = parentMessage?.message ? (
        cropName(parentMessage?.message, 40)
    ) : (
        <MessageMedia component='img' height='120' image={parentMessage?.url} />
    )

    return (
        <CompanionMessage
            chatMessage={chatMessage}
            createdAt={createdAt}
            updatedAt={updatedAt}
            coverUrl={coverUrl}
            messageImgUrl={url}
            parentMessage={parentMessage}
            messageId={messageId}
            sender={sender}
            patientNickname={patientNickname}
        >
            <CompanionMessageContent
                className={classNames({
                    'reply-msg': isReplyMessage,
                })}
            >
                <Box className='user_nickname'>{senderNickname}</Box>
                <Box className='user_message'>{replyMessageContent}</Box>
            </CompanionMessageContent>
        </CompanionMessage>
    )
}

export default CompanionReplyMessage
