import lowPrioritySvg from '@/assets/images/priority-low.svg'
import medPrioritySvg from '@/assets/images/priority-medium.svg'
import highPrioritySvg from '@/assets/images/priority-high.svg'

interface IPriorityIcons {
    [key: string]: {
        icon: string,
        classname: string,
    };
}

interface IStatusColors {
    [key: string]: string;
}

const priorityIcons: IPriorityIcons = {
    Low: {
        icon: lowPrioritySvg,
        classname: 'low-priority',
    },
    Medium: {
        icon: medPrioritySvg,
        classname: 'medium-priority',
    },
    High: {
        icon: highPrioritySvg,
        classname: 'high-priority',
    },
}

const statusColors: IStatusColors = {
    'not started': 'not-started-todo',
    'in progress': 'in-progress-todo',
    completed: 'completed-todo',
    canceled: 'canceled-todo',
}

export { priorityIcons, statusColors }
