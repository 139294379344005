import React from 'react'
import { Stack, Button } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { IUnsavedDataNotifier } from '@/components/UI/interfaces'

import {
    ModalContent,
    ModalText,
    ModalWrapper,
} from '@/components/UI/Modals/ExpiredTokenNotifier/styles'

const UnsavedDataNotifier: React.FC<IUnsavedDataNotifier> = ({
    open,
    toggleUnsavedDataModal,
    handleClose,
}) => {
    const exitModalWindows = () => {
        toggleUnsavedDataModal()
        handleClose()
    }

    return (
        <ModalWrapper open={open} closeAfterTransition>
            <ModalContent className='column-content'>
                <Stack direction='row'>
                    <ErrorOutlineIcon color='primary' />
                    <ModalText>
                        Are you sure you want to leave this page? All changes will be lost.
                    </ModalText>
                </Stack>
                <Stack className='modal-btns' spacing={2} direction='row'>
                    <Button color='info' variant='outlined' onClick={toggleUnsavedDataModal}>
                        Go Back
                    </Button>
                    <Button
                        className='quit-btn'
                        color='info'
                        variant='contained'
                        onClick={exitModalWindows}
                    >
                        Confirm
                    </Button>
                </Stack>
            </ModalContent>
        </ModalWrapper>
    )
}

export default UnsavedDataNotifier
