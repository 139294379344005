import {
    SubscriptionTypesEnum,
    ReadablePromoCodeTypesEnum,
    ReadableSubscriptionTypesEnum,
} from '@/constants/subscriptionConstants'
import { IReadablePromoCodeTypes, IReadableSubscriptionTypes } from '@/constants/interfaces'

export const checkSubscriptionFreemium = (subscriptionType: string | null | undefined): boolean => {
    const isFreemium =
      subscriptionType === SubscriptionTypesEnum.FREEMIUM ||
      subscriptionType === ReadableSubscriptionTypesEnum.freemium ||
      !subscriptionType

    return isFreemium
}

// - Gets a patient's current subscription plan - can be monthly, yearly, B2B, promo code, free trial, or freemium
// - Returns readable values - Monthly, Yearly, B2B, Free Trial, or Freemium -> If the patient is on a
//     promo code plan, we look at their revCatSubscriptions to check if the promo is monthly or annual (i.e. promoCodePlan)

export const getSubscriptionCurrentPlan = (
    subscriptionType: string | null | undefined,
    promoCodePlan?: string | null,
    readablePartner?: string | null,
) => {
    if (subscriptionType === SubscriptionTypesEnum.PROMO_CODE) {
        return ReadablePromoCodeTypesEnum[promoCodePlan as keyof IReadablePromoCodeTypes] ?? ReadableSubscriptionTypesEnum.promo_code
    } else if (subscriptionType === SubscriptionTypesEnum.B2B) {
        return ReadableSubscriptionTypesEnum[subscriptionType as keyof IReadableSubscriptionTypes] + ' - ' + readablePartner
    } else {
        return ReadableSubscriptionTypesEnum[subscriptionType as keyof IReadableSubscriptionTypes] ?? ReadableSubscriptionTypesEnum.freemium
    }
}