import { gql } from '@apollo/client'

export const FETCH_QUESTIONNAIRE_LIST = gql`
    {
        questionnaireList {
            id
            name
        }
    }
`

export const QUESTIONNAIRE_BY_ID_QUERY = gql`
    query QuestionnaireById($questionnaireByIdId: ID!) {
        questionnaireById(id: $questionnaireByIdId) {
            name
        }
    }
`
