import React from 'react'
import { Select, Typography } from '@mui/material'

import { IToDoRepeat } from '@/components/ToDo/ToDoSettings/interfaces'

import { DropDownItems, MainHeader, ToDoFormDropdown } from '@/components/ToDo/ToDoForm/styles'

const ToDoRepeat: React.FC<IToDoRepeat> = ({ isViewMode, handleToDoData, repeat }) => {
    return (
        <MainHeader>
            <Typography className='settings-field todo-repeat required-field'>Repeat</Typography>
            <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                <Select
                    required
                    label='Repeat'
                    name='repeat'
                    className='settings-notification'
                    disabled={isViewMode}
                    value={repeat || 'None'}
                    onChange={(e: any) => {
                        handleToDoData(e.target.name, e.target.value)
                    }}
                >
                    <DropDownItems value='None'>None</DropDownItems>
                    <DropDownItems value='Daily'>Daily</DropDownItems>
                    <DropDownItems value='Weekly'>Weekly</DropDownItems>
                    <DropDownItems value='Monthly'>Monthly</DropDownItems>
                </Select>
            </ToDoFormDropdown>
        </MainHeader>
    )
}

export default ToDoRepeat
