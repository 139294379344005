import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useFormik } from 'formik';
import { Button, CardContent, CardHeader, TextField, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import CircularLoader from '@/components/Loader/CircularLoader'
import { CardContentBox, FormContainer, FormRow, PatientDataBlockWrapper } from '@/components/Patient/PatientContent/styles'
import { SubscriptionTypesEnum } from '@/constants/subscriptionConstants'
import { getSubscriptionCurrentPlan, formatISO } from '@/utils'
import { UPDATE_SUBSCRIPTION_END_DATE } from '@/graphql/subscriptions/mutations'
import { FETCH_MEMBERSHIP_BY_USER_ID } from '@/graphql/users/queries'

const getAccountStatus = (inactive: boolean): string => (inactive ? 'Deactivated' : 'Active')

const PatientMembershipInfo: React.FC = () => {
    const { id: userId } = useParams()
    const [endDate, setEndDate] = useState<Date>(() => (null as unknown as Date));
    const [ updateEndDate, { data: subData, loading: subLoading, error: subError }  ] = useMutation(UPDATE_SUBSCRIPTION_END_DATE);
    const { loading, data } = useQuery(FETCH_MEMBERSHIP_BY_USER_ID, {
        variables: {
            userId: userId,
        },
    })

    const {
        inactive = false,
        offerCode,
        revCatSubscriptions,
        activeSubscription,
    } = data?.userById || {}

    const subscriptionType: string = activeSubscription?.subscriptionType
    const startDate: string = activeSubscription?.startDate
    const b2bPartner: string = activeSubscription?.b2bPartner
    const b2bSubPartner: string = activeSubscription?.b2bSubPartner
    const readablePartner: string = b2bSubPartner ? b2bSubPartner : b2bPartner ? b2bPartner : 'N/A'
    
    useEffect(() => {
        if (activeSubscription?.endDate) {
            setEndDate(new Date(activeSubscription?.endDate));
        }
    }, [data])

    useEffect(() => {
        formik.resetForm({values: { endDate: endDate }});
    }, [endDate])

    const isPromoCode = subscriptionType === SubscriptionTypesEnum.PROMO_CODE
    const promoCodePlan: string | null = revCatSubscriptions?.[0]?.currentPlan

    const formik = useFormik({
        initialValues: {
            endDate: endDate,
        },
        onSubmit: async values => {
            try {
                await updateEndDate({
                    variables: {
                        userId: userId,
                        endDate: (new Date(values.endDate)).setHours(14), //to avoid issue with UTC making the date jump one day
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
    });

    if (loading) {
        return <CircularLoader size={60} />
    }

    return (
        <>
            <PatientDataBlockWrapper>
                <CardHeader title='Membership' />
                <CardContent>
                    <CardContentBox>
                        <Typography>Current plan</Typography>
                        <Typography className='membership-field'>
                            {getSubscriptionCurrentPlan(subscriptionType, promoCodePlan, readablePartner) || '--'}
                        </Typography>
                    </CardContentBox>
                    <CardContentBox>
                        <Typography>Date started</Typography>
                        <Typography className='membership-field'>
                            {formatISO(startDate) || '--'}
                        </Typography>
                    </CardContentBox>
                    {isPromoCode && (
                        <CardContentBox>
                            <Typography>Promo Code</Typography>
                            <Typography className='membership-field'>
                                {offerCode || '--'}
                            </Typography>
                        </CardContentBox>
                    )}
                    <FormContainer>
                        {isPromoCode ? (
                            <Typography>
                                Promo Code
                                <br />
                                Expiration Date
                            </Typography>
                        ) : (
                            <form onSubmit={formik.handleSubmit}>
                                <FormRow>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Typography>Expiration Date</Typography>
                                        {endDate ? 
                                            (<DatePicker
                                                inputFormat='MM/dd/yyyy'
                                                value={endDate}
                                                onChange={val => {
                                                    setEndDate(new Date(val || ''));
                                                    formik.resetForm();
                                                }}
                                                renderInput={({ onKeyDown, ...params }) => (
                                                    <TextField 
                                                        style={{'width': '37%', 'margin': '0 15px 0 0'}}
                                                        onKeyDown={(e) => {
                                                            e.preventDefault();
                                                            return;
                                                        }}
                                                        {...params}
                                                    />
                                                )}
                                            />)
                                            : ('N/A')}
                                    </LocalizationProvider>
                                    <Button variant='text' className='save-btn' type='submit' disabled={endDate === null}>
                                        Update
                                    </Button>
                                    {subLoading && <CircularLoader size={60} color='primary' />}
                                </FormRow>
                            </form>
                        )}
                    </FormContainer>
                </CardContent>
            </PatientDataBlockWrapper>
            <PatientDataBlockWrapper>
                <CardHeader title='Account Status' />
                <CardContent>
                    <CardContentBox>
                        <Typography>Status</Typography>
                        <Typography className={`membership-field ${inactive && 'deactivated'}`}>
                            {getAccountStatus(inactive) || '--'}
                        </Typography>
                    </CardContentBox>
                    <CardContentBox>
                        <Typography>Change Date</Typography>
                        <Typography className='membership-field'>
                            {formatISO(startDate) || '--'}
                        </Typography>
                    </CardContentBox>
                </CardContent>
            </PatientDataBlockWrapper>
        </>
    )
}

export default PatientMembershipInfo