import React, { ReactElement, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FETCH_STORYBLOK_DATASOURCE_ENTRIES_BY_SLUG } from '@/graphql/storyblok/queries'
import { StoryblokDatasourceSlugsEnum } from '@/constants/storyblokDatasourceConstants'
import { SubmitButton } from './SubmitButton'
import { Dropdown } from './Dropdown'
import { DropdownContainerWrapper, DropdownHeader, DropdownFormContainer } from '../../styles'
import { getCurrentAdminEntry } from '../../utils/getCurrentAdminMessage'
import AdminMessageModal from '../../modals/AdminMessageModal'

export const TabAdminMessages: React.FC = (): ReactElement => {
    const [adminMessageOptions, setAdminMessageOptions] = useState<any>([])
    const [currentAdminMessage, setCurrentAdminMessage] = useState<any>({})
    const [selectedAdminMessage, setSelectedAdminMessage] = useState<any>({})
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [isErrorModal, setIsErrorModal] = useState<boolean>(false)

    const [storyblokDatasourceEntries] = useLazyQuery(FETCH_STORYBLOK_DATASOURCE_ENTRIES_BY_SLUG)

    const fetchDatasourceEntries = async () => {
        const { data } = await storyblokDatasourceEntries({
            variables: {
                datasourceSlug: StoryblokDatasourceSlugsEnum.ADMIN_MESSAGE_RED_ZONE,
            },
        })

        const currentAdminEntryOptions = data.storyblokDatasourceEntries
        const currentAdminEntry =
            data.storyblokDatasourceEntries && getCurrentAdminEntry(data.storyblokDatasourceEntries)

        setAdminMessageOptions(currentAdminEntryOptions)
        setCurrentAdminMessage(currentAdminEntry)
        setSelectedAdminMessage(currentAdminEntry)
    }

    const handleSelectOption = (option: any) => {
        setSelectedAdminMessage(option)
    }

    const handleModalOpen = (isError?: boolean) => {
        setModalOpen(true)

        if (isError) {
            setIsErrorModal(true)
        }
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setIsErrorModal(false)
    }

    useEffect(() => {
        fetchDatasourceEntries()
    }, [])

    return (
        <DropdownContainerWrapper>
            <DropdownHeader>Send message to all users</DropdownHeader>
            <DropdownFormContainer>
                <Dropdown
                    selectedAdminMessage={selectedAdminMessage}
                    handleSelectOption={handleSelectOption}
                    adminMessageOptions={adminMessageOptions}
                />
                <SubmitButton
                    currentAdminMessage={currentAdminMessage}
                    selectedAdminMessage={selectedAdminMessage}
                    adminMessageOptions={adminMessageOptions}
                    setCurrentAdminMessage={setCurrentAdminMessage}
                    setSelectedAdminMessage={setSelectedAdminMessage}
                    setAdminMessageOptions={setAdminMessageOptions}
                    handleModalOpen={handleModalOpen}
                />
            </DropdownFormContainer>
            <AdminMessageModal
                modalOpen={modalOpen}
                isErrorModal={isErrorModal}
                handleModalClose={handleModalClose}
            />
        </DropdownContainerWrapper>
    )
}
