import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import axios from 'axios'
import { Box, Button, IconButton, Input, Typography } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import CircularLoader from '@/components/Loader/CircularLoader'
import FileMenuBtn from '@/components/ToDo/ToDoContent/ToDoFreeText/FileMenuBtn'
import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

import { IUploadToDoContentFile } from '@/components/ToDo/ToDoContent/interfaces'

import { MainHeader } from '@/components/ToDo/ToDoForm/styles'

const ALLOWED_CONTENT_TYPES = ['image/*', 'application/pdf']
const isAllowedFileForUpload = (fileType?: string) => {
    return fileType && (fileType.startsWith("image") || fileType === "application/pdf")
}

const UploadToDoContentFile: React.FC<IUploadToDoContentFile> = ({ toDoContentFile, setToDoContentFile, rowTitle }) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [fileId, setFileId] = useState('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const fileRef = useRef<HTMLElement | any>(null)

    const [fetchSignedUrl, { data }] = useLazyQuery(DOWNLOAD_SIGNED_URL)

    const isEqualToZero = toDoContentFile?.name?.length === 0

    useEffect(() => {
        if (!toDoContentFile?.id) {
            return
        }

        const downloadSignedUrl = async () => {
            try {
                await fetchSignedUrl({
                    variables: {
                        downloadSignedUrlId: toDoContentFile?.id
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }

        downloadSignedUrl()
    }, [toDoContentFile?.id])

    useEffect(() => {
        if (data?.downloadSignedUrl?.id) {
            const fetchFileInBlob = async () => {
                setIsLoading(true)
                const { name, displayName, url, fileType, id } = data.downloadSignedUrl || {}
                let fileName = name

                if (displayName) {
                    fileName = displayName
                }

                const { data: blob } = await axios.get(url)
                const fileFromBlob = new File([blob], fileName, { type: fileType })

                setFileId(id)
                setToDoContentFile(fileFromBlob)
                setIsLoading(false)
            }

            fetchFileInBlob()
        }
    }, [data?.downloadSignedUrl])

    let uploadFileIcon = null

    const selectFile = () => {
        if (fileRef.current) {
            fileRef.current.click()
        }
    }

    const uploadNewFile = async (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        const file: File = (target.files as FileList)[0]
        const fileType = file?.type
        if (isAllowedFileForUpload(fileType)) {
            const fileNameWithoutType = file?.name.replace(/\.[^/.]+$/, "")
            const blob: any = file?.slice(0, file?.size);
            const newFile = new File([blob], fileNameWithoutType, { type: `${file?.type}` });

            setToDoContentFile(newFile)
        }
    }

    const deleteUploadedFile = () => {
        setToDoContentFile(null)
        setFileId('')
        fileRef.current.value = ''
    }

    const toggleEditMode = () => {
        setEditMode(prevState => !prevState)
    }

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        const blob: any = toDoContentFile?.slice(0, toDoContentFile?.size);
        const newFile = new File([blob], value, { type: `${toDoContentFile?.type}` });

        setToDoContentFile(newFile)
    }

    if (editMode) {
        uploadFileIcon = (
            <IconButton disabled={isEqualToZero} onClick={toggleEditMode}>
                <SaveOutlinedIcon />
            </IconButton>
        )
    } else {
        uploadFileIcon = (
            <FileMenuBtn
                toggleEditMode={toggleEditMode}
                file={toDoContentFile}
                deleteUploadedFile={deleteUploadedFile}
                fileId={fileId}
            />
        )
    }

    return (
        <MainHeader>
            <Typography className='uploaded-files-typography required-field w-130'>
                {rowTitle}
            </Typography>
            <input
                type='file'
                className='medical-file-input'
                accept={ALLOWED_CONTENT_TYPES.join(",")}
                data-testid='content-upload'
                ref={fileRef}
                onChange={uploadNewFile}
            />
            {toDoContentFile?.name || (editMode && isEqualToZero) ? (
                isLoading ? (
                    <CircularLoader size={20} />
                ) : (
                    <Box className='free-text_upload-file'>
                        <Input
                            onChange={handleInput}
                            disabled={!editMode}
                            value={toDoContentFile.name}
                            name='name'
                            fullWidth
                            disableUnderline
                        />
                        {uploadFileIcon}
                    </Box>
                )
            ) : (
                <Button onClick={selectFile} className='free-text_upload-file'>
                    <FileUploadOutlinedIcon />
                    <Typography marginLeft='11px'>Upload file</Typography>
                </Button>
            )}
        </MainHeader>
    )
}

export default UploadToDoContentFile