import React from 'react'
import { Box } from '@mui/material'
import { IChatbotList, IChatbotEntryResource } from './interfaces'
import { EmptyStateBlock } from './styles'
import ChatbotPreviewInfo from './ChatbotPreviewInfo'

const ChatbotPreview: React.FC<IChatbotList> = ({ chatPreviewData, onChatClick }) => {
    if (!chatPreviewData?.total) {
        return <EmptyStateBlock>No chats found</EmptyStateBlock>
    }

    return (
        <Box>
            {chatPreviewData.items.map((chat: IChatbotEntryResource) => (
                <ChatbotPreviewInfo
                    key={chat?.chatId}
                    chat={chat}
                    onChatClick={() => onChatClick(chat.chatId)}
                />
            ))}
        </Box>
    )
}

export default ChatbotPreview
