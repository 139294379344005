import React from 'react'
import { EditButtonConfirm } from '../../../ToDo/ModalToDo/styles'
import { useMutation } from '@apollo/client'
import { UPDATE_STORYBLOK_DATASOURCE_ENTRIES } from '@/graphql/storyblok/mutations'
import { updateAdminMessageOptionsValue } from '../../utils/updateAdminMessageOptionsValue'
import { ISubmitButton } from '../../interfaces'

export const SubmitButton: React.FC<ISubmitButton> = ({
    currentAdminMessage,
    selectedAdminMessage,
    adminMessageOptions,
    setCurrentAdminMessage,
    setSelectedAdminMessage,
    setAdminMessageOptions,
    handleModalOpen,
}) => {
    const [updateStoryblokDatasourceEntries] = useMutation(UPDATE_STORYBLOK_DATASOURCE_ENTRIES)

    const handleSubmit = async () => {
        try {
            const updatedOptions = updateAdminMessageOptionsValue(
                adminMessageOptions,
                selectedAdminMessage
            )

            const { data } = await updateStoryblokDatasourceEntries({
                variables: {
                    datasourceEntries: updatedOptions,
                },
            })

            const responseMsg =
                data.updateStoryblokDatasourceEntries && data.updateStoryblokDatasourceEntries.msg

            if (responseMsg === 'OK') {
                setAdminMessageOptions(updatedOptions)
                setCurrentAdminMessage(selectedAdminMessage)
                handleModalOpen()
            } else {
                const isError = true
                setSelectedAdminMessage(currentAdminMessage)
                handleModalOpen(isError)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <EditButtonConfirm
            onClick={() => handleSubmit()}
            disabled={currentAdminMessage === selectedAdminMessage}
            className='edit-admin-message-btn'
            variant='contained'
            color='info'
        >
            Submit
        </EditButtonConfirm>
    )
}
