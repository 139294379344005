import { gql } from '@apollo/client'

export const UPDATE_STORYBLOK_DATASOURCE_ENTRIES = gql`
    mutation UpdateStoryblokDatasourceEntries(
        $datasourceEntries: [StoryblokDatasourceEntryInput!]!
    ) {
        updateStoryblokDatasourceEntries(datasourceEntries: $datasourceEntries) {
            msg
        }
    }
`
