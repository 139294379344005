import { Box, Tab, styled } from '@mui/material'

export const TabsMenuWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    padding: '32px 35px',
    overflow: 'hidden',
    backgroundColor: '#fdfcf7',

    '.tab_panel-wrapper': {
        position: 'relative',
        height: '100%',
        padding: '0px',

        '& > .MuiBox-root': {
            height: '100%',
            overflowY: 'auto',
        },

        '.MuiBox-root .MuiPaper-root:last-child': {
            marginBottom: '27px',

            '&.uploaded-files': {
                marginBottom: 0,
            },
        },
    },

    '.file-input': {
        display: 'none',
    },

    '.file-btn': {
        margin: '18px 0 10px 34px',
        fontSize: '16px',
        fontWeight: 700,
    },

    '& .MuiTabs-flexContainer': {
        borderBottom: '1px solid #EAE9E5',
        overflowX: 'auto',
    },

    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.orange.main,
        height: 4,
    },
}))

export const PatientTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 400,
    opacity: '0.5',

    '&.Mui-selected': {
        opacity: '1',
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
}))
