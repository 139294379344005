import React from 'react'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined'

import { dateToString, formatISO } from '@/utils'

import { IExportToExcelBtn } from '../interfaces'

import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'

const headers = [
    { label: 'College Name', key: 'name' },
    { label: 'State', key: 'state' },
    { label: 'Date Added', key: 'createdDate' },
    { label: 'Trial Period End', key: 'endDate' },
    { label: 'Students', key: 'users' },
    { label: 'Campus Crisis Resource', key: 'campusCrisisInfo' },
]

const ExportToExcelBtn: React.FC<IExportToExcelBtn> = ({ collegeList }) => {
    const tableData =
        collegeList?.map((college: any) => ({
            name: college?.name,
            state: college?.state,
            createdDate: formatISO(college?.createdDate),
            endDate: dateToString(college?.trial?.endDate, 'MM/dd/yyyy'),
            users: college?.users?.length,
            campusCrisisInfo: college?.campusCrisisInfo,
        })) || []

    return (
        <CSVLink
            data={tableData}
            headers={headers}
            filename='Colleges_table.csv'
            style={{ textDecoration: 'none' }}
        >
            <AddNewChannelBtn startIcon={<SaveAltOutlinedIcon />} className='export-to-excel-btn'>
                Export to Excel
            </AddNewChannelBtn>
        </CSVLink>
    )
}

export default ExportToExcelBtn
