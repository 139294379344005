import { Box, ListItem, ListItemText, styled, Typography } from '@mui/material'

export const ToDoListItem = styled(ListItem)`
    align-items: center;
    background-color: #fff;
    padding: 0;
    border-left: 4px solid transparent;
    transition: all 0.3s;

    &.open-menu {
        background-color: #f3f0ea;
        border-left: 4px solid #fc7960;
    }
`

export const ToDoContainer = styled(Box)`
    display: grid;
    grid-template-columns: 30px 30% calc(15% + 9px) repeat(2, 2fr) 3fr 2fr 20px;
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;

    width: 100%;
    align-items: center;
    padding: 1rem calc(1rem + 1rem + 24px) 1rem 8px;
`

export const ToDoBox = styled(Box)`
    display: flex;
    align-items: center;
    position: relative;

    &.patient-column {
        min-width: 150px;
        max-width: 300px;

        & .patient-fullname {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 8px;
        }
    }
`

export const PriorityTag = styled('img')`
    width: 22px;
    height: 22px;
    margin: 0 1rem 0 0;
`

export const ToDoText = styled(ListItemText)`
    margin-right: 8px;
    word-break: break-word;
`

export const ToDoCategory = styled(Box)`
    width: 25%;

    & Button {
        padding: 0.3rem 1rem;
        color: white;
    }
`

export const ToDoTypography = styled(Typography)`
    position: relative;
    &.not-started-todo {
        color: #769fc1;
    }

    &.in-progress-todo {
        color: #97c287;
    }

    &.completed-todo {
        color: #b871b5;
    }

    &.canceled-todo {
        color: #fc7960;
    }
`
