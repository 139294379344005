import { gql } from '@apollo/client'

export const FETCH_STORYBLOK_DATASOURCE_ENTRIES_BY_SLUG = gql`
    query StoryblokDatasourceEntries($datasourceSlug: String!) {
        storyblokDatasourceEntries(datasourceSlug: $datasourceSlug) {
            id
            name
            value
        }
    }
`
