import { format, parseISO } from 'date-fns'

const formatISO = (formatISO: string, dateFormat = 'MM/dd/yyyy'): string => {
    if (!formatISO) {
        return ''
    }

    return format(parseISO(formatISO), dateFormat)
}

export default formatISO
