import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import PrivateRoutes from '@/routes/PrivateRoutes'
import PublicRoutes from '@/routes/PublicRoutes'

import { FETCH_PROVIDER_BY_EMAIL } from '@/graphql/providers/queries'

import AuthContext from '../context/AuthContext'

import { getSessionUser } from '@/utils'

const AppRouter: React.FC = () => {
    const { currentUser, setCurrentUser } = useContext(AuthContext)
    const [isLoading, setIsloading] = useState(false)
    const [fetchProviderByEmail] = useLazyQuery(FETCH_PROVIDER_BY_EMAIL)

    const sessionUser: any = getSessionUser()

    const fetchProvider = async () => {
        try {
            if (!currentUser.email) {
                return
            }
            setIsloading(true)
            await fetchProviderByEmail({
                variables: {
                    email: currentUser.email,
                },
                onCompleted: ({ providerByEmail }) => {
                    setCurrentUser(providerByEmail)
                    sessionStorage.setItem('sessionUser', JSON.stringify(providerByEmail))
                },
            })
        } catch (error) {
            console.error(error)
        } finally {
            setIsloading(false)
        }
    }

    useEffect(() => {
        fetchProvider()
    }, [currentUser.email])

    return (
        <Router>
            {!isLoading && (
                <Routes>
                    {sessionUser?.sub ? (
                        <Route path='*' element={<PrivateRoutes />} />
                    ) : (
                        <Route path='*' element={<PublicRoutes />} />
                    )}
                </Routes>
            )}
        </Router>
    )
}

export default AppRouter
