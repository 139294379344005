import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box } from '@mui/material'

import { ChannelHeader } from '@/components'
import PatientsPreview from '@/components/Patient/PatientSidebar/PatientsPreview'
import EllipsisLoader from '@/components/Loader/EllipsisLoader'

import { useDebounce, useObserver } from '@/hooks'

import { FETCH_USERS } from '@/graphql/users/queries'

import { PatientSidebarWrapper } from './styles'

const queryLimit = 10
const maxQueryLimit = 100

const PatientSidebar: React.FC = () => {
    const [userList, setUserList] = useState<any>([])
    const [searchName, setSearchName] = useState<string>('')
    const [offset, setOffset] = useState<number>(0)
    const [usersCount, setUsersCount] = useState<number>(0)
    const lastElement = useRef<any>(null)
    const [fetchUserList, { loading }] = useLazyQuery(FETCH_USERS)

    useObserver(lastElement, offset, usersCount, loading, () => {
        setOffset((prevState: number) => prevState + 10)
    })

    const searchUserList = useCallback(
        async (search: string, offset?: number) => {
            if (searchName) {
                setOffset(0)
            }

            const { data } = await fetchUserList({
                variables: {
                    search,
                    offset,
                    limit: searchName ? maxQueryLimit : queryLimit,
                },
            })

            setUsersCount(data?.users?.count)

            if (searchName) {
                setUserList([...data?.users?.userList])
                return
            }

            if (data && data.users) {
                setUserList([...userList, ...data?.users?.userList])
            }
        },
        [searchName, offset]
    )

    const debouncedSearch = useDebounce(searchUserList, 500)

    useEffect(() => {
        if (loading) {
            return
        }

        searchUserList(searchName, offset)
    }, [offset])

    useEffect(() => {
        debouncedSearch(searchName, 0)
    }, [searchName])

    const searchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value

        if (!text) {
            setUserList([])
        }

        setSearchName(text)
    }

    const clearSearch = () => {
        setSearchName('')
        setUserList([])
    }

    return (
        <PatientSidebarWrapper>
            <div className='sendbird-channel-list__header'>
                <ChannelHeader
                    searchName={searchName}
                    searchByName={searchByName}
                    clearSearch={clearSearch}
                    isPatientsPage
                />
            </div>
            <div className='sendbird-channel-list__body'>
                <PatientsPreview patientPreviewData={userList} loading={loading} />
                {loading && Boolean(userList.length) && (
                    <Box display='flex' justifyContent='center'>
                        <EllipsisLoader />
                    </Box>
                )}
                {!loading && <Box ref={lastElement} height={20} />}
            </div>
        </PatientSidebarWrapper>
    )
}

export default PatientSidebar
