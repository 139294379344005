import React from 'react'
import { useQuery } from '@apollo/client'
import { TextField, Typography } from '@mui/material'

import CircularLoader from '@/components/Loader/CircularLoader'
import { FETCH_ROLES } from '@/graphql/roles/queries'

import { ISearchDropdown } from '@/components/UI/interfaces'

import { CustomAutocomplete } from '@/components/ToDo/ToDoForm/styles'

const RoleListDropdown: React.FC<ISearchDropdown> = ({
    label,
    name,
    value,
    isEditMode,
    handleData,
    isDisabled,
}) => {
    const { data, loading } = useQuery(FETCH_ROLES)

    const findRoleById = (id?: string) => {
        return data?.roles?.find((role: any) => role.id === id)
    }

    return (
        <>
            <Typography>{label}</Typography>
            {loading ? (
                <CircularLoader size={20} />
            ) : (
                <CustomAutocomplete
                    className='search-box'
                    value={findRoleById(value?.id) ?? null}
                    onChange={(_: any, data: any) => handleData(name, data?.id)}
                    options={data?.roles}
                    getOptionLabel={(option: any) => {
                        return `${option.name}` ?? ''
                    }}
                    renderOption={(props, option: any) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={!isEditMode || isDisabled}
                    disableClearable
                    disablePortal
                />
            )}
        </>
    )
}

export default RoleListDropdown
