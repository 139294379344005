import { useMutation } from '@apollo/client'
import { UPDATE_UNVERIFIED_COLLEGE } from '@/graphql/unverifiedColleges/mutations'
import { UNVERIFIED_COLLEGE_LIST } from '@/graphql/unverifiedColleges/queries'

const useUpdateUnverifiedCollege = () => {
    const [updateUnverifiedCollege] = useMutation(UPDATE_UNVERIFIED_COLLEGE)

    const handleUpdateUnverifiedCollege = async (
        unverifiedCollegeData: any,
        unverifiedCollegeInput: any,
        unverifiedCollegeId: string,
        offset: number,
        limit: number
    ) => {
        try {
            await updateUnverifiedCollege({
                variables: {
                    updateUnverifiedCollegeId: unverifiedCollegeId,
                    unverifiedCollegeInput,
                },
                update(proxy) {
                    const cachedUnverifiedCollegeList: any = proxy.readQuery({
                        query: UNVERIFIED_COLLEGE_LIST,
                        variables: {
                            offset,
                            limit,
                        },
                    })

                    const newCachedUnverifiedCollegeList =
                        cachedUnverifiedCollegeList?.unverifiedColleges?.unverifiedCollegeList?.map(
                            (cachedCollege: any) => {
                                if (cachedCollege.id === unverifiedCollegeData?.id) {
                                    return {
                                        ...cachedCollege,
                                        ...unverifiedCollegeData,
                                    }
                                }

                                return cachedCollege
                            }
                        )

                    proxy.writeQuery({
                        query: UNVERIFIED_COLLEGE_LIST,
                        variables: {
                            offset,
                            limit,
                        },
                        data: {
                            unverifiedColleges: {
                                count: newCachedUnverifiedCollegeList.length,
                                unverifiedCollegeList: newCachedUnverifiedCollegeList,
                            },
                        },
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    return {
        handleUpdateUnverifiedCollege,
    }
}

export default useUpdateUnverifiedCollege
