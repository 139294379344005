import { ICriteriaFilter } from '@/components/Chat/ChannelHeader/interfaces'
import {
    BLUE_ZONE,
    GREEN_ZONE,
    RED_ZONE,
    GRAY_ZONE,
    IS_JOINED,
    TODAY,
    LAST_THIRTY_DAYS,
    OPEN,
    CLOSED,
    WAITING_FOR_FOLLOW_UP_FLAG,
} from '@/core/constants'

export const criteriaForCareTeam: ICriteriaFilter[] = [
    {
        title: 'zone',
        checkboxes: [
            {
                name: RED_ZONE,
                checked: false,
                label: 'Red zone (Incoming)',
            },
            {
                name: BLUE_ZONE,
                checked: false,
                label: 'Blue zone (Ongoing)',
            },
            {
                name: GREEN_ZONE,
                checked: false,
                label: 'Green zone (Closed)',
            },
            {
                name: GRAY_ZONE,
                checked: false,
                label: 'Grey zone (New Patient)',
            },
        ],
    },
    {
        title: 'status',
        checkboxes: [
            {
                name: WAITING_FOR_FOLLOW_UP_FLAG,
                checked: false,
                label: 'Waiting for Follow Up',
            },
        ],
    },
    {
        title: 'chats',
        checkboxes: [
            {
                name: IS_JOINED,
                checked: false,
                label: "Chats I've joined",
            },
        ],
    },
    {
        title: 'date',
        checkboxes: [
            {
                name: TODAY,
                checked: false,
                label: 'Last 24 hours',
            },
            {
                name: LAST_THIRTY_DAYS,
                checked: false,
                label: 'Last 30 days',
            },
        ],
    },
]

export const criteriaFor1o1Chat: ICriteriaFilter[] = [
    {
        title: 'status',
        checkboxes: [
            {
                name: OPEN,
                checked: false,
                label: 'Open',
            },
            {
                name: CLOSED,
                checked: false,
                label: 'Closed',
            },
        ],
    },
    {
        title: 'date',
        checkboxes: [
            {
                name: TODAY,
                checked: false,
                label: 'Last 24 hours',
            },
            {
                name: LAST_THIRTY_DAYS,
                checked: false,
                label: 'Last 30 days',
            },
        ],
    },
]
export const defaultStateOfCriteria: ICriteriaFilter[] = [
    { title: '', checkboxes: [{ name: '', checked: false, label: '' }] },
]
