import { IContentTypes } from '@/components/ToDo/ToDoContent/interfaces'

export const contentTypes: IContentTypes[] = [
    {
        name: 'Article',
        content: 'Post-V1-article',
    },
    {
        name: 'Video content',
        content: 'Post-V3-video',
    },
    {
        name: 'Audio content',
        content: 'Post-V1-audio',
    },
]
