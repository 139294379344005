import React, { useState } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import EditNoteIcon from '@mui/icons-material/EditNote'

import { IThreeDotBtn } from '@/components/CollegesAndPartners/interfaces'
import { MessageMenuWrapper } from '@/components/Chat/ChatMessage/styles'

const ThreeDotBtn: React.FC<IThreeDotBtn> = ({
    openMenu,
    enableEditMode,
    handleEdit,
    item,
    college,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const archiveBtnClassName = college ? (item?.isArchived ? 'unarchive-btn' : 'archive-btn') : ''
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        openMenu()
    }

    const handleClose = () => {
        setAnchorEl(null)
        openMenu()
    }

    const openEditModal = () => {
        handleClose()
        enableEditMode()
    }

    const toggleCollegeArchiveState = () => {
        const collegeData = {
            ...item,
            isArchived: !item?.isArchived,
        }

        handleEdit && handleEdit(collegeData)
        handleClose()
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={openEditModal} className='edit-btn'>
                    <IconButton disabled>
                        <EditNoteIcon />
                    </IconButton>
                    Edit
                </MenuItem>
                {college && (
                    <MenuItem onClick={toggleCollegeArchiveState} className={archiveBtnClassName}>
                        <IconButton disableRipple>
                            <Inventory2OutlinedIcon />
                        </IconButton>
                        {item?.isArchived ? 'Unarchive' : 'Archive'}
                    </MenuItem>
                )}
            </MessageMenuWrapper>
        </>
    )
}

export default ThreeDotBtn
