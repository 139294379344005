import React from 'react'
import { IChatConversationsProps } from '@/pages/Dashboard/DashboardChatbotPage/interfaces'
import { ChatMessage } from './ChatMessage'
import { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { CircularProgressBox } from './styles'

export const ChatConversations = ({
    userId,
    conversations,
    isQuerying,
    chatConversationsContainerRef,
    token,
}: IChatConversationsProps) => {
    useEffect(() => {
        const chatConversationsContainer = chatConversationsContainerRef?.current
        if (chatConversationsContainer) {
            chatConversationsContainer.scrollTo(0, chatConversationsContainer.scrollHeight)
        }
    }, [conversations])

    return (
        <div className='w-2/3'>
            {conversations?.map((chatEntry) => (
                <ChatMessage
                    key={`chatbot-message-${chatEntry.messageId}`}
                    message={chatEntry}
                    userId={userId}
                    token={token}
                />
            ))}
            {isQuerying && (
                <CircularProgressBox>
                    <CircularProgress size={40} />
                </CircularProgressBox>
            )}
        </div>
    )
}
