import { IRequiredFields } from '@/components/ToDo/interfaces'

const requiredFields: IRequiredFields = {
    Activities: [
        'duration',
        'priority',
        'type',
        'title',
        'intakeAssigned',
        'description',
        'startingDate',
        'startingTime',
        'dueDate',
        'dueTime',
        'repeat',
    ],
    'Free Text': [
        'duration',
        'type',
        'title',
        'taskDescription',
        'description',
        'startingDate',
        'startingTime',
    ],
    Appointment: [
        'duration',
        'priority',
        'type',
        'title',
        'practitionerId',
        'visitDate',
        'visitTime',
        'description',
        'dueDate',
        'dueTime',
    ],
    Content: ['type', 'priority', 'title', 'description', 'contentType', 'searchContent'],
    'Lab/Rx Order': ['title', 'duration'],
    'Lab Result': [
        'duration',
        'priority',
        'type',
        'title',
        'startingDate',
        'labResultName',
        'labResultDate',
        'labResultExplanation',
    ],
    'After Visit Summary': [
        'duration',
        'priority',
        'type',
        'title',
        'startingDate',
        'afterVisitDate',
        'afterVisitReason',
    ],
}

export default requiredFields
