import React, { useEffect, useState } from 'react'
import { Box, IconButton, TextField, Typography } from '@mui/material'
import { DatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import classNames from 'classnames'

import { combineDateAndTime } from '@/utils'

import { IToDoDateTime } from '@/components/UI/interfaces'

import { DatePickerWrapper, MainHeader } from '@/components/ToDo/ToDoForm/styles'

const onlyLettersRegEx = /[^a-zA-Z]+/g

const ToDoDateTime: React.FC<IToDoDateTime> = ({
    handleToDoData,
    existingDate,
    existingTime,
    dateFieldName,
    timeFieldName,
    rowTitle,
    isDisabled,
    isAppointment,
    isLabResult,
    isAfterVisitSummary,
}) => {
    const [customDate, setCustomDate] = useState<Date | null>(null)
    const [time, setTime] = useState<Date | null>(null)

    const rowTitleClassName = rowTitle
        .toLowerCase()
        .replace(onlyLettersRegEx, ' ')
        .split(' ')
        .join('-')

    useEffect(() => {
        if (existingDate) {
            const dateInMilliseconds = new Date(Number(existingDate))
            setCustomDate(dateInMilliseconds)
        } else {
            setCustomDate(null)
        }

        if (existingTime) {
            const timeInMilliseconds = new Date(Number(existingTime))
            setTime(timeInMilliseconds)
        } else {
            setTime(null)
        }
    }, [existingDate, existingTime])

    const handleDateTime = (date: Date | null, name?: string) => {
        const isTime = name === 'time'

        const newDate = isTime ? customDate || new Date() : date
        const newTime = isTime ? date : time

        const clearedDateTime = combineDateAndTime(newDate, newTime)

        setTime(clearedDateTime)
        setCustomDate(clearedDateTime)

        const dateInMilliseconds = clearedDateTime?.getTime().toString()

        handleToDoData(dateFieldName, dateInMilliseconds)

        if (timeFieldName) {
            handleToDoData(timeFieldName, dateInMilliseconds)
        }

        if (isAppointment) {
            handleToDoData('dueDate', dateInMilliseconds)
            handleToDoData('dueTime', dateInMilliseconds)
        }
    }

    const clearDateTime = (field: string) => {
        const isTime = field === 'time'

        if (isTime && timeFieldName) {
            setTime(null)
            handleToDoData(timeFieldName, '')

            if (isAppointment) {
                handleToDoData('dueTime', '')
            }
            return
        }

        if (isAppointment) {
            handleToDoData('dueDate', '')
        }

        setCustomDate(null)
        handleToDoData(dateFieldName, '')
    }

    return (
        <MainHeader>
            <Typography
                className={classNames('settings-field required-field', {
                    [rowTitleClassName]: true,
                })}
            >
                {rowTitle}
            </Typography>
            <Box
                className={classNames('wrapper_date-picker', {
                    'visit_date-picker': isAppointment,
                    'labresult_date-picker': isLabResult,
                    'aftervisitsummary_date-picker': isAfterVisitSummary,
                })}
            >
                <DatePickerWrapper>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={customDate}
                            onChange={(newDueDate) => handleDateTime(newDueDate)}
                            disabled={isDisabled}
                            className='settings-datepicker'
                            renderInput={({ onKeyDown, ...params }) => (
                                <TextField
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                        return
                                    }}
                                    {...params}
                                />
                            )}
                        />
                        <IconButton
                            disabled={!customDate || isDisabled}
                            onClick={() => clearDateTime(dateFieldName)}
                        >
                            <CloseOutlinedIcon />
                        </IconButton>
                    </LocalizationProvider>
                </DatePickerWrapper>
                {!(timeFieldName === '') ? (
                    <DatePickerWrapper>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopTimePicker
                                value={time}
                                onChange={(newTime) => handleDateTime(newTime, 'time')}
                                disabled={isDisabled}
                                renderInput={({ onKeyDown, ...params }) => (
                                    <TextField
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                            return
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                            <IconButton
                                disabled={!time || isDisabled}
                                onClick={() => clearDateTime('time')}
                            >
                                <CloseOutlinedIcon />
                            </IconButton>
                        </LocalizationProvider>
                    </DatePickerWrapper>
                ) : null}
            </Box>
        </MainHeader>
    )
}

export default ToDoDateTime
