import React, { ReactElement } from 'react'

import PageHeader from '@/components/MessageSettings/components/PageHeader'
import { PageWrapper } from '@/components/MessageSettings/styles'
import { PageSubHeader } from '@/components/MessageSettings/components/PageSubHeader'

const DashboardMessageSettingsPage: React.FC = (): ReactElement => {
    return (
        <PageWrapper>
            <PageHeader />
            <PageSubHeader />
        </PageWrapper>
    )
}

export default DashboardMessageSettingsPage
