import React, { useState } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import CloseIcon from '@mui/icons-material/Close'

import editMessageSvg from '@/assets/images/edit-message.svg'

import { IPatientMenuBtn } from '@/components/Patient/PatientTabsPanel/interfaces'

import { MessageMenuWrapper } from '@/components/Chat/ChatMessage/styles'

const PatientMenuBtn: React.FC<IPatientMenuBtn> = ({
    isEditMode,
    isCreateMode,
    enableEditMode,
    editPatientData,
    isToDoEdit,
    openMenuItems,
    cancelEditingMode,
    isModal,
    completeToDo,
    isCompleted,
    isAppointment,
    enableViewMode,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)

        if (openMenuItems) {
            openMenuItems()
        }
    }

    const handleClose = () => {
        setAnchorEl(null)

        if (openMenuItems) {
            openMenuItems()
        }
    }

    const editingModeHandler = () => {
        handleClose()

        if (enableEditMode) {
            enableEditMode()
        }
    }

    const viewModeHandler = () => {
        handleClose()

        if (enableViewMode) {
            enableViewMode()
        }
    }

    const completeToDoHandler = (status: string) => {
        if (completeToDo) {
            completeToDo(status)
            setAnchorEl(null)
        }

        if (openMenuItems) {
            openMenuItems()
        }
    }

    return (
        <>
            <IconButton disabled={isCreateMode} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {!isModal && !isCompleted && (
                    <MenuItem onClick={editingModeHandler} className='edit-btn'>
                        <IconButton disabled>
                            <img src={editMessageSvg} alt='Edit' />
                        </IconButton>
                        Edit
                    </MenuItem>
                )}

                {isToDoEdit && !isCompleted && (
                    <MenuItem
                        onClick={() => completeToDoHandler('Completed')}
                        className='complete-btn'
                    >
                        <IconButton disableRipple className='complete-btn'>
                            <CheckCircleOutlineOutlinedIcon />
                        </IconButton>
                        Complete
                    </MenuItem>
                )}

                {isAppointment && !isCompleted && (
                    <MenuItem
                        onClick={() => completeToDoHandler('Canceled')}
                        className='cancel-btn'
                    >
                        <IconButton disableRipple className='complete-btn'>
                            <CloseIcon />
                        </IconButton>
                        Cancel
                    </MenuItem>
                )}

                {(isToDoEdit || isAppointment) && isCompleted && (
                    <MenuItem className='edit-btn' onClick={viewModeHandler}>
                        <IconButton disableRipple>
                            <VisibilityOutlinedIcon />
                        </IconButton>
                        View
                    </MenuItem>
                )}
            </MessageMenuWrapper>
        </>
    )
}

export default PatientMenuBtn
