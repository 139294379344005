import { useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { FETCH_SINGLE_RESOURCE_BY_TYPE } from '@/graphql/resources/queries'
import { ResourceTypesEnum } from '@/constants/resourceConstants'

const useSingleResourceByType = (
    isPatientB2b: boolean,
    patientPartner: string,
    patientSubPartner: string,
    setPartnerSlug: (prop: any) => void
) => {
    const [fetchPartnerSubPartnerSingleResource] = useLazyQuery(FETCH_SINGLE_RESOURCE_BY_TYPE)

    const fetchSingleResourceData = useCallback(async () => {
        if (patientSubPartner) {
            const resource = await fetchPartnerSubPartnerSingleResource({
                variables: {
                    name: patientSubPartner,
                    partnerType: ResourceTypesEnum.B2B_SUB_PARTNER,
                },
            })
            setPartnerSlug(resource?.data?.partnerSubPartnerSingleResource?.slug)
            return
        } else {
            const resource = await fetchPartnerSubPartnerSingleResource({
                variables: {
                    name: patientPartner,
                    partnerType: ResourceTypesEnum.B2B_PARTNER,
                },
            })
            setPartnerSlug(resource?.data?.partnerSubPartnerSingleResource?.slug)
            return
        }
    }, [isPatientB2b])

    return {
        fetchSingleResourceData,
    }
}

export default useSingleResourceByType
