import React, { useRef, useState } from 'react'
import Papa from 'papaparse'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'

import { IModalBulkColleges } from '@/components/CollegesAndPartners/interfaces'

import { ModalWrapper } from '../../Chat/CreateChatModal/styles'
import { DialogTitleWrapper, DialogWrapper } from '@/components/Patient/PatientTabsPanel/styles'
import { EditButtonConfirm } from '@/components/ToDo/ModalToDo/styles'
import { FormSection, MainHeader } from '@/components/ToDo/ToDoForm/styles'

import csvExample from '@/assets/images/csv-example.png'

const ModalBulkColleges: React.FC<IModalBulkColleges> = ({
    open,
    handleClose,
    handleBulkCreateCollege,
}) => {
    const attachBtnRef = useRef<HTMLInputElement | null>(null)
    const [uploadedFileName, setUploadedFileName] = useState<any>(null)
    const [uploadedColleges, setUploadedColleges] = useState<any>(null)

    const handleUpload = (event: any) => {
        setUploadedFileName(event.target.files[0].name)

        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {
                const colleges: any = []

                await results?.data?.shift()

                await results?.data?.map((result: any) => {
                    const college = {
                        name: result['name'],
                        state: result['state'],
                        address: result['address'],
                        city: result['city'],
                        zipCode: result['zipCode'],
                        trial: {
                            id: 1,
                        },
                        isArchived: false,
                        campusCrisisInfo: result['campusCrisisInfo'],
                    }
                    colleges.push(college)
                })

                await setUploadedColleges(colleges)
            },
        })
    }

    const handleUploadSubmit = (colleges: any) => {
        handleBulkCreateCollege(colleges)
        handleClose()
    }

    const clickOnAttachBtn = () => {
        if (attachBtnRef.current) {
            attachBtnRef.current.click()
        }
    }

    return (
        <ModalWrapper open={open} onClose={handleClose}>
            <DialogWrapper className='dialog-wrapper college-page'>
                <DialogTitleWrapper>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        Add Colleges - Bulk Upload
                    </Box>
                </DialogTitleWrapper>
                <FormSection className='content'>
                    <MainHeader>
                        <Typography
                            className='college-name-typography w-130'
                            style={{ width: '100%', marginBottom: -14 }}
                        >
                            <p style={{ fontWeight: 600, margin: 0 }}>Steps to upload:</p>
                            <ol>
                                <li>
                                    Create Google Sheets spreadsheet based on example provided
                                    below. Take care to:
                                </li>
                                <ul>
                                    <li>
                                        <b>Not</b> change the header row.
                                    </li>
                                    <li>
                                        Ensure each row entry contains <b>valid</b> responses.
                                    </li>
                                    <li>
                                        Ensure each row name is <b>unique</b>.
                                    </li>
                                </ul>
                                <li>Export Google Sheets spreadsheet as .csv file.</li>
                                <li>Upload .csv file here.</li>
                            </ol>
                            <p style={{ fontWeight: 600, marginTop: 0 }}>Example:</p>
                            <img src={csvExample} alt='.csv upload example' width='80%' />
                        </Typography>
                    </MainHeader>
                </FormSection>
                <FormSection className='content'>
                    <MainHeader>
                        <label htmlFor='attach-csv' style={{ marginBottom: -14 }}>
                            <IconButton
                                onClick={clickOnAttachBtn}
                                className='free-text_upload-file'
                            >
                                <FileUploadOutlinedIcon />
                                <Typography marginLeft='11px' marginRight='5px'>
                                    Attach File (.csv)
                                </Typography>
                            </IconButton>
                        </label>
                        <input
                            id='attach-csv'
                            ref={attachBtnRef}
                            type='file'
                            name='file'
                            accept='.csv'
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                        />
                        <div style={{ fontSize: 14, marginTop: 16, marginLeft: 16 }}>
                            {uploadedFileName}
                        </div>
                    </MainHeader>
                </FormSection>
                <Box display='flex' justifyContent='flex-end'>
                    <EditButtonConfirm
                        onClick={() => handleUploadSubmit(uploadedColleges)}
                        disabled={!uploadedColleges}
                        className='edit-college-btn'
                        variant='contained'
                        color='info'
                    >
                        Save
                    </EditButtonConfirm>
                </Box>
            </DialogWrapper>
        </ModalWrapper>
    )
}

export default ModalBulkColleges
