import React, { ChangeEvent, useRef } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import FileLoader from '@/components/Patient/PatientTabsPanel/PatientMedicalHistory/FileLoader'
import { useUploadFile } from '@/hooks'

import { IAccordionFiles } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'

import {
    AccordionFilesWrapper,
    FileLoaderWrapper,
} from '@/components/Patient/PatientTabsPanel/styles'
import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'

const AccordionFiles: React.FC<IAccordionFiles> = ({ files, userId, todoId }) => {
    const uploadFile = useUploadFile(userId, todoId)
    const inputRef = useRef<HTMLElement | any>(null)

    const selectFile = () => {
        inputRef.current.click()
    }

    const uploadNewFile = async (e: ChangeEvent<HTMLInputElement>) => {
        await uploadFile(e)
    }

    return (
        <AccordionFilesWrapper>
            <Accordion className='uploaded-files' disableGutters>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                        Uploaded files <span className='files-count'>({files?.length})</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className='upload-btn'>
                    <input
                        type='file'
                        className='file-input'
                        ref={inputRef}
                        onChange={uploadNewFile}
                    />
                    <AddNewChannelBtn
                        onClick={selectFile}
                        startIcon={<AddCircleOutlineIcon />}
                        variant='text'
                    >
                        Upload New
                    </AddNewChannelBtn>
                </AccordionDetails>
                {files?.map((file) => (
                    <FileLoaderWrapper key={file?.id}>
                        <FileLoader file={file} />
                    </FileLoaderWrapper>
                ))}
            </Accordion>
        </AccordionFilesWrapper>
    )
}

export default AccordionFiles
