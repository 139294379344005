import React, { FunctionComponent, ReactElement, useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import AuthContext from '@/context/AuthContext'

import logo from '@/assets/images/logo-name-dark.svg'
import wave from '@/assets/images/3Wave_Horizontal_PAG.svg'

import { Img, ImgBottom, LoginButton, LoginContainer, PageContainer } from './styles'

const Index: FunctionComponent<any> = (): ReactElement => {
    const { signIn } = useContext(AuthContext)

    const sessionUser: any = sessionStorage.getItem('sessionUser')
    let parsedUser = {}

    if (JSON.parse(sessionUser)) {
        parsedUser = JSON.parse(sessionUser)
    }

    const COGNITO_LOGIN_URL = window.env.COGNITO_LOGIN_URL
    const queryParams = new URLSearchParams(useLocation().search)
    const authCode = queryParams.get('code')

    // Only sign in if current user is not set and we have an auth code
    if (Object.keys(parsedUser).length === 0 && authCode) {
        signIn(authCode)
    }

    if (Object.keys(parsedUser).length !== 0) {
        return <Navigate to='/dashboard' />
    }

    const onLoginClick = (event: any) => {
        event.preventDefault()

        window.location.replace(COGNITO_LOGIN_URL)
    }

    return (
        <PageContainer>
            <LoginContainer>
                <Img alt='logo' src={logo} />
                <LoginButton
                    variant='contained'
                    color='orange'
                    size='medium'
                    onClick={onLoginClick}
                    data-testid='login-button'
                >
                    Provider Login
                </LoginButton>
                <ImgBottom alt='logo' src={wave} />
            </LoginContainer>
        </PageContainer>
    )
}

export default Index
