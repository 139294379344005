import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FETCH_PATIENT_DATA_BY_USER_ID } from '@/graphql/users/queries'
import { FETCH_INTAKE_BY_USER_ID } from '@/graphql/intakes/queries'
import { UNVERIFIED_COLLEGE_BY_USER_ID } from '@/graphql/unverifiedColleges/queries'

import CircularLoader from '@/components/Loader/CircularLoader'
import PatientDataBlock from './PatientDataBlock'
import PatientHIEConsent from '@/components/Patient/PatientTabsPanel/PatientPersonalInfo/PatientHIEConsent'
import PatientIntake from './PatientIntake'

import {
    mockPersonalInfo,
    mockPersonalInfoAddress,
    mockPersonalInfoEmergencyContact,
} from '@/components/Patient/PatientTabsPanel/PatientPersonalInfo/mockPersonalInfo'

import { IPatientPersonalInfo } from '@/components/Patient/PatientTabsPanel/interfaces'
import { useFormik } from 'formik'
import { IPatientData } from '../../PatientContent/interfaces'
import { UPDATE_PATIENT_ENTITY_DATA } from '@/graphql/patients/mutations'
import { UPDATE_USER } from '@/graphql/users/mutations'
import {
    BasicInfoSchema,
    AddressSchema,
    EmergencyContactSchema,
} from '@/validations/patientValidation'
import { format } from 'date-fns'
import genderAbbreviations from './genderAbbreviations'
import { PATIENT_INFO_TAB_KEYS } from '@/core/constants'

const SCHEMA_KEYS: any = {
    'basic info': BasicInfoSchema,
    address: AddressSchema,
    'emergency contact': EmergencyContactSchema,
}

const PatientPersonalInfo: React.FC<IPatientPersonalInfo> = ({ userId }) => {
    const { data, loading: patientLoading } = useQuery(FETCH_PATIENT_DATA_BY_USER_ID, {
        variables: { userId: userId },
    })

    const { data: intakeData, loading: intakeLoading } = useQuery(FETCH_INTAKE_BY_USER_ID, {
        variables: { userId: userId },
    })

    const { data: unverifiedCollegeData, loading: unverifiedCollegeLoading } = useQuery(
        UNVERIFIED_COLLEGE_BY_USER_ID,
        {
            variables: { userId: userId },
        }
    )

    const patientDataById = data?.userById?.patient

    const sanitizeValueForInput = (value: string | null) => {
        return value ?? ''
    }

    const initialState = {
        email: sanitizeValueForInput(patientDataById?.email),
        phone: sanitizeValueForInput(patientDataById?.phone || patientDataById?.contact?.phone),
        firstName: Array.isArray(patientDataById?.firstName)
            ? patientDataById?.firstName.join(' ')
            : sanitizeValueForInput(patientDataById?.firstName),
        lastName: sanitizeValueForInput(patientDataById?.lastName),
        preferredName: Array.isArray(patientDataById?.preferredName)
            ? patientDataById?.preferredName.join(' ')
            : sanitizeValueForInput(patientDataById?.preferredName),
        gender: sanitizeValueForInput(patientDataById?.gender),
        preferredGender: sanitizeValueForInput(data?.userById?.preferredGender),
        pronouns: sanitizeValueForInput(data?.userById?.pronouns),
        birthDate: patientDataById?.birthDate ?? null,
        line: Array.isArray(patientDataById?.homeAddress?.line)
            ? patientDataById?.homeAddress?.line.join(' ')
            : sanitizeValueForInput(patientDataById?.homeAddress?.line),
        city: sanitizeValueForInput(patientDataById?.homeAddress?.city),
        state: sanitizeValueForInput(patientDataById?.homeAddress?.state),
        postalCode: sanitizeValueForInput(patientDataById?.homeAddress?.postalCode),
        emergencyContactName: sanitizeValueForInput(patientDataById?.contact?.name),
        emergencyContactRelationship: sanitizeValueForInput(patientDataById?.contact?.relationship),
        emergencyContactPhone: sanitizeValueForInput(
            patientDataById?.contact?.phone || patientDataById?.phone
        ),
        college: sanitizeValueForInput(data?.userById?.college),
    }

    const [patientData, setPatientData] = useState<IPatientData>(() => initialState)
    const [tabBeingEdited, setTabBeingEdited] = useState<string>('')
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [updatePatientEntity] = useMutation(UPDATE_PATIENT_ENTITY_DATA)
    const [updateUser] = useMutation(UPDATE_USER)

    const isStringDate = typeof patientData.birthDate === 'string'

    // NOTE: When editing patients in Canvas, Canvas requires email, first name, and last name to be sent in the request
    const formik = useFormik({
        initialValues: patientData,
        validationSchema: tabBeingEdited ? SCHEMA_KEYS[tabBeingEdited] : '',
        onSubmit: async () => {
            try {
                setSubmitting(true)

                const patientInput = {
                    ...patientData,
                    birthDate: isStringDate
                        ? patientData.birthDate
                        : format(patientData.birthDate, 'yyyy-MM-dd'),
                    gender: genderAbbreviations[patientData.gender],
                    college: patientData.college.name,
                    preferredGender: patientData.preferredGender,
                    pronouns: patientData.pronouns,
                    state: patientData.state.toUpperCase(),
                }

                const homeAddressInput = {
                    line: patientData.line,
                    city: patientData.city,
                    state: patientData.state.toUpperCase(),
                    postalCode: patientData.postalCode,
                }

                const contactInput = {
                    name: patientData.emergencyContactName,
                    relationship: patientData.emergencyContactRelationship,
                    phone: patientData.emergencyContactPhone,
                }

                const userInput = {
                    firstName: patientData.firstName,
                    lastName: patientData.lastName,
                    birthDate: patientData.birthDate,
                    preferredGender: patientData.preferredGender,
                    state: patientData.state.toUpperCase(),
                    pronouns: patientData.pronouns,
                }

                delete patientInput.college
                delete patientInput.preferredGender
                delete patientInput.pronouns

                await updatePatientEntity({
                    variables: {
                        updatePatientId: patientDataById?.id,
                        patientInput,
                    },
                })

                await updateUser({
                    variables: {
                        updateUserId: userId,
                        userInput: {
                            ...userInput,
                            college: {
                                id: patientData.college.id,
                            },
                        },
                    },
                    update(proxy) {
                        const cachedUserData: any = proxy.readQuery({
                            query: FETCH_PATIENT_DATA_BY_USER_ID,
                            variables: {
                                userId: userId,
                            },
                        })

                        proxy.writeQuery({
                            query: FETCH_PATIENT_DATA_BY_USER_ID,
                            variables: {
                                userId: userId,
                            },
                            data: {
                                userById: {
                                    ...cachedUserData?.userById,
                                    ...patientData,
                                    patient: {
                                        ...cachedUserData?.userById?.patient,
                                        ...patientData,
                                        contact: contactInput,
                                        homeAddress: homeAddressInput,
                                    },
                                    college: patientData.college,
                                },
                            },
                        })
                    },
                })

                setSubmitting(false)
                setTabBeingEdited('')
            } catch (e) {
                setSubmitting(false)
                console.error(e)
            }
        },
    })

    useEffect(() => {
        setPatientData(initialState)
    }, [data, tabBeingEdited])

    if (patientLoading || intakeLoading || unverifiedCollegeLoading) {
        return <CircularLoader size={60} />
    }

    return (
        <>
            <PatientDataBlock
                templateData={mockPersonalInfo}
                data={patientData}
                initialState={initialState}
                title='Basic Info'
                id={PATIENT_INFO_TAB_KEYS.BASIC_INFO}
                setPatientData={setPatientData}
                enableEditModeForTab={setTabBeingEdited}
                isEditMode={tabBeingEdited === PATIENT_INFO_TAB_KEYS.BASIC_INFO}
                isDisabled={
                    tabBeingEdited !== PATIENT_INFO_TAB_KEYS.BASIC_INFO && tabBeingEdited !== ''
                }
                formik={formik}
                submitting={submitting}
            />
            <PatientDataBlock
                templateData={mockPersonalInfoAddress}
                data={patientData}
                initialState={initialState}
                unverifiedCollege={unverifiedCollegeData?.unverifiedCollegeByUserId?.name}
                title='Address'
                id={PATIENT_INFO_TAB_KEYS.ADDRESS}
                setPatientData={setPatientData}
                enableEditModeForTab={setTabBeingEdited}
                isEditMode={tabBeingEdited === PATIENT_INFO_TAB_KEYS.ADDRESS}
                isDisabled={
                    tabBeingEdited !== PATIENT_INFO_TAB_KEYS.ADDRESS && tabBeingEdited !== ''
                }
                formik={formik}
                submitting={submitting}
            />
            <PatientDataBlock
                templateData={mockPersonalInfoEmergencyContact}
                data={patientData}
                initialState={initialState}
                title='Emergency Contact'
                id={PATIENT_INFO_TAB_KEYS.EMERGENCY_CONTACT}
                setPatientData={setPatientData}
                enableEditModeForTab={setTabBeingEdited}
                isEditMode={tabBeingEdited === PATIENT_INFO_TAB_KEYS.EMERGENCY_CONTACT}
                isDisabled={
                    tabBeingEdited !== PATIENT_INFO_TAB_KEYS.EMERGENCY_CONTACT &&
                    tabBeingEdited !== ''
                }
                formik={formik}
                submitting={submitting}
            />
            <PatientHIEConsent
                isConsentObtained={data?.userById?.isConsentObtained}
                dateOfConsentObtained={data?.userById?.dateOfConsentObtained}
                title='HIE consent'
            />
            <PatientIntake intakeData={intakeData?.intakeByUserId} title='Intake' />
        </>
    )
}

export default PatientPersonalInfo
