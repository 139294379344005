import { styled, Card } from '@mui/material'
import H5AudioPlayer from 'react-h5-audio-player'

import AudioSvg from '@/assets/images/audio-preview.svg'

export const PreviewContent = styled(Card)`
    display: flex;
    padding: 16px;
    margin-top: 8px;
    margin-bottom: 16px;
    box-shadow: 0 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
        0px 1px 3px rgba(64, 72, 100, 0.12);

    & .article-bg {
        width: 145px;
        height: 96px;
        background-color: #dfecdd;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        flex: initial;

        & .article-heart {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & .audio-img {
            width: 55px;
            height: 55px;
        }
    }

    &.MuiPaper-root {
        max-width: 100%;
        height: 128px;

        img.MuiCardMedia-root,
        video,
        iframe {
            min-width: 145px;
            max-width: 145px;
            height: 96px;
            border-radius: 6px;
        }

        .MuiCardMedia-root.MuiCardMedia-media.MuiCardMedia-img {
            width: initial;
        }

        .MuiCardContent-root {
            margin-left: 16px;
            padding: 0;
            overflow: hidden;
        }

        .content-title {
            font-weight: 700;
            font-size: 16px;
        }

        .content-author {
            font-size: 14px;
            opacity: 0.6;
            margin: 4px 0 6px;
        }

        .content-description,
        .content-intro {
            max-width: 430px;
            text-overflow: ellipsis;

            p {
                margin: 0;
            }
        }
    }
`

export const AudioPlayerWrapper = styled(H5AudioPlayer)(() => ({
    boxShadow: 'none',
    width: '143px',
    borderRadius: '6px',
    padding: '0 9px',

    background: `center no-repeat #DFECDD url(${AudioSvg})`,
    backgroundSize: '28px',

    '& .rhap_repeat-button, .rhap_volume-controls': {
        display: 'none',
    },

    '& .rhap_current-time, .rhap_total-time': {
        fontSize: '8px',
        color: '#2b3073',
        letterSpacing: '0.5px',
    },

    '& .rhap_controls-section': {
        display: 'flex',
        height: '100%',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    '& .rhap_progress-bar': {
        height: '2px',
        borderRadius: '1px',
    },

    '& .rhap_progress-filled': {
        background: '#2b3073',
    },

    '& .rhap_download-progress': {
        background: '#a0aeba',
    },

    '& .rhap_additional-controls': {
        display: 'none',
    },

    '& .rhap_button-clear.rhap_main-controls-button': {
        fontSize: '25px',
        height: '100%',

        '& path': {
            fill: '#2b3073',
        },
    },

    '& .rhap_progress-indicator': {
        width: '6px',
        height: '6px',
        background: '#2b3073',
        top: '-2px',
        marginLeft: 0,
        transform: 'translateX(-50%)',
    },
}))
