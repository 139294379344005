import React from 'react'
import { Avatar, Box, CardContent, Stack } from '@mui/material'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'

import { cropName } from '@/utils'

import { IPatientToDoLabResult } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'
import {
    ChipIcon,
    DescriptionBlock,
    PatientToDoChip,
    TitleBlock,
} from '@/components/Patient/PatientTabsPanel/styles'

const PatientToDoLabResult: React.FC<IPatientToDoLabResult> = ({
    priorityClass,
    priorityIcon,
    duration,
    title,
    description,
}) => {
    return (
        <CardContent>
            <Stack direction='row'>
                <PatientToDoChip className={priorityClass} label={<Avatar src={priorityIcon} />} />
                {duration && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <TimerOutlinedIcon />
                                </ChipIcon>
                                {duration} min
                            </Box>
                        }
                    />
                )}
            </Stack>
            {title && <TitleBlock>{cropName(title, 20)}</TitleBlock>}
            {description && <DescriptionBlock>{cropName(description, 65)}</DescriptionBlock>}
        </CardContent>
    )
}

export default PatientToDoLabResult
