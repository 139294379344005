import React from 'react'
import { FormControl, MenuItem, Select } from '@mui/material';
import { DropdownContainer } from '../../styles';
import { IDropdown } from '../../interfaces';

export const Dropdown: React.FC<IDropdown> = ({
    selectedAdminMessage,
    handleSelectOption,
    adminMessageOptions,
}) => {
    return (
        <DropdownContainer>
            <FormControl fullWidth>
                <Select
                    id='admin-message-dropdown'
                    displayEmpty
                    renderValue={(selected) => {
                        if (!selected) {
                            return <div>Select message</div>;
                        }
                        return selected.name;
                    }}
                    value={selectedAdminMessage}
                    onChange={(event) => handleSelectOption(event.target.value as any)}
                >
                    {adminMessageOptions.map((entry, index) => {
                        return <MenuItem key={index} value={entry as any}>{entry.name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </DropdownContainer>
    );
}
