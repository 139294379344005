import { gql } from '@apollo/client'

export const UPLOAD_FILE_WITH_SIGNED_URL = gql`
    mutation UploadFileWithSignedUrl($fileInput: FileInputSignedUrl) {
        uploadFileWithSignedUrl(fileInput: $fileInput) {
            url
            file {
                id
            }
        }
    }
`

export const UPDATE_FILE = gql`
    mutation UpdateFile($updateFileId: ID!, $fileInput: UpdateFileInput) {
        updateFile(id: $updateFileId, fileInput: $fileInput) {
            id
            name
            displayName
        }
    }
`
