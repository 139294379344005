import React from 'react'
import { patientTabComponents, tabsPanelArray } from '../PatientTabsPanel/tabsPanelArray'
import { IPatientContentBody } from '@/components/Patient/PatientContent/interfaces'
import TabsMenu from '@/components/UI/TabsMenu'

const PatientContentBody: React.FC<IPatientContentBody> = ({ email, userId }) => {
    return (
        <TabsMenu
            menuItems={tabsPanelArray}
            menuItemComponents={patientTabComponents}
            email={email}
            userId={userId}
        />
    )
}

export default PatientContentBody
