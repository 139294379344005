import { Box, styled } from '@mui/material'

export const MessageInputWrapper = styled(Box)`
    padding: 10px 32px;
    box-shadow: 0px -1px 0px #c8c8c8, 0px -12px 24px rgba(65, 55, 37, 0.05);
    background-color: white;

    &.message-input-edit {
        padding: 16px 32px 24px;

        & .MuiInputBase-multiline {
            margin-left: 14px;
        }
    }

    .sender-msg {
        color: #401f52;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: 2px solid #401f52;
    }

    & .MuiPaper-root {
        display: flex;
        flex-direction: column;
        border: none;
        box-shadow: none;
        background: #f8f6f3;
        border-radius: 4px;
        padding: 14px 10px;
        position: relative;
        margin-top: 12px;
    }

    & .MuiInputBase-multiline {
        font-weight: 400;
        font-size: 16px;
        color: #7c7974;
        flex: 1;
        line-height: 150%;
        margin-bottom: 10px;
    }

    & .wrapper-btn--attach {
        & button {
            padding: 0;
        }
    }

    & .wrapper-action_btn {
        display: flex;
        justify-content: space-between;
    }

    & .wrapper-btn--send,
    & .wrapper-btn--edit {
        .MuiIconButton-root {
            background-color: #401f52;
            border-radius: 50%;
            padding: 5px;
            color: #ffffff;
        }
    }
`

export const EditAlertWrapper = styled(Box)`
    display: flex;
    color: #401f52;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    & button {
        color: #401f52;
    }

    &.reply-msg {
        margin-bottom: 0;

        .alert-text {
            svg {
                margin-right: 15px;
            }
        }
    }

    .alert-text {
        display: flex;
        align-items: center;

        img {
            margin-right: 15px;
        }
    }
`
