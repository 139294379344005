export interface IProviderData {
    doctor_id: string;
    firstName: string;
    role: string;
}

const filterJoinedProviders = (
    currentChannelData: any,
    providerData: IProviderData,
    isJoinedProvider: boolean
) => {
    let members = []

    if (isJoinedProvider) {
        members = currentChannelData?.members?.filter((member: IProviderData) => {
            return member?.doctor_id !== providerData?.doctor_id
        })
    } else {
        members = currentChannelData?.members
            ? [...currentChannelData?.members, providerData]
            : [providerData]
    }

    return members
}

export default filterJoinedProviders
