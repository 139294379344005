import React from 'react'
import { Box, Typography } from '@mui/material'
import { ChannelSearchBtn } from '@/components'

import { IChannelHeader } from './interfaces'
import ChannelFilterBtn from './ChannelFilterBtn'
import { ChannelTabsWrapper } from './styles'

const ChannelHeader: React.FC<IChannelHeader> = ({
    isPatientsPage,
    searchName,
    searchByName,
    filterCheckboxes,
    filterCriteria,
    clearSearch,
    handleSetFilter,
    clearFilter,
    handleResetFilterState,
}) => {
    const allowShowFilter =
        filterCriteria &&
        handleSetFilter &&
        clearFilter &&
        filterCheckboxes &&
        handleResetFilterState
    return (
        <>
            <Box
                sx={{
                    display: 'inline-flex',
                    width: '100%',
                    alignItems: 'center',
                }}
            >
                <ChannelSearchBtn
                    searchName={searchName}
                    searchByName={searchByName}
                    clearSearch={clearSearch}
                />
                {allowShowFilter && (
                    <ChannelFilterBtn
                        filterCheckboxes={filterCheckboxes}
                        filterCriteria={filterCriteria}
                        handleSetFilter={handleSetFilter}
                        clearFilter={clearFilter}
                        handleResetFilterState={handleResetFilterState}
                    />
                )}
            </Box>
            <ChannelTabsWrapper>
                {isPatientsPage && <Typography>Patients list</Typography>}
            </ChannelTabsWrapper>
        </>
    )
}

export default React.memo(ChannelHeader)
