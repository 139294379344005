import {
    Autocomplete,
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    MenuItem,
    styled,
    TextField,
    Typography,
} from '@mui/material'

export const FormSection = styled(Box)`
    display: flex;
    flex-direction: column;

    padding: 1rem 1rem 0 1rem;
    margin-bottom: 1rem;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 8px 10px -9px #9e9c93;

    & .MuiTypography-root.section-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 17px;
    }

    & .w-130 {
        width: 130px;
    }

    .MuiTypography-root:not(.current-plan) {
        font-size: 14px;
    }

    &.main.lab-rx-order {
        & .duration-typography {
            &::after {
                display: inline-flex;
            }
        }
    }

    &.content.lab-rx-order {
        & .todo-inputs {
            margin-top: 10px;
        }

        & .content-limit {
            top: 15px;
        }
    }

    & .content-body {
        & .w-130 {
            width: 130px;
        }

        .MuiTypography-root {
            word-break: keep-all;
        }

        .content-limit {
            color: rgba(43, 48, 115, 0.6);
        }

        .MuiChip-root {
            background-color: #edebe5;
            color: #2b3073;
        }
    }

    & .wrapper_date-picker.visit_date-picker {
        justify-content: flex-start;
    }

    &.settings,
    &.content .visit_date-picker,
    &.content .labresult_date-picker,
    &.content .aftervisitsummary_date-picker {
        justify-content: flex-start;
        margin-right: 0;
        flex: 1;

        & .MuiFormControl-root.MuiTextField-root {
            flex: 1;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
    }

    &.content .content-body {
        .MuiOutlinedInput-root {
            // padding: 10px 90px 10px 12px;
        }

        & .reason-for-visit .MuiOutlinedInput-root {
            width: 100%;
        }
    }

    &.content {
        & .trial-date .MuiBox-root {
            margin-bottom: 0;
        }
    }

    //& .MuiBox-root {
    //    margin-bottom: 15px;
    //    flex: 1;
    //}

    & .settings-field {
        width: 200px;
        font-size: 14px;
        padding-left: 42px;

        &.visit-date-time,
        &.were-received-,
        &.visit-date- {
            padding-left: 0;
            width: 130px;
        }
    }

    & .required-field {
        &::after {
            content: '*';
            display: none;
        }
    }

    & .patient-typography,
    .type-typography,
    .priority-typography,
    .title-typography {
        &::after {
            display: inline-flex;
        }
    }

    &.free-text {
        .duration-typography,
        .title-typography,
        .text-typography,
        .starting-date {
            &::after {
                display: inline-flex;
            }
        }
    }

    &.activities {
        .duration-typography,
        .priority-typography,
        .title-typography,
        .activity-typography,
        .starting-date,
        .due-date,
        .todo-repeat {
            &::after {
                display: inline-flex;
            }
        }
    }

    &.content {
        .priority-typography,
        .title-typography,
        .content-type-typography,
        .search-content-typography,
        .college-name-typography,
        .state-typography {
            &::after {
                display: inline-flex;
            }
        }
    }

    &.appointment {
        .duration-typography,
        .priority-typography,
        .doctor-typography,
        .title-typography,
        .visit-date-time,
        .due-date {
            &::after {
                display: inline-flex;
            }
        }
    }
`

export const MainHeader = styled(Box)`
    display: flex;
    position: relative;
    margin-bottom: 12px;
    align-items: center;

    &.mt-0 {
        margin-top: 0;
    }

    & .medical-file-input {
        display: none;
    }

    & .MuiButton-root.free-text_upload-file {
        text-transform: capitalize;
    }

    & .free-text_upload-file {
        display: flex;
        align-items: center;
        background-color: #edebe5;
        padding: 6px 8px 6px 12px;
        border-radius: 16px;
        margin-bottom: 0;
        height: 32px;
        color: #2b3073;
        max-width: 350px;

        .MuiInput-input {
            color: #2b3073;
            letter-spacing: 0.5px;
            font-weight: 700;
            font-size: 14px;
            -webkit-text-fill-color: initial;
        }

        .MuiIconButton-root {
            padding: 2px;
            margin-left: 5px;
        }

        .MuiSvgIcon-root {
            opacity: 0.6;
        }

        &.MuiMenuItem-root {
            .edit-btn {
                color: red;
            }
        }
    }

    & .todo-inputs,
    & .partner-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        input,
        textarea,
        .MuiSelect-select {
            font-size: 14px;
        }

        .MuiInputBase-input {
            color: #401f52;
            font-size: 14px;
            padding-left: 0;
        }
    }

    & .content-limit {
        position: absolute;
        right: 11px;
        top: 11px;
    }

    & .wrapper_date-picker {
        display: flex;
        justify-content: space-between;
        flex: 1;

        &.labresult_date-picker {
            .MuiBox-root:first-of-type {
                margin-right: 0px !important;
            }
        }

        &.aftervisitsummary_date-picker {
            .MuiBox-root:first-of-type {
                margin-right: 0px !important;
            }
        }

        .MuiBox-root:first-of-type {
            margin-right: 20px;
        }

        input.MuiInputBase-input {
            padding: 5px 0;
            font-size: 14px;
            cursor: default;
            caret-color: transparent;
        }

        &.trial-date.MuiBox-root,
        .MuiBox-root {
            margin-bottom: 0;
        }

        &.trial-date fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
    }

    & .notification-text {
        width: 200px;
        padding-left: 42px;
    }

    &.notification-field {
        margin-right: 0;
    }

    .todo-inputs.notification-field {
        border: 1px solid rgba(43, 48, 115, 0.6);
        border-radius: 4px;

        &.MuiTextField-root:first-of-type {
            margin-right: 0;
        }

        fieldset {
            border-bottom: none;
        }
    }

    & .todo-inputs {
        &.duration {
            width: 100%;
            padding-right: 0;

            div {
                width: 100%;
            }
        }
    }
`

export const DatePickerWrapper = styled(Box)`
    display: flex;
    align-items: center;
`

export const NotificationText = styled(Typography)`
    color: rgb(43, 48, 115);
    max-width: 460px;
    white-space: pre-wrap;
    line-height: 24px;

    &.notification-tip {
        margin-bottom: 8px;
        opacity: 0.6;
    }
`

export const ToDoFormDropdown = styled(FormControl)`
    .select-type {
        flex: 1;

        &.select-lab {
            max-width: 260px;
        }
    }

    .patient-autocomplete {
        display: flex;
        flex: 1;
        align-items: flex-end;

        .MuiAutocomplete-inputRoot {
            padding-left: 12px;
        }
    }

    &.select-doctor {
        .MuiAutocomplete-root {
            width: 270px;
        }
    }

    .dropdown-priority {
        width: 60%;

        .Mui-disabled {
            -webkit-text-fill-color: #2b3073;
            padding-bottom: 0;
        }

        &.MuiInput-root.Mui-disabled:before {
            border: none;
        }

        svg.Mui-disabled {
            display: none;
        }
    }

    &:nth-of-type(2) {
        .MuiTypography-root {
            width: 30%;
        }
    }

    & .MuiSelect-select {
        display: flex;
        align-items: center;
    }

    & .settings-notification {
        width: 100%;
        margin-right: 0;
    }

    & .MuiInput-root.Mui-disabled:before {
        border-bottom-style: solid;
    }
`

export const CustomAutocomplete = styled(Autocomplete)`
    flex: 1;

    &.search-box {
        .MuiAutocomplete-input {
            color: #401f52;
            font-size: 14px;
            padding-left: 0 !important;
        }

        .MuiInputBase-root {
            padding: 0;
        }

        .MuiInputBase-formControl.Mui-disabled {
            padding: 0;
            color: #2b3073;
        }

        input.Mui-disabled {
            font-size: 18px;
            color: #2b3073;
            -webkit-text-fill-color: #2b3073;
        }
    }

    & + .MuiAutocomplete-popper {
        & .MuiAutocomplete-noOptions {
            position: relative;
            color: #fc7960;
            font-weight: 700;
            font-size: 14px;
            min-height: 52px;
        }

        .MuiPaper-root {
            max-width: 100%;
        }
    }

    & .MuiOutlinedInput-root {
        padding-top: 0;
        padding-bottom: 5px;
    }

    & .MuiOutlinedInput-root,
    & .MuiOutlinedInput-input {
        cursor: pointer;

        & .MuiAutocomplete-endAdornment {
            right: 0;
        }
    }

    & .MuiOutlinedInput-notchedOutline {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);

        &:hover {
            border-bottom: 2px solid #000;
        }
    }
`

export const DropDownItems = styled(MenuItem)`
    display: flex;
    align-items: center;
`

export const ToDoPriorityTag = styled('img')`
    width: 22px;
    height: 22px;
    margin: 0 1rem 0 0;
`

export const ToDoMenuItemWrapper = styled(MenuItem)`
    text-transform: capitalize;

    &.in-progress-todo,
    &.not-started-todo {
        color: rgba(43, 48, 115);
        display: flex;
        justify-content: center;
    }

    &.completed-todo {
        color: #b871b5;
        opacity: 1;
    }

    &.canceled-todo {
        color: #fc7960;
        opacity: 1;
    }
`

export const UploadChip = styled(Chip)`
    background-color: #edebe5;
    padding: 6px 8px 6px 12px;
    border-radius: 16px;
    color: #2b3073;
    letter-spacing: 0.5px;

    .MuiSvgIcon-root.MuiChip-icon {
        opacity: 0.6;
    }
`

export const ToDoOptionalCheck = styled(FormControlLabel)`
    display: flex;
    justify-content: start;
    margin: 0 0 1rem 0;
    width: 200px;
    color: #a0aeba;

    &.medical-checkbox {
        margin-top: 15px;

        .MuiCheckbox-root {
            padding: 0;
            margin-right: 35px;
        }

        .MuiTypography-root {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            flex: none;
        }
    }

    & span.MuiCheckbox-root.Mui-checked + span.MuiTypography-root {
        color: rgba(43, 48, 115, 0.6);
    }
`

export const ToDoFormText = styled(TextField)`
    & .MuiInput-root,
    & .MuiOutlinedInput-root {
        width: 100%;
        // padding-left: 6px;
    }

    &.todo-inputs.duration {
        margin-bottom: 0;
        margin-right: 10px;

        .MuiInput-root {
            // width: 80px;
        }

        input {
            text-align: center;
        }
    }

    &.todo-inputs.error-color {
        .MuiInput-root::before {
            border-bottom: 1px solid #fc7960;
        }
    }
`
