import React from 'react'
import { useQuery } from '@apollo/client'
import { Avatar, Box, CardHeader, Typography } from '@mui/material'

import AccordionFiles from './AccordionFiles'
import CircularLoader from '@/components/Loader/CircularLoader'

import { useProviderAvatarInToDo } from '@/hooks'
import { dateToString, getTime, getFullName } from '@/utils'

import { FETCH_FILE_LIST_BY_TODO_ID } from '@/graphql/files/queries'
import { statusColors } from '@/components/Patient/PatientTabsPanel/PatientToDos/priorityIcons'

import { IPatientMedicalBlock } from '@/components/Patient/PatientTabsPanel/interfaces'
import {
    CardContentBox,
    MedicalHistoryBox,
    PatientDataBlockWrapper,
} from '@/components/Patient/PatientContent/styles'
import { PatientToDoChip } from '@/components/Patient/PatientTabsPanel/styles'

const PatientMedicalBlock: React.FC<IPatientMedicalBlock> = ({ toDo, userId, todoId }) => {
    const providerIdentifier = toDo.type === 'Appointment' ? toDo?.practitionerId : toDo?.createdBy
    const { previewAvatar, providerData, loading } = useProviderAvatarInToDo(
        providerIdentifier,
        toDo.type !== 'Appointment'
    )

    const { visitTime, visitReason, visitDate, afterVisitReason, afterVisitDate, status } = toDo
    const statusClass = statusColors[status.toLowerCase()]

    const { data: todoFiles } = useQuery(FETCH_FILE_LIST_BY_TODO_ID, {
        variables: {
            todoId,
        },
    })

    let practitionerContent: string | JSX.Element = ''

    if (loading) {
        practitionerContent = <CircularLoader size={20} />
    }

    if (!loading && (toDo?.practitionerId || toDo?.createdBy)) {
        practitionerContent = (
            <>
                <Avatar src={previewAvatar} className='avatar' />
                {getFullName(providerData?.firstName, providerData?.lastName)}
            </>
        )
    }

    const sortedFiles = todoFiles?.fileListByTodoId
        ?.slice()
        ?.sort((currentFile: any, nextFile: any) => nextFile.createdDate - currentFile.createdDate)

    const shownVisitDate =
        toDo.type === 'Appointment'
            ? `${visitDate && dateToString(visitDate, 'MM/dd/yyyy')} ${
                visitTime && getTime(visitTime)
            }`
            : `${afterVisitDate && dateToString(afterVisitDate, 'MM/dd/yyyy')}`
    const shownVisitReason = toDo.type === 'Appointment' ? visitReason : afterVisitReason

    return (
        <PatientDataBlockWrapper>
            <PatientToDoChip
                className='due-date_chip'
                label={
                    <Box display='flex' alignItems='center'>
                        {toDo.type}
                    </Box>
                }
            />
            <CardHeader
                action={<PatientToDoChip className={statusClass} label={status} />}
                className='absolute'
            />
            <CardContentBox>
                <Typography>Visit Date</Typography>
                <MedicalHistoryBox>{shownVisitDate}</MedicalHistoryBox>
            </CardContentBox>
            <CardContentBox>
                <Typography>Doctor</Typography>
                <MedicalHistoryBox>{practitionerContent}</MedicalHistoryBox>
            </CardContentBox>
            <CardContentBox>
                <Typography>Reason for Visit</Typography>
                <MedicalHistoryBox>{shownVisitReason ?? 'N/A'}</MedicalHistoryBox>
            </CardContentBox>

            <AccordionFiles files={sortedFiles} userId={userId} todoId={todoId} />
        </PatientDataBlockWrapper>
    )
}

export default PatientMedicalBlock
