import { gql } from '@apollo/client'

export const SEND_ADMIN_MESSAGE = gql`
    mutation SendAdminMessage($channelUrl: String!, $adminMessageInput: AdminMessageInput!) {
        sendAdminMessage(channelUrl: $channelUrl, adminMessageInput: $adminMessageInput) {
            message
        }
    }
`

export const UPDATE_CHANNEL_METADATA = gql`
    mutation UpdateChannelMetadata($channelUrl: String!, $metadata: GroupChannelMetadataInput!) {
        updateChannelMetadata(channelUrl: $channelUrl, metadata: $metadata) {
            waiting_for_follow_up
        }
    }
`

export const INVITE_TO_GROUP_CHANNEL = gql`
    mutation InviteToGroupChannel($channelUrl: String!, $userId: String!) {
        inviteToGroupChannel(channelUrl: $channelUrl, userId: $userId) {
            channelUrl
        }
    }
`

export const CREATE_GROUP_CHANNEL = gql`
    mutation CreateGroupChannel($createGroupChannelInput: CreateGroupChannelInput!) {
        createGroupChannel(createGroupChannelInput: $createGroupChannelInput) {
            name
            channelUrl
            hiddenState
            data
            customType
            memberCount
            members {
                userId
                nickname
                isActive
                isOnline
                state
                role
            }
            operators {
                userId
                nickname
            }
            createdBy {
                userId
                nickname
            }
            maxLengthMessage
            createdAt
            freeze
            unreadMessageCount
            metadata {
                waiting_for_follow_up
            }
            lastMessage {
                id
                type
                message
                customType
                createdAt
                chatUser {
                    userId
                }
            }
        }
    }
`
