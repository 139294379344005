import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import App from '@/App'
import reportWebVitals from '@/reportWebVitals'

import theme from '@/theme'
import shouldEnableDatadog from './utils/datadog'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

declare global {
    interface Window {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        env?: any;
    }
}

const environmentLoader = async () => {
    const response = await fetch('/environment.json')
    window.env = await response.json()
};
  
environmentLoader().then(() => {
    if (shouldEnableDatadog()) {
        datadogRum.init({
            applicationId: window.env.DATADOG_APP_ID,
            clientToken: window.env.DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'carawayclinic-frontend',
            env: window.env.ENVIRONMENT,
            version: window.env.CLINIC_APP_VERSION,
            allowedTracingUrls: ["https://carawayclinic.health", "https://staging.carawayclinic.health"],
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
        datadogLogs.init({
            clientToken: window.env.DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'carawayclinic-frontend',
            env: window.env.ENVIRONMENT,
            version: window.env.CLINIC_APP_VERSION,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        })
    }

    ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>,
        document.getElementById('root')
    )
}).catch(() => {
    ReactDOM.render(<div>refresh page</div>, document.getElementById("root"))
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
