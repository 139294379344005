import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'

import PatientsTablePagination from '@/components/Chat/CreateChatModal/PatientsTablePagination'
import ModalPatientRow from '@/components/Chat/CreateChatModal/ModalPatientRow'
import CircularLoader from '@/components/Loader/CircularLoader'

import { useDebounce } from '@/hooks'
import { FETCH_USERS } from '@/graphql/users/queries'
import { IPatientTable } from './interfaces'

import { TableContainerWrapper } from '@/components/ToDo/ToDoList/styles'

const PatientsTable: React.FC<IPatientTable> = ({ searchName, currentUser, setCurrentUser }) => {
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(5)

    const [fetchUserList, { data, loading }] = useLazyQuery(FETCH_USERS)

    const offset = page * limit
    const users = data?.users?.userList

    const searchUserList = useCallback(
        async (offset: number, limit: number, search: string) => {
            try {
                await fetchUserList({
                    variables: {
                        search,
                        offset,
                        limit,
                    },
                })
            } catch (e) {
                console.error(e)
            }
        },
        [searchName, offset, limit]
    )

    const debouncedSearch = useDebounce(searchUserList, 500)

    useEffect(() => {
        setPage(0)

        debouncedSearch(0, limit, searchName)
    }, [searchName])

    useEffect(() => {
        searchUserList(offset, limit, searchName)
    }, [offset, limit])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setLimit(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <TableContainerWrapper className='table'>
            <Table stickyHeader sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Avatar</TableCell>
                        <TableCell align='right'>Name</TableCell>
                        <TableCell align='right'>Date of Birth</TableCell>
                        <TableCell align='right'>State</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading ? (
                        <CircularLoader size={50} />
                    ) : (
                        users?.map((user: any) => (
                            <ModalPatientRow
                                key={user.id}
                                currentUser={currentUser}
                                setCurrentUser={setCurrentUser}
                                user={user}
                            />
                        ))
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={data?.usersList?.count || 0}
                            rowsPerPage={limit}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={PatientsTablePagination}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainerWrapper>
    )
}

export default PatientsTable
