import { useMutation } from '@apollo/client'
import axios from 'axios'

import { UPLOAD_FILE_WITH_SIGNED_URL } from '@/graphql/files/mutations'

import { fileToBlob } from '@/utils'

const useUploadFileSignedUrl = () => {
    const [uploadFileSignedUrl] = useMutation(UPLOAD_FILE_WITH_SIGNED_URL)

    const uploadFile = async (fileInput: any, file: File) => {
        try {
            const { data } = await uploadFileSignedUrl({
                variables: {
                    fileInput,
                },
            })

            const blobFile = await fileToBlob(file)

            if (data?.uploadFileWithSignedUrl) {
                await axios.put(data?.uploadFileWithSignedUrl?.url, blobFile, {
                    headers: { 'Content-Type': 'application/octet-stream' },
                })
            }

            return data
        } catch (e) {
            console.error(e)
        }
    }

    return uploadFile
}

export default useUploadFileSignedUrl
