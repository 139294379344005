import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from '@/pages'

const PublicRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    )
}

export default PublicRoutes
