import { dateToString } from '@/utils'

const REG_EXP_WITHOUT_TYPE = /\.[^/.]+$/
const REG_EXP_WHITESPACES = /\s/g
const REG_EXP_DOTS = /\./g

const setUniqueFileName = (name: string) => {
    const currentDate = dateToString(Date.now(), "yyyy-MM-dd'T'HH:mm:ss")
    const fileNameWithoutType = name.replace(REG_EXP_WITHOUT_TYPE, '')

    return `${currentDate}_${fileNameWithoutType}`
        .replace(REG_EXP_WHITESPACES, '_')
        .replace(REG_EXP_DOTS, '_')
}

export default setUniqueFileName
