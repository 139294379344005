import { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import { useSignedUrl } from '@/hooks'
import { FETCH_PROVIDER_BY_ID, FETCH_PROVIDER_BY_SUB } from '@/graphql/providers/queries'

const useProviderAvatarInToDo = (practitionerIdentifier?: string, practitionerBySub?: boolean) => {
    const { previewAvatar, setPreviewAvatar, fetchSignedUrl } = useSignedUrl()
    const [fetchProviderById, { data: dataById, loading: loadingById }] =
        useLazyQuery(FETCH_PROVIDER_BY_ID)
    const [fetchProviderBySub, { data: dataBySub, loading: loadingBySub }] =
        useLazyQuery(FETCH_PROVIDER_BY_SUB)

    const fetchProviderAvatar = async () => {
        if (!practitionerIdentifier) {
            setPreviewAvatar('')
            return
        }

        if (practitionerBySub) {
            await fetchProviderBySub({
                variables: {
                    sub: practitionerIdentifier,
                },
                onCompleted: async ({ providerBySub }) => {
                    if (!providerBySub?.avatar) {
                        setPreviewAvatar('')
                        return
                    }

                    await fetchSignedUrl(providerBySub?.avatar)
                },
            })
        } else {
            await fetchProviderById({
                variables: {
                    providerByIdId: practitionerIdentifier,
                },
                onCompleted: async ({ providerById }) => {
                    if (!providerById?.avatar) {
                        setPreviewAvatar('')
                        return
                    }

                    await fetchSignedUrl(providerById?.avatar)
                },
            })
        }
    }

    useEffect(() => {
        fetchProviderAvatar()
    }, [practitionerIdentifier])

    return {
        previewAvatar,
        providerData: practitionerBySub ? dataBySub?.providerBySub : dataById?.providerById,
        loading: practitionerBySub ? loadingBySub : loadingById,
    }
}

export default useProviderAvatarInToDo
