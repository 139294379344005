import React from 'react'
import classNames from 'classnames'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import editMessageSvg from '@/assets/images/edit-message.svg'
import { IMessageMenu } from '../interfaces'
import { MessageMenuWrapper } from '../styles'

const MessageMenu: React.FC<IMessageMenu> = ({
    deleteMessage,
    selectMessage,
    chatMessage,
    messageId,
    isCompanionMessage,
    nickname,
    messageImgUrl,
    messageType,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const openEditField = (isEditMessage: boolean, isReplyMessage: boolean) => {
        handleClose()

        if (selectMessage) {
            selectMessage({
                message: chatMessage,
                messageImgUrl,
                messageId,
                isEditMessage,
                isReplyMessage,
                nickname,
            })
        }
    }

    const menuItems = [
        {
            id: 2,
            icon: <img src={editMessageSvg} alt='Edit' />,
            name: 'Edit',
            callback: () => openEditField(true, false),
            companionMessage: false,
            disabled: false,
        },
        {
            id: 3,
            icon: <DeleteForeverOutlinedIcon />,
            name: 'Delete',
            callback: deleteMessage,
            companionMessage: false,
            disabled: false,
        },
    ]

    let filteredMenuItems = isCompanionMessage
        ? menuItems.filter(({ companionMessage }) => companionMessage)
        : menuItems

    if (messageType === 'file') {
        filteredMenuItems = menuItems.filter(({ name }) => name !== 'Edit')
    }

    return (
        <div>
            {Boolean(filteredMenuItems?.length) && (
                <>
                    <IconButton onClick={handleClick} disableRipple>
                        <MoreVertIcon />
                    </IconButton>
                    <MessageMenuWrapper
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {filteredMenuItems?.map(({ id, icon, name, callback, disabled }) => (
                            <MenuItem
                                key={id}
                                onClick={callback}
                                disabled={disabled}
                                className={classNames({
                                    'delete-message': name === 'Delete',
                                })}
                            >
                                <IconButton disabled>{icon}</IconButton>
                                {name}
                            </MenuItem>
                        ))}
                    </MessageMenuWrapper>
                </>
            )}
        </div>
    )
}

export default MessageMenu
