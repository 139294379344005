import React from 'react'
import { IUnverifiedCollegeSingleRow } from '@/components/CollegesAndPartners/interfaces'
import { ListTableBodyRow, ListTableCell } from '@/components/CollegesAndPartners/styles'
import ReviewCheckbox from './ReviewCheckbox'
import useUpdateUnverifiedCollege from '../hooks/useUpdateUnverifiedCollege'

const UnverifiedCollegeSingleRow: React.FC<IUnverifiedCollegeSingleRow> = ({
    unverifiedCollege,
    offset,
    limit,
}) => {
    const { handleUpdateUnverifiedCollege } = useUpdateUnverifiedCollege()

    const handleEditUnverifiedCollege = async (unverifiedCollegeData: any) => {
        const unverifiedCollegeInput = {
            name: unverifiedCollegeData?.name,
            state: unverifiedCollegeData?.state,
            isReviewed: unverifiedCollegeData?.isReviewed,
            user: {
                id: unverifiedCollegeData?.user?.id,
            },
        }

        await handleUpdateUnverifiedCollege(
            unverifiedCollegeData,
            unverifiedCollegeInput,
            unverifiedCollege?.id,
            offset,
            limit
        )
    }

    return (
        <ListTableBodyRow>
            <ListTableCell>{unverifiedCollege?.name}</ListTableCell>
            <ListTableCell>{unverifiedCollege?.state}</ListTableCell>
            <ListTableCell>
                {unverifiedCollege?.user?.firstName} {unverifiedCollege?.user?.lastName}
            </ListTableCell>
            <ListTableCell>
                <ReviewCheckbox
                    college={unverifiedCollege}
                    editUnverifiedCollege={handleEditUnverifiedCollege}
                />
            </ListTableCell>
        </ListTableBodyRow>
    )
}

export default UnverifiedCollegeSingleRow
