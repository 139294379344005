import React, { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Table, TableBody, TableFooter, TablePagination, TableRow } from '@mui/material'

import { FETCH_TODOS_QUERY } from '@/graphql/toDos/queries'
import { useDebounce } from '@/hooks'

import { ToDoSingle } from '@/components'
import CircularLoader from '@/components/Loader/CircularLoader'
import ModalToDo from '@/components/ToDo/ModalToDo/ModalToDo'

import { IToDoPagination } from '@/components/ToDo/interfaces'

import { TableCellCustom, TableContainerWrapper } from './styles'

const ToDoPagination: React.FC<IToDoPagination> = ({
    searchName,
    isCreateMode,
    openCreateModal,
}) => {
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [fetchToDoList, { data, loading }] = useLazyQuery(FETCH_TODOS_QUERY)

    const offset = page * limit
    const toDos = data?.todoList?.todoList

    const searchToDoList = useCallback(
        async (search: string, offset?: number, limit?: number) => {
            await fetchToDoList({
                variables: {
                    search,
                    offset,
                    limit,
                },
            })
        },
        [searchName, offset, limit]
    )

    const debouncedSearch = useDebounce(searchToDoList, 500)

    useEffect(() => {
        setPage(0)

        debouncedSearch(searchName, 0, limit)
    }, [searchName])

    useEffect(() => {
        searchToDoList(searchName, offset, limit)
    }, [offset, limit])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setLimit(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <TableContainerWrapper>
            <Table>
                {loading ? (
                    <CircularLoader size={60} />
                ) : (
                    <>
                        <TableBody>
                            {toDos?.map((row: any) => (
                                <TableRow key={row.id}>
                                    <TableCellCustom>
                                        <ToDoSingle
                                            toDo={row}
                                            search={searchName}
                                            offset={offset}
                                            limit={limit}
                                            count={data?.todoList?.count || 0}
                                        />
                                    </TableCellCustom>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={data?.todoList?.count || 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={limit}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </>
                )}
            </Table>
            {isCreateMode && (
                <ModalToDo
                    open={isCreateMode}
                    handleClose={openCreateModal}
                    limit={limit}
                    offset={offset}
                    search={searchName}
                    isCreateMode
                />
            )}
        </TableContainerWrapper>
    )
}

export default ToDoPagination
