import React, { ReactElement } from 'react'
import { Box } from '@mui/material'

import { ToDoList } from '@/components'

const DashboardToDosPage: React.FC = (): ReactElement => {
    return (
        <div style={{ backgroundColor: 'transparent' }}>
            <Box sx={{ display: 'flex' }}>
                <ToDoList />
            </Box>
        </div>
    )
}

export default DashboardToDosPage
