import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useMutation } from '@apollo/client'
import AuthContext from './AuthContext'
import ExpiredTokenContext from '@/context/ExpiredTokenContext'
import { GET_SESSION_TOKEN } from '@/graphql/auth/mutations'

import { useIdleTimer } from 'react-idle-timer'

const AuthProvider = ({ children }: React.PropsWithChildren<any>) => {
    const [getSessionToken] = useMutation(GET_SESSION_TOKEN)

    const [currentUser, setCurrentUser] = useState({})
    const { doShowError, setDoShowError } = useContext(ExpiredTokenContext)

    const BACKEND_AUTH_URL = window.env.BACKEND_AUTH_URL

    useEffect(() => {
        if (doShowError) {
            const timer = setTimeout(() => {
                setDoShowError(false)
                signOut()
            }, 3000)

            return () => clearTimeout(timer)
        }
    }, [doShowError])

    const handleOnIdle = () => {
        signOut()
    }

    const {} = useIdleTimer({
        timeout: 1000 * 60 * 30, // 30 min
        onIdle: handleOnIdle,
        eventsThrottle: 300,
        crossTab: true,
        syncTimers: 100,
    })

    const signIn = async (authCode: string) => {
        const tokenUrl = `${BACKEND_AUTH_URL}/token/${authCode}`
        const res = await axios.get(tokenUrl)

        if (res.status == 200 && !res.data.error) {
            const signInResponse = res.data

            sessionStorage.setItem('accessToken', signInResponse.access_token)
            sessionStorage.setItem('refreshToken', signInResponse.refresh_token)
            sessionStorage.setItem('sessionUser', JSON.stringify({}))

            if (signInResponse?.userInfo?.sub) {
                const { data } = await getSessionToken({
                    variables: {
                        userId: signInResponse?.userInfo?.sub,
                    },
                })
                if (data.issueSessionToken) {
                    sessionStorage.setItem('issueSessionTokenUser', data.issueSessionToken)
                }
            }
            if (window.env.SENDBIRD_USERID) {
                const responseDocIssueToken = await getSessionToken({
                    variables: {
                        userId: window.env.SENDBIRD_USERID,
                    },
                })
                if (responseDocIssueToken?.data?.issueSessionToken) {
                    sessionStorage.setItem(
                        'issueSessionTokenDoc',
                        responseDocIssueToken.data.issueSessionToken
                    )
                }
            }
            if (signInResponse?.userInfo?.email) {
                setCurrentUser(signInResponse.userInfo)
                sessionStorage.setItem('sessionUser', JSON.stringify(signInResponse.userInfo))
            }
        }
    }

    const signOut = () => {
        const logoutUrlFromCognito = window.env.COGNITO_LOGOUT_URL
        sessionStorage.clear()
        setCurrentUser({})
        window.location.replace(logoutUrlFromCognito)
    }

    return (
        <>
            <AuthContext.Provider
                value={{
                    signIn,
                    signOut,
                    currentUser,
                    setCurrentUser,
                }}
            >
                {children}
            </AuthContext.Provider>
        </>
    )
}

export default AuthProvider
