import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, CardHeader } from '@mui/material'

import EditModeBtn from '@/components/UI/Buttons/EditModeBtn'
import ProviderAvatar from '@/components/Provider/ProviderPrimaryEditCard/ProviderAvatar'
import { InputField } from '@/components/UI'
import StateListDropdown from '@/components/UI/Dropdowns/StateListDropdown'
import RoleListDropdown from '@/components/UI/Dropdowns/RoleListDropdown'
import ProviderPhoneField from '@/components/Provider/ProviderPrimaryEditCard/ProviderPhoneField'

import { UPDATE_PROVIDER } from '@/graphql/providers/mutations'
import { useUploadPhoto } from '@/hooks'

import { getBase64 } from '@/utils'

import { IProvider, IProviderEditCard } from '@/components/Provider/interfaces'

import { ProviderCardWrapper } from '@/components/Provider/styles'
import { CardContentBox } from '@/components/Patient/PatientContent/styles'

const alphanumericRegExp = new RegExp(/^[-\w\s]+$/)
const nonDigitsReg = /\D+/g

const ProviderPrimaryEditCard: React.FC<IProviderEditCard> = ({ provider, setProvider }) => {
    const [providerEditData, setProviderEditData] = useState<IProvider | any>({})
    const [previewPhoto, setPreviewPhoto] = useState<any>('')
    const [photoAsFile, setPhotoAsFile] = useState<File | null>(null)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isPhoneError, setIsPhoneError] = useState<boolean>(false)

    const [ updateProvider ] = useMutation(UPDATE_PROVIDER)
    const { uploadFile } = useUploadPhoto()

    const isEmptyField = !providerEditData?.firstName?.length || !providerEditData?.lastName?.length

    const filteredProviderData = useMemo(() => {
        let phoneNumber = provider?.phoneNumber

        if (provider?.phoneNumber?.startsWith('+1')) {
            phoneNumber = provider?.phoneNumber?.substring(2)
        }

        return {
            firstName: provider.firstName,
            lastName: provider.lastName,
            avatar: provider.avatar,
            role: provider.role,
            state: provider.state,
            phoneNumber,
            smsNotification: provider.smsNotification
        }
    }, [provider])

    useEffect(() => {
        sessionStorage.setItem('sessionUser', JSON.stringify(provider))

        setProviderEditData(filteredProviderData)
    }, [provider])

    const enableEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const cancelEditingMode = () => {
        setProviderEditData(filteredProviderData)
        setIsPhoneError(false)
        setIsEditMode(!isEditMode)
    }

    const sendModifiedData = async () => {
        try {
            const phoneNumberWithOnlyNumbers = providerEditData?.phoneNumber?.replace(nonDigitsReg, '')
            const phoneNumberWithPlus = `+1${phoneNumberWithOnlyNumbers}`
            let photoId = provider.avatar
            setIsLoading(true)

            if (phoneNumberWithOnlyNumbers?.length < 10) {
                setIsPhoneError(true)
                setIsLoading(false)
                return
            }

            if (photoAsFile) {
                photoId = await uploadFile(photoAsFile)
                setPreviewPhoto('')
            }

            await updateProvider({
                variables: {
                    updateProviderId: provider.id,
                    providerInput: {
                        ...providerEditData,
                        avatar: photoId,
                        phoneNumber: phoneNumberWithPlus,
                        role: {
                            id: providerEditData?.role?.id,
                        }
                    }
                }
            })

            setProvider({
                id: provider.id,
                sub: provider.sub,
                email: provider.email,
                avatar: photoId,
                phoneNumber: provider.phoneNumber,
                ...providerEditData
            })

            sessionStorage.setItem('sessionUser', JSON.stringify({
                id: provider.id,
                sub: provider.sub,
                email: provider.email,
                avatar: photoId,
                phoneNumber: provider.phoneNumber,
                isAdmin: provider.isAdmin,
                ...providerEditData
            }))
            
            setIsEditMode(false)
        } catch (e) {
            console.error(e.message)
        } finally {
            setIsLoading(false)
        }
    }

    const uploadProviderPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];

        const photoInBase64 = await getBase64(file)
        setPhotoAsFile(file)
        setPreviewPhoto(photoInBase64)
    }

    const handleProviderDropdownData = (name: string, value: string) => {
        const isState = name === 'state'

        if (isState) {
            setProviderEditData((prevState: IProvider) => ({
                ...prevState,
                [name]: value,
            }))
        } else {
            setProviderEditData((prevState: IProvider) => ({
                ...prevState,
                [name]: {
                    id: value,
                },
            }))
        }
    }

    const editProviderData = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        const isLessThanOrEqualTo50Symbols = value.length <= 50
        const isOnlyAlphanumeric = alphanumericRegExp.test(value)

        switch (name) {
            case 'firstName':
            case 'lastName':
                if ((isOnlyAlphanumeric || !value?.length) && isLessThanOrEqualTo50Symbols) {
                    setProviderEditData((prevState: IProvider) => ({
                        ...prevState,
                        [name]: value,
                    }))
                }
                break
            case 'smsNotification':
                setProviderEditData((prevState: IProvider) => ({
                    ...prevState,
                    [name]: !prevState[name],
                }))
                break
            default:
                setProviderEditData((prevState: IProvider) => ({
                    ...prevState,
                    [name]: value,
                }))
                setIsPhoneError(false)
        }
    }

    return (
        <ProviderCardWrapper className='edit-card'>
            <CardHeader
                action={
                    <EditModeBtn
                        isEditMode={isEditMode}
                        cancelEditingMode={cancelEditingMode}
                        sendModifiedData={sendModifiedData}
                        enableEditMode={enableEditMode}
                        isLoading={isLoading}
                        isEmptyField={isEmptyField}
                        isDisabled={false}
                    />
                }
                avatar={
                    <ProviderAvatar
                        src={previewPhoto}
                        photoId={providerEditData?.avatar}
                        setPreviewPhoto={setPreviewPhoto}
                        isEditMode={isEditMode}
                        uploadProviderPhoto={uploadProviderPhoto}
                        isDisabled={isLoading}
                    />
                }
                subheader={
                    <Box className='provider-edit__wrapper'>
                        <CardContentBox>
                            <InputField
                                label='First Name'
                                name='firstName'
                                value={providerEditData?.firstName}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <InputField
                                label='Last Name'
                                name='lastName'
                                value={providerEditData?.lastName}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                onChangeHandler={editProviderData}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <StateListDropdown
                                label='State'
                                name='state'
                                value={providerEditData?.state}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                handleData={handleProviderDropdownData}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <RoleListDropdown
                                label='Role'
                                name='role'
                                value={providerEditData?.role}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                handleData={handleProviderDropdownData}
                            />
                        </CardContentBox>
                        <CardContentBox>
                            <ProviderPhoneField
                                editProviderData={editProviderData}
                                phoneNumber={providerEditData?.phoneNumber}
                                smsNotification={providerEditData?.smsNotification}
                                isEditMode={isEditMode}
                                isDisabled={isLoading}
                                isPhoneError={isPhoneError}
                            />
                        </CardContentBox>
                    </Box>
                }
            />
        </ProviderCardWrapper>
    )
}

export default ProviderPrimaryEditCard
