import { styled, Dialog, Box, Typography, Button } from '@mui/material'

export const ModalWrapper = styled(Dialog)`
    .MuiPaper-root {
        max-width: 100%;
        overflow-y: initial;
    }

    .MuiBackdrop-root {
        background-color: rgba(43, 48, 115, 0.6);
    }
`

export const ChipIcon = styled(Typography)`
    display: flex;
    align-items: center;
    color: rgba(43, 48, 115, 0.6);
    margin-right: 12px;
`

export const DialogTitleWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;

    .MuiIconButton-root {
        margin-right: 5px;
    }

    .search-box {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #769fc1;
        width: 55%;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    .icon-box {
        margin-bottom: 25px;
    }
`
export const PatientsList = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    min-width: 800px;
    background-color: rgba(255, 255, 255, 0.8);
    color: rgba(43, 48, 115, 0.6);
    overflow: hidden;

    .table {
        position: relative;
        margin-top: 25px;
        margin-bottom: 25px;

        & .MuiTable-root {
            min-height: 220px;
        }

        & .MuiTableCell-footer {
            width: 100%;
            border-bottom: none;
        }
    }

    .active {
        background-color: #f3f0ea;
    }

    .header {
        font-weight: bold;
        font-size: 20px;
        color: #2b3073;
    }
`

export const ConfirmButton = styled(Button)`
    width: 33.33%;
    align-self: end;
    color: whitesmoke;
    border-radius: 10px;
`
