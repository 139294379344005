import { IMockData } from '@/components/Patient/PatientTabsPanel/interfaces'
import { IPatientData } from '@/components/Patient/PatientContent/interfaces'

const filterPatientsData = (templateData: IMockData, patientInput: IPatientData) => {
    let filteredTemplateData: any = []

    filteredTemplateData = templateData
        .filter(({ key }: any) => patientInput.hasOwnProperty(key))
        .map((mockData: any) => ({
            ...mockData,
            value: patientInput[mockData.key],
        }))

    return { filteredTemplateData, patientInput }
}

export default filterPatientsData
