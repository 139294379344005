import { gql } from '@apollo/client'

export const CREATE_RESOURCES = gql`
    mutation CreateResources($entityId: Float!, $partnerType: String!, $slugs: [String!]!) {
        createResources(entityId: $entityId, partnerType: $partnerType, slugs: $slugs) {
            msg
        }
    }
`

export const DELETE_RESOURCES = gql`
    mutation DeleteResources($resourceIds: [ID!]!) {
        deleteResources(resourceIds: $resourceIds) {
            msg
        }
    }
`
