import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { render } from 'storyblok-rich-text-react-renderer'

import { useStoryblokContent } from '@/hooks'
import { ISupportResources, ICrisisResource } from '../../interfaces'
import {
    SupportResourcesContainer,
    SupportResourcesEmptyState,
    RedirectButton,
    RedirectIcon,
} from '../../styles'
import { SubscriptionTypesEnum } from '@/constants/subscriptionConstants'
import useSingleResourceByType from '../../hooks/useSingleResourceByType'

const SupportResources: React.FC<ISupportResources> = ({ patientData }) => {
    const isPatientB2b =
        patientData?.userById?.activeSubscription?.subscriptionType === SubscriptionTypesEnum.B2B
    const patientPartner = patientData?.userById?.activeSubscription?.b2bPartner
    const patientSubPartner = patientData?.userById?.activeSubscription?.b2bSubPartner
    const patientCollege = patientData?.userById?.college
    const collegeSlug = patientCollege?.campusCrisisInfo

    const [partnerSlug, setPartnerSlug] = useState<string>('')
    const [partnerResource, setPartnerResource] = useState<any>(null)
    const partnerResourceLink = partnerResource?.resource_link

    const [crisisResource, setCrisisResource] = useState<ICrisisResource>()
    const importantNumbers = crisisResource?.important_numbers
    const nearestPharmacy = crisisResource?.nearest_pharmacy
    const collegeResourceLink = crisisResource?.resource_link

    const collegeSlugs = '*/' + collegeSlug
    const { content: collegeContent } = useStoryblokContent('', '', collegeSlugs)

    const partnerSlugs = '*/' + partnerSlug
    const { content: partnerContent } = useStoryblokContent('', '', partnerSlugs)

    const { fetchSingleResourceData } = useSingleResourceByType(
        isPatientB2b,
        patientPartner,
        patientSubPartner,
        setPartnerSlug
    )

    useEffect(() => {
        if (collegeSlug && collegeContent) {
            setCrisisResource(collegeContent?.[0]?.content?.clinic_block?.[0])
        }

        if (partnerSlug && partnerContent) {
            setPartnerResource(partnerContent?.[0]?.content?.clinic_block?.[0])
        }
    }, [partnerSlug, partnerContent, collegeSlug, collegeContent])

    const redirectToGuide = (link: any) => {
        window.open(link, '_blank')
    }

    useEffect(() => {
        if (!collegeSlug && isPatientB2b) {
            fetchSingleResourceData()
        }
    }, [collegeSlug, isPatientB2b])

    return (
        <>
            {collegeSlug ? (
                <SupportResourcesContainer>
                    <Box className='campus-crisis__numbers'>
                        <Typography>IMPORTANT NUMBERS</Typography>
                        {render(importantNumbers)}
                    </Box>
                    <Box className='campus-crisis__pharmacy'>
                        <Typography>NEAREST PHARMACY</Typography>
                        {render(nearestPharmacy)}
                    </Box>
                    <RedirectButton
                        onClick={() => redirectToGuide(collegeResourceLink)}
                        sx={{ alignSelf: 'start', marginTop: '20px' }}
                    >
                        Open Resource Guide
                        <RedirectIcon />
                    </RedirectButton>
                </SupportResourcesContainer>
            ) : partnerResourceLink ? (
                <SupportResourcesContainer>
                    <RedirectButton
                        onClick={() => redirectToGuide(partnerResourceLink)}
                        sx={{ alignSelf: 'start', marginTop: '20px' }}
                    >
                        Open Resource Guide
                        <RedirectIcon />
                    </RedirectButton>
                </SupportResourcesContainer>
            ) : (
                <SupportResourcesEmptyState>No Support Resources Found</SupportResourcesEmptyState>
            )}
        </>
    )
}

export default SupportResources
