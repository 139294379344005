import React, { ReactElement, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, CssBaseline, ListItemIcon, ListItemText } from '@mui/material'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import navigationLinks from '@/components/DashboardLayout/navigationLinks'
import AuthContext from '@/context/AuthContext'
import darkLogo from '@/assets/images/logo-dark.svg'
import { ISubNavigationItem, ISubNavigation } from './interfaces'

import {
    DashboardContentWrapper,
    DrawerWrapper,
    ListItemWrapper,
    ListWrapper,
    LogoImg,
    LogoutButton,
    NavLinkItem,
    ListSubItemWrapper,
    SubListWrapper,
    SubNavLinkItem,
} from './styles'
import { getSessionUser } from '@/utils'

const SubMenu: React.FC<ISubNavigation> = ({ subMenus }) => {
    return (
        <SubListWrapper>
            {subMenus.map(({ id, name, subPath }: ISubNavigationItem) => (
                <React.Fragment key={id}>
                    <SubNavLinkItem to={subPath}>
                        <ListSubItemWrapper>
                            <ListItemText style={{ margin: 0 }} primary={name} />
                        </ListSubItemWrapper>
                    </SubNavLinkItem>
                </React.Fragment>
            ))}
        </SubListWrapper>
    )
}

const DashboardLayout: React.FC = (): ReactElement => {
    const { isAdmin } = getSessionUser()
    const { signOut } = useContext(AuthContext)
    return (
        <Box sx={{ display: 'flex', background: '#ffffff' }}>
            <CssBaseline />
            <DrawerWrapper variant='permanent' anchor='left'>
                <LogoImg src={darkLogo} />
                <ListWrapper>
                    {navigationLinks.map(({ id, icon, name, path, subMenu, isAdminOnly }) => {
                        const shouldDisplay = isAdminOnly ? isAdmin : true
                        return (
                            shouldDisplay && (
                                <React.Fragment key={id}>
                                    <NavLinkItem to={path}>
                                        <ListItemWrapper key={id}>
                                            <ListItemIcon>{icon}</ListItemIcon>
                                            <ListItemText primary={name} />
                                        </ListItemWrapper>
                                        {subMenu && <SubMenu subMenus={subMenu} />}
                                    </NavLinkItem>
                                </React.Fragment>
                            )
                        )
                    })}
                </ListWrapper>
                <LogoutButton
                    startIcon={<LogoutOutlinedIcon style={{ marginRight: '15px' }} />}
                    variant='outlined'
                    onClick={signOut}
                >
                    Logout
                </LogoutButton>
            </DrawerWrapper>

            <DashboardContentWrapper>
                <Outlet />
            </DashboardContentWrapper>
        </Box>
    )
}

export default DashboardLayout
