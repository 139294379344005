import { Box, styled, TableRow } from '@mui/material'

export const ListHeaderTableContainer = styled(Box)`
    width: 100%;
    padding: 25px;

    & .MuiInputLabel-root {
        font-weight: 700;
        font-size: 14px;
        opacity: 0.42;
    }

    & .MuiPaper-root {
        position: relative;
        border-radius: 0 0 8px 8px;
        background-color: #fdfcf7;
        box-shadow: none;
        min-height: 160px;
    }

    & .MuiTableFooter-root {
        float: right;
        border-bottom: none;
    }
`

export const ListTableHeader = styled('h4')`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0;
    padding: 1.5rem;
    font-size: 16px;
    font-weight: 400;
    color: rgba(43, 48, 115, 0.6);
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0px 1px 0px rgba(200, 200, 200, 0.25), 0px 4px 24px rgba(55, 43, 18, 0.05);
    background-color: white;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    & .MuiButton-root {
        margin-bottom: 0;
    }
`

export const ListTableHeaderRow = styled(TableRow)`
    & .MuiTableCell-root {
        font-weight: 500;
        font-size: 12px;
        color: rgba(43, 48, 115, 0.6);
        min-width: 100px;

        &:nth-of-type(1) {
            min-width: 230px;
        }

        &:nth-of-type(3) {
            min-width: 160px;
        }

        &:nth-of-type(5) {
            width: 100px;
        }

        &:last-of-type {
            min-width: 100%;
        }
    }
`
