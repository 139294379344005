import React from 'react'
import { IconButton } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { IToDoCancelBtn } from '@/components/ToDo/interfaces'

import { ToDoMenuItemWrapper } from '@/components/ToDo/ToDoForm/styles'
import { statusColors } from '@/components/Patient/PatientTabsPanel/PatientToDos/priorityIcons'
import todoStatuses from '@/components/ToDo/ModalToDo/todoStatuses'

const ToDoCancelBtn: React.FC<IToDoCancelBtn> = ({ changeStatus, status, initialStatus }) => {
    const statusText = status === 'Canceled' ? initialStatus : 'Canceled'
    const isCanceled = status === 'Canceled'
    const statusClass = statusColors[statusText?.toLowerCase()]

    return (
        <ToDoMenuItemWrapper className={statusClass} onClick={() => changeStatus(statusText)}>
            {!isCanceled && (
                <IconButton className='cancel-btn'>
                    <CloseOutlinedIcon />
                </IconButton>
            )}
            {isCanceled ? todoStatuses.NOT_STARTED : 'Cancel'}
        </ToDoMenuItemWrapper>
    )
}

export default ToDoCancelBtn
