import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import {
    DashboardToDosPage,
    DashboardPatientsPage,
    DashboardProviderPage,
    DashboardCollegesAndPartnersPage,
    DashboardAdminPage,
    DashboardChatsPage,
    DashboardChatbotPage,
} from '@/pages'
import { DashboardLayout, PatientContent } from '@/components'
import DashboardMessageSettingsPage from '@/pages/Dashboard/DashboardMessageSettingsPage'

const PrivateRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path='/dashboard' element={<DashboardLayout />}>
                <Route index element={<Navigate to='/dashboard/chats' />} />
                <Route path='chats'>
                    <Route path='care-team-chats' element={<DashboardChatsPage iscareteamtab />} />
                    <Route
                        path='own-chats'
                        element={<DashboardChatsPage iscareteamtab={false} />}
                    />
                    <Route index element={<Navigate to='/dashboard/chats/care-team-chats' />} />
                </Route>
                <Route path='patients' element={<DashboardPatientsPage />}>
                    <Route path=':id' element={<PatientContent />} />
                </Route>
                <Route path='todos' element={<DashboardToDosPage />} />
                <Route
                    path='colleges-and-partners'
                    element={<DashboardCollegesAndPartnersPage />}
                />
                <Route path='message-settings' element={<DashboardMessageSettingsPage />} />
                <Route path='chatbot' element={<DashboardChatbotPage />} />
                <Route path='profile' element={<DashboardProviderPage />} />
                <Route path='admin' element={<DashboardAdminPage />} />
            </Route>

            <Route path='*' element={<Navigate to='/dashboard' />} />
        </Routes>
    )
}

export default PrivateRoutes
