enum SendbirdUserRole {
    PROVIDER = 'provider',
    OPERATOR = 'operator',
}

export const getChannelData = (channel: any) => {
    if (channel?.data) {
        try {
            return JSON.parse(channel?.data)
        } catch (e) {
            return ''
        }
    } else {
        return ''
    }
}

export const getChannelUserId = (channel: any) => {
    const channelData = getChannelData(channel)
    return channelData ? channelData?.user_id : ''
}

export const getUserSessionToken = () => {
    return sessionStorage.getItem('issueSessionTokenUser') ?? ''
}

export const getSenderNickname = (sender: any, patientNickname: string) => {
    if (
        sender.metaData?.role === SendbirdUserRole.PROVIDER ||
        sender.metaData?.role === SendbirdUserRole.OPERATOR
    ) {
        return sender.nickname
    } else {
        return patientNickname
    }
}
