import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { SelectChangeEvent } from '@mui/material/Select'
import { Select, Typography } from '@mui/material'

import ToDoFormUserList from '@/components/ToDo/ToDoForm/ToDoFormUserList'
import { NOTIFICATION_TEXT } from '@/components/ToDo/ToDoContent/notification_texts'

import { FETCH_USER_AVATAR_BY_EMAIL } from '@/graphql/users/queries'
import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

import { useToDoMainValidation } from '@/hooks'
import { dateToString } from '@/utils'

import { IToDoMain } from '@/components/ToDo/ToDoContent/interfaces'

import {
    DropDownItems,
    FormSection,
    MainHeader,
    ToDoFormDropdown,
    ToDoFormText,
    ToDoPriorityTag,
} from '@/components/ToDo/ToDoForm/styles'
import { ErrorField } from '@/components/Patient/PatientContent/styles'
import { categories, priorities, prioritiesTag } from '@/components/ToDo/styles'

const regex = /[^A-Za-z0-9]+/
const ERROR_TITLE = 'Action item with this title already added to this Patient. Try another one.'

const ToDoMain: React.FC<IToDoMain> = ({
    toDoData,
    handleToDoData,
    isExistingPatient,
    isViewMode,
    setToDoData,
    isExistingTitle,
    setIsExistingTitle,
}) => {
    const [profileAvatar, setProfileAvatar] = useState<string>('')
    const { errorTitle, errorDesc, checkFieldValidation } = useToDoMainValidation()

    const [getPatientAvatar] = useLazyQuery(FETCH_USER_AVATAR_BY_EMAIL)
    const [downloadAvatarUrl, { loading: urlLoading }] = useLazyQuery(DOWNLOAD_SIGNED_URL)

    const { type, user } = toDoData
    const isAppointment = type === 'Appointment'
    const isContent = type === 'Content'
    const isLabResult = type === 'Lab Result'
    const isAfterVisitSummary = type === 'After Visit Summary'
    const typeAsClassName = useMemo(() => {
        return type?.toLowerCase().split(regex).join('-')
    }, [type])

    useEffect(() => {
        if (isExistingTitle) {
            const dialogWrapper = document.querySelector('.dialog-wrapper')

            dialogWrapper?.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }, [isExistingTitle])

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, limit: number) => {
        const { name, value } = e.target
        const isTitle = name === 'title'

        if (isTitle) {
            setIsExistingTitle(false)
        }

        checkFieldValidation(name, value, limit, handleToDoData)
    }

    const changeType = (e: SelectChangeEvent) => {
        const { name, value } = e.target
        const isAppointment = name === 'type' && value === 'Appointment'
        const isContent = name === 'type' && value === 'Content'
        const isActivities = name === 'type' && value === 'Activities'
        const isFreeText = name === 'type' && value === 'Free Text'
        const isLabRx = name === 'type' && value === 'Lab/Rx Order'
        const isLabResult = name === 'type' && value === 'Lab Result'
        const isAfterVisitSummary = name === 'type' && value === 'After Visit Summary'

        if (isFreeText) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                laboratory: '',
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: true,
                notificationText: NOTIFICATION_TEXT.FREE_TEXT,
            }))
            return
        }

        if (isActivities) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                laboratory: '',
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: true,
                notificationText: NOTIFICATION_TEXT.ACTIVITIES,
            }))
            return
        }

        if (isContent) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                duration: '10',
                laboratory: '',
                priority: 'Low',
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: false,
                notificationText: NOTIFICATION_TEXT.CONTENT,
            }))
            return
        }

        if (isAppointment) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                laboratory: '',
                priority: 'High',
                startingDate: '',
                startingTime: '',
                notifications: true,
                notificationText: NOTIFICATION_TEXT.APPOINTMENT,
            }))
            return
        }

        if (isLabRx) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: true,
                notificationText: NOTIFICATION_TEXT.LAB_RX,
            }))
            return
        }

        if (isLabResult) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                duration: '10',
                priority: 'High',
                title: `Lab Results - ${dateToString(Date.now(), "MM/dd/yyyy HH:mm aaaaa'm'")}`,
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: true,
                notificationText: NOTIFICATION_TEXT.LAB_RESULT,
            }))
            return
        }

        if (isAfterVisitSummary) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                duration: '10',
                priority: 'High',
                title: `Follow Up from your Care Team - ${dateToString(
                    Date.now(),
                    "MM/dd/yyyy HH:mm aaaaa'm'"
                )}`,
                isAddedToMedicalHistory: true,
                startingDate: String(Date.now()),
                startingTime: String(Date.now()),
                notifications: true,
                notificationText: NOTIFICATION_TEXT.AFTER_VISIT_SUMMARY,
            }))
            return
        }
    }

    return (
        <FormSection className={`main ${typeAsClassName}`}>
            <Typography className='section-title' gutterBottom>
                Main
            </Typography>
            <MainHeader>
                <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                    <Typography className='patient-typography required-field w-130'>
                        Patient
                    </Typography>
                    <ToDoFormUserList
                        isExistingPatient={isExistingPatient}
                        isViewMode={isViewMode}
                        urlLoading={urlLoading}
                        profileAvatar={profileAvatar}
                        userId={user?.id}
                        getPatientAvatar={getPatientAvatar}
                        downloadAvatarUrl={downloadAvatarUrl}
                        setProfileAvatar={setProfileAvatar}
                        handleToDoData={handleToDoData}
                    />
                </ToDoFormDropdown>
                <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                    <Typography marginLeft='40px' className='type-typography required-field'>
                        Type
                    </Typography>
                    <Select
                        required
                        label='Category'
                        name='type'
                        className='select-type'
                        value={type}
                        disabled={isViewMode}
                        onChange={changeType}
                    >
                        {categories.map((category: string) => {
                            return (
                                <DropDownItems key={category} value={category}>
                                    {category}
                                </DropDownItems>
                            )
                        })}
                    </Select>
                </ToDoFormDropdown>
            </MainHeader>
            <MainHeader>
                <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                    <Typography className='duration-typography required-field w-130'>
                        Duration
                    </Typography>
                    <ToDoFormText
                        required
                        className='todo-inputs duration'
                        type='text'
                        name='duration'
                        variant='standard'
                        size='small'
                        value={toDoData.duration}
                        disabled={isLabResult || isViewMode}
                        onChange={(e) => handleInput(e, 3)}
                    />
                    <Typography>min</Typography>
                </ToDoFormDropdown>
                <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                    <Typography marginLeft='40px' className='priority-typography required-field'>
                        Priority
                    </Typography>
                    <Select
                        required
                        className='dropdown-priority'
                        label='Priority'
                        name='priority'
                        disabled={isAppointment || isContent || isLabResult || isViewMode}
                        value={toDoData.priority}
                        onChange={(e) => handleToDoData(e.target.name, e.target.value)}
                    >
                        {priorities.map((priority: string) => {
                            return (
                                <DropDownItems key={priority} value={priority}>
                                    <ToDoPriorityTag
                                        src={prioritiesTag[priority]['img']}
                                        alt={prioritiesTag[priority]['alt']}
                                    />
                                    {priority}
                                </DropDownItems>
                            )
                        })}
                    </Select>
                </ToDoFormDropdown>
            </MainHeader>
            <MainHeader>
                <Typography className='title-typography required-field w-130'>Title</Typography>
                <ToDoFormText
                    value={toDoData?.title}
                    onChange={(e) => handleInput(e, 20)}
                    disabled={isLabResult || isAfterVisitSummary || isViewMode}
                    className={`todo-inputs ${isExistingTitle ? 'error-color' : ''}`}
                    type='text'
                    name='title'
                    variant='standard'
                    size='small'
                    placeholder='min 4 max 20 ch'
                    required
                />
                <ErrorField
                    className={errorTitle || isExistingTitle ? 'error-color' : ''}
                    bottom={-8}
                >
                    {isExistingTitle ? ERROR_TITLE : `(${toDoData?.title?.length ?? 0}/20)`}
                </ErrorField>
            </MainHeader>
            <MainHeader>
                <Typography className='w-130'>Description</Typography>
                <ToDoFormText
                    required
                    multiline
                    className='todo-inputs'
                    type='text'
                    name='description'
                    variant='standard'
                    size='small'
                    placeholder='max 65 ch'
                    disabled={isViewMode}
                    value={toDoData?.description}
                    onChange={(e) => handleInput(e, 65)}
                />
                <ErrorField className={errorDesc ? 'error-color' : ''} bottom={-8}>{`(${
                    toDoData?.description?.length ?? 0
                }/65)`}</ErrorField>
            </MainHeader>
        </FormSection>
    )
}

export default React.memo(ToDoMain)
