import React from 'react'
import { Typography } from '@mui/material'

import UploadToDoContentFile from '@/components/ToDo/ToDoContent/ToDoFreeText/UploadToDoContentFile'
import MedicalHistoryCheckbox from '@/components/UI/Buttons/MedicalHistoryCheckbox'

import { IToDoFreeText } from './interfaces'

import { MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'

const ToDoFreeText: React.FC<IToDoFreeText> = ({
    taskDescValue,
    changeTaskDesc,
    isViewMode,
    isAddedToMedicalHistory,
    handleCheckbox,
    toDoContentFile,
    setToDoContentFile,
}) => {
    return (
        <>
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>Text</Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='taskDescription'
                    variant='outlined'
                    size='small'
                    value={taskDescValue?.substring(0, 250)}
                    disabled={isViewMode}
                    onChange={changeTaskDesc}
                />
                <span className='content-limit'>{`(${taskDescValue?.length ?? 0}/250)`}</span>
            </MainHeader>
            <UploadToDoContentFile
                rowTitle='Uploaded files'
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
            <MedicalHistoryCheckbox
                isAddedToMedicalHistory={isAddedToMedicalHistory}
                isViewMode={isViewMode}
                handleCheckbox={handleCheckbox}
            />
        </>
    )
}

export default ToDoFreeText
