import React, { createContext, useState } from 'react'

interface ISelectedMessage {
    isEditMessage: boolean;
    isReplyMessage: boolean;
    message: string;
    messageImgUrl: string;
    nickname: string;
    messageId: number;
}

export const ChannelContext = createContext({
    isEditMessage: false,
    isReplyMessage: false,
    message: '',
    messageImgUrl: '',
    nickname: '',
    messageId: 0,
    selectMessage: (data: ISelectedMessage) => {},
    isJoined: false,
    setisJoined: (data: boolean) => {},
})

export const ChannelProvider = (props: any) => {
    const [selectedMessage, setSelectedMessage] = useState<ISelectedMessage>({
        isEditMessage: false,
        isReplyMessage: false,
        message: '',
        messageImgUrl: '',
        nickname: '',
        messageId: 0,
    })

    const selectMessage = (data: ISelectedMessage) => {
        setSelectedMessage(data)
    }
    const [isJoined, setisJoined] = useState(false)

    return (
        <ChannelContext.Provider
            value={{ ...selectedMessage, selectMessage, isJoined, setisJoined }}
            {...props}
        />
    )
}
