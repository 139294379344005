import { gql } from '@apollo/client'

export const FETCH_TODOS_QUERY = gql`
    query TodoList($offset: Int, $limit: Int, $search: String) {
        todoList(offset: $offset, limit: $limit, search: $search) {
            count
            todoList {
                id
                title
                priority
                patientId
                startingDate
                dueDate
                description
                status
                type
                user {
                    id
                    email
                    avatar
                    firstName
                    lastName
                    subscriptionStatus # Deprecated
                    activeSubscription {
                        subscriptionType
                    }
                }
                createdBy
            }
        }
    }
`

export const FETCH_TODO_BY_ID = gql`
    query TodoById($todoByIdId: ID!) {
        todoById(id: $todoByIdId) {
            id
            patientId
            title
            priority
            duration
            description
            optional
            dueDate
            status
            user {
                id
            }
            files {
                id
            }
            provider {
                id
            }
            intakeAssigned
            taskDescription
            notifications
            notificationsTime
            notificationText
            notificationsChannels
            isAddedToMedicalHistory
            practitionerId
            visitReason
            visitDate
            visitTime
            preVisitContent
            contentType
            searchContent
            searchByTag
            dueTime
            type
            praise
            startingDate
            startingTime
            repeat
            laboratory
            medicine
            pharmacy
            pharmacyLocation
            instructions
            labResultName
            labResultDate
            labResultExplanation
            afterVisitDate
            afterVisitReason
            afterAction
            afterMedicationList
            afterEducation
            afterTimeline
            createdBy
        }
    }
`

export const FETCH_USER_TODOS_BY_EMAIL = gql`
    query UserByEmail($email: String!) {
        userByEmail(email: $email) {
            id
            email
            sub
            todos {
                id
                patientId
                type
                title
                priority
                duration
                description
                optional
                dueDate
                dueTime
                intakeAssigned
                taskDescription
                notifications
                notificationsTime
                notificationText
                notificationsChannels
                files {
                    id
                }
                isAddedToMedicalHistory
                practitionerId
                visitReason
                visitDate
                visitTime
                preVisitContent
                status
                contentType
                searchByTag
                searchContent
                praise
                startingDate
                startingTime
                repeat
                laboratory
                medicine
                pharmacy
                pharmacyLocation
                instructions
                labResultName
                labResultDate
                labResultExplanation
                afterVisitDate
                afterVisitReason
                afterAction
                afterMedicationList
                afterEducation
                afterTimeline
                createdBy
            }
            patient {
                id
            }
        }
    }
`
