import { useEffect, useState, useCallback, useMemo } from 'react'
import Storyblok from '@/core/storyblok.config'

const MAX_LIMIT_FOR_REQ = 100

const useStoryblokContent = (contentPref: string | null, selectedTags: any, slugs: string) => {
    const [tags, setTags] = useState<any>([])
    const [totalItems, setTotalItems] = useState<number>(0)
    const [content, setContent] = useState<any>([])
    const [page, setPage] = useState<number>(1)

    const isFullData = useMemo(
        () => (totalItems ? MAX_LIMIT_FOR_REQ * page - MAX_LIMIT_FOR_REQ >= totalItems : false),
        [page, totalItems]
    )

    const resetAllData = () => {
        setContent([])
        setTotalItems(0)
        setPage(1)
    }

    const fetchData = useCallback(
        async (currentPage = page) => {
            try {
                const responseData = await Storyblok.get('cdn/stories', {
                    content_type: contentPref,
                    with_tag: selectedTags,
                    by_slugs: slugs,
                    page: currentPage,
                    per_page: MAX_LIMIT_FOR_REQ,
                })
                const newContent = responseData?.data?.stories || []
                setContent((prev: any[]) => [...prev, ...newContent])
                setPage((prev: number) => prev + 1)
                setTotalItems(responseData?.total || 0)

                if (selectedTags) {
                    const responseData = await Storyblok.get('cdn/tags')
                    const arrayTagNames = responseData.data.tags.map((tag: any) => tag.name)
                    setTags(arrayTagNames)
                }
            } catch (error) {
                console.error(error)
            }
        },
        [contentPref, selectedTags.length, slugs, page]
    )
    useEffect(() => {
        let isMounted = true
        if (isMounted && contentPref !== null) {
            resetAllData()
            fetchData(1)
        }
        return () => {
            isMounted = false
        }
    }, [contentPref, selectedTags.length, slugs])

    return { tags, content, fetchData, isFullData }
}

export default useStoryblokContent
