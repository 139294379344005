const getFullName = (
    firstName: string | string[],
    lastName: string,
    preferredName?: string[]
): string => {
    const patientPreferredName = preferredName?.length
        ? Array.isArray(preferredName)
            ? `(${preferredName?.join(' ')})`
            : preferredName
        : ''

    return `${firstName ?? '-'} ${lastName ?? '-'} ${patientPreferredName}`
}

export default getFullName
