import { gql } from '@apollo/client'

export const FETCH_STATES = gql`
    query States {
        states {
            id
            name
            abbreviation
        }
    }
`

export const FETCH_STATE_BY_ID = gql`
    query State($stateId: ID!) {
        state(id: $stateId) {
            id
            name
            abbreviation
        }
    }
`
