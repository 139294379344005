import React from 'react'
import SendIcon from '@mui/icons-material/Send'
import { IconButton } from '@mui/material'
import { ISendBtn } from './interfaces'

const SendBtn: React.FC<ISendBtn> = ({ sendMessage }) => {
    return (
        <div className='wrapper-btn--send'>
            <IconButton onClick={sendMessage}>
                <SendIcon fontSize='small' />
            </IconButton>
        </div>
    )
}

export default SendBtn
