import { gql } from '@apollo/client'

export const GET_B2B_PARTNER_LIST = gql`
    query getB2bPartnerList {
        b2bPartnerList {
            id
            name
            code
            resources {
                id
                slug
            }
        }
    }
`

export const GET_B2B_SUBPARTNER_LIST = gql`
    query getB2bSubPartnerList {
        b2bSubPartnerList {
            id
            name
            b2bPartner {
                id
                name
            }
            resources {
                id
                slug
            }
        }
    }
`
