import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import PatientContentHeader from './PatientContentHeader'
import PatientContentBody from '@/components/Patient/PatientContent/PatientContentBody'
import CircularLoader from '@/components/Loader/CircularLoader'
import { FETCH_PATIENT_DATA_BY_USER_ID } from '@/graphql/users/queries'
import { getDoBAndState, getFullName } from '@/utils'
import { TWO_MINUTES_IN_MILLISECONDS } from '@/core/constants'
import { IPatient } from './interfaces'

const PatientContent: React.FC = () => {
    const { id: userId } = useParams()
    const [patientData, setPatientData] = useState<IPatient>(() => ({} as IPatient));

    const [fetchPatientData, {loading, data: patientResponseData}] = useLazyQuery(FETCH_PATIENT_DATA_BY_USER_ID, {
        pollInterval: TWO_MINUTES_IN_MILLISECONDS,
    })

    useEffect(() => {
        fetchPatientData({variables: { userId: userId }})
    }, [userId])

    useEffect(() => {
        setPatientData(patientResponseData?.userById);
    }, [patientResponseData])

    if (loading) {
        return <CircularLoader size={60} color='primary' />
    }
    
    return (
        <>
            <PatientContentHeader
                name={getFullName(patientData?.firstName, patientData?.lastName)}
                avatarLink={patientData?.avatarLink}
                info={getDoBAndState(patientData?.birthDate, patientData?.state)}
                patientId={patientData?.patient?.id}
                subscriptionType={patientData?.activeSubscription?.subscriptionType}
                userId={userId}
            />
            <PatientContentBody email={patientData?.patient?.email} userId={userId} />
        </>
    )
}

export default PatientContent
