import { gql } from '@apollo/client'

export const FETCH_INTAKE_BY_USER_ID = gql`
    query IntakeByUserId($userId: ID!) {
        intakeByUserId(userId: $userId) {
            id
            isAllergic
            allergyResponse
            isOnMedication
            medicationResponse
            isUnderCare
            underCareResponse
            isUnderMentalHealthCare
            underMentalHealthCareResponse
            createdDate
            user {
                id
            }
        }
    }
`
