import React from 'react'
import { Avatar } from '@mui/material'

import { IPatientAvatar } from '@/components/UI/PatientAvatar/interfaces'
import {
    PatientAvatarFreemium,
    PatientAvatarWrapper,
    PatientCurrentPlan,
} from '@/components/UI/PatientAvatar/styles'
import { checkSubscriptionFreemium } from '@/utils'

const PatientAvatar: React.FC<IPatientAvatar> = ({
    src,
    currentPlan,
    fontSize,
    wrapperWidth,
    wrapperHeight,
    isBorder = true,
}) => {
    const isFreemium: boolean = checkSubscriptionFreemium(currentPlan)

    if (isFreemium && isBorder) {
        return (
            <PatientAvatarWrapper width={wrapperWidth} height={wrapperHeight}>
                <Avatar src={src} />
                <PatientAvatarFreemium />
                <PatientCurrentPlan className='current-plan' fontSize={fontSize}>
                    {currentPlan}
                </PatientCurrentPlan>
            </PatientAvatarWrapper>
        )
    }

    return (
        <PatientAvatarWrapper className='without-plan' width={wrapperWidth} height={wrapperHeight}>
            <Avatar src={src} />
        </PatientAvatarWrapper>
    )
}

export default PatientAvatar
