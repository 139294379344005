import { styled } from '@mui/material'

export const ChatsHeaderWrapper = styled('header')`
    & div {
        flex: 0 1 302px;
        &:nth-of-type(2) {
            flex: 1 0 auto;
        }

        &:nth-of-type(3) {
            border-left: 1px solid #c8c8c8;
            margin: 6px 0;
            maxwidth: 302px;
        }
    }
`
