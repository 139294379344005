import React from 'react'
import { Box } from '@mui/material'
import { CardHeaderPreview } from './styles'
import { IChatbotPreviewInfo } from './interfaces'
import { ChannelPreviewWrapper } from '@/components/Chat/ChannelPreview/styles'
import { stringToDate } from '@/utils/stringToDate'

const ChatbotPreviewInfo: React.FC<IChatbotPreviewInfo> = ({ chat, onChatClick }) => {
    const { lastMessageSent } = chat
    return (
        <ChannelPreviewWrapper onClick={onChatClick}>
            <CardHeaderPreview
                title={'Cara AI Chat'}
                subheader={<Box>{stringToDate(lastMessageSent)}</Box>}
                className='patients__patient-preview'
            />
        </ChannelPreviewWrapper>
    )
}

export default ChatbotPreviewInfo
