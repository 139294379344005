import React from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { TextField, Typography } from '@mui/material'

import {
    MEDICATION_REQUEST_BY_ID,
    MEDICATION_STATEMENT_BY_PATIENT_ID,
} from '@/graphql/labRx/queries'

import { ILabTestName } from '@/components/ToDo/ToDoContent/ToDoLab/interfaces'

import { CustomAutocomplete, MainHeader } from '@/components/ToDo/ToDoForm/styles'
import CircularLoader from '@/components/Loader/CircularLoader'

const MedicineName: React.FC<ILabTestName> = ({
    isViewMode,
    medicine,
    patientId,
    handleToDoData,
}) => {
    const { data, loading } = useQuery(MEDICATION_STATEMENT_BY_PATIENT_ID, {
        variables: {
            patientId,
        },
    })
    const [fetchMedicationById] = useLazyQuery(MEDICATION_REQUEST_BY_ID)

    const findMedicationByName = (medicationName: string) => {
        return data?.medicationStatementByPatientId?.find(
            ({ medicine }: any) => medicationName === medicine
        )
    }

    const changeMedicine = async (data: unknown | any) => {
        if (data?.medicationRequestId) {
            const { data: medicationById } = await fetchMedicationById({
                variables: {
                    medicationRequestByIdId: data?.medicationRequestId,
                },
            })

            if (handleToDoData) {
                handleToDoData('pharmacy', medicationById?.medicationRequestById?.pharmacy)
            }
        }

        if (handleToDoData) {
            handleToDoData('medicine', data?.medicine)
        }
    }

    return (
        <MainHeader className='content-body mt-0' alignItems='center'>
            <Typography className='activity-typography required-field w-130'>Medicine</Typography>
            {loading ? (
                <CircularLoader size={20} />
            ) : (
                <CustomAutocomplete
                    className='search-box'
                    value={findMedicationByName(medicine) ?? null}
                    onChange={(_: any, data: unknown | any) => changeMedicine(data)}
                    options={data?.medicationStatementByPatientId || []}
                    getOptionLabel={(option: any) => option.medicine ?? ''}
                    renderOption={(props: any, option: any) => {
                        return (
                            <li {...props} key={option.medicine}>
                                {option.medicine}
                            </li>
                        )
                    }}
                    renderInput={(params: any) => <TextField placeholder='Name' {...params} />}
                    disabled={isViewMode || !patientId}
                    disableClearable
                    disablePortal
                />
            )}
        </MainHeader>
    )
}

export default MedicineName
