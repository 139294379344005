import { IStoryblokDatasourceEntry } from '../interfaces'

export const updateAdminMessageOptionsValue = (
    adminMessageOptions: IStoryblokDatasourceEntry[],
    selectedAdminMessage: IStoryblokDatasourceEntry
) => {
    const updatedArray = adminMessageOptions.map((option: IStoryblokDatasourceEntry) => {
        if (option.id === selectedAdminMessage.id) {
            return {
                ...option,
                value: '1',
            }
        } else {
            return {
                ...option,
                value: '0',
            }
        }
    })

    return updatedArray
}
