import React, { useRef, useState } from 'react'
import { EditButtonConfirm } from '@/components/ToDo/ModalToDo/styles'
import { ProviderCardWrapper, CardContentValidationBox } from '@/components/Provider/styles'
import { CardContentBox } from '@/components/Patient/PatientContent/styles'
import { Box, TextField } from '@mui/material'
import axios from 'axios'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material//IconButton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { dateToString } from '@/utils'

export function exportCSVFile(items: string, fileTitle: string): void {
    const exportedFilename = fileTitle ? `${fileTitle}.csv` : 'export.csv'

    const blob = new Blob([items], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
}

const ProviderValidateCard: React.FC = () => {
    const BACKEND_ADDRESS_GENAI = window.env.BACKEND_ADDRESS_GENAI

    const token = sessionStorage.getItem('accessToken')
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [channelURL, setChannelURL] = useState('')
    const [expanded, setExpanded] = useState(false)

    const handleChannelURLChange = (event: any) => {
        setChannelURL(event.target.value)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const handleExportClick = async () => {
        try {
            if (startDate && endDate) {
                const url = BACKEND_ADDRESS_GENAI + '/chat-classification/export'

                let body
                if (channelURL) {
                    body = {
                        startDate: dateToString(startDate.getTime(), 'yyyy-MM-dd'),
                        endDate: dateToString(endDate.getTime(), 'yyyy-MM-dd'),
                        channelUrl: channelURL,
                    }
                } else {
                    body = {
                        startDate: dateToString(startDate.getTime(), 'yyyy-MM-dd'),
                        endDate: dateToString(endDate.getTime(), 'yyyy-MM-dd'),
                    }
                }

                const response = await axios.post(url, body, {
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                })
                exportCSVFile(response.data.data, 'validate_messages')
            }
        } catch (error) {
            console.error('Error exporting CSV:', error)
        }
    }

    const handleFileChange = async (event: any) => {
        const file = event.target.files[0]
        setSelectedFile(file)

        try {
            const formData = new FormData()
            formData.append('file', file)
            await axios.post(BACKEND_ADDRESS_GENAI + '/chat-classification/import', formData, {
                headers: {
                    'Content-type': 'multipart/form-data;',
                    Authorization: token ? `Bearer ${token}` : '',
                },
            })
        } catch (error) {
            console.error('Error uploading file:', error)
        }
    }

    return (
        <ProviderCardWrapper>
            <CardHeader
                title={
                    <span style={{ fontSize: 16, color: 'rgba(127, 131, 172)' }}>
                        Cara AI Validation
                    </span>
                }
                action={
                    <IconButton
                        aria-expanded={expanded}
                        aria-label='show more'
                        onClick={handleExpandClick}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
                <Box
                    sx={{
                        display: 'inline-block',
                        width: '50%',
                    }}
                >
                    <CardContentValidationBox>
                        <label htmlFor='start-date'>Start Date:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat='MM/dd/yyyy'
                                value={startDate}
                                onChange={(val) => {
                                    setStartDate(new Date(val || ''))
                                }}
                                renderInput={({ onKeyDown, ...params }) => (
                                    <TextField
                                        size='small'
                                        style={{ height: '37px', margin: '0 0 0 10px' }}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                            return
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </CardContentValidationBox>
                    <CardContentValidationBox>
                        <label htmlFor='end-date'>End Date:</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                inputFormat='MM/dd/yyyy'
                                value={endDate}
                                onChange={(val) => {
                                    setEndDate(new Date(val || ''))
                                }}
                                renderInput={({ onKeyDown, ...params }) => (
                                    <TextField
                                        size='small'
                                        style={{ height: '37px', margin: '0 0 0 10px' }}
                                        onKeyDown={(e) => {
                                            e.preventDefault()
                                        }}
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </CardContentValidationBox>
                    <CardContentValidationBox>
                        <label htmlFor='channel-url'> Channel URL:</label>
                        <input
                            type='text'
                            id='channel-url'
                            style={{
                                marginLeft: 10,
                                height: '35px',
                                border: '1px solid #CCCCCC',
                                borderRadius: '4px',
                            }}
                            value={channelURL}
                            onChange={handleChannelURLChange}
                        />
                    </CardContentValidationBox>
                    <CardContentBox>
                        <EditButtonConfirm
                            onClick={handleExportClick}
                            className='export-import-btn'
                            disabled={!startDate || !endDate}
                            variant='contained'
                            color='info'
                        >
                            Export
                        </EditButtonConfirm>
                        <input
                            type='file'
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                        />
                    </CardContentBox>
                </Box>
                <Box
                    sx={{
                        display: 'inline-block',
                        width: '50%',
                    }}
                >
                    <CardContentBox>
                        <EditButtonConfirm
                            onClick={() => fileInputRef?.current?.click()}
                            className='export-import-btn'
                            variant='contained'
                            color='info'
                        >
                            Import
                            {selectedFile && <p>: {selectedFile.name}</p>}
                        </EditButtonConfirm>
                    </CardContentBox>
                </Box>
            </Collapse>
        </ProviderCardWrapper>
    )
}

export default ProviderValidateCard
