import React from 'react'

import { ITableRowNames } from '../../interfaces'
import { AccordionFieldsName } from '../../styles'

const TableRowNames: React.FC<ITableRowNames> = ({ icon, name }) => {
    return (
        <AccordionFieldsName
            component='th'
            scope='row'
            align='left'
            sx={{ display: 'flex', alignItems: 'start', paddingRight: 1 }}
        >
            {icon}
            {name}
        </AccordionFieldsName>
    )
}

export default TableRowNames
