import React from 'react'

import { IChatPatientPanelAccordion } from '../interfaces'
import { Accordion, AccordionSummary, AccordionDetails, AccordionTitleText } from '../styles'
import { accordionSections } from './accordionComponents/accordionFields'
import SupportResources from './accordionComponents/SupportResources'
import MedicalHistory from './accordionComponents/MedicalHistory'
import MyPath from './accordionComponents/MyPath'
import PersonalInformation from './accordionComponents/PersonalInformation'

const PanelAccordion: React.FC<IChatPatientPanelAccordion> = ({ patientUserId, patientData }) => {
    const [expanded, setExpanded] = React.useState<string | false>('')

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <div>
            {accordionSections.map((section: any, index: number) => {
                return (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            aria-controls={`panel${index}d-content`}
                            id={`panel${index}d-header`}
                        >
                            <AccordionTitleText>{section.title}</AccordionTitleText>
                        </AccordionSummary>

                        <AccordionDetails>
                            {accordionSections[index].title === 'Personal Information' && (
                                <PersonalInformation patientData={patientData} section={section} />
                            )}
                            {accordionSections[index].title === 'Medical History' && (
                                <MedicalHistory patientUserId={patientUserId} section={section} />
                            )}
                            {accordionSections[index].title === 'My Path' && (
                                <MyPath patientUserId={patientUserId} />
                            )}
                            {accordionSections[index].title === 'Support Resources' && (
                                <SupportResources patientData={patientData} />
                            )}
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    )
}

export default PanelAccordion
