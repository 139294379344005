import { useState } from 'react'

const regNumbers = new RegExp('(^[0-9]+$|^$)')

const useToDoMainValidation = () => {
    const [errorTitle, setErrorTitle] = useState<boolean>(false)
    const [errorDesc, setErrorDesc] = useState<boolean>(false)

    function checkFieldValidation(name: string, value: string, limit: number, handleToDoData: any) {
        switch (name) {
            case 'duration': {
                if (value?.length > limit) {
                    return
                }

                if (regNumbers.test(value)) {
                    handleToDoData(name, value)
                }

                return
            }
            case 'title': {
                if (value?.length > limit) {
                    setErrorTitle(true)
                } else {
                    setErrorTitle(false)
                }

                break
            }
            case 'description': {
                if (value?.length > limit) {
                    setErrorDesc(true)
                } else {
                    setErrorDesc(false)
                }
            }
        }

        handleToDoData(name, value)
    }

    return { errorTitle, errorDesc, checkFieldValidation }
}

export default useToDoMainValidation
