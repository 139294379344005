export const NOTIFICATION_TEXT = {
    LAB_ORDER:
        'Your provider has added your necessary lab work. Open the Caraway App to read more.',
    RX_PRESCRIPTION:
        'Your provider has prescribed your medication. Open the Caraway App to read more.',
    FREE_TEXT: 'Your provider has added a message for you. Open the Caraway App to read more.',
    ACTIVITIES:
        'Your provider has added a new activity for you. Open the Caraway App to read more.',
    APPOINTMENT:
        'Your visit is coming up in 24 hours. Open the Caraway App to read more.\nYour visit is in 15 minutes. Open the Caraway App to read more.',
    CONTENT: '',
    LAB_RX: '',
    LAB_RESULT: 'Your provider has uploaded your lab results. Open the Caraway app to read more.',
    AFTER_VISIT_SUMMARY:
        'Your provider has uploaded your after visit summary. Open the Caraway app to read more.',
}
