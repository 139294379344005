import React, { useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Typography, Box } from '@mui/material'

import { IChannelFilterBtn } from '@/components/Chat/ChannelHeader/interfaces'

import { ReactComponent as SettingVerticalSvg } from '@/assets/images/settings-vertical.svg'
import { FormControlWrapper, IconButtonWrapper } from '@/components/Chat/ChannelHeader/styles'
import { MessageMenuWrapper } from '../ChatMessage/styles'
import { CustomButton, ClearAllFilterBtn } from './styles'

const ChannelFilterBtn: React.FC<IChannelFilterBtn> = ({
    filterCheckboxes,
    filterCriteria,
    handleSetFilter,
    clearFilter,
    handleResetFilterState,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        clearFilter()
    }

    const handleApplyFilter = () => {
        handleSetFilter()
        setAnchorEl(null)
    }

    return (
        <>
            <IconButtonWrapper onClick={handleClick} disableRipple className={open ? 'active' : ''}>
                <SettingVerticalSvg />
            </IconButtonWrapper>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className='criteria-menu'
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <FormControlWrapper>
                    <Box className='header-title'>
                        <Typography className='formControl-title'>Filter</Typography>
                        <ClearAllFilterBtn onClick={handleResetFilterState}>
                            Clear All
                        </ClearAllFilterBtn>
                    </Box>
                    <FormGroup className='checkboxes-box'>
                        {filterCheckboxes?.map(({ title, checkboxes }) => (
                            <Box key={title}>
                                <Typography className='formControl-sub-title'>{title}</Typography>
                                {checkboxes?.map(({ checked, label, name }) => (
                                    <FormControlLabel
                                        key={label}
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                name={name}
                                                onChange={(e) => filterCriteria(e, title)}
                                            />
                                        }
                                        label={label}
                                        value='end'
                                        labelPlacement='end'
                                        className='formControl-checkbox'
                                    />
                                ))}
                            </Box>
                        ))}
                    </FormGroup>
                    <CustomButton onClick={handleApplyFilter} className='apply-filter_btn'>
                        Apply Filter
                    </CustomButton>
                </FormControlWrapper>
            </MessageMenuWrapper>
        </>
    )
}

export default ChannelFilterBtn
