const combineDateAndTime = (date: Date | null, time: Date | null): Date | null => {
    if (!date || !time) {
        return date
    }

    date = new Date(date)

    date.setHours(time.getHours())
    date.setMinutes(time.getMinutes())
    date.setSeconds(time.getSeconds())

    return date
}

export default combineDateAndTime
