import React, { useContext } from 'react'
import Linkify from 'linkify-react'
import { Avatar, Box } from '@mui/material'

import MessageMenu from './MessageMenu'

import { ChannelContext } from '@/context/ChannelContext'
import { getTime, getSenderNickname } from '@/utils'

import { ICompanionMessage } from '../interfaces'

import {
    CompanionMessageContent,
    CompanionMessageWrapper,
    CompanionReplyWrapper,
    MessageDateAndSettings,
    MessageInfo,
    MessageMedia,
    MessageWrapper,
} from '../styles'

const CompanionMessage: React.FC<ICompanionMessage> = ({
    coverUrl,
    chatMessage,
    createdAt,
    messageImgUrl,
    messageId,
    updatedAt,
    children,
    parentMessage,
    sender,
    patientNickname,
}) => {
    const { selectMessage } = useContext(ChannelContext)
    const messageContent: string | JSX.Element = chatMessage ? (
        chatMessage
    ) : (
        <MessageMedia component='img' height='194' image={messageImgUrl} />
    )

    const nickname = getSenderNickname(sender, patientNickname)

    return (
        <MessageWrapper>
            <Avatar className='companion-avatar' src={coverUrl} />
            <CompanionReplyWrapper>
                <Linkify options={{ target: '_blank' }}>{children}</Linkify>
                <CompanionMessageWrapper>
                    <CompanionMessageContent>
                        <Box className='user_nickname'>{nickname}</Box>
                        <Box className='user_message'>
                            <Linkify options={{ target: '_blank' }}>{messageContent}</Linkify>
                        </Box>
                    </CompanionMessageContent>
                    <MessageInfo className='message__info-companion'>
                        {Boolean(updatedAt) && (
                            <MessageDateAndSettings className='edited-msg'>
                                Edited
                            </MessageDateAndSettings>
                        )}
                        <MessageDateAndSettings>{getTime(createdAt)}</MessageDateAndSettings>
                        <MessageMenu
                            chatMessage={chatMessage}
                            messageImgUrl={messageImgUrl}
                            messageId={messageId}
                            selectMessage={selectMessage}
                            nickname={nickname}
                            parentMessage={parentMessage}
                            isCompanionMessage
                        />
                    </MessageInfo>
                </CompanionMessageWrapper>
            </CompanionReplyWrapper>
        </MessageWrapper>
    )
}

export default CompanionMessage
