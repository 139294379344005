import { ITabsMenuItem } from '@/components/UI/TabsMenu/interfaces'
import { TabAdminMessages } from '../components/TabAdminMessages'
import { TabGroupMessages } from '../components/TabGroupMessages'

export const headerMenuItem = 'Message Settings'

export const subHeaderMenuItems: ITabsMenuItem[] = [
    {
        id: '1',
        name: 'Admin Messages',
        isAdminTab: false,
    },
    {
        id: '2',
        name: 'Group Messages',
        isAdminTab: false,
    },
]

export const subHeaderMenuItemComponents = [
    {
        id: '1',
        isAdminTab: false,
        TabComponent: TabAdminMessages,
    },
    {
        id: '2',
        isAdminTab: false,
        TabComponent: TabGroupMessages,
    },
]
