import { gql } from '@apollo/client'

export const CREATE_UNVERIFIED_COLLEGE = gql`
    mutation CreateUnverifiedCollege($unverifiedCollegeInput: UnverifiedCollegeInput) {
        createUnverifiedCollege(unverifiedCollegeInput: $unverifiedCollegeInput) {
            id
            name
            state
            isReviewed
            user {
                id
            }
        }
    }
`

export const UPDATE_UNVERIFIED_COLLEGE = gql`
    mutation UpdateUnverifiedCollege(
        $updateUnverifiedCollegeId: ID!
        $unverifiedCollegeInput: UnverifiedCollegeInput
    ) {
        updateUnverifiedCollege(
            id: $updateUnverifiedCollegeId
            unverifiedCollegeInput: $unverifiedCollegeInput
        ) {
            id
            name
            state
            isReviewed
            user {
                id
            }
        }
    }
`
