import React from 'react'
import { useRef } from 'react'
import { ChatConversations } from './ChatConversation'
import { ChatInput } from './ChatInput'
import { IChatUIProps } from '@/pages/Dashboard/DashboardChatbotPage/interfaces'
import { ChatContainer, ChatConversationContainer, ChatInputContainer } from './styles'

export const ChatUI = ({
    userId,
    disabled,
    conversations,
    isQuerying,
    customSubmitIcon,
    placeholder,
    onSubmit,
    token,
}: IChatUIProps) => {
    const chatConversationsContainerRef = useRef<HTMLDivElement>(null)

    return (
        <ChatContainer>
            <ChatConversationContainer ref={chatConversationsContainerRef}>
                <ChatConversations
                    userId={userId}
                    conversations={conversations}
                    isQuerying={isQuerying}
                    chatConversationsContainerRef={chatConversationsContainerRef}
                    token={token}
                />
            </ChatConversationContainer>
            <ChatInputContainer>
                <ChatInput
                    disabled={disabled}
                    customSubmitIcon={customSubmitIcon}
                    onSubmit={onSubmit}
                    placeholder={placeholder}
                />
            </ChatInputContainer>
        </ChatContainer>
    )
}
