import React from 'react'
import { Typography, TextareaAutosize } from '@mui/material'

import { IInputField } from '../interfaces'

const TextareaField: React.FC<IInputField> = ({
    label,
    value,
    isEditMode,
    onChangeHandler,
    name,
    isDisabled,
    classNames = {},
}) => {
    const { typographyClass: typographyClassName, inputClass: inputClassName } = classNames
    return (
        <>
            <Typography className={typographyClassName}>{label}</Typography>
            <TextareaAutosize
                value={value}
                name={name}
                disabled={!isEditMode || isDisabled}
                onChange={onChangeHandler}
                className={inputClassName}
            />
        </>
    )
}

export default TextareaField
