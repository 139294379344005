import { Box, CircularProgress, styled } from '@mui/material'

export const CircularLoaderWrapper = styled(CircularProgress)`
    position: absolute;
    left: 45%;
    top: 45%;

    &.relative {
        position: relative;
        left: 0;
        top: 0;
    }
`

export const EllipsisLoaderWrapper = styled(Box)`
    display: flex;
    position: relative;
    width: 60px;
    height: 30px;

    & div {
        position: absolute;
        top: 15px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #fc7960;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    & div:nth-of-type(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    & div:nth-of-type(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    & div:nth-of-type(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    & div:nth-of-type(4) {
        left: 44px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(18px, 0);
        }
    }
`
