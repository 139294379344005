import { gql } from '@apollo/client'

// Deprecated and will be removed - use UPDATE_PATIENT_ENTITY_DATA instead.
export const UPDATE_PATIENT_DATA = gql`
    mutation UpdatePatient($updatePatientId: ID!, $patientInput: PatientInput!) {
        updatePatient(id: $updatePatientId, patientInput: $patientInput) {
            id
            birthDate
            firstName
            lastName
            gender
        }
    }
`

export const UPDATE_PATIENT_ENTITY_DATA = gql`
    mutation UpdatePatientEntity($updatePatientId: ID!, $patientInput: UpdatePatientInput!) {
        updatePatientEntity(id: $updatePatientId, patientInput: $patientInput) {
            id
            birthDate
            firstName
            lastName
            gender
        }
    }
`
