import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import classNames from 'classnames'

import { CREATE_RESOURCES, DELETE_RESOURCES } from '@/graphql/resources/mutations'
import { GET_B2B_SUBPARTNER_LIST } from '@/graphql/partners/queries'

import { ISubPartnerSingleRow, ISubPartner } from '@/components/CollegesAndPartners/interfaces'
import { ListTableBodyRow, ListTableCell } from '@/components/CollegesAndPartners/styles'
import ThreeDotBtn from '@/components/CollegesAndPartners/components/ThreeDotBtn'
import { ResourceTypesEnum } from '@/constants/resourceConstants'
import ModalSubPartner from '../modals/ModalSubPartner'
import { getReadableResources } from '../utils/getReadableResources'

const SubPartnerSingleRow: React.FC<ISubPartnerSingleRow> = ({ subPartner }) => {
    const [isSubPartnerMenuOpen, setIsSubPartnerMenuOpen] = useState<boolean>(false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const [createResources] = useMutation(CREATE_RESOURCES)
    const [deleteResources] = useMutation(DELETE_RESOURCES)

    const readableResources = getReadableResources(subPartner?.resources)

    const openPartnerMenu = () => {
        setIsSubPartnerMenuOpen((prevState) => !prevState)
    }

    const enableEditMode = () => {
        setIsEditMode((prevState) => !prevState)
    }

    const getUpdateFunction = (cacheReplaceFn: (cachedSubPartner: ISubPartner) => any) => {
        return (cache: any) => {
            const cachedB2bSubPartnerList: any = cache.readQuery({
                query: GET_B2B_SUBPARTNER_LIST,
            })
            const newCachedB2bSubPartnerList = cachedB2bSubPartnerList?.b2bSubPartnerList?.map(
                (cachedSubPartner: any) => {
                    if (cachedSubPartner.id === subPartner.id) {
                        return {
                            ...cachedSubPartner,
                            ...cacheReplaceFn(cachedSubPartner),
                        }
                    }
                    return cachedSubPartner
                }
            )

            cache.writeQuery({
                query: GET_B2B_SUBPARTNER_LIST,
                data: {
                    b2bSubPartnerList: newCachedB2bSubPartnerList,
                },
            })
        }
    }

    const handleEditSubPartner = async (deletedResourceIds: any, createdResources: any) => {
        !!deletedResourceIds.length &&
            (await deleteResources({
                variables: {
                    resourceIds: deletedResourceIds,
                },
                update: getUpdateFunction((cachedSubPartner: ISubPartner) => {
                    return {
                        resources: cachedSubPartner?.resources?.filter(
                            (resource: any) => !deletedResourceIds.includes(resource.id)
                        ),
                    }
                }),
            }))

        !!createdResources.length &&
            (await createResources({
                variables: {
                    entityId: parseFloat(subPartner?.id),
                    partnerType: ResourceTypesEnum.B2B_SUB_PARTNER,
                    slugs: createdResources,
                },
                update: getUpdateFunction((cachedSubPartner: ISubPartner) => {
                    return { resources: cachedSubPartner?.resources?.concat(createdResources) }
                }),
            }))
    }

    return (
        <ListTableBodyRow
            className={classNames({
                'open-menu': isSubPartnerMenuOpen,
            })}
        >
            <ListTableCell>{subPartner?.b2bPartner?.name || '-'}</ListTableCell>
            <ListTableCell>{subPartner?.name}</ListTableCell>
            <ListTableCell className='partner-resources'>{readableResources || '-'}</ListTableCell>
            <ListTableCell>
                <ThreeDotBtn openMenu={openPartnerMenu} enableEditMode={enableEditMode} />
            </ListTableCell>
            {isEditMode && (
                <ModalSubPartner
                    open={isEditMode}
                    handleClose={enableEditMode}
                    handleEditSubPartner={handleEditSubPartner}
                    subPartner={subPartner}
                />
            )}
        </ListTableBodyRow>
    )
}

export default SubPartnerSingleRow
