import { gql } from '@apollo/client'

export const FETCH_ROLES = gql`
    query Roles {
        roles {
            id
            name
        }
    }
`

export const FETCH_ROLE_BY_ID = gql`
    query Role($roleId: ID!) {
        role(id: $roleId) {
            id
            name
        }
    }
`
