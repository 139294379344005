import React from 'react'

import { Box, Button, styled, TableCell, Typography } from '@mui/material'

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export const PatientPanelContainer = styled(Box)(() => ({
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '&-ms-overflow-style': 'none',
}))

export const PatientPanelHeader = styled(Box)(({ theme }) => ({
    minHeight: '120px',

    '& .MuiCardHeader-root': {
        padding: 0,
    },

    '& .MuiCardHeader-title': {
        fontWeight: 700,
        fontSize: '18px',
        textTransform: 'capitalize',

        a: {
            color: theme.palette.violet.main,
            textDecoration: 'none',
        },

        '&:hover': {
            textDecoration: 'underline',
        },
    },

    '& .MuiCardHeader-subheader': {
        color: 'rgba(64, 31, 82, 0.6)',

        '& .partner-info': {
            fontSize: '0.8rem',
        },
    },
}))

export const RedirectButton = styled(Button)(({ theme }) => ({
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.violet.main,
    textTransform: 'inherit',
    textDecoration: 'underline',
    padding: 0,

    '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
}))

export const RedirectIcon = styled(OpenInNewIcon)(({ theme }) => ({
    fontSize: 15,
    color: '#401F52',
}))

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },

    '&:before': {
        display: 'none',
    },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon fontSize='small' sx={{ color: '#401f52' }} />}
        {...props}
    />
))(() => ({
    borderBottom: '1.75px solid #A0AEBA',
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.5),
}))

export const AccordionTitleText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    lineHeight: '26px',
    color: theme.palette.violet.main,
}))

export const AccordionFieldsName = styled(TableCell)(({ theme }) => ({
    fontSize: '14px',
    color: theme.palette.purple.light,
    border: 0,
    padding: '0px 0px 15px 0px',
}))

export const AccordionFieldsContent = styled(TableCell)(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.purple.dark,
    border: 0,
    padding: '0px 0px 15px 0px',
}))

export const MyPathSkillsetsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingBottom: '15px',

    '& .my-path__name': {
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: 600,
        color: theme.palette.violet.main,
    },

    '& .my-path__date': {
        fontSize: '14px',
        color: theme.palette.purple.light,
    },

    '& .my-path__intensity-block': {
        display: 'flex',

        '& > p': {
            flex: 1,
            fontSize: '15px',
            color: theme.palette.purple.dark,
        },
    },
}))

export const MyPathSkillsetsEmptyState = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingBottom: '15px',
    fontSize: '14px',
    fontStyle: 'italic',
    lineHeight: '24px',
    color: theme.palette.purple.dark,
}))

export const SupportResourcesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingBottom: '15px',

    '& .campus-crisis__numbers, & .campus-crisis__pharmacy': {
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.palette.purple.dark,

        '& p:nth-of-type(1)': {
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.palette.purple.light,
            marginBottom: '5px',
        },

        '& > p': {
            margin: 0,
        },
    },

    '& .campus-crisis__pharmacy': {
        marginTop: '20px',
    },
}))

export const SupportResourcesEmptyState = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingBottom: '15px',
    fontSize: '14px',
    fontStyle: 'italic',
    lineHeight: '24px',
    color: theme.palette.purple.dark,
}))
