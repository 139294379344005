import React from 'react'
import classNames from 'classnames'
import { Box } from '@mui/material'

import OwnMessage from '@/components/Chat/ChatMessage/ChatItems/OwnMessage'

import { cropName } from '@/utils'

import { IMyReplyMessage } from '../interfaces'

import { CompanionMessageContent, MessageMedia } from '../styles'

const MyReplyMessage: React.FC<IMyReplyMessage> = ({
    messageStatus,
    myMessage,
    deleteMessage,
    isReplyMessage,
    parentMessage,
    nickname,
    myOwnId,
}) => {
    const { message: chatMessage, messageId, messageType, createdAt, updatedAt, url } = myMessage
    const senderNickname =
        parentMessage?._sender.userId === myOwnId ? parentMessage?._sender.nickname : nickname

    const replyMessageContent: string | JSX.Element = parentMessage?.message ? (
        cropName(parentMessage?.message, 40)
    ) : (
        <MessageMedia component='img' height='120' image={parentMessage?.url} />
    )

    return (
        <OwnMessage
            messageStatus={messageStatus}
            chatMessage={chatMessage}
            nickname={nickname}
            messageId={messageId}
            createdAt={createdAt}
            updatedAt={updatedAt}
            messageImgUrl={url}
            parentMessage={parentMessage}
            deleteMessage={deleteMessage}
            messageType={messageType}
        >
            <CompanionMessageContent
                className={classNames({
                    'reply-msg': isReplyMessage,
                })}
            >
                <Box className='user_nickname'>{senderNickname}</Box>
                <Box className='user_message'>{replyMessageContent}</Box>
            </CompanionMessageContent>
        </OwnMessage>
    )
}

export default MyReplyMessage
