import React from 'react'

import { IListTableSingleRow } from '@/components/CollegesAndPartners/interfaces'
import CollegeSingleRow from './CollegeSingleRow'
import PartnerSingleRow from './PartnerSingleRow'
import SubPartnerSingleRow from './SubPartnerSingleRow'
import UnverifiedCollegeSingleRow from './UnverifiedCollegeSingleRow'

const ListTableSingleRow: React.FC<IListTableSingleRow> = ({
    item,
    search,
    offset,
    limit,
    count,
    isPartners,
    isSubPartners,
    isColleges,
    isUnverifiedColleges,
    testId,
}) => {
    return (
        <>
            {isColleges && (
                <CollegeSingleRow
                    college={item}
                    search={search}
                    offset={offset}
                    limit={limit}
                    count={count}
                    testId={testId}
                />
            )}
            {isPartners && <PartnerSingleRow partner={item} />}
            {isSubPartners && <SubPartnerSingleRow subPartner={item} />}
            {isUnverifiedColleges && (
                <UnverifiedCollegeSingleRow
                    unverifiedCollege={item}
                    offset={offset}
                    limit={limit}
                />
            )}
        </>
    )
}

export default ListTableSingleRow
