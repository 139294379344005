import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

import { getBase64, downloadFileByBase64 } from '@/utils'

import { IFileMenuBtn } from '@/components/ToDo/ToDoContent/interfaces'
import { ReactComponent as EditMessageSvg } from '@/assets/images/edit-message.svg'
import { MessageMenuWrapper } from '@/components/Chat/ChatMessage/styles'

const FileMenuBtn: React.FC<IFileMenuBtn> = ({
    file: fullFile,
    deleteUploadedFile,
    toggleEditMode,
    fileId,
}) => {
    const [downloadSignedUrl] = useLazyQuery(DOWNLOAD_SIGNED_URL, {
        variables: {
            downloadSignedUrlId: fileId,
        },
    })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const downloadFile = async () => {
        if (fileId) {
            const { data } = await downloadSignedUrl()
            const { name, url } = data.downloadSignedUrl

            downloadFileByBase64(name, url)
        } else {
            const fileInBase64: any = await getBase64(fullFile)

            downloadFileByBase64(fullFile?.name, fileInBase64)
        }
        handleClose()
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='upload-file_wrapper'
            >
                <MenuItem onClick={downloadFile} className='download-btn'>
                    <IconButton disableRipple>
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                    Download
                </MenuItem>

                <MenuItem onClick={toggleEditMode} className='edit-btn'>
                    <IconButton disabled>
                        <EditMessageSvg />
                    </IconButton>
                    Edit
                </MenuItem>

                <MenuItem onClick={deleteUploadedFile} className='cancel-btn'>
                    <IconButton disableRipple className='complete-btn'>
                        <DeleteForeverOutlinedIcon />
                    </IconButton>
                    Delete
                </MenuItem>
            </MessageMenuWrapper>
        </>
    )
}

export default FileMenuBtn
