export const headerMenuColleges: string[] = [
    'College Name',
    'State',
    'Date Added',
    'Students',
    'Campus Crisis Article',
    '',
]

export const headerMenuUnverifiedColleges: string[] = [
    'College Name',
    'College State',
    'Created By (Patient)',
    'Reviewed?',
]

export const headerMenuPartners: string[] = ['Partner Name', 'Partner Code', 'Resource List', '']

export const headerMenuSubPartners: string[] = [
    'Partner Name',
    'Subpartner Name',
    'Resource List',
    '',
]
