import React from 'react'
import { Table, TableBody, TableRow } from '@mui/material'

import { AccordionFieldsContent } from '../../styles'
import { IPersonalInformation, IPersonalInformationFields } from '../../interfaces'
import TableRowNames from './tableRowNames'
import ResponseTooltip from './responseTooltip'
import { formatISO, getSubscriptionCurrentPlan } from '@/utils'

const PersonalInformation: React.FC<IPersonalInformation> = ({ patientData, section }) => {
    const combinedUserPatientData = patientData?.userById

    const b2bPartner: string = combinedUserPatientData?.activeSubscription?.b2bPartner
    const b2bSubPartner: string = combinedUserPatientData?.activeSubscription?.b2bSubPartner
    const readablePartner: string = b2bSubPartner ? b2bSubPartner : b2bPartner ? b2bPartner : ''

    // Any fields that are null will display as '--'
    const personalInformationData: any = {
        preferredName: combinedUserPatientData?.patient?.preferredName?.[0],
        preferredGender: combinedUserPatientData?.preferredGender,
        sexAtBirth: combinedUserPatientData?.patient?.gender,
        pronouns: combinedUserPatientData?.pronouns,
        email: combinedUserPatientData?.patient?.email,
        homeAddress: {
            line: combinedUserPatientData?.patient?.homeAddress?.line?.join(' '),
            cityAndState:
                combinedUserPatientData?.patient?.homeAddress?.city &&
                combinedUserPatientData?.patient?.homeAddress?.state
                    ? combinedUserPatientData?.patient?.homeAddress?.city +
                      ', ' +
                      combinedUserPatientData?.patient?.homeAddress?.state
                    : null,
        },
        phone: combinedUserPatientData?.patient?.phone,
        college: combinedUserPatientData?.college?.name,
        emergencyContact: combinedUserPatientData?.patient?.contact,
        membership: getSubscriptionCurrentPlan(
            combinedUserPatientData?.activeSubscription?.subscriptionType,
            combinedUserPatientData?.revCatSubscriptions?.[0]?.currentPlan,
            readablePartner
        ),
        planStart:
            combinedUserPatientData?.activeSubscription?.startDate &&
            formatISO(combinedUserPatientData?.activeSubscription?.startDate, 'MMM dd yyyy'),
        planExpiration:
            combinedUserPatientData?.activeSubscription?.endDate &&
            formatISO(combinedUserPatientData?.activeSubscription?.endDate, 'MMM dd yyyy'),
    }

    const renderTableRow = (rowKey: string) => {
        switch (rowKey) {
            case 'homeAddress':
                return (
                    <>
                        {personalInformationData[rowKey]?.line &&
                        personalInformationData[rowKey]?.cityAndState ? (
                            <>
                                {Object.keys(personalInformationData[rowKey] || {}).map(
                                    (address: any, index: number) => (
                                        <ResponseTooltip
                                            response={personalInformationData[rowKey][address]}
                                            key={index}
                                        />
                                    )
                                )}
                            </>
                        ) : (
                            '--'
                        )}
                    </>
                )
            case 'emergencyContact':
                return (
                    <>
                        {personalInformationData[rowKey]?.name ? (
                            <>
                                {Object.keys(personalInformationData[rowKey] || {}).map(
                                    (contact: any, index: number) => (
                                        <ResponseTooltip
                                            response={personalInformationData[rowKey][contact]}
                                            key={index}
                                        />
                                    )
                                )}
                            </>
                        ) : (
                            '--'
                        )}
                    </>
                )
            default:
                return <ResponseTooltip response={personalInformationData[rowKey] || '--'} />
        }
    }

    return (
        <Table aria-label='simple table' sx={{ tableLayout: 'fixed' }}>
            <TableBody>
                {section.component.map((row: IPersonalInformationFields) => (
                    <TableRow key={row.name} sx={{ '& > *': { border: 0, padding: 0 } }}>
                        <TableRowNames icon={row.icon} name={row.name} />
                        <AccordionFieldsContent align='left'>
                            {renderTableRow(row.id)}
                        </AccordionFieldsContent>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default PersonalInformation
