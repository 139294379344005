import { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

import { useDebounce, useObserver } from '@/hooks'

import { FETCH_USERS_WITH_CHATS } from '@/graphql/users/queries'
import { TWO_MINUTES_IN_MILLISECONDS } from '@/core/constants'
import { ICriteriaFilter, IUser } from '@/components/Chat/ChannelHeader/interfaces'
import getSessionUser from '../utils/getSessionUser'
import { getProviderSub } from '../utils/providerHelper'
import { INVITE_TO_GROUP_CHANNEL } from '../graphql/chat/mutations'

const useSearchSendbirdUsers = (
    searchName: string,
    limit: number,
    customType: string,
    providerId: string,
    filterCriteries: ICriteriaFilter[],
    allowObserve: boolean,
    selectedChannel: string,
    refetchChannelsToggle: boolean,
    setRefetchChannelsToggle: (prop: boolean) => void,
    setCurrentFilterCriteres: (prop: ICriteriaFilter[]) => void
) => {
    const [usersWithChannels, setUsersWithChannels] = useState<IUser[]>([])
    const [offset, setOffset] = useState<number>(0)
    const [filters, setFilters] = useState({})
    const [isUpdateFilter, setIsUpdateFilter] = useState(false)
    const lastElement = useRef<HTMLDivElement>(null)

    const [inviteToGroupChannel] = useMutation(INVITE_TO_GROUP_CHANNEL)
    const [fetchUserList, { data, loading }] = useLazyQuery(FETCH_USERS_WITH_CHATS, {
        pollInterval: TWO_MINUTES_IN_MILLISECONDS,
        fetchPolicy: 'network-only',
    })

    const handleSetFilter = useCallback(() => {
        setCurrentFilterCriteres(filterCriteries)
        let sendFilterObject = {}
        filterCriteries.forEach(({ title, checkboxes }) => {
            if (title === 'zone') {
                if (!checkboxes.length) {
                    return (sendFilterObject = { [title]: { zones: [] }, ...sendFilterObject })
                }
                const checkedValue = checkboxes
                    .filter((filterObj) => filterObj.checked)
                    .map((filterObj) => filterObj.name)
                return (sendFilterObject = {
                    [title]: { zones: [...checkedValue] },
                    ...sendFilterObject,
                })
            }
            let accFilterObject = {}
            checkboxes
                .filter((filterObj) => filterObj.checked)
                .forEach(
                    ({ name, checked }) =>
                        (accFilterObject = { [name]: checked, ...accFilterObject })
                )
            return (sendFilterObject = { [title]: { ...accFilterObject }, ...sendFilterObject })
        })
        sessionStorage.setItem(`${customType}_filter`, JSON.stringify(filterCriteries))
        setIsUpdateFilter(true)
        setFilters(sendFilterObject)
    }, [filterCriteries])

    const inviteProviderToGroupChannel = async (channelUrl: string, providerSub?: string) => {
        if (!providerSub) {
            const sessionUser = getSessionUser()
            providerSub = getProviderSub(sessionUser)
        }

        await inviteToGroupChannel({
            variables: {
                channelUrl: channelUrl,
                userId: providerSub,
            },
        })
    }

    useObserver(
        lastElement,
        offset,
        data?.usersWithGroupChannels?.count,
        loading,
        () => {
            setOffset((prevState: number) => prevState + limit)
        },
        allowObserve
    )

    const searchUserList = useCallback(
        async (search: string) => {
            try {
                await fetchUserList({
                    variables: {
                        search: search.trim(),
                        offset,
                        limit,
                        customType,
                        filters,
                        providerId,
                    },
                })
            } catch (e) {
                console.error(e)
            }
        },
        [offset, customType, filters, providerId]
    )

    const debouncedSearch = useDebounce(searchUserList, 500)

    useEffect(() => {
        if (!refetchChannelsToggle) {
            return
        }
        debouncedSearch(searchName)
        setRefetchChannelsToggle(false)
    }, [refetchChannelsToggle])

    useEffect(() => {
        let mounted = true
        if (mounted && filterCriteries[0].title !== '') {
            handleSetFilter()
        }
        return () => {
            mounted = false
        }
    }, [filterCriteries[0].title])

    useEffect(() => {
        if (
            selectedChannel.length &&
            !usersWithChannels?.find((item) => item.groupChannel?.channelUrl === selectedChannel)
        ) {
            debouncedSearch(searchName)
        }
    }, [selectedChannel])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setOffset(0)
            setUsersWithChannels([])
            debouncedSearch(searchName)
            if (isUpdateFilter) {
                setIsUpdateFilter(false)
            }
        }
        return () => {
            mounted = false
        }
    }, [searchName, customType, isUpdateFilter])

    useEffect(() => {
        if (offset !== 0) {
            searchUserList(searchName)
        }
    }, [offset])

    useEffect(() => {
        if (data) {
            const newItems = data?.usersWithGroupChannels?.userList || []
            if (newItems.length) {
                setUsersWithChannels([...newItems])
            }
        }
    }, [data])

    return {
        usersWithChannels,
        loading,
        lastElement,
        handleSetFilter,
        inviteProviderToGroupChannel,
    }
}

export default useSearchSendbirdUsers
