import React from 'react'

import PatientPersonalInfo from '@/components/Patient/PatientTabsPanel/PatientPersonalInfo/PatientPersonalInfo'
import PatientMedicalHistory from './PatientMedicalHistory/PatientMedicalHistory'
import PatientToDos from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientToDos'
import PatientMembershipInfo from '@/components/Patient/PatientTabsPanel/PatientMembershipInfo/PatientMembershipInfo'
import PatientMyPath from '@/components/Patient/PatientTabsPanel/PatientMyPath/PatientMyPath'

import { ITabsMenuItem } from '@/components/UI/TabsMenu/interfaces'

export const tabsPanelArray: ITabsMenuItem[] = [
    {
        id: '1',
        name: 'Personal Information',
        isAdminTab: false,
    },
    {
        id: '2',
        name: 'Membership Info',
        isAdminTab: false,
    },
    {
        id: '3',
        name: 'Medical History',
        isAdminTab: false,
    },
    {
        id: '4',
        name: 'Action Items',
        isAdminTab: false,
    },
    {
        id: '5',
        name: 'My Path',
        isAdminTab: false,
    },
]

export const patientTabComponents = [
    {
        id: '1',
        isAdminTab: false,
        TabComponent: PatientPersonalInfo,
    },
    {
        id: '2',
        isAdminTab: false,
        TabComponent: PatientMembershipInfo,
    },
    {
        id: '3',
        isAdminTab: false,
        TabComponent: PatientMedicalHistory,
    },
    {
        id: '4',
        isAdminTab: false,
        TabComponent: PatientToDos,
    },
    {
        id: '5',
        isAdminTab: false,
        TabComponent: PatientMyPath,
    },
]
