import { useEffect, useRef, useState } from 'react'

const useOutside = (initialVisible: boolean, callback?: () => void) => {
    const [isOpen, setIsOpen] = useState(initialVisible)
    const ref = useRef<HTMLInputElement>(null)

    const handleClickOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setIsOpen(false)

            if (callback) {
                callback()
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)

        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return { ref, isOpen, setIsOpen }
}

export default useOutside
