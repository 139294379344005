import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import ToDoSettings from '@/components/ToDo/ToDoSettings/ToDoSettings'
import ToDoContent from '@/components/ToDo/ToDoContent/ToDoContent'
import ToDoMain from '@/components/ToDo/ToDoContent/ToDoMain'
import ToDoMenuBtn from '@/components/ToDo/ToDoForm/ToDoMenuBtn'
import CircularLoader from '@/components/Loader/CircularLoader'

import { FETCH_USER_BY_EMAIL } from '@/graphql/users/queries'
import { FETCH_FILE_BY_ID } from '@/graphql/files/queries'
import { UPDATE_FILE } from '@/graphql/files/mutations'

import { useUploadFileSignedUrl } from '@/hooks'
import checkToDoValidation from '@/validations/checkToDoValidation'
import { setUniqueFileName, getSessionUser } from '@/utils'

import todoStatuses from '@/components/ToDo/ModalToDo/todoStatuses'
import { IToDoForm } from '@/components/ToDo/ToDoContent/interfaces'
import { IToDo } from '@/components/Patient/PatientTabsPanel/interfaces'

import { DialogTitleWrapper, DialogWrapper } from '@/components/Patient/PatientTabsPanel/styles'
import { EditButtonConfirm, EditForm } from '@/components/ToDo/ModalToDo/styles'

const ToDoForm: React.FC<IToDoForm> = ({
    userId = '',
    patientId = '',
    isEditMode,
    isCreateMode,
    toDo,
    isExistingPatient,
    submitData,
    handleClose,
    modalTitle,
    isViewMode,
    isExistingTitle,
    setIsExistingTitle,
    isQueryLoading,
}) => {
    const sessionUser: any = getSessionUser()

    const [toDoData, setToDoData] = useState<IToDo>({
        patientId: '',
        type: '',
        title: '',
        priority: 'Low',
        duration: '',
        description: '',
        optional: false,
        dueDate: '',
        dueTime: '',
        intakeAssigned: '',
        taskDescription: '',
        practitionerId: '',
        visitReason: '',
        visitDate: '',
        visitTime: '',
        preVisitContent: '',
        notifications: false,
        notificationsTime: [],
        notificationText: '',
        notificationsChannels: '',
        isAddedToMedicalHistory: false,
        contentType: '',
        searchByTag: [],
        searchContent: '',
        status: '',
        user: {
            id: '',
        },
        provider: {
            id: null,
        },
        praise: false,
        startingDate: String(Date.now()),
        startingTime: String(Date.now()),
        repeat: '',
        laboratory: '',
        medicine: '',
        pharmacy: '',
        pharmacyLocation: '',
        instructions: '',
        labResultName: '',
        labResultDate: '',
        labResultExplanation: '',
        afterVisitDate: '',
        afterVisitReason: '',
        afterAction: '',
        afterMedicationList: '',
        afterEducation: '',
        afterTimeline: '',
        files: '',
        createdBy: '',
    })
    const [fetchUserByEmail] = useLazyQuery(FETCH_USER_BY_EMAIL)
    const [refetchFileById] = useLazyQuery(FETCH_FILE_BY_ID)
    const [updateFileName] = useMutation(UPDATE_FILE)

    const [toDoContentFile, setToDoContentFile] = useState<File | any>(null)
    const uploadFile = useUploadFileSignedUrl()

    const isInitialFreeText = toDo?.type === 'Free Text'
    const isInitialLabRx = toDo?.type === 'Lab/Rx Order'
    const isInitialLabResult = toDo?.type === 'Lab Result'
    const isInitialAfterVisitSummary = toDo?.type === 'After Visit Summary'
    const toDoStatus = toDo?.status?.length ? toDo?.status : todoStatuses.NOT_STARTED
    const isAppointment = toDoData.type === 'Appointment'
    const isLabResult = toDoData.type === 'Lab Result'
    const isFileAttached = isLabResult && !toDoContentFile

    const isValidToDo = checkToDoValidation(toDoData, toDoData.type)
    const isDisabledSaveBtn =
        !isValidToDo || isViewMode || isExistingTitle || isQueryLoading || isFileAttached

    useEffect(() => {
        if (isCreateMode) {
            setToDoData({
                ...toDoData,
                patientId,
                status: todoStatuses.NOT_STARTED,
                repeat: 'None',
                user: {
                    ...toDoData?.user,
                    id: userId,
                },
                createdBy: sessionUser?.sub,
            })
        }

        if (isEditMode) {
            setToDoData({
                ...toDo,
                patientId,
                user: {
                    ...toDo?.user,
                    id: userId,
                },
                repeat: toDo?.repeat || 'None',
            })
        }

        if (
            isEditMode &&
            (isInitialFreeText ||
                isInitialLabRx ||
                isInitialLabResult ||
                isInitialAfterVisitSummary)
        ) {
            if (!toDo?.files[0]?.id) {
                setToDoContentFile(null)
                return
            }

            const fetchFileById = async () => {
                const { data } = await refetchFileById({
                    variables: {
                        fileByIdId: toDo?.files[0]?.id,
                    },
                })

                setToDoContentFile(data?.fileById)
            }

            fetchFileById()
        }
    }, [toDo])

    const handleToDoData = async (name: string, value: any) => {
        const isPatientIdField = name === 'patientId'
        const isPractitionerField = name === 'practitionerId'
        const isCheckbox =
            name === 'isAddedToMedicalHistory' || name === 'notifications' || name === 'praise'
        const isLaboratory = name === 'laboratory'

        if (isPatientIdField) {
            setToDoData((prevState) => ({
                ...prevState,
                [name]: value?.canvasPatientId,
                user: {
                    id: value?.id ?? '',
                },
            }))

            return
        }

        if (isCheckbox) {
            setToDoData((prevState) => ({ ...prevState, [name]: !prevState[name] }))
            return
        }

        if (isLaboratory) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                medicine: '',
            }))
            return
        }

        if (isPractitionerField) {
            setToDoData((prevState) => ({
                ...prevState,
                [name]: value,
                provider: {
                    id: value,
                },
            }))
        }

        setToDoData((prevState) => ({ ...prevState, [name]: value }))
    }

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const isFreeText = toDoData.type === 'Free Text'
        const isLabRx = toDoData.type === 'Lab/Rx Order'
        const isLabResult = toDoData.type === 'Lab Result'
        const isAfterVisitSummary = toDoData.type === 'After Visit Summary'
        const acceptableTypes = isFreeText || isLabRx || isLabResult || isAfterVisitSummary
        delete toDoData.files

        const commonToDoData = {
            ...toDoData,
            duration: toDoData.duration ? Number(toDoData.duration) : null,
        }

        const { data: fetchedToDoData } = await submitData(e, commonToDoData, toDo?.id)

        const fileName = toDoContentFile && setUniqueFileName(toDoContentFile?.name)

        const fileInput: any = {
            name: fileName,
            displayName: toDoContentFile?.name,
            fileType: toDoContentFile?.type,
            user: {
                id: fetchedToDoData?.createTodo?.user?.id || userId,
            },
            todo: {
                id: fetchedToDoData?.createTodo?.id || toDo?.id,
            },
            isAddedToMedicalHistory: fetchedToDoData?.createTodo?.isAddedToMedicalHistory,
        }

        if (acceptableTypes && fileName && isCreateMode) {
            try {
                const data = await uploadFile(fileInput, toDoContentFile)

                if (isEditMode) {
                    await submitData(
                        e,
                        {
                            ...commonToDoData,
                            files: [{ id: data?.uploadFileWithSignedUrl?.file.id }],
                        },
                        toDo?.id
                    )
                }
            } catch (e) {
                console.error(e)
            }
        }

        if (acceptableTypes && fileName && isEditMode) {
            const fileId = toDo?.files[0]?.id

            if (!fileId) {
                try {
                    await uploadFile(fileInput, toDoContentFile)

                    return
                } catch (e) {
                    console.error(e)
                }
            }

            await updateFileName({
                variables: {
                    updateFileId: fileId,
                    fileInput: {
                        displayName: toDoContentFile?.name,
                        isAddedToMedicalHistory:
                            fetchedToDoData?.createTodo?.isAddedToMedicalHistory,
                    },
                },
                onCompleted: () => {
                    if (handleClose) {
                        handleClose()
                    }
                },
            })
        }
    }

    return (
        <DialogWrapper className='dialog-wrapper'>
            <EditForm>
                <DialogTitleWrapper>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                        Action Item {modalTitle}
                    </Box>
                    <Box className='todo-status'>
                        {toDoData?.status ?? todoStatuses.NOT_STARTED}
                        <ToDoMenuBtn
                            handleToDoData={handleToDoData}
                            initialStatus={toDoStatus}
                            status={toDoData?.status}
                            isViewMode={isViewMode}
                            isAppointment={isAppointment}
                        />
                    </Box>
                </DialogTitleWrapper>
                <ToDoMain
                    toDoData={toDoData}
                    handleToDoData={handleToDoData}
                    isExistingPatient={isExistingPatient}
                    isViewMode={isViewMode}
                    setToDoData={setToDoData}
                    isExistingTitle={isExistingTitle}
                    setIsExistingTitle={setIsExistingTitle}
                />
                <ToDoContent
                    handleToDoData={handleToDoData}
                    type={toDoData.type}
                    toDoData={toDoData}
                    isViewMode={isViewMode}
                    toDoContentFile={toDoContentFile}
                    setToDoContentFile={setToDoContentFile}
                    setToDoData={setToDoData}
                />
                <ToDoSettings
                    handleToDoData={handleToDoData}
                    toDoData={toDoData}
                    type={toDoData.type}
                    isViewMode={isViewMode}
                    isEditMode={isEditMode}
                />
                <Box display='flex' justifyContent='space-between'>
                    <Typography fontSize={14}>* Required</Typography>
                    <EditButtonConfirm
                        onClick={handleSubmit}
                        disabled={isDisabledSaveBtn}
                        variant='contained'
                        color='info'
                    >
                        {isQueryLoading ? (
                            <CircularLoader size={20} color='info' relative />
                        ) : (
                            'Save'
                        )}
                    </EditButtonConfirm>
                </Box>
            </EditForm>
        </DialogWrapper>
    )
}

export default ToDoForm
