import { Box, Dialog, IconButton, styled } from '@mui/material'

export const PageWrapper = styled(Box)(
    ({ theme }) => `
    position: relative;
    height: 100vh;
    min-height: 100vh;
  `
)

export const PageHeaderWrapper = styled('header')(
    ({ theme }) => `
    display: flex;
    position: relative;
    height: 60px;
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000000;
    background-color: white;

    & p {
        font-family: 'DM Sans', sans-serif;
        font-size: 18px;
        color: ${theme.palette.violet.main};
        padding-left: 38px;
    }
  `
)

export const DropdownContainerWrapper = styled('header')(
    ({ theme }) => `
    display: flex;
    width: 100%;
    padding: 20px 16px;
    margin-top: 30px;
    flex-direction: column;
    border-radius: 6px;
    background-color: #edebe5;
    box-shadow: 0px 3px 3px 0px rgba(64, 72, 100, 0.12);
  `
)

export const DropdownHeader = styled('div')(
    ({ theme }) => `
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: ${theme.palette.violet.main};
  `
)

export const DropdownFormContainer = styled('div')(
    ({ theme }) => `
    display: flex;
    margin-top: 10px;
  `
)

export const DropdownContainer = styled('div')(
    ({ theme }) => `
    min-width: 120px ;
    width: 100%;
    background-color: white;
    border-radius: 6px;
  `
)

export const SuccessModalContainer = styled(Dialog)(
    ({ theme }) => `
  z-index: 9999999;

  & .MuiDialog-paper {
    border-radius: 6px;
  }
  `
)

export const SuccessModalXButton = styled(IconButton)(
    ({ theme }) => `
  position: absolute;
  right: 8px;
  top: 8px;
  color: ${theme.palette.grey[500]};
  `
)

export const SuccessContentContainer = styled('div')(
    ({ theme }) => `
    display: flex;
    width: 409px;
    padding: 61px 0px 24px 0px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    `
)

export const SuccessModalHeaderText = styled('div')(
    ({ theme }) => `
  font-family: 'DM Sans', sans-serif;
  font-size: 20px;
  line-height: 22px;
  color: ${theme.palette.violet.main};
`
)

export const SuccessModalText = styled('div')(
    ({ theme }) => `
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000;
  padding: 37px 0px;
  max-width: 310px;
  white-space: pre-wrap;
`
)
