import { getDoB } from '@/utils'

const getDoBAndState = (createdAt: string, state: string): string => {
    const dateOfBirth = getDoB(createdAt, 'MM/dd/yyyy')
    const formattedState = state ?? '-'

    return `${dateOfBirth || '-'}, ${formattedState}`
}

export default getDoBAndState
