import { Box, styled, Button } from '@mui/material'

export const ChatbotSidebarWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c8c8c8;
    flex: 2;
    overflow: auto;
    background: rgba(255, 255, 255);
    margin-top: 50px;
    position: relative;
    box-sizing: border-box;
`

export const ChatbotPreviewWrapper = styled(Box)`
    padding: 24px 32px;
    overflow: auto;
    margin-bottom: 100px;
`

export const ChatbotHeader = styled(Box)`
    position: absolute;
    top: 0;
    padding-left: 4%;
    padding-top: 10px;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-start;
    font-size: 1.25rem;
    font-weight: bold;
    color: #401f52;
    z-index: 1;
`

export const ChatUIWrapper = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
`

export const FooterWrapper = styled(Box)`
    position: absolute;
    bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
`

export const FooterButtonWrapper = styled(Box)`
    color: #ffffff;
    background-color: #401F52;
    width: 245px;
    height: 48px;
    align-items: center;

    &:hover {
      background-color: #401F52;
    }
  }
    `

export const CustomButton = styled(Button)({
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500,
    padding: '5px 15px',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    background: '#401F52',
    color: '#ffffff',
    width: '245px',
    height: '48px',
})
