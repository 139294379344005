import { Box, styled, Typography } from '@mui/material'

export const PatientAvatarWrapper = styled(Box)`
    display: inline-block;
    position: relative;
    border-radius: 50%;
    box-shadow: 0px 9px 12px -6px rgba(127, 154, 159, 0.44);

    &.without-plan {
        border: 2px solid #fff;

        & .MuiAvatar-root {
            opacity: 1;
        }
    }

    & .MuiAvatar-root {
        width: 100%;
        height: 100%;
    }
`

export const PatientCurrentPlan = styled(Typography)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #fc7960;
`

export const PatientAvatarFreemium = styled(`div`)`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(252, 121, 96, 0.3);
`
