import React from 'react'

import SendbirdApp from '@/pages/Dashboard/DashboardChatsPage/SendbirdApp'
import { IDashboardChatsPage } from '@/pages/Dashboard/DashboardChatsPage/interfaces'
import { getSessionUser, getUserSessionToken } from '@/utils'
import {
    getProviderRoleName,
    getProviderInAdminMessage,
    getProviderFullName,
    getProviderSub,
} from '@/utils/providerHelper'
import { DashboardChatsWrapper } from './styles'
import '@sendbird/uikit-react/dist/index.css'
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider'

export const DashboardChatsPage: React.FC<IDashboardChatsPage> = ({ iscareteamtab }) => {
    const sessionUser: any = getSessionUser()
    const issueSessionTokenUser: string = getUserSessionToken()

    const providerSub = getProviderSub(sessionUser)
    const providerRoleName = getProviderRoleName(sessionUser)
    const providerInAdminMessage = getProviderInAdminMessage(sessionUser)
    const providerfullName = getProviderFullName(sessionUser)

    return (
        <DashboardChatsWrapper>
            <SendbirdProvider
                appId={window.env.SENDBIRD_APP_ID}
                userId={providerSub}
                nickname={providerfullName}
                accessToken={issueSessionTokenUser}
            >
                <SendbirdApp
                    providerId={providerSub}
                    providerRoleName={providerRoleName}
                    providerInAdminMessage={providerInAdminMessage}
                    isCareTeamTab={iscareteamtab}
                />
            </SendbirdProvider>
        </DashboardChatsWrapper>
    )
}
