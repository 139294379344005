import React from 'react'
import { Link } from 'react-router-dom'
import { Box, CardHeader, Tooltip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { cropName, getFullName, getDoBAndState, getSubscriptionCurrentPlan } from '@/utils'
import PatientAvatar from '@/components/UI/PatientAvatar'
import { PatientPanelHeader, RedirectButton } from '../styles'
import { IChatPatientPanelHeader } from '../interfaces'

const PanelHeader: React.FC<IChatPatientPanelHeader> = ({ patientUserId, patientData }) => {
    const { firstName, lastName, birthDate, patient, activeSubscription, avatarLink } =
        patientData?.userById || {}

    const subscriptionType: string = activeSubscription?.subscriptionType
    const b2bPartner: string = activeSubscription?.b2bPartner
    const b2bSubPartner: string = activeSubscription?.b2bSubPartner
    const readablePartner: string = b2bSubPartner ? b2bSubPartner : b2bPartner ? b2bPartner : ''

    const redirectToCanvas = () => {
        window.open(`${window.env.CANVAS_URL}/patient/${patient.id}`, '_blank')
    }

    return (
        <PatientPanelHeader>
            <CardHeader
                avatar={
                    <PatientAvatar
                        src={avatarLink}
                        currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                        fontSize={10}
                        wrapperWidth={76}
                        wrapperHeight={76}
                    />
                }
                title={
                    <Tooltip title={getFullName(firstName, lastName, patient?.preferredName)} arrow>
                        <Link to={`/dashboard/patients/${patientUserId}`}>
                            {cropName(getFullName(firstName, lastName, patient?.preferredName), 15)}
                        </Link>
                    </Tooltip>
                }
                subheader={
                    <Box sx={{ paddingTop: '2px' }}>
                        {getDoBAndState(birthDate, patient?.homeAddress?.state)}
                        <div className='partner-info'>{readablePartner}</div>
                        <RedirectButton onClick={redirectToCanvas}>
                            Open in Canvas
                            <OpenInNewIcon
                                sx={{
                                    fontSize: 15,
                                    color: '#401f52',
                                }}
                            />
                        </RedirectButton>
                    </Box>
                }
            />
        </PatientPanelHeader>
    )
}

export default PanelHeader
