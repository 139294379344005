import React, { ReactElement } from 'react'
import { PageHeaderWrapper } from '../styles'
import { headerMenuItem } from '../models/menuItems'

const PageHeader: React.FC = (): ReactElement => {
    return (
        <PageHeaderWrapper>
            <p>{headerMenuItem}</p>
        </PageHeaderWrapper>
    )
}

export default PageHeader
