import React from 'react'
import { TableCell, TableRow } from '@mui/material'

import PatientAvatar from '@/components/UI/PatientAvatar'
import { IModalPatientRow } from '@/components/Chat/CreateChatModal/interfaces'
import { getDoB, getSubscriptionCurrentPlan } from '@/utils'

const ModalPatientRow: React.FC<IModalPatientRow> = ({ user, currentUser, setCurrentUser }) => {
    const userName = `${user?.firstName || ''} ${user?.lastName || ''}`
    const { id, activeSubscription } = user || {}
    const subscriptionType: string = activeSubscription?.subscriptionType

    return (
        <TableRow
            key={user.id}
            className={currentUser && id === currentUser.id ? 'active' : ''}
            sx={{ cursor: 'pointer' }}
            onClick={() => setCurrentUser(user)}
        >
            <TableCell component='th' scope='row'>
                <PatientAvatar
                    src={user?.avatarLink}
                    currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                    fontSize={6}
                    wrapperWidth={40}
                    wrapperHeight={40}
                />
            </TableCell>
            <TableCell align='right'>{userName}</TableCell>
            <TableCell align='right'>{getDoB(user?.birthDate, 'MM/dd/yyyy')}</TableCell>
            <TableCell align='right'>{user?.state}</TableCell>
        </TableRow>
    )
}

export default ModalPatientRow
