const downloadFileByBase64 = (name: string, url: string) => {
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)

    downloadLink.href = url
    downloadLink.target = '_self'
    downloadLink.download = name

    downloadLink.click()
    downloadLink.remove()
}

export default downloadFileByBase64
