import { gql } from '@apollo/client'

export const CREATE_COLLEGE = gql`
    mutation CreateCollege($collegeInput: CollegeInput) {
        createCollege(collegeInput: $collegeInput) {
            id
            name
            address
            city
            state
            zipCode
            trial {
                id
            }
            isArchived
            campusCrisisInfo
        }
    }
`

export const CREATE_BULK_COLLEGES = gql`
    mutation CreateBulkColleges($input: [CollegeInput!]!) {
        createBulkColleges(input: $input) {
            id
            name
            address
            city
            state
            zipCode
            articles
            trial {
                id
            }
            isArchived
            campusCrisisInfo
        }
    }
`

export const UPDATE_COLLEGE = gql`
    mutation UpdateCollege($updateCollegeId: ID!, $collegeInput: CollegeInput) {
        updateCollege(id: $updateCollegeId, collegeInput: $collegeInput) {
            id
            name
            address
            city
            state
            zipCode
            trial {
                id
            }
            isArchived
            campusCrisisInfo
            createdDate
        }
    }
`
