import { gql } from '@apollo/client'

export const SEND_ADMIN_MESSAGE = gql`
    mutation SendAdminMessage($channelUrl: String!, $adminMessageInput: AdminMessageInput!) {
        sendAdminMessage(channelUrl: $channelUrl, adminMessageInput: $adminMessageInput) {
            type
            message
        }
    }
`
