import React from 'react'
import { Outlet } from 'react-router-dom'

import { PatientSidebar } from '@/components'

import { DashboardChatsWrapper, SendbirdAppWrapper } from '../DashboardChatsPage/styles'

const DashboardPatientsPage: React.FC = () => {
    return (
        <DashboardChatsWrapper>
            <SendbirdAppWrapper sx={{ height: '100%' }}>
                <div className='sendbird-app__conversation-wrap'>
                    <div className='sendbird-channel-list'>
                        <PatientSidebar />
                    </div>
                    <div className='sendbird-conversation'>
                        <Outlet />
                    </div>
                </div>
            </SendbirdAppWrapper>
        </DashboardChatsWrapper>
    )
}

export default DashboardPatientsPage
