import React, { useState } from 'react'
import { useRef } from 'react'
import { MessageRole, IChatMessageProps } from '@/pages/Dashboard/DashboardChatbotPage/interfaces'
import Avatar from '@mui/material/Avatar'
import { FeedbackContainer, StyledRating } from './styles'
import TextField from '@mui/material/TextField'
import { IconContainerProps } from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import axios from 'axios'

const customIcons: {
    [index: number]: {
        icon: React.ReactElement,
        label: string,
    },
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color='error' />,
        label: 'Very Dissatisfied',
    },
    2: {
        icon: <SentimentVerySatisfiedIcon color='success' />,
        label: 'Very Satisfied',
    },
}

function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props
    const icon = customIcons[value]?.icon || null
    return <span {...other}>{icon}</span>
}

function mapBoolApprovalToRating(a: any) {
    return a === true ? 2 : a === false ? 1 : 0
}

function mapRatingApprovalToBoolean(a: number) {
    return a === 2 ? true : a === 1 ? false : null
}

export const ChatMessage = ({ message, userId, token }: IChatMessageProps) => {
    const BACKEND_ADDRESS_GENAI = window.env.BACKEND_ADDRESS_GENAI
    const messageRef = useRef<HTMLDivElement>(null)
    const isBot = message.role !== MessageRole.USER
    const [feedback, setFeedback] = useState(message.comment)
    const [approval, setApproval] = useState(mapBoolApprovalToRating(message.approval))

    const handleFeedbackChange = (event: any) => {
        setFeedback(event.target.value)
    }

    const handleRatingChange = (event: any, newValue: any) => {
        setApproval(newValue)
    }

    const handleSaveFeedback = async () => {
        try {
            const url = BACKEND_ADDRESS_GENAI + `/chatbot/feedback`

            const body = {
                approval: mapRatingApprovalToBoolean(approval),
                comment: feedback,
                userId: userId,
                chatId: message.chatId,
                messageId: message.messageId,
            }
            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                },
            })
            return response.data
        } catch (error) {
            console.error('Error fetching chat bot response:', error)
            return 'An error occurred while fetching the response.'
        }
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                    sx={{
                        bgcolor: isBot ? 'grey.500' : 'primary.main',
                        marginRight: '16px',
                        alignItems: 'center',
                    }}
                >
                    <div className='bg-neutral text-neutral-content h-10 w-10 flex items-center justify-center'>
                        {isBot ? (
                            'AI'
                        ) : message.userInfo?.name ? (
                            <p>{`${message.userInfo.name.charAt(0)}`}</p>
                        ) : (
                            'icon'
                        )}
                    </div>
                </Avatar>
                <h4 className='m-0'>{isBot ? 'Cara AI' : message.userInfo?.name} </h4>
            </div>
            <div className='ml-16 mt-4'>
                <div ref={messageRef}>{message.message}</div>
            </div>
            {isBot && message.messageId !== 1 ? (
                <FeedbackContainer>
                    <div>
                        <TextField
                            id='standard-textarea'
                            label='Feedback comment'
                            placeholder='Enter text'
                            multiline
                            variant='standard'
                            style={{ width: '70%', verticalAlign: 'bottom' }}
                            onChange={handleFeedbackChange}
                            value={feedback}
                        />
                        <StyledRating
                            name='highlight-selected-only'
                            IconContainerComponent={IconContainer}
                            getLabelText={(value: number) => customIcons[value]?.label || ''}
                            highlightSelectedOnly
                            onChange={handleRatingChange}
                            style={{ width: '50%', verticalAlign: 'bottom', paddingLeft: '10px' }}
                            value={approval}
                        />
                    </div>
                    <Button
                        variant='text'
                        style={{ marginRight: '35px' }}
                        size='small'
                        endIcon={<CheckIcon />}
                        onClick={handleSaveFeedback}
                    >
                        Save
                    </Button>
                </FeedbackContainer>
            ) : (
                <></>
            )}
        </>
    )
}
