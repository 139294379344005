import { gql } from '@apollo/client'

export const UPDATE_PARTNER = gql`
    mutation updateB2bPartner($b2bPartnerInput: UpdateB2bPartnerInput) {
        updateB2bPartner(b2bPartnerInput: $b2bPartnerInput) {
            id
            name
            code
        }
    }
`
