import React from 'react'

import settingVerticalSvg from '@/assets/images/settings-vertical.svg'

import { AudioPlayerWrapper } from '@/components/ToDo/ToDoContent/components/styles'
import 'react-h5-audio-player/lib/styles.css'

const AudioContent: React.FC<{ audio: string }> = ({ audio }) => {
    return (
        <AudioPlayerWrapper
            src={audio}
            showJumpControls={false}
            showFilledVolume={false}
            layout='stacked-reverse'
            autoPlay={false}
        >
            <img src={settingVerticalSvg} className='audio-test' alt='Settings' />
        </AudioPlayerWrapper>
    )
}

export default AudioContent
