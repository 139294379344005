import { Card, CardHeader, styled } from '@mui/material'

export const ChannelPreviewWrapper = styled(Card)(({ theme }) => ({
    borderRadius: 0,
    boxShadow: 'none',
    cursor: 'pointer',
    color: theme.palette.violet.main,
    opacity: 1,

    '&.inactive': {
        opacity: 0.7,
        color: theme.palette.orange.main,
    },

    '&.active': {
        position: 'relative',
        backgroundColor: '#f3f0ea',
    },

    '& .MuiCardHeader-root': {
        padding: '24px',
        position: 'relative',
    },

    '& .MuiCardHeader-title': {
        fontWeight: 700,
        fontSize: '1em',
        textTransform: 'capitalize',
        marginBottom: '4px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '130px',
        marginTop: '-10px',
    },

    '& .MuiCardHeader-subheader': {
        color: theme.palette.violet.light,
    },

    '& .MuiCardHeader-action': {
        fontWeight: 700,
        fontSize: 12,
        color: 'rgba(131, 133, 159, 0.5)',
        margin: 0,
        marginTop: '10px',
    },

    '& .image-preview': {
        display: 'flex',
        alignItems: 'center',
    },

    '& .ticker.initial-translate > .ticker__element': {
        transform: 'translate3d(0, 0, 0) !important',
    },
}))

export const ChannelBadge = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: 24,
    display: 'inline-flex',
    borderRadius: '50%',
    marginLeft: '3px',
    width: 12,
    height: 12,
    color: theme.palette.primary.main,
    transform: 'translateY(25%)',

    '&.contained': {
        position: 'absolute',
        right: 24,
        bottom: 24,
        padding: '2px 5px',
        backgroundColor: theme.palette.lightGray.main,
        borderRadius: '6px',
        color: theme.palette.primary.contrastText,
        height: 'auto',
        width: 'auto',
    },

    '& .unread-message': {
        position: 'absolute',
        right: 15,
        top: -2,
        whiteSpace: 'nowrap',
        fontSize: 12,
        fontWeight: 400,
    },

    '&.zone.red-zone': {
        backgroundColor: theme.palette.primary.main,
    },

    '&.zone.blue-zone': {
        backgroundColor: theme.palette.info.contrastText,
    },

    '&.zone.green-zone': {
        backgroundColor: theme.palette.green.contrastText,
    },

    '&.zone.gray-zone': {
        backgroundColor: theme.palette.gray.main,
    },
}))

export const CardHeaderPreview = styled(CardHeader)`
    .MuiCardHeader-content {
        padding-top: 9px;
    }

    div:nth-of-type(2) > span {
        overflow: visible;
    }

    div:nth-of-type(3) {
        margin: 0px;
    }

    .additional-info {
        position: absolute;

        .partner-info {
            font-size: 0.8rem;
        }

        .doctor-info {
            font-style: italic;
            font-size: 0.7rem;
            font-weight: bold;
            line-height: 20px;
        }
    }
`
