import React from 'react'

import { EllipsisLoaderWrapper } from '@/components/Loader/styles'

const EllipsisLoader: React.FC = () => {
    return (
        <EllipsisLoaderWrapper>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </EllipsisLoaderWrapper>
    )
}

export default EllipsisLoader
