import React from 'react'
import {
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'

import ListTablePagination from '@/components/CollegesAndPartners/components/ListTablePagination'
import CircularLoader from '@/components/Loader/CircularLoader'
import {
    headerMenuColleges,
    headerMenuUnverifiedColleges,
    headerMenuPartners,
    headerMenuSubPartners,
} from '@/components/CollegesAndPartners/models/headerMenuItems'
import { ListTableHeaderRow } from '@/pages/Dashboard/DashboardCollegesAndPartnersPage/styles'
import { IListTableContainer } from './interfaces'

const ListTableContainer: React.FC<IListTableContainer> = ({
    listData,
    listDataLoading,
    listDataCount,
    tableRef,
    searchName,
    offset,
    limit,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    isPartners,
    isSubPartners,
    isColleges,
    isUnverifiedColleges,
    testId,
}) => {
    const tableHeaderValues =
        (isPartners && headerMenuPartners) ||
        (isSubPartners && headerMenuSubPartners) ||
        (isColleges && headerMenuColleges) ||
        (isUnverifiedColleges && headerMenuUnverifiedColleges) ||
        []

    return (
        <TableContainer component={Paper} data-testid={testId}>
            {listDataLoading ? (
                <CircularLoader size={60} />
            ) : (
                <>
                    <Table ref={tableRef}>
                        <TableHead>
                            <ListTableHeaderRow>
                                {tableHeaderValues?.map((menuItem: string) => (
                                    <TableCell key={menuItem}>{menuItem}</TableCell>
                                ))}
                            </ListTableHeaderRow>
                        </TableHead>
                        <ListTablePagination
                            listData={listData}
                            search={searchName}
                            offset={offset}
                            limit={limit}
                            count={listDataCount}
                            isPartners={isPartners}
                            isSubPartners={isSubPartners}
                            isColleges={isColleges}
                            isUnverifiedColleges={isUnverifiedColleges}
                        />
                    </Table>
                    <Table>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    count={listDataCount || 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={limit}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    showFirstButton={true}
                                    showLastButton={true}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </>
            )}
        </TableContainer>
    )
}

export default ListTableContainer
