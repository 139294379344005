import { Box, Card, styled, Tab, Typography, CardContent } from '@mui/material'

export const PatientHeaderWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    padding: '9px 5px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 5px -1px rgba(64, 72, 100, 0.2)',
    zIndex: 9,

    ' & .header__patient-block': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& .header__patient-name': {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '24px',
            color: theme.palette.violet.main,
        },

        '& .header__patient-info': {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            color: theme.palette.purple.light,
        },
    },
}))

export const PatientContentBodyWrapper = styled(Box)`
    height: 100%;
    padding: 32px 35px;
    overflow: hidden;
    background-color: #fdfcf7;

    .tab_panel-wrapper {
        position: relative;
        height: 100%;
        padding: 0px;

        & > .MuiBox-root {
            height: 100%;
            overflow-y: auto;
        }

        .MuiBox-root .MuiPaper-root:last-child {
            margin-bottom: 27px;

            &.uploaded-files {
                margin-bottom: 0;
            }
        }
    }

    .file-input {
        display: none;
    }

    .file-btn {
        margin: 18px 0 10px 34px;
        font-size: 16px;
        font-weight: 700;
    }
`

export const PatientTabWrapper = styled(Box)(({ theme }) => ({
    height: '100%',
    textTransform: 'capitalize',

    '& .MuiTabs-flexContainer': {
        borderBottom: '1px solid #EAE9E5',
        overflowX: 'auto',
    },

    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.orange.main,
        height: 4,
    },
}))

export const PatientTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.text.primary,
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 400,
    opacity: '0.5',

    '&.Mui-selected': {
        opacity: '1',
        fontWeight: 700,
        color: theme.palette.text.primary,
    },
}))

export const PatientDataBlockWrapper = styled(Card)`
    overflow: visible;
    margin-top: 24px;
    padding: 10px 15px;
    position: relative;
    color: rgba(43, 48, 115, 0.6);
    box-shadow: 0px 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
        0px 1px 3px rgba(64, 72, 100, 0.12);

    & .edit-icon {
        opacity: 0.5;
    }

    &.patient-consent .MuiCardHeader-content {
        display: flex;
        align-items: center;

        & .MuiCardHeader-title {
            padding-right: 10px;
        }

        & .MuiCardHeader-subheader {
            margin-left: 18px;
            color: #fc7960;
            font-size: 18px;
            text-transform: uppercase;
        }
    }

    &.patient-consent {
        & .patient__card-content span {
            margin: 0 5px;
        }

        & .MuiDivider-root {
            margin-top: 20px;
        }
    }

    &.patient-consent .MuiCardHeader-action {
        display: flex;
        align-items: center;
        color: rgba(43, 48, 115, 0.6);
        font-size: 14px;
    }

    .MuiCardHeader-root.absolute {
        position: absolute;
        padding: 0;
        top: 29px;
        right: 27px;
    }

    .MuiCardHeader-title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
    }

    .MuiCardHeader-action {
        height: 40px;

        .MuiButton-root {
            text-transform: capitalize;
            color: rgba(43, 48, 115, 0.6);

            &.save-btn {
                color: #97c287;
                margin-left: 32px;
            }

            &.save-btn.Mui-disabled {
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }

    & .info-field-header {
        margin-bottom: 15px;
        padding: 0;

        .MuiCardHeader-title {
            display: inline;
        }

        .MuiCardHeader-subheader {
            color: #401f52;
            display: inline;
            font-size: 18px;
        }
    }
`

export const FormContainer = styled(Box)`
    display: flex;
    justifycontent: space-between;
    position: relative;
    align-items: center;
    margin-top: 20px;
    min-width: 370px;
`

export const FormRow = styled(Box)`
    display: flex;
    justifycontent: flex-end;
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 20px;
    min-width: 370px;
    &:first-of-type {
        margin-top: 0;
    }
    &.mt-0 {
        margin-top: 0;
    }
    .MuiTypography-root {
        min-width: 120px;
        margin-right: 9px;
        font-weight: 400;
        font-size: 14px;
        color: rgba(43, 48, 115, 0.6);
    }
    .MuiInput-root {
        width: 100%;
        background-color: transparent;
    }
    .membership-field,
    .info-field-value,
    .MuiInput-input,
    .MuiFormControl-root {
        width: 100%;
        font-size: 18px;
        color: #401f52;
        &.Mui-disabled {
            -webkit-text-fill-color: initial;
        }
        &.deactivated {
            color: #fc7960;
        }
    }
    .MuiInput-root.Mui-disabled {
        &::before {
            border-bottom: none;
        }
        svg {
            display: none;
        }
    }
    .MuiInputBase-root {
        &.MuiInputBase-formControl {
            border-radius: 0;
        }
        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }
        &.Mui-disabled {
            color: #2b3073;
            fieldset.MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 4px 0 5px;
        font-size: 18px;
    }
    .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: initial;
    }
    .MuiButtonBase-root.clear-btn {
        width: 30px;
        height: 30px;
    }
`

export const CardContentBox = styled(Box)`
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 20px;
    min-width: 370px;

    &:first-of-type {
        margin-top: 0;
    }

    &.mt-0 {
        margin-top: 0;
    }

    .MuiTypography-root {
        min-width: 120px;
        margin-right: 9px;
        font-weight: 400;
        font-size: 14px;
        color: rgba(43, 48, 115, 0.6);
    }

    .MuiInput-root {
        width: 100%;
        background-color: transparent;
    }

    .membership-field,
    .info-field-value,
    .MuiInput-input,
    .MuiFormControl-root {
        width: 100%;
        font-size: 18px;
        color: #401f52;

        &.Mui-disabled {
            -webkit-text-fill-color: initial;
        }

        &.deactivated {
            color: #fc7960;
        }
    }

    .MuiInput-root.Mui-disabled {
        &::before {
            border-bottom: none;
        }

        svg {
            display: none;
        }
    }

    .MuiInputBase-root {
        &.MuiInputBase-formControl {
            border-radius: 0;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        }

        &.Mui-disabled {
            color: #2b3073;

            fieldset.MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 4px 0 5px;
        font-size: 18px;
    }

    .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: initial;
    }

    .MuiButtonBase-root.clear-btn {
        width: 30px;
        height: 30px;
    }
`

export const ErrorField = styled(Typography)`
    position: absolute;
    display: inline-block;
    left: 130px;
    font-size: 10px !important;

    &.error-color {
        color: #fc7960;
    }
`

export const MedicalHistoryBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    color: theme.palette.text.primary,

    '& .avatar': {
        marginRight: 8,
    },
}))

export const IntakeBox = styled(Box)`
    display: flex;
    position: relative;
    align-items: center;
    margin-top: 20px;
    min-width: 370px;

    & div:nth-of-type(1) {
        min-width: 120px;
        margin-right: 9px;
        font-weight: 400;
        font-size: 14px;
        color: rgba(43, 48, 115, 0.6);
    }

    & div:nth-of-type(2) {
        width: 100%;
        font-size: 18px;
        color: #2b3073;
    }
`

export const ProfileAddressBox = styled(CardContent)`
    .unverified-college-box {
        display: flex;
        position: relative;
        align-items: center;
        margin-top: 20px;
        min-width: 370px;
        width: 370px;
        max-width: 500px;

        & div:nth-of-type(1) {
            min-width: 120px;
            margin-right: 9px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(43, 48, 115, 0.6);
        }

        & div:nth-of-type(2) {
            width: 100%;
            font-size: 18px;
            color: #2b3073;
        }
    }
`
