import React, { useEffect, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import UnsavedDataNotifier from '@/components/UI/Modals/UnsavedDataNotifier'
import StateListDropdown from '@/components/UI/Dropdowns/StateListDropdown'

import { IModalCollege } from '@/components/CollegesAndPartners/interfaces'

import { ModalWrapper } from '../../Chat/CreateChatModal/styles'
import { DialogTitleWrapper, DialogWrapper } from '@/components/Patient/PatientTabsPanel/styles'
import { EditButtonConfirm, EditForm } from '@/components/ToDo/ModalToDo/styles'
import { FormSection, MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'
import { formatISO } from '@/utils'

const ModalCollege: React.FC<IModalCollege> = ({
    open,
    handleClose,
    handleCreateCollege,
    handleEditCollege,
    college,
    isEditMode,
}) => {
    const [isUnsavedData, setIsUnsavedData] = useState<boolean>(false)

    const [collegeData, setCollegeData] = useState<any>({
        name: '',
        state: '',
        address: '',
        city: '',
        zipCode: '',
        isArchived: false,
        campusCrisisInfo: '',
    })

    useEffect(() => {
        if (isEditMode) {
            setCollegeData({
                ...collegeData,
                ...college,
            })
        } else {
            setCollegeData({
                ...collegeData,
            })
        }
    }, [college])

    const isValidCollege = collegeData?.name?.length && collegeData?.state?.length

    const handleCollegeData = (name: string, value: any) => {
        setCollegeData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const editCollege = () => {
        if (!isEditMode && handleCreateCollege) {
            handleCreateCollege(collegeData)
        }

        if (isEditMode && handleEditCollege) {
            handleEditCollege(collegeData)
        }

        handleClose()
    }

    const toggleUnsavedDataModal = () => {
        setIsUnsavedData((prevState) => !prevState)
    }

    const closeEditingModal = () => {
        const collegeInJSON = { ...college }

        if (JSON.stringify(collegeInJSON) !== JSON.stringify(collegeData)) {
            toggleUnsavedDataModal()
            return
        }

        handleClose()
    }

    return (
        <ModalWrapper open={open} onClose={closeEditingModal}>
            <DialogWrapper className='dialog-wrapper college-page'>
                <EditForm>
                    <DialogTitleWrapper>
                        <Box>
                            <IconButton onClick={closeEditingModal}>
                                <ArrowBackIcon />
                            </IconButton>
                            {isEditMode ? 'Edit A College' : 'Add A College'}
                        </Box>
                    </DialogTitleWrapper>
                    <FormSection className='content'>
                        <MainHeader style={{ marginTop: 14 }}>
                            <Typography className='college-name-typography required-field w-130'>
                                College Name
                            </Typography>
                            <ToDoFormText
                                required
                                className='todo-inputs'
                                type='text'
                                name='name'
                                variant='standard'
                                size='small'
                                placeholder='Enter official college name and an acronym'
                                value={collegeData?.name}
                                onChange={({ target }) =>
                                    handleCollegeData(target.name, target.value)
                                }
                            />
                        </MainHeader>
                        <MainHeader>
                            <Typography className='college-name-typography w-130'>
                                Address
                            </Typography>
                            <ToDoFormText
                                className='todo-inputs'
                                type='text'
                                name='address'
                                variant='standard'
                                size='small'
                                placeholder='Enter official college address'
                                value={collegeData?.address}
                                onChange={({ target }) =>
                                    handleCollegeData(target.name, target.value)
                                }
                            />
                        </MainHeader>
                        <MainHeader>
                            <Typography className='college-name-typography w-130'>City</Typography>
                            <ToDoFormText
                                className='todo-inputs'
                                type='text'
                                name='city'
                                variant='standard'
                                size='small'
                                placeholder='Enter official college city'
                                value={collegeData?.city}
                                onChange={({ target }) =>
                                    handleCollegeData(target.name, target.value)
                                }
                            />
                        </MainHeader>
                        <MainHeader>
                            <StateListDropdown
                                label='State'
                                name='state'
                                value={collegeData?.state}
                                handleData={handleCollegeData}
                                isEditMode
                            />
                        </MainHeader>
                        <MainHeader>
                            <Typography className='college-name-typography w-130'>
                                Zipcode
                            </Typography>
                            <ToDoFormText
                                className='todo-inputs'
                                type='text'
                                name='zipCode'
                                variant='standard'
                                size='small'
                                placeholder='Enter official college zipcode'
                                value={collegeData?.zipCode}
                                onChange={({ target }) =>
                                    handleCollegeData(target.name, target.value)
                                }
                            />
                        </MainHeader>
                        {isEditMode && (
                            <>
                                <MainHeader>
                                    <Typography className='college-name-typography w-130'>
                                        Date Created
                                    </Typography>
                                    <Typography className='college-created-date'>
                                        {formatISO(collegeData?.createdDate)}
                                    </Typography>
                                </MainHeader>
                            </>
                        )}
                        <MainHeader>
                            <Typography className='maps-typography w-130'>
                                Campus Crisis Resource
                            </Typography>
                            <ToDoFormText
                                className='todo-inputs'
                                type='text'
                                name='campusCrisisInfo'
                                variant='standard'
                                size='small'
                                placeholder='Enter article slug'
                                value={collegeData?.campusCrisisInfo}
                                onChange={({ target }) => {
                                    handleCollegeData(target.name, target.value)
                                }}
                            />
                        </MainHeader>
                    </FormSection>
                </EditForm>
                <Box display='flex' justifyContent='space-between'>
                    <Typography fontSize={14}>* Required</Typography>
                    <EditButtonConfirm
                        onClick={editCollege}
                        disabled={!isValidCollege}
                        className='edit-college-btn'
                        variant='contained'
                        color='info'
                    >
                        Save
                    </EditButtonConfirm>
                </Box>
            </DialogWrapper>
            {isUnsavedData && (
                <UnsavedDataNotifier
                    open={isUnsavedData}
                    toggleUnsavedDataModal={toggleUnsavedDataModal}
                    handleClose={handleClose}
                />
            )}
        </ModalWrapper>
    )
}

export default ModalCollege
