import React, { useMemo } from 'react'
import { Typography } from '@mui/material'

import ToDoQuestionnaire from '@/components/ToDo/ToDoContent/ToDoQuestionnaire'
import ToDoFreeText from '@/components/ToDo/ToDoContent/ToDoFreeText'
import ToDoAppointment from '@/components/ToDo/ToDoContent/ToDoAppointment'
import ToDoArticle from '@/components/ToDo/ToDoContent/ToDoArticle'
import ToDoLab from '@/components/ToDo/ToDoContent/ToDoLab'
import ToDoLabResult from './ToDoLabResult'
import ToDoAfterVisitSummary from './ToDoAfterVisitSummary'

import { IAssignedContentComponents, IToDoContent } from './interfaces'

import { FormSection } from '@/components/ToDo/ToDoForm/styles'

const regex = /[^A-Za-z0-9]+/

const ToDoContent: React.FC<IToDoContent> = ({
    handleToDoData,
    type,
    isViewMode,
    toDoData,
    toDoContentFile,
    setToDoContentFile,
    setToDoData,
}) => {
    const typeAsClassName = useMemo(() => {
        return type?.toLowerCase().split(regex).join('-')
    }, [type])

    const changeTaskDesc = (e: any) => {
        const { name, value } = e.target
        const limit = 250

        if (value?.length > limit) {
            return
        }

        handleToDoData(name, value)
    }

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        handleToDoData(name, value)
    }

    const assignedContentComponents: IAssignedContentComponents = {
        Activities: (
            <ToDoQuestionnaire
                handleToDoData={handleToDoData}
                questionnaireValue={toDoData.intakeAssigned}
                isViewMode={isViewMode}
            />
        ),
        'Free Text': (
            <ToDoFreeText
                changeTaskDesc={changeTaskDesc}
                taskDescValue={toDoData.taskDescription}
                isAddedToMedicalHistory={toDoData.isAddedToMedicalHistory}
                isViewMode={isViewMode}
                handleCheckbox={handleCheckbox}
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
        ),
        Appointment: (
            <ToDoAppointment
                changeTaskDesc={changeTaskDesc}
                handleToDoData={handleToDoData}
                isViewMode={isViewMode}
                toDoData={toDoData}
            />
        ),
        Content: (
            <ToDoArticle
                handleToDoData={handleToDoData}
                toDoData={toDoData}
                isViewMode={isViewMode}
            />
        ),
        'Lab/Rx Order': (
            <ToDoLab
                toDoData={toDoData}
                handleToDoData={handleToDoData}
                isViewMode={isViewMode}
                setToDoData={setToDoData}
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
                isAddedToMedicalHistory={toDoData.isAddedToMedicalHistory}
                handleCheckbox={handleCheckbox}
            />
        ),
        'Lab Result': (
            <ToDoLabResult
                toDoData={toDoData}
                handleToDoData={handleToDoData}
                isViewMode={isViewMode}
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
                isAddedToMedicalHistory={toDoData.isAddedToMedicalHistory}
                handleCheckbox={handleCheckbox}
            />
        ),
        'After Visit Summary': (
            <ToDoAfterVisitSummary
                toDoData={toDoData}
                handleToDoData={handleToDoData}
                isViewMode={isViewMode}
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
        ),
    }

    return (
        <FormSection className={`content ${typeAsClassName}`}>
            <Typography className='section-title' gutterBottom>
                Content
            </Typography>
            {assignedContentComponents[type]}
        </FormSection>
    )
}

export default React.memo(ToDoContent)
