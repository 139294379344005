import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { differenceInMinutes } from 'date-fns'
import { Box } from '@mui/material'

import PatientAvatar from '@/components/UI/PatientAvatar'
import { RED_ZONE } from '@/core/constants'
import {
    getLastMessageCreatedAt,
    getFullName,
    cropName,
    getDoBAndState,
    getChannelData,
    getSubscriptionCurrentPlan,
} from '@/utils'
import { IChannelPreview } from './interfaces'
import { CardHeaderPreview, ChannelBadge, ChannelPreviewWrapper } from './styles'

const MINUTE_IN_MS = 60000

const getDoctorInChannel = ({
    firstName,
    role,
}: {
    doctor_id: string,
    firstName: string,
    role: string,
}): string => {
    return role ? `${firstName}, ${role} joined` : `${firstName} joined`
}

const ChannelPreview: React.FC<IChannelPreview> = ({
    data,
    selectedChannel,
    setSelectedChannel,
    selectedCustomType,
    inviteProviderToGroupChannel,
}) => {
    const [isActiveChannel, setIsActiveChannel] = useState<boolean>(false)
    const [timeAfter10Minutes, setTimeAfter10Minutes] = useState<string>('')
    const [isShowTime, setIsShowTime] = useState<boolean>(false)
    const timerRef = useRef<any>(null)

    const {
        firstName,
        lastName,
        birthDate,
        state,
        preferredName,
        activeSubscription,
        avatarLink,
        groupChannel: channel,
    } = data || {}

    const subscriptionType: string = activeSubscription?.subscriptionType
    const b2bPartner: string = activeSubscription?.b2bPartner
    const b2bSubPartner: string = activeSubscription?.b2bSubPartner
    const readablePartner: string = b2bSubPartner ? b2bSubPartner : b2bPartner ? b2bPartner : ''

    const isCareTeamChannel = selectedCustomType === 'care_team'
    const parseChannelData = getChannelData(channel)
    const firstUnreadMessageInMs = parseChannelData?.firstUnreadMessageTime
    const lastMessageInMs = channel?.lastMessage?.createdAt
    const doctorInChannel = parseChannelData?.doctor_id
    const isRedZone = channel?.zones?.includes(RED_ZONE)
    const dateOfLastMessage = getLastMessageCreatedAt(lastMessageInMs)

    const handleOpenChat = async (urlOfChat: string) => {
        await inviteProviderToGroupChannel(urlOfChat)
        setSelectedChannel(urlOfChat)
        setIsActiveChannel(true)
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setIsActiveChannel(selectedChannel === channel.channelUrl)
        }
        return () => {
            isMounted = false
        }
    }, [channel, selectedChannel])

    useEffect(() => {
        let isMounted = true
        if (isMounted && !isRedZone && (timeAfter10Minutes || timerRef.current)) {
            setTimeAfter10Minutes('')
            clearInterval(timerRef.current)
            clearTimeout(timerRef.current)
            setIsShowTime(false)
        }
        return () => {
            isMounted = false
        }
    }, [isRedZone, timeAfter10Minutes, timerRef.current])

    useEffect(() => {
        let isMounted = true
        if (selectedChannel === channel.channelUrl && isMounted) {
            const channelListBody: any | null = document.querySelector(
                '.sendbird-channel-list__body'
            )
            channelListBody?.querySelector('.MuiCard-root.active')?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        }
        return () => {
            isMounted = false
        }
    }, [isActiveChannel])

    useEffect(() => {
        let isMounted = true
        if (isMounted && isRedZone && firstUnreadMessageInMs) {
            const diffInMinutes = differenceInMinutes(new Date(), firstUnreadMessageInMs)

            if (diffInMinutes >= 10) {
                setTimeAfter10Minutes(`+ ${diffInMinutes}`)
                setIsShowTime(true)
            } else {
                timerRef.current = setTimeout(() => {
                    setTimeAfter10Minutes('+ 10')
                    setIsShowTime(true)
                }, (10 - diffInMinutes) * MINUTE_IN_MS)
            }
        }
        return () => {
            isMounted = false
            clearTimeout(timerRef.current)
            clearInterval(timerRef.current)
        }
    }, [])

    useEffect(() => {
        let isMounted = true
        if (isShowTime && isMounted) {
            timerRef.current = setInterval(() => {
                const diffInMinutes = differenceInMinutes(new Date(), firstUnreadMessageInMs)
                setTimeAfter10Minutes(`+ ${diffInMinutes}`)
            }, MINUTE_IN_MS)
        } else {
            clearInterval(timerRef.current)
        }

        return () => {
            isMounted = false
            clearInterval(timerRef.current)
        }
    }, [firstUnreadMessageInMs, isShowTime])

    return (
        <ChannelPreviewWrapper
            onClick={async () => await handleOpenChat(channel?.channelUrl)}
            className={isActiveChannel ? 'active' : ''}
        >
            <CardHeaderPreview
                sx={{ alignItems: 'flex-start' }}
                avatar={
                    <PatientAvatar
                        src={avatarLink}
                        currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                        fontSize={10}
                        wrapperWidth={72}
                        wrapperHeight={72}
                    />
                }
                action={dateOfLastMessage}
                title={cropName(getFullName(firstName, lastName, preferredName), 12)}
                subheader={
                    <Box>
                        {getDoBAndState(birthDate, state)}
                        <div className='additional-info'>
                            {readablePartner && (
                                <div className='partner-info'>{readablePartner}</div>
                            )}
                            {doctorInChannel && isCareTeamChannel && (
                                <div className='doctor-info'>
                                    {getDoctorInChannel(JSON.parse(channel?.data))}
                                </div>
                            )}
                        </div>
                        {channel?.unreadMessageCount > 0 && (
                            <ChannelBadge className='contained'>
                                {channel?.unreadMessageCount}
                            </ChannelBadge>
                        )}
                        <ChannelBadge
                            className={classNames(
                                `${channel?.zones?.length && channel?.zones[0]}-zone`,
                                {
                                    zone: isCareTeamChannel,
                                }
                            )}
                        >
                            {isShowTime && (
                                <span className='unread-message'>{timeAfter10Minutes}</span>
                            )}
                        </ChannelBadge>
                    </Box>
                }
            />
        </ChannelPreviewWrapper>
    )
}

export default ChannelPreview
