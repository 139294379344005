import { RefObject } from 'react'

export enum MessageRole {
    SYSTEM = 'system',
    ASSISTANT = 'assistant',
    USER = 'user',
}

export type User = {
    name: string,
    avatarUrl?: string,
}

export type Message = {
    chatId: string | null,
    messageId: number,
    role: MessageRole,
    message: string,
    userInfo?: User,
    approval?: boolean,
    comment?: string,
}

export type Conversations = Array<Message>

export interface IChatUIProps {
    userId: number;
    isQuerying: boolean;
    onSubmit: (value: any) => void;
    placeholder: string;
    disabled: boolean;
    conversations: Conversations;
    customSubmitIcon?: React.ReactNode;
    token: string | null;
}

export interface IChatInputProps {
    disabled: boolean;
    onSubmit: (value: any) => void;
    placeholder: string;
    customSubmitIcon?: React.ReactNode;
}

export interface IChatConversationsProps {
    userId: number;
    conversations: Conversations;
    isQuerying: boolean;
    chatConversationsContainerRef: RefObject<HTMLDivElement>;
    token: string | null;
}

export interface IChatMessageProps {
    userId: number;
    message: Message;
    token: string | null;
    approval?: boolean;
    comment?: string;
}
