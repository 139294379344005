import React, { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'

import { IUnverifiedCollege } from '@/components/CollegesAndPartners/interfaces'

const ReviewCheckbox: React.FC<any> = ({ college, editUnverifiedCollege }) => {
    const [unverifiedCollegeData, setUnverifiedCollegeData] = useState<IUnverifiedCollege>(() => ({
        ...college,
    }))

    const handleReviewToggle = (event: any) => {
        setUnverifiedCollegeData((prevState: any) => ({
            ...prevState,
            ['isReviewed']: event.target.checked,
        }))
    }

    useEffect(() => {
        editUnverifiedCollege(unverifiedCollegeData)
    }, [unverifiedCollegeData])

    return (
        <>
            <Checkbox
                checked={unverifiedCollegeData?.isReviewed}
                onChange={(event) => {
                    handleReviewToggle(event)
                }}
            />
        </>
    )
}

export default ReviewCheckbox
