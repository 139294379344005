import { gql } from '@apollo/client'

export const UNVERIFIED_COLLEGE_LIST = gql`
    query UnverifiedCollegeList($offset: Int, $limit: Int, $search: String) {
        unverifiedColleges(offset: $offset, limit: $limit, search: $search) {
            count
            unverifiedCollegeList {
                id
                name
                state
                isReviewed
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`

export const UNVERIFIED_COLLEGE_BY_USER_ID = gql`
    query UnverifiedCollegeByUserId($userId: ID!) {
        unverifiedCollegeByUserId(userId: $userId) {
            id
            name
            state
            isReviewed
            user {
                id
            }
        }
    }
`
