import React, { ChangeEvent, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined'

import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

import { IProviderAvatar } from '@/components/Provider/interfaces'

import { AvatarWrapper, IconButtonWrapper, ProviderAvatarWrapper } from '../styles'

const ProviderAvatar: React.FC<IProviderAvatar> = ({
    src,
    isEditMode,
    uploadProviderPhoto,
    photoId,
    setPreviewPhoto,
    isDisabled,
}) => {
    const [downloadSignedUrl] = useLazyQuery(DOWNLOAD_SIGNED_URL)
    const inputRef = useRef<HTMLElement | any>(null)

    useEffect(() => {
        if (photoId) {
            const downloadPhoto = async () => {
                const { data } = await downloadSignedUrl({
                    variables: {
                        downloadSignedUrlId: photoId,
                    },
                })

                setPreviewPhoto(data?.downloadSignedUrl?.url)
            }

            downloadPhoto()
        }
    }, [photoId, isEditMode])

    const selectPhoto = () => {
        inputRef.current.click()
    }

    const uploadPhoto = async (e: ChangeEvent<HTMLInputElement>) => {
        uploadProviderPhoto(e)
    }

    return (
        <ProviderAvatarWrapper>
            <AvatarWrapper src={src} variant='circular' />

            {isEditMode && (
                <>
                    <input
                        ref={inputRef}
                        type='file'
                        accept='image/png, image/jpeg'
                        onChange={uploadPhoto}
                        hidden
                    />
                    <IconButtonWrapper onClick={selectPhoto} disabled={isDisabled}>
                        <AddAPhotoOutlinedIcon />
                    </IconButtonWrapper>
                </>
            )}
        </ProviderAvatarWrapper>
    )
}

export default ProviderAvatar
