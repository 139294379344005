import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
    SuccessModalContainer,
    SuccessModalXButton,
    SuccessContentContainer,
    SuccessModalHeaderText,
    SuccessModalText,
} from '../styles'
import successCheck from '@/assets/images/success-check.svg'
import { IAdminMessageModal } from '../interfaces'

const AdminMessageModal: React.FC<IAdminMessageModal> = ({
    modalOpen,
    isErrorModal,
    handleModalClose,
}) => {
    const headerText = isErrorModal ? 'Error' : 'Success!'
    const bodyText = isErrorModal
        ? 'Message failed to submit.\nPlease try again.'
        : 'Message successfully submitted.'

    return (
        <>
            <SuccessModalContainer open={modalOpen} onClose={handleModalClose}>
                <SuccessModalXButton aria-label='close' onClick={handleModalClose}>
                    <CloseIcon />
                </SuccessModalXButton>
                <SuccessContentContainer>
                    {!isErrorModal && <img src={successCheck} alt='Success' />}
                    <SuccessModalHeaderText>{headerText}</SuccessModalHeaderText>
                    <SuccessModalText>{bodyText}</SuccessModalText>
                </SuccessContentContainer>
            </SuccessModalContainer>
        </>
    )
}

export default AdminMessageModal
