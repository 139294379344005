import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Checkbox, FormControlLabel } from '@mui/material'

import { UPDATE_CHANNEL_METADATA } from '../../../../graphql/chat/mutations'
import convertStringToBoolean from '../../../../utils/convertStringToBoolean'

import { IChatChannelMetaData } from '../interfaces'

const FlagCheckbox: React.FC<any> = ({ channel }) => {
    const flagKey = 'waiting_for_follow_up'
    const metadataKey: string[] = [flagKey]
    const [channelMetaData, setChannelMetaData] = useState<IChatChannelMetaData>({
        waiting_for_follow_up: '',
    })

    const [updateChannelMetadata] = useMutation(UPDATE_CHANNEL_METADATA)

    const getMetadata = async () => {
        const metadata = await channel.getMetaData(metadataKey)

        setChannelMetaData(metadata)
    }

    useEffect(() => {
        if (channel?.url) {
            getMetadata()
        }
    }, [channel])

    const handleFlagToggle = async (event: any) => {
        setChannelMetaData((prevState: IChatChannelMetaData) => ({
            ...prevState,
            [flagKey]: event.target.checked.toString(),
        }))

        if (channelMetaData[flagKey] !== event.target.checked.toString()) {
            await updateChannelFlagMetadata({
                ...channelMetaData,
                [flagKey]: event.target.checked.toString(),
            })
        }
    }

    const updateChannelFlagMetadata = async (channelMetaData: IChatChannelMetaData) => {
        try {
            await updateChannelMetadata({
                variables: {
                    channelUrl: channel.url,
                    metadata: {
                        ...channelMetaData,
                    },
                },
            })
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={convertStringToBoolean(channelMetaData?.[flagKey]) || false}
                    onChange={async (event) => {
                        await handleFlagToggle(event)
                    }}
                    size='small'
                />
            }
            label='Waiting for Follow Up'
        />
    )
}

export default FlagCheckbox
