import React from 'react'
import { CardHeader } from '@mui/material'

import { getDoB } from '@/utils'

import { IPatientHIEConsent } from '@/components/Patient/PatientTabsPanel/interfaces'
import { PatientDataBlockWrapper } from '../../PatientContent/styles'

const PatientHieConsent: React.FC<IPatientHIEConsent> = ({
    title,
    isConsentObtained,
    dateOfConsentObtained,
}) => {
    const consent = isConsentObtained ? 'YES' : 'NO'

    return (
        <PatientDataBlockWrapper className='patient-consent'>
            <CardHeader
                action={getDoB(dateOfConsentObtained, 'MM/dd/yyyy')}
                title={title}
                subheader={consent}
            />
        </PatientDataBlockWrapper>
    )
}

export default PatientHieConsent
