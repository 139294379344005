import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SearchBar from '@/components/UI/Inputs/SearchBar'
import ExportToExcelBtn from '@/components/CollegesAndPartners/components/ExportToExcelBtn'
import ListTableContainer from '../../../components/CollegesAndPartners/ListTableContainer'
import ModalCollege from '@/components/CollegesAndPartners/modals/ModalCollege'
import ModalBulkColleges from '@/components/CollegesAndPartners/modals/ModalBulkColleges'

import { useDebounce } from '@/hooks'
import { COLLEGE_LIST } from '@/graphql/colleges/queries'
import { UNVERIFIED_COLLEGE_LIST } from '@/graphql/unverifiedColleges/queries'
import { CREATE_BULK_COLLEGES, CREATE_COLLEGE } from '@/graphql/colleges/mutations'

import {
    ListHeaderTableContainer,
    ListTableHeader,
} from '@/pages/Dashboard/DashboardCollegesAndPartnersPage/styles'
import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'
import { GET_B2B_PARTNER_LIST, GET_B2B_SUBPARTNER_LIST } from '@/graphql/partners/queries'

const DashboardCollegesAndPartnersPage: React.FC = () => {
    const [searchName, setSearchName] = useState<string>('')
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false)
    const [isBulkCreateMode, setIsBulkCreateMode] = useState<boolean>(false)
    const [verifiedPage, setVerifiedPage] = useState(0)
    const [unverifiedPage, setUnverifiedPage] = useState(0)
    const [partnerPage, setPartnerPage] = useState(0)
    const [subPartnerPage, setSubPartnerPage] = useState(0)
    const [verifiedLimit, setVerifiedLimit] = useState(10)
    const [unverifiedLimit, setUnverifiedLimit] = useState(10)
    const [partnerLimit, setPartnerLimit] = useState(10)
    const [subPartnerLimit, setsubPartnerLimit] = useState(10)

    const tableRef = useRef(null)
    const [
        fetchCollegeList,
        { data: collegeData, loading: collegeLoading, refetch: refetchColleges },
    ] = useLazyQuery(COLLEGE_LIST)
    const [
        fetchUnverifiedCollegeList,
        { data: unverifiedCollegeData, loading: unverifiedCollegeLoading },
    ] = useLazyQuery(UNVERIFIED_COLLEGE_LIST)
    const [fetchB2bPartnerList, { data: b2bPartnerListData, loading: b2bPartnerListLoading }] =
        useLazyQuery(GET_B2B_PARTNER_LIST)
    const [
        fetchB2bSubPartnerList,
        { data: b2bSubPartnerListData, loading: b2bSubPartnerListLoading },
    ] = useLazyQuery(GET_B2B_SUBPARTNER_LIST)

    const [createCollege] = useMutation(CREATE_COLLEGE)
    const [createBulkColleges] = useMutation(CREATE_BULK_COLLEGES)

    const verifiedOffset = verifiedPage * verifiedLimit
    const unverifiedOffset = unverifiedPage * unverifiedLimit

    const searchCollegeList = useCallback(
        async (search: string, offset?: number, limit?: number) => {
            await fetchCollegeList({
                variables: {
                    search,
                    offset,
                    limit,
                },
            })
        },
        [searchName, verifiedOffset, verifiedLimit]
    )

    const unverifiedCollegeList = useCallback(
        async (offset?: number, limit?: number) => {
            await fetchUnverifiedCollegeList({
                variables: {
                    offset,
                    limit,
                },
            })
        },
        [unverifiedOffset, unverifiedLimit]
    )

    const b2bPartnerList = useCallback(async () => {
        await fetchB2bPartnerList()
    }, [partnerLimit])

    const b2bSubPartnerList = useCallback(async () => {
        await fetchB2bSubPartnerList()
    }, [subPartnerLimit])

    const debouncedSearch = useDebounce(searchCollegeList, 500)

    useEffect(() => {
        setVerifiedPage(0)
        debouncedSearch(searchName, 0, verifiedLimit)
    }, [searchName])

    useEffect(() => {
        searchCollegeList(searchName, verifiedOffset, verifiedLimit)
    }, [verifiedOffset, verifiedLimit])

    useEffect(() => {
        unverifiedCollegeList(unverifiedOffset, unverifiedLimit)
    }, [unverifiedOffset, unverifiedLimit])

    useEffect(() => {
        b2bPartnerList()
    }, [partnerLimit])

    useEffect(() => {
        b2bSubPartnerList()
    }, [subPartnerLimit])

    const handleVerifiedChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setVerifiedPage(newPage)
    }

    const handleVerifiedChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setVerifiedLimit(parseInt(event.target.value, 10))
        setVerifiedPage(0)
    }

    const handleUnverifiedChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setUnverifiedPage(newPage)
    }

    const handleUnverifiedChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setUnverifiedLimit(parseInt(event.target.value, 10))
        setUnverifiedPage(0)
    }

    const handlePartnerChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPartnerPage(newPage)
    }

    const handlePartnerChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPartnerLimit(parseInt(event.target.value, 10))
        setPartnerPage(0)
    }

    const handleSubPartnerChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setSubPartnerPage(newPage)
    }

    const handleSubPartnerChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setsubPartnerLimit(parseInt(event.target.value, 10))
        setSubPartnerPage(0)
    }

    const searchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value

        setSearchName(text)
    }

    const clearSearch = () => {
        setSearchName('')
    }

    const openCreateModal = () => {
        setIsCreateMode(!isCreateMode)
    }

    const openBulkCreateModal = () => {
        setIsBulkCreateMode(!isBulkCreateMode)
    }

    const handleCreateCollege = async (collegeInput: any) => {
        await createCollege({
            variables: {
                collegeInput: collegeInput,
            },
        })

        await refetchColleges({ fetchPolicy: 'network-only' })
    }

    const handleBulkCreateCollege = async (colleges: any) => {
        await createBulkColleges({
            variables: {
                input: colleges,
            },
        })

        await refetchColleges({ fetchPolicy: 'network-only' })
    }

    return (
        <>
            <ListHeaderTableContainer>
                <SearchBar
                    placeholder='Search in Colleges'
                    searchName={searchName}
                    clearSearch={clearSearch}
                    searchByName={searchByName}
                />
                <ListTableHeader>
                    Colleges List
                    <div>
                        <ExportToExcelBtn collegeList={collegeData?.colleges?.collegeList} />
                        <AddNewChannelBtn
                            onClick={openCreateModal}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Add New
                        </AddNewChannelBtn>
                        {isCreateMode && (
                            <ModalCollege
                                open={isCreateMode}
                                handleClose={openCreateModal}
                                handleCreateCollege={handleCreateCollege}
                                isEditMode={false}
                            />
                        )}
                        <AddNewChannelBtn
                            onClick={openBulkCreateModal}
                            startIcon={<AddCircleOutlineIcon />}
                        >
                            Add New (Bulk)
                        </AddNewChannelBtn>
                        {isBulkCreateMode && (
                            <ModalBulkColleges
                                open={isBulkCreateMode}
                                handleClose={openBulkCreateModal}
                                handleBulkCreateCollege={handleBulkCreateCollege}
                            />
                        )}
                    </div>
                </ListTableHeader>
                <ListTableContainer
                    testId='colleges-list'
                    listData={collegeData?.colleges?.collegeList}
                    listDataLoading={collegeLoading}
                    listDataCount={collegeData?.colleges?.count}
                    tableRef={tableRef}
                    searchName={searchName}
                    offset={verifiedOffset}
                    limit={verifiedLimit}
                    page={verifiedPage}
                    handleChangePage={handleVerifiedChangePage}
                    handleChangeRowsPerPage={handleVerifiedChangeRowsPerPage}
                    isColleges
                />
            </ListHeaderTableContainer>
            <ListHeaderTableContainer>
                <ListTableHeader>Partners List</ListTableHeader>
                <ListTableContainer
                    testId='partners-list'
                    listData={b2bPartnerListData?.b2bPartnerList}
                    listDataLoading={b2bPartnerListLoading}
                    listDataCount={b2bPartnerListData?.b2bPartnerList?.length}
                    tableRef={tableRef}
                    searchName={''}
                    offset={0}
                    limit={partnerLimit}
                    page={partnerPage}
                    handleChangePage={handlePartnerChangePage}
                    handleChangeRowsPerPage={handlePartnerChangeRowsPerPage}
                    isPartners
                />
            </ListHeaderTableContainer>
            <ListHeaderTableContainer>
                <ListTableHeader>Subpartners List</ListTableHeader>
                <ListTableContainer
                    testId='subpartners-list'
                    listData={b2bSubPartnerListData?.b2bSubPartnerList}
                    listDataLoading={b2bSubPartnerListLoading}
                    listDataCount={b2bSubPartnerListData?.b2bSubPartnerList?.length}
                    tableRef={tableRef}
                    searchName={''}
                    offset={0}
                    limit={subPartnerLimit}
                    page={subPartnerPage}
                    handleChangePage={handleSubPartnerChangePage}
                    handleChangeRowsPerPage={handleSubPartnerChangeRowsPerPage}
                    isSubPartners
                />
            </ListHeaderTableContainer>
            <ListHeaderTableContainer>
                <ListTableHeader>Unverified Colleges List</ListTableHeader>
                <ListTableContainer
                    testId='unverified-colleges-list'
                    listData={unverifiedCollegeData?.unverifiedColleges?.unverifiedCollegeList}
                    listDataLoading={unverifiedCollegeLoading}
                    listDataCount={unverifiedCollegeData?.unverifiedColleges?.count}
                    tableRef={tableRef}
                    searchName={''}
                    offset={unverifiedOffset}
                    limit={unverifiedLimit}
                    page={unverifiedPage}
                    handleChangePage={handleUnverifiedChangePage}
                    handleChangeRowsPerPage={handleUnverifiedChangeRowsPerPage}
                    isUnverifiedColleges
                />
            </ListHeaderTableContainer>
        </>
    )
}

export default DashboardCollegesAndPartnersPage
