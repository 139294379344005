import React, { ChangeEvent, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { AccordionDetails, Box, IconButton, Input } from '@mui/material'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import FileLoaderMenuBtn from '@/components/Patient/PatientTabsPanel/PatientMedicalHistory/FileLoaderMenuBtn'
import CircularLoader from '@/components/Loader/CircularLoader'

import { downloadFileByBase64 } from '@/utils'
import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'
import { UPDATE_FILE } from '@/graphql/files/mutations'

import { IFileLoader } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'

const FileLoader: React.FC<IFileLoader> = ({ file }) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [fileName, setFileName] = useState<string>('')

    const isEqualToZero = file?.displayName?.length === 0
    let uploadFileIcon = null

    const [updateFileName, { loading }] = useMutation(UPDATE_FILE)
    const [downloadSignedUrl] = useLazyQuery(DOWNLOAD_SIGNED_URL, {
        variables: {
            downloadSignedUrlId: file.id,
        },
    })

    useEffect(() => {
        if (!file.displayName) {
            setFileName(file.name)
            return
        }

        setFileName(file.displayName)
    }, [file])

    const downloadFile = async () => {
        const { data } = await downloadSignedUrl()

        const { name, url } = data.downloadSignedUrl

        downloadFileByBase64(name, url)
    }

    const toggleEditMode = () => {
        setEditMode((prevState) => !prevState)
    }

    const renameFileName = async () => {
        await updateFileName({
            variables: {
                updateFileId: file.id,
                fileInput: {
                    displayName: fileName,
                },
            },
            onCompleted: () => {
                setFileName(fileName)
                toggleEditMode()
            },
        })
    }

    const editFileName = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target

        setFileName(value)
    }

    if (editMode) {
        uploadFileIcon = (
            <IconButton disabled={isEqualToZero} onClick={renameFileName}>
                <SaveOutlinedIcon />
            </IconButton>
        )
    } else {
        uploadFileIcon = (
            <FileLoaderMenuBtn downloadFile={downloadFile} toggleEditMode={toggleEditMode} />
        )
    }

    if (loading) {
        uploadFileIcon = (
            <IconButton>
                <CircularLoader size={24} relative />
            </IconButton>
        )
    }

    return (
        <AccordionDetails>
            <Box className='free-text_upload-file file-loader'>
                <Input
                    onChange={editFileName}
                    disabled={!editMode}
                    value={fileName}
                    name='name'
                    fullWidth
                    disableUnderline
                />
            </Box>
            {uploadFileIcon}
        </AccordionDetails>
    )
}

export default FileLoader
