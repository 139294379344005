import React, { useMemo } from 'react'
import { FormControl, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { IInputField } from '@/components/UI/interfaces'

import { GenderMenuItem, GenderSelect } from '@/components/Patient/PatientTabsPanel/styles'

const sexAtBirthItems = ['male', 'female', 'unknown']
const preferredGenderItems = ['Male', 'Female', 'Other', 'Unknown']

const GenderDropdown: React.FC<IInputField> = ({
    label,
    value,
    isEditMode,
    onChangeHandler,
    name,
}) => {
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        if (onChangeHandler) {
            onChangeHandler(event)
        }
    }

    const memoizedDropdownItems = useMemo(() => {
        const isPreferredGender = name === 'preferredGender'

        return isPreferredGender ? preferredGenderItems : sexAtBirthItems
    }, [name])

    return (
        <>
            <Typography>{label}</Typography>
            <FormControl disabled={!isEditMode} variant='standard'>
                <GenderSelect value={value} onChange={handleChange} name={name} label='Gender'>
                    {memoizedDropdownItems.map((gender) => (
                        <GenderMenuItem key={gender} value={gender}>
                            {gender}
                        </GenderMenuItem>
                    ))}
                </GenderSelect>
            </FormControl>
        </>
    )
}

export default GenderDropdown
