import React, { useState } from 'react'

import ExpiredTokenContext from '@/context/ExpiredTokenContext'

const ExpiredTokenProvider = ({ children }: React.PropsWithChildren<any>) => {
    const [doShowError, setDoShowError] = useState(false)

    return (
        <>
            <ExpiredTokenContext.Provider
                value={{
                    doShowError,
                    setDoShowError,
                }}
            >
                {children}
            </ExpiredTokenContext.Provider>
        </>
    )
}

export default ExpiredTokenProvider
