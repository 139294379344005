import format from 'date-fns/format'

const dateToString = (dateInMs: number | string, dateFormat: string): string => {
    if (!dateInMs) {
        return ''
    }

    return format(Number(dateInMs), dateFormat)
}

export default dateToString
