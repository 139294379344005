export enum SubscriptionTypesEnum {
    FREEMIUM = 'freemium',
    INTRO_FREE_TRIAL = 'intro_free_trial',
    PROMO_CODE = 'promo_code',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
    B2B = 'b2b',
}

export enum ReadableSubscriptionTypesEnum {
    freemium = 'Freemium',
    intro_free_trial = 'Free Trial',
    promo_code = 'Promo Code',
    monthly = 'Monthly',
    yearly = 'Yearly',
    b2b = 'B2B',
}

export enum ReadablePromoCodeTypesEnum {
    crw_health_apple_yearly_sub_prod = 'Yearly',
    crw_health_apple_monthly_sub_prod = 'Monthly',
    crw_health_android_yearly_sub_prod = 'Yearly',
    crw_health_android_monthly_sub_prod = 'Monthly',
}
