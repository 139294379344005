import React from 'react'
import { ChatsHeaderWrapper } from './styles'

const ChatsHeader: React.FC<{ isMyChannelsTab: boolean }> = ({ isMyChannelsTab }) => {
    return (
        <ChatsHeaderWrapper>
            <div>
                <p>{`Chats: ${isMyChannelsTab ? '1:1' : 'Care Team'}`}</p>
            </div>
            <div></div>
            <div></div>
        </ChatsHeaderWrapper>
    )
}

export default ChatsHeader
