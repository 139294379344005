import React, { useState } from 'react'
import classNames from 'classnames'

import { formatISO } from '@/utils'
import { ICollegeSingleRow } from '@/components/CollegesAndPartners/interfaces'
import { ListTableBodyRow, ListTableCell } from '@/components/CollegesAndPartners/styles'
import ThreeDotBtn from '@/components/CollegesAndPartners/components/ThreeDotBtn'
import ModalCollege from '@/components/CollegesAndPartners/modals/ModalCollege'
import useUpdateCollege from '../hooks/useUpdateCollege'

const CollegeSingleRow: React.FC<ICollegeSingleRow> = ({
    college,
    search,
    offset,
    limit,
    count,
    testId,
}) => {
    const [isOpenCollegeMenu, setIsOpenCollegeMenu] = useState<boolean>(false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const { handleUpdateCollege } = useUpdateCollege()

    const openCollegeMenu = () => {
        setIsOpenCollegeMenu((prevState) => !prevState)
    }

    const enableEditMode = () => {
        setIsEditMode((prevState) => !prevState)
    }

    const handleEditCollege = async (collegeData: any) => {
        const collegeInput = {
            name: collegeData.name,
            address: collegeData.address,
            city: collegeData.city,
            state: collegeData.state,
            zipCode: collegeData.zipCode,
            isArchived: collegeData.isArchived,
            campusCrisisInfo: collegeData.campusCrisisInfo,
        }

        await handleUpdateCollege(
            collegeData,
            collegeInput,
            college?.id,
            search,
            offset,
            limit,
            count
        )
    }

    return (
        <ListTableBodyRow
            data-testid={testId}
            className={classNames({
                'open-menu': isOpenCollegeMenu,
            })}
        >
            <ListTableCell>{college?.name}</ListTableCell>
            <ListTableCell>{college?.state}</ListTableCell>
            <ListTableCell>{formatISO(college?.createdDate)}</ListTableCell>
            <ListTableCell className='colleges-students'>{college?.users?.length}</ListTableCell>
            <ListTableCell className='colleges-articles'>{college?.campusCrisisInfo}</ListTableCell>
            <ListTableCell>
                <ThreeDotBtn
                    openMenu={openCollegeMenu}
                    enableEditMode={enableEditMode}
                    handleEdit={handleEditCollege}
                    item={college}
                    college
                />
            </ListTableCell>
            {isEditMode && (
                <ModalCollege
                    open={isEditMode}
                    handleClose={enableEditMode}
                    handleEditCollege={handleEditCollege}
                    college={college}
                    isEditMode={true}
                />
            )}
        </ListTableBodyRow>
    )
}

export default CollegeSingleRow
