import {
    Box,
    Card,
    Chip,
    styled,
    DialogTitle,
    Dialog,
    Typography,
    MenuItem,
    Select,
} from '@mui/material'

export const PatientToDosWrapper = styled(Box)`
    position: relative;
    padding-top: 18px;
    height: inherit;

    & > .MuiButton-root {
        margin-bottom: 10px;
    }
`

export const PatientToDoBlockWrapper = styled(Card)`
    padding: 16px;
    margin-top: 16px;
    box-shadow: 0px 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
        0px 1px 3px rgba(64, 72, 100, 0.12);
    border-radius: 6px;
    transition: all 0.3s;

    .MuiCardHeader-content {
        .MuiTypography-root {
            font-weight: 400;
            font-size: 18px;
            color: rgba(43, 48, 115, 0.6);
        }
    }

    .MuiCardContent-root {
        padding-top: 0;
    }

    .MuiCardHeader-title {
        font-size: 16px;
        font-weight: 400;
    }

    &.open-menu {
        background-color: #f3f0ea;

        .due-date_chip {
            background-color: #e5ded0;
        }
    }
`

export const EmptyStatePatientBlock = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    font-weight: 500;
    font-size: 24px;
    color: rgba(43, 48, 115, 0.5);
    text-align: center;
`

export const GenderMenuItem = styled(MenuItem)`
    text-transform: capitalize;
`

export const GenderSelect = styled(Select)`
    text-transform: capitalize;
`

export const PatientToDoChip = styled(Chip)`
    border-radius: 6px;
    padding: 5px 8px;
    margin-right: 8px;
    background-color: transparent;
    font-weight: 500;

    &.absolute {
        position: absolute;
        top: 0;
        right: 0;
    }

    &.due-date_chip {
        background-color: #edebe5;
    }

    &.low-priority {
        background-color: #769fc1;
    }

    &.medium-priority {
        background-color: #ffe662;
    }

    &.high-priority {
        background-color: #fc7960;
    }

    &.not-started-todo {
        color: #769fc1;
    }

    &.in-progress-todo {
        color: #97c287;
    }

    &.completed-todo {
        color: #b871b5;
    }

    &.canceled-todo {
        color: #fc7960;
    }

    & .MuiBox-root {
        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }

    span {
        font-weight: 700;
        font-size: 14px;
        padding: 0;

        .MuiAvatar-root {
            width: 16px;
        }

        img {
            width: 32px;
            height: 32px;
        }
    }
`

export const ChipIcon = styled(Typography)`
    display: flex;
    align-items: center;
    color: rgba(43, 48, 115, 0.6);
    margin-right: 12px;
`

export const TitleBlock = styled(Box)`
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-weight: 700;
    font-size: 16px;
    color: #2b3073;
    margin-top: 16px;

    & .MuiAvatar-root {
        margin-right: 16px;
    }
`

export const ToDoTypeBlock = styled(Box)`
    display: flex;
    align-items: center;
    margin-top: 22px;
    color: rgba(43, 48, 115, 0.6);

    .MuiTypography-root {
        font-size: 14px;
        width: 160px;
    }

    span {
        margin-left: 15px;
        margin-right: 23px;
        font-size: 14px;
        color: #2b3073;
    }
`

export const DescriptionBlock = styled(Box)`
    font-size: 14px;
    margin-top: 16px;
    word-break: break-word;

    &.free-text {
        color: rgba(43, 48, 115, 0.6);
    }
`

export const ModalWrapper = styled(Dialog)`
    .MuiPaper-root {
        max-width: 70%;
        overflow-y: initial;
    }

    .MuiBackdrop-root {
        background-color: rgba(43, 48, 115, 0.6);
    }
`

export const DialogTitleWrapper = styled(DialogTitle)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #edebe5;
    color: #2b3073;
    padding: 13px 0 20px;

    .MuiIconButton-root {
        margin-right: 5px;
    }

    .todo-status {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #769fc1;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }
`

export const DialogWrapper = styled(Box)`
    padding: 16px;
    width: 708px;
    background-color: #edebe5;
    color: rgba(43, 48, 115, 0.6);
    overflow-y: auto;

    &.college-page {
        border-radius: 6px;

        .todo-inputs {
            margin-bottom: 0;
        }

        & .content .MuiBox-root {
            margin-bottom: 30px;
        }

        & .content .state-typography {
            width: 130px;
        }

        & .content .search-box input.MuiAutocomplete-input {
            font-size: 14px;
            padding-left: 6px;
        }
    }
`

export const AccordionFilesWrapper = styled(Box)`
    margin-top: 18px;
    border-top: 1px solid #eae9e5;

    & .MuiAccordion-root {
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    & .MuiAccordionSummary-root {
        padding: 0;
    }

    & .MuiAccordionDetails-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        padding: 12px 18px;
        border: 1px solid #edebe5;
        border-radius: 4px;
    }

    & .files-count {
        opacity: 0.6;
    }

    & .upload-btn {
        width: 100%;
        padding: 0;

        .file-input {
            display: none;
        }

        .MuiButton-root {
            margin-left: 0;
            padding: 12px 18px;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            justify-content: flex-start;
        }
    }
`

export const FileLoaderWrapper = styled(Box)`
    & .MuiAccordionDetails-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        padding: 12px 18px;
        border-radius: 4px;
        background: #ffffff;
        box-shadow: 0px 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
            0px 1px 3px rgba(64, 72, 100, 0.12);
    }

    & .file-loader {
        width: 100%;

        & .MuiInput-input {
            font-size: 14px;
            font-weight: 400;
            color: #2b3073;
        }

        & .Mui-disabled {
            -webkit-text-fill-color: #2b3073;
        }
    }
`
