import { Box, List, styled } from '@mui/material'

export const CollegeListDropdownWrapper = styled('div')`
    display: inline-flex;
    width: 100%;
`

export const CollegeListWrapper = styled(Box)`
    position: relative;
    width: 100%;
`

export const CollegeListInputWrapper = styled('form')`
    display: flex;
    align-items: center;

    & .MuiInputBase-input {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & .MuiButtonBase-root.MuiIconButton-root {
        width: 30px;
        height: 30px;
        transform: rotate(0deg);

        &.open-dropdown {
            transform: rotate(-180deg);
        }
    }
`

export const CollegeListDropdownBodyWrapper = styled(List)`
    width: 100%;
    max-height: 250px;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%);
    overflow-y: auto;

    & .MuiListItemButton-root {
        padding: 2px 16px;
    }

    & .MuiListItemText-root .MuiTypography-root {
        font-size: 16px;
        color: #201f58;
    }
`

export const CircularLoaderWrapper = styled('div')`
    position: relative;
    width: 100%;
    min-height: 30px;
`

export const NoResultsText = styled('span')`
    color: #fc7960;
    font-weight: 700;
    margin-left: 16px;
`
