import { Box, CardHeader, styled } from '@mui/material'

export const PatientSidebarWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    border-right: 1px solid #c8c8c8;
    flex: 2;
    max-width: 427px;
    overflow: auto;

    .sendbird-channel-list__header {
        position: relative;
        padding: 24px 32px 0;
    }
`

export const CardHeaderPreview = styled(CardHeader)`
    div:nth-of-type(2) > span {
        overflow: visible;
    }

    .partner-info {
        font-size: 0.8rem;
    }
`
