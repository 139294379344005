import { gql } from '@apollo/client'

export const UPDATE_PROVIDER = gql`
    mutation Mutation($updateProviderId: ID!, $providerInput: UpdateProviderInput) {
        updateProvider(id: $updateProviderId, providerInput: $providerInput) {
            id
            sub
            email
            firstName
            lastName
            phoneNumber
            role {
                id
            }
            avatar
            state
            credentials
            licensedStates
            education
            background
        }
    }
`
