import React from 'react'
import { CardHeader, Typography } from '@mui/material'
import { IDefaultInfoField } from '../../../PatientContent/interfaces'
import { CardContentBox } from '../../../PatientContent/styles'

const InfoField: React.FC<IDefaultInfoField> = ({ name, label, value, isHeader = false }) => {
    return isHeader ? (
        <CardHeader className='info-field-header' title={label} subheader={value} />
    ) : (
        <CardContentBox key={name}>
            <Typography>{label}</Typography>
            <Typography className='info-field-value'>{value}</Typography>
        </CardContentBox>
    )
}

export default InfoField
