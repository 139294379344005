import { Box, styled, CardHeader } from '@mui/material'
import Rating from '@mui/material/Rating'

export const EmptyStateBlock = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 230px;
    font-weight: 500;
    font-size: 24px;
    color: rgba(43, 48, 115, 0.5);
    text-align: center;
`

export const ChatContainer = styled(Box)`
    height: calc(100vh - 68px);
    position: relative;
    display: flex;
    flex-direction: column;
`

export const ChatConversationContainer = styled(Box)`
    width: 100%;
    padding: 20px 16px;
    display: flex;
    justify-content: left;
    padding-bottom: 8px;
    margin-top: 10px;
    max-height: calc(100vh - 250px);
    flex: 1;
    overflow-y: auto;
    box-sizing: border-box;
`

export const ChatInputContainer = styled(Box)`
    align-items: center;
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 16px;
`

export const CardHeaderPreview = styled(CardHeader)`
    div:nth-of-type(2) > span {
        overflow: visible;
    }

    .partner-info {
        font-size: 0.8rem;
    }
`

export const FeedbackContainer = styled(Box)`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 24px;
    color: rgba(43, 48, 115, 0.5);
    flex-direction: column;
    vertical-align: middle;
`

export const StyledRating = styled(Rating)`
    & .MuiRating-iconEmpty .MuiSvgIcon-root {
        color: #d3d3d3;
    }
`
export const CircularProgressBox = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4;
    margin-left: 16;
`
