import React from 'react'
import InputMask from 'react-input-mask'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'

import { IProviderPhoneField } from '@/components/Provider/interfaces'

import { CardContentBox, ErrorField } from '@/components/Patient/PatientContent/styles'
import {
    InputMaskWrapper,
    ProviderPhoneWrapper,
    ProviderSMSWrapper,
} from '@/components/Provider/styles'

const ProviderPhoneField: React.FC<IProviderPhoneField> = ({
    isEditMode,
    isDisabled,
    editProviderData,
    smsNotification,
    phoneNumber,
    isPhoneError,
}) => {
    return (
        <ProviderPhoneWrapper>
            <CardContentBox>
                <Typography className='field-label'>Phone</Typography>

                <InputMaskWrapper display='flex' alignItems='center'>
                    <Typography className='phone-region'>+1</Typography>
                    <InputMask
                        mask='(999) 999-9999'
                        maskPlaceholder='(___) ___-____'
                        value={phoneNumber ?? ''}
                        name='phoneNumber'
                        className='phone-input'
                        disabled={!isEditMode || isDisabled}
                        onChange={editProviderData}
                    ></InputMask>
                </InputMaskWrapper>
                {isPhoneError && (
                    <ErrorField className='error-color' right={0} bottom={'-70%'}>
                        Please enter a valid phone number
                    </ErrorField>
                )}
            </CardContentBox>
            <ProviderSMSWrapper>
                (
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={editProviderData}
                            checked={smsNotification ?? false}
                            color='info'
                        />
                    }
                    disabled={!isEditMode || isDisabled}
                    name='smsNotification'
                    labelPlacement='start'
                    label='Receive SMS notifications'
                    className='sms-label'
                />
                )
            </ProviderSMSWrapper>
        </ProviderPhoneWrapper>
    )
}

export default ProviderPhoneField
