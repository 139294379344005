import { gql } from '@apollo/client'

export const UPDATE_USER = gql`
    mutation UpdateUser($updateUserId: ID!, $userInput: UpdateUserInput) {
        updateUser(id: $updateUserId, userInput: $userInput) {
            id
            college {
                id
                name
                address
            }
        }
    }
`

export const JOIN_PATIENT_TO_CHANNEL = gql`
    mutation JoinPatientToGroupChannel($channelUrl: String!, $userId: String!) {
        joinGroupChannel(channelUrl: $channelUrl, userId: $userId) {
            channelUrl
        }
    }
`
