import React from 'react'
import { useQuery } from '@apollo/client'
import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react'

import {
    OwnMessage,
    CompanionMessage,
    CompanionReplyMessage,
    MyReplyMessage,
    ChatSeparator,
} from './ChatItems'

import { FETCH_USER_WITH_MEMBERSHIP_INFO } from '@/graphql/users/queries'
import { useOutgoingMessageStatus } from '@/hooks'
import { ADMIN } from '@/core/constants'

import { getFullName, getChannelUserId } from '@/utils'
import { IChatMessage } from './interfaces'

import { AdminMessageWrapper } from './styles'

export const ChatMessage: React.FC<IChatMessage> = ({ message, myOwnId }: IChatMessage) => {
    const { currentGroupChannel } = useChannelContext()
    const userId = getChannelUserId(currentGroupChannel)

    const { data: userData } = useQuery(FETCH_USER_WITH_MEMBERSHIP_INFO, {
        variables: { userId: userId },
    })
    const messageStatus = useOutgoingMessageStatus(currentGroupChannel as any, message) || ''

    const {
        message: chatMessage,
        sender,
        createdAt,
        updatedAt,
        url,
        messageType,
        messageId,
        isReplyToChannel,
        parentMessage,
        hasSeparator,
    } = message
    const isAdmin = messageType === ADMIN
    const isMyMessage = sender?.userId === myOwnId
    const isMyReply = isMyMessage && isReplyToChannel
    const isCompanionReply = !isMyMessage && isReplyToChannel
    const chatSeparator = hasSeparator && <ChatSeparator createdAt={createdAt} />
    
    const globalStore = useSendbirdStateContext()
    const deleteMessageCallback = sendbirdSelectors.getDeleteMessage(globalStore)
    const deleteMessage = () => {
        currentGroupChannel && deleteMessageCallback(currentGroupChannel, message)
    }

    const { firstName, lastName, avatarLink } = userData?.userById || {}

    if (isAdmin) {
        return (
            <>
                {chatSeparator}
                <AdminMessageWrapper className='admin-message'>{chatMessage}</AdminMessageWrapper>
            </>
        )
    }

    if (isMyReply) {
        return (
            <MyReplyMessage
                messageStatus={messageStatus}
                myMessage={message}
                parentMessage={parentMessage}
                deleteMessage={deleteMessage}
                nickname={getFullName(firstName, lastName)}
                myOwnId={myOwnId}
                isReplyMessage
            />
        )
    }

    if (isCompanionReply) {
        return (
            <CompanionReplyMessage
                companionMessage={message}
                parentMessage={parentMessage}
                coverUrl={avatarLink}
                sender={sender}
                patientNickname={getFullName(firstName, lastName)}
                myOwnId={myOwnId}
                isReplyMessage
            />
        )
    }

    return (
        <>
            {chatSeparator}
            {isMyMessage ? (
                <OwnMessage
                    messageStatus={messageStatus}
                    chatMessage={chatMessage}
                    nickname={sender?.nickname}
                    messageId={messageId}
                    parentMessage={parentMessage}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    messageImgUrl={url}
                    messageType={messageType}
                    deleteMessage={deleteMessage}
                />
            ) : (
                <CompanionMessage
                    chatMessage={chatMessage}
                    sender={sender}
                    patientNickname={getFullName(firstName, lastName)}
                    createdAt={createdAt}
                    parentMessage={parentMessage}
                    updatedAt={updatedAt}
                    coverUrl={avatarLink}
                    messageImgUrl={url}
                    messageId={messageId}
                />
            )}
        </>
    )
}
