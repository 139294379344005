import React, { useState } from 'react'
import { IconButton } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import { statusColors } from '@/components/Patient/PatientTabsPanel/PatientToDos/priorityIcons'

import { IToDoMenuBtn } from '@/components/ToDo/ToDoContent/interfaces'

import { MessageMenuWrapper } from '@/components/Chat/ChatMessage/styles'
import { ToDoMenuItemWrapper } from '@/components/ToDo/ToDoForm/styles'
import ToDoCancelBtn from '@/components/ToDo/ToDoForm/ToDoCancelBtn'

const ToDoMenuBtn: React.FC<IToDoMenuBtn> = ({
    status,
    handleToDoData,
    initialStatus,
    isViewMode,
    isAppointment,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const statusText = status === 'Completed' ? initialStatus : 'Completed'
    const isCompleted = statusText === 'Completed'
    const statusClass = statusColors[statusText?.toLowerCase()]

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const changeStatus = (statusText: string) => {
        handleToDoData('status', statusText)
    }

    return (
        <>
            <IconButton onClick={handleClick} disabled={isViewMode}>
                <MoreVertIcon />
            </IconButton>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <ToDoMenuItemWrapper
                    className={statusClass}
                    onClick={() => changeStatus(statusText)}
                >
                    {isCompleted && (
                        <IconButton className='complete-btn'>
                            <CheckCircleOutlineOutlinedIcon />
                        </IconButton>
                    )}
                    {statusText}
                </ToDoMenuItemWrapper>
                {isAppointment && (
                    <ToDoCancelBtn
                        changeStatus={changeStatus}
                        status={status}
                        initialStatus={initialStatus}
                    />
                )}
            </MessageMenuWrapper>
        </>
    )
}

export default ToDoMenuBtn
