import React, { useState } from 'react'
import { Box } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { getSessionUser } from '@/utils'
import { TabsMenuWrapper, PatientTab } from './styles'
import { ITabsMenu, ITabsMenuItem } from './interfaces'

const TabsMenu: React.FC<ITabsMenu> = ({ menuItems, menuItemComponents, email, userId }) => {
    const [selectedTab, setSelectedTab] = useState<string>('1')
    const { isAdmin } = getSessionUser()

    const tabSwitchingHandler = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue)
    }

    return (
        <TabsMenuWrapper>
            <TabContext value={selectedTab}>
                <TabList onChange={tabSwitchingHandler}>
                    {menuItems.map(({ id, name, isAdminTab }: ITabsMenuItem) => {
                        if (!isAdmin && isAdminTab) {
                            return
                        }

                        return <PatientTab key={id} label={name} value={id} />
                    })}
                </TabList>
                {menuItemComponents.map(({ id, isAdminTab, TabComponent }: any) => {
                    if (!isAdmin && isAdminTab) {
                        return
                    }

                    return (
                        <TabPanel className='tab_panel-wrapper' value={id} key={id}>
                            <Box>
                                <TabComponent email={email} userId={userId} />
                            </Box>
                        </TabPanel>
                    )
                })}
            </TabContext>
        </TabsMenuWrapper>
    )
}

export default TabsMenu
