import { Button, FormControl, IconButton, Paper, styled, Tab } from '@mui/material'

export const ChannelTabsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.orange.main,
        borderRadius: '8px 8px 0px 0px',
        height: 4,
    },

    '& .MuiTypography-root': {
        fontWeight: 700,
        padding: '10px 0 0',
        fontSize: '16px',
        marginBottom: '18px',
    },
}))

export const SearchBtnWrapper = styled('form')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F8F6F3',
    borderRadius: '0.5em',
    padding: '13px 16px',
    border: 'none',
    boxShadow: 'none',
    width: '100%',

    '& .MuiInputBase-root': {
        width: '100%',
        paddingRight: '10px',
    },

    '& .MuiInputBase-input': {
        fontWeight: 700,
        fontSize: 14,
        lineHeight: 22,
        padding: 0,
        color: '#32333d6b',
        '&::placeholder': {
            opacity: 1, 
        }
    },

    '& .MuiIconButton-root': {
        padding: 0,
    },
})) as typeof Paper;

export const ChannelTab = styled(Tab)(({ theme }) => ({
    position: 'relative',
    color: 'rgba(43, 48, 115, 0.6)',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,

    '&.Mui-selected': {
        fontWeight: 700,
        color: theme.palette.text.primary,
    },

    '&.badge::before': {
        position: 'absolute',
        content: '""',
        width: 12,
        height: 12,
        top: 5,
        right: 5,
        backgroundColor: theme.palette.orange.main,
        borderRadius: '50%',
    },
}))

export const AddNewChannelBtn = styled(Button)(({ theme }) => ({
    color: theme.palette.orange.main,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 0,
    marginLeft: 34,

    '& .MuiButton-startIcon': {
        marginRight: 8,
    },

    '&.export-to-excel-btn': {
        fontSize: 14,
        opacity: 0.6,
        color: theme.palette.text.primary,
    },
}))

export const ClearAllFilterBtn = styled(Button)(({ theme }) => ({
    color: theme.palette.info.contrastText,
    margin: '6px 18px',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500,
    padding: '6px',
}))

export const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.violet.main,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 500,
    height: '32px',
    padding: '5px 15px',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
}))

export const IconButtonWrapper = styled(IconButton)(({theme}) => ({
    borderRadius: '0.25em',
    height: '1.5em',
    width: '1.5em',
    padding: 0,
    marginLeft: '5px',
    '& svg path': {
        fill: theme.palette.violet.main,
        fillOpacity: 1
    },
    '&.active': {
        backgroundColor: theme.palette.violet.main,
        '& svg path': {
            fill: "#fff",
            fillOpacity: 1
        },
    }
}))

export const FormControlWrapper = styled(FormControl)(({ theme }) => ({
    width: '390px',
    color: theme.palette.violet.main,

    '& .header-title': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },

    '& .formControl-title': {
        fontWeight: 700,
        fontSize: 20,
        padding: '12px 24px',
    },

    '& .checkboxes-box': {
        height: '100%',
        overflow: 'auto',
        flexWrap: 'nowrap',
    },

    '& .formControl-sub-title': {
        textTransform: 'capitalize',
        fontWeight: 500,
        fontSize: 18,
        padding: '12px 24px',
        width: '100%',
        borderTop: `1px solid ${theme.palette.gray.main}`
    },

    '& .formControl-checkbox': {
        width:'100%',
        margin: 0,
        paddingLeft: '24px',

        '& .Mui-checked': {
            color: `${theme.palette.violet.main}`,
        },
        ':last-child' : {
            paddingBottom:'12px',
        },
    },
    '& .apply-filter_btn': {
        color: `${theme.palette.primary.contrastText}`,
        backgroundColor: `${theme.palette.violet.main}`,
        height: '48px',
        alignItems: 'center',
        margin: '20px 24px 25px',

        '&:hover': {
            backgroundColor: `${theme.palette.violet.main}`,
        }
    }
}))
