import { Avatar, Box, Card, IconButton, styled } from '@mui/material'

export const ProviderCardWrapper = styled(Card)`
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
        0px 1px 3px rgba(64, 72, 100, 0.12);
    border-radius: 8px;
    padding: 24px 32px;
    margin-bottom: 24px;
    overflow: initial;

    &.edit-card {
        position: relative;

        .MuiAvatar-root {
            width: 128px;
            height: 128px;
        }

        .MuiCardHeader-root {
            padding: 0;
            width: 100%;
        }

        & .edit-icon {
            opacity: 0.5;
        }

        .MuiCardHeader-action {
            height: 40px;
            position: absolute;
            top: 22px;
            right: 30px;

            .MuiButton-root {
                text-transform: capitalize;
                color: rgba(43, 48, 115, 0.6);

                &.save-btn {
                    color: #97c287;
                    margin-left: 32px;
                }

                &.save-btn.Mui-disabled {
                    color: rgba(0, 0, 0, 0.26);
                }
            }
        }
    }

    .provider-edit__wrapper {
        .search-box .MuiFormControl-root input,
        input {
            font-size: 14px;
        }
    }

    .secondary-edit-provider-input-label {
        min-width: 250px !important;
    }

    .secondary-edit-provider-input-textarea {
        width: 100%;
        font-family: inherit;
        font-size: 14px;
        font-weight: inherit;
        margin-left: -2px;
        border-top: none;
        border-right: none;
        border-left: none;
        color: #2b3073;
        border-bottom-color: rgb(43, 48, 115, 0.5);
        border-bottom-width: 1px;
        padding-bottom: 6px;
        &.disabled {
            border-bottom: none;
            resize: none;
        }
        :hover {
            border-bottom-color: rgb(43, 48, 115);
            border-bottom-width: 2.5px;
            padding-bottom: 5px;
            transition: border-bottom-color 125ms ease-in-out, border-bottom-width 125ms ease-in-out;
        }
        :focus {
            outline: none;
            border-bottom-color: #f67960;
            border-bottom-width: 2.5px;
            padding-bottom: 5px;
            transition: border-bottom-color 125ms ease-in-out, border-bottom-width 125ms ease-in-out;
        }
    }
`

export const ProviderAvatarWrapper = styled(Box)`
    position: relative;
    display: flex;
    border: 2px solid #ffffff;
    border-radius: 50%;
    box-shadow: 0px 9px 12px -6px rgba(127, 154, 159, 0.44);
    margin-right: 32px;
`

export const AvatarWrapper = styled(Avatar)`
    min-width: 88px;
    min-height: 88px;
`

export const IconButtonWrapper = styled(IconButton)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #2b3073;
    opacity: 0.6;
    transition: 0.3s;

    .MuiSvgIcon-root {
        width: 42px;
        height: 42px;
        color: white;
    }

    &:hover {
        background-color: #2b3073;
        opacity: 1;
    }
`

export const ProviderInfo = styled(Box)`
    color: #2b3073;
    margin-left: 32px;

    & .provider-name {
        font-size: 18px;
        font-weight: 700;
    }

    & .provider-position {
        font-size: 14px;
        font-weight: 400;
        opacity: 0.5;
    }
`

export const ProviderPhoneWrapper = styled(Box)`
    display: flex;
    align-items: center;

    & .sms-label {
        white-space: nowrap;
        padding-right: 10px;

        & .Mui-disabled {
            color: rgba(43, 48, 115, 0.6);
        }
    }

    & .field-label {
        color: rgba(43, 48, 115, 0.6);
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 7.5px;
    }

    & .phone-input {
        width: 100%;
        padding-bottom: 7.5px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        color: #2b3073;
        background-color: transparent;

        &:disabled {
            border-bottom: 1px solid transparent;
        }

        &:focus-visible {
            outline: none;
        }
    }
`

export const ProviderSMSWrapper = styled(Box)`
    width: 100%;
    margin-left: 32px;
    color: rgba(43, 48, 115, 0.6);
    font-size: 14px;
    font-weight: 400;
    height: 21px;
    margin-bottom: 7.5px;
    white-space: nowrap;
`

export const InputMaskWrapper = styled(Box)`
    display: flex;
    align-items: center;

    & .phone-region {
        font-size: 14px;
        padding-bottom: 7.5px;
        min-width: inherit;
        color: rgba(43, 48, 115);
        margin-right: 0;
    }
`

export const CardContentValidationBox = styled(Box)`
    display: grid;
    position: relative;
    align-items: center;
    margin-top: 20px;
    font-size: 13px;
    color: rgba(127, 131, 172);
    max-width: 200px;
`
