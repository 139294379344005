import { Box, Button, styled } from '@mui/material'

export const PageContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const LoginContainer = styled(Box)`
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
    width: 30%;
    min-width: 350px;
    max-width: 575px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 9px 12px -3px rgba(127, 154, 159, 0.44);
`

export const Img = styled('img')`
    width: 250px;
`

export const LoginButton = styled(Button)`
    z-index: 1;
    width: 150px;
    margin-top: 20px;
`

export const ImgBottom = styled('img')`
    position: absolute;
    left: 0;
    bottom: 0;
`
