import React, { useEffect, useState } from 'react'
import { Box, Chip, IconButton, TextField, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import UnsavedDataNotifier from '@/components/UI/Modals/UnsavedDataNotifier'
import { IModalPartner } from '@/components/CollegesAndPartners/interfaces'
import { ModalWrapper } from '../../Chat/CreateChatModal/styles'
import { DialogTitleWrapper, DialogWrapper } from '@/components/Patient/PatientTabsPanel/styles'
import { EditButtonConfirm, EditForm } from '@/components/ToDo/ModalToDo/styles'
import { FormSection, MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'
import { PartnerFormChipField } from '../styles'
import { getResourceChanges } from '../utils/getResourceChanges'

const ModalPartner: React.FC<IModalPartner> = ({
    open,
    handleClose,
    handleEditPartner,
    partner,
}) => {
    const [isUnsavedData, setIsUnsavedData] = useState<boolean>(false)
    const [originalResources, setOriginalResources] = useState<string[]>([])
    const [newResources, setNewResources] = useState<string[]>([])
    const [code, setCode] = useState<string | null>(null)

    useEffect(() => {
        if (partner?.resources && !!partner.resources.length) {
            const resourceSlugs = partner.resources.map((resource: any) => resource.slug)

            setOriginalResources(resourceSlugs)
            setNewResources(resourceSlugs)
        }
        if (partner?.code) {
            setCode(partner.code)
        }
    }, [partner])

    const handleEditPartnerConfirm = async (originalResources: any, newResources: any) => {
        const result = getResourceChanges(originalResources, newResources, partner?.resources)
        handleEditPartner(result.deletedIds, result.created, code)
        handleClose()
    }

    const toggleUnsavedDataModal = () => {
        setIsUnsavedData((prevState) => !prevState)
    }

    const closeEditingModal = () => {
        if (originalResources !== newResources) {
            toggleUnsavedDataModal()
            return
        }

        handleClose()
    }

    return (
        <ModalWrapper open={open} onClose={closeEditingModal}>
            <DialogWrapper className='dialog-wrapper college-page'>
                <EditForm>
                    <DialogTitleWrapper>
                        <Box>
                            <IconButton onClick={closeEditingModal}>
                                <ArrowBackIcon />
                            </IconButton>
                            {'Edit Partner'}
                        </Box>
                    </DialogTitleWrapper>
                    <FormSection className='content'>
                        <MainHeader style={{ marginTop: 14 }}>
                            <Typography className='college-name-typography required-field w-130'>
                                Partner Name
                            </Typography>
                            <ToDoFormText
                                required
                                className='partner-inputs'
                                type='text'
                                name='name'
                                variant='standard'
                                size='small'
                                placeholder='Error retrieving partner name'
                                defaultValue={partner?.name}
                                disabled={true}
                            />
                        </MainHeader>
                        <MainHeader style={{ marginTop: 14 }}>
                            <Typography className='college-name-typography required-field w-130'>
                                Partner Code
                            </Typography>
                            <ToDoFormText
                                className='partner-inputs'
                                type='text'
                                name='name'
                                variant='standard'
                                size='small'
                                placeholder='Enter a partner code'
                                defaultValue={partner?.code}
                                onChange={(event: any) => {
                                    setCode(event.target.value)
                                }}
                            />
                        </MainHeader>
                        <MainHeader>
                            <Typography className='maps-typography w-130'>
                                Resources List
                            </Typography>
                            <PartnerFormChipField
                                multiple
                                freeSolo
                                className='partner-inputs'
                                options={[]}
                                defaultValue={originalResources}
                                placeholder='Enter resource slug(s)'
                                onChange={(event: any, value: any) => {
                                    setNewResources(value)
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option: any, index: any) => (
                                        <Chip
                                            {...getTagProps({ index })}
                                            key={option}
                                            label={option}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField {...params} variant='standard' />
                                )}
                            />
                        </MainHeader>
                    </FormSection>
                </EditForm>
                <Box display='flex' justifyContent='space-between'>
                    <Typography fontSize={14}>* Required</Typography>
                    <EditButtonConfirm
                        onClick={() => handleEditPartnerConfirm(originalResources, newResources)}
                        className='edit-college-btn'
                        variant='contained'
                        color='info'
                    >
                        Save
                    </EditButtonConfirm>
                </Box>
            </DialogWrapper>
            {isUnsavedData && (
                <UnsavedDataNotifier
                    open={isUnsavedData}
                    toggleUnsavedDataModal={toggleUnsavedDataModal}
                    handleClose={handleClose}
                />
            )}
        </ModalWrapper>
    )
}

export default ModalPartner
