import { useMemo } from 'react'

import { GroupChannelType, IOutgoingMessageStates, Message } from './interfaces'

export const OutgoingMessageStates: IOutgoingMessageStates = {
    NONE: 'NONE',
    PENDING: 'PENDING',
    SENT: 'SENT',
    FAILED: 'FAILED',
    DELIVERED: 'DELIVERED',
    READ: 'READ',
}

const useOutgoingMessageStatus = (channel: GroupChannelType, message: Message): string => {
    const messageStatus = useMemo(
        () => getOutgoingMessageState(channel, message),
        [channel?.getUnreadMemberCount?.(message), channel?.getUndeliveredMemberCount?.(message)]
    )

    function getOutgoingMessageState(channel: GroupChannelType, message: Message) {
        switch (message.sendingStatus) {
            case 'pending':
                return OutgoingMessageStates.PENDING
            case 'failed':
                return OutgoingMessageStates.FAILED
            case 'succeeded':
                return OutgoingMessageStates.SENT
            default:
                if (channel?.getUnreadMemberCount(message) === 0) {
                    return OutgoingMessageStates.READ
                } else if (channel?.getUndeliveredMemberCount(message) === 0) {
                    return OutgoingMessageStates.DELIVERED
                }
                return OutgoingMessageStates.NONE
        }
    }

    return messageStatus
}

export default useOutgoingMessageStatus
