import React, { ChangeEvent, useMemo } from 'react'
import { Select, Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import LabTestName from '@/components/ToDo/ToDoContent/ToDoLab/LabTestName'
import MedicineName from '@/components/ToDo/ToDoContent/ToDoLab/MedicineName'
import InstructionField from '@/components/ToDo/ToDoContent/ToDoLab/InstructionField'
import UploadToDoContentFile from '@/components/ToDo/ToDoContent/ToDoFreeText/UploadToDoContentFile'
import MedicalHistoryCheckbox from '@/components/UI/Buttons/MedicalHistoryCheckbox'

import { NOTIFICATION_TEXT } from '@/components/ToDo/ToDoContent/notification_texts'
import { IToDoLab } from '@/components/ToDo/ToDoContent/interfaces'

import {
    DropDownItems,
    MainHeader,
    ToDoFormDropdown,
    ToDoFormText,
} from '@/components/ToDo/ToDoForm/styles'

interface ILabTypeComponents {
    [key: string]: JSX.Element;
}

const ToDoLab: React.FC<IToDoLab> = ({
    toDoData,
    handleToDoData,
    isViewMode,
    setToDoData,
    toDoContentFile,
    setToDoContentFile,
    isAddedToMedicalHistory,
    handleCheckbox,
}) => {
    const { laboratory, medicine, pharmacy, pharmacyLocation, instructions, patientId } = toDoData
    const isLabOrder = laboratory === 'Lab Order'
    const fieldName = isLabOrder ? 'Lab' : 'Pharmacy'
    const fileNameField = isLabOrder ? 'Lab' : 'Rx'

    const handleInput = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
        const { name, value } = e.target
        const isLabOrder = value === 'Lab Order'
        const isRxPrescription = value === 'Rx prescription'

        if (isLabOrder) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                notifications: true,
                notificationText: NOTIFICATION_TEXT.LAB_ORDER,
            }))

            return
        }

        if (isRxPrescription) {
            setToDoData((prevState: any) => ({
                ...prevState,
                [name]: value,
                notifications: true,
                notificationText: NOTIFICATION_TEXT.RX_PRESCRIPTION,
            }))

            return
        }

        handleToDoData(name, value)
    }

    const labTypeComponents = useMemo((): ILabTypeComponents => {
        return {
            'Lab Order': (
                <LabTestName
                    medicine={medicine}
                    isViewMode={isViewMode}
                    handleInput={handleInput}
                />
            ),
            'Rx prescription': (
                <MedicineName
                    medicine={medicine}
                    isViewMode={isViewMode}
                    patientId={patientId}
                    handleToDoData={handleToDoData}
                />
            ),
        }
    }, [medicine, isViewMode, patientId])

    return (
        <>
            <MainHeader>
                <ToDoFormDropdown variant='standard' size='small' className='todo-inputs'>
                    <Typography className='lab-typography required-field w-130'>Type</Typography>
                    <Select
                        required
                        label='Type Lab'
                        name='laboratory'
                        className='select-type select-lab'
                        value={laboratory}
                        disabled={isViewMode || !patientId}
                        onChange={handleInput}
                    >
                        <DropDownItems value='Lab Order'>Lab Order</DropDownItems>
                        <DropDownItems value='Rx prescription'>Rx Prescription</DropDownItems>
                    </Select>
                </ToDoFormDropdown>
            </MainHeader>
            {labTypeComponents[laboratory]}
            <MainHeader>
                <Typography className='place-typography required-field w-130'>
                    {isLabOrder ? 'Lab order' : `${fieldName} name`}
                </Typography>
                <ToDoFormText
                    className='todo-inputs'
                    type='text'
                    name='pharmacy'
                    variant='standard'
                    size='small'
                    placeholder='Laboratory/Pharmacy name'
                    disabled={isViewMode || !patientId}
                    value={pharmacy}
                    onChange={handleInput}
                    multiline
                    required
                />
            </MainHeader>
            <MainHeader>
                <Typography className='maps-typography required-field w-130'>
                    {fieldName} location
                </Typography>
                <ToDoFormText
                    required
                    className='todo-inputs'
                    type='text'
                    name='pharmacyLocation'
                    variant='standard'
                    size='small'
                    placeholder='Google maps link (location)'
                    disabled={isViewMode || !patientId}
                    value={pharmacyLocation}
                    onChange={handleInput}
                />
            </MainHeader>
            <InstructionField
                handleToDoData={handleToDoData}
                isViewMode={isViewMode || !patientId}
                instructions={instructions}
                isLabOrder={isLabOrder}
            />
            <UploadToDoContentFile
                rowTitle={`${fileNameField} Requisition`}
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
            <MedicalHistoryCheckbox
                isAddedToMedicalHistory={isAddedToMedicalHistory}
                isViewMode={isViewMode}
                handleCheckbox={handleCheckbox}
            />
        </>
    )
}

export default ToDoLab
