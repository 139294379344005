import { Button, styled } from '@mui/material'
import { IChatHeaderStyleProps } from './interfaces'

export const ChatHeaderWrapper = styled('div')<IChatHeaderStyleProps>(({ theme }) => ({
    width: '100%',
    height: '72px',
    backgroundColor: '#edebe5',
    padding: '20px 14px',
    alignSelf: 'center',

    ' & .header__chat-block': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '.video-call-btn': {
            backgroundColor: theme.palette.primary.contrastText,
            border: `1px solid ${theme.palette.violet.main}`,
            '&.Mui-disabled': {
                background: theme.palette.gray.main,
                border: 'none',
                color: theme.palette.primary.contrastText,
            },
        },

        '& label > .MuiFormControlLabel-label': {
            fontSize: '14px',
            color: theme.palette.purple.dark,
        },
    },

    '& .header__chat-btn': {
        display: 'flex',
        gap: '12px',
        alignItems: 'center',

        '.join-btn': {
            backgroundColor: theme.palette.violet.main,
            color: theme.palette.primary.contrastText,
            '&.Mui-disabled': {
                background: theme.palette.gray.main,
            },
        },

        '.leave-btn': {
            backgroundColor: theme.palette.orange.main,
            color: theme.palette.primary.contrastText,
            '&.Mui-disabled': {
                background: theme.palette.gray.main,
            },
        },
    },
}))

export const RedirectChatBtn = styled(Button)(({ theme }) => ({
    padding: '8px 12px',
    background: theme.palette.violet.main,
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginRight: '10px',
    color: 'white',

    '&:hover': {
        background: theme.palette.violet.light,
    },
}))

export const RedirectToCanvas = styled(Button)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 700,
    marginRight: '28px',
    color: theme.palette.violet.main,
    textTransform: 'inherit',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',

    '&:hover': {
        color: theme.palette.violet.light,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
}))
