import React from 'react'
import { Checkbox } from '@mui/material'

import { IMedicalHistoryCheckbox } from '@/components/UI/interfaces'

import { MainHeader, ToDoOptionalCheck } from '@/components/ToDo/ToDoForm/styles'

const MedicalHistoryCheckbox: React.FC<IMedicalHistoryCheckbox> = ({
    isAddedToMedicalHistory,
    isViewMode,
    handleCheckbox,
}) => {
    return (
        <MainHeader>
            <ToDoOptionalCheck
                label='Add to medical history'
                name='isAddedToMedicalHistory'
                className='medical-checkbox'
                labelPlacement='end'
                control={
                    <Checkbox
                        checked={isAddedToMedicalHistory}
                        disabled={isViewMode}
                        onChange={handleCheckbox}
                        color='info'
                    />
                }
            />
        </MainHeader>
    )
}

export default MedicalHistoryCheckbox
