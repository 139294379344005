import React from 'react'
import { CardContent, CardHeader, Divider } from '@mui/material'

import { dateToString } from '@/utils'
import { IPatientIntake } from '@/components/Patient/PatientTabsPanel/interfaces'

import { IntakeBox, PatientDataBlockWrapper } from '../../PatientContent/styles'

const PatientIntake: React.FC<IPatientIntake> = ({ intakeData, title }) => {
    const {
        isAllergic,
        allergyResponse,
        isOnMedication,
        medicationResponse,
        isUnderCare,
        underCareResponse,
        isUnderMentalHealthCare,
        underMentalHealthCareResponse,
        createdDate,
    } = intakeData || {}
    const patientIntakeFields = [
        {
            id: 1,
            title: 'Is allergic?',
            responseA: isAllergic,
            responseB: allergyResponse,
            isDividerExist: true,
        },
        {
            id: 2,
            title: 'Is on medication?',
            responseA: isOnMedication,
            responseB: medicationResponse,
            isDividerExist: true,
        },
        {
            id: 3,
            title: 'Is under care?',
            responseA: isUnderCare,
            responseB: underCareResponse,
            isDividerExist: true,
        },
        {
            id: 4,
            title: 'Is under mental health care?',
            responseA: isUnderMentalHealthCare,
            responseB: underMentalHealthCareResponse,
            isDividerExist: false,
        },
    ]

    return (
        <PatientDataBlockWrapper className='patient-consent'>
            <CardHeader title={title} />
            <CardContent className='patient__card-content'>
                <b>Created:</b>
                <span>{createdDate ? dateToString(createdDate, 'MMMM dd, yyyy') : 'N/A'}</span>
                (Read Only)
            </CardContent>
            <CardContent>
                {patientIntakeFields.map(({ id, title, responseA, responseB, isDividerExist }) => (
                    <div key={id}>
                        <IntakeBox>
                            <div>{title}</div>
                            <div>{intakeData ? (responseA ? 'Yes' : 'No') : 'N/A'}</div>
                        </IntakeBox>
                        <IntakeBox>
                            <div>Details</div>
                            <div>{responseB || 'N/A'}</div>
                        </IntakeBox>
                        {isDividerExist && <Divider />}
                    </div>
                ))}
            </CardContent>
        </PatientDataBlockWrapper>
    )
}

export default PatientIntake
