import React from 'react'

import chatsIconSvg from '@/assets/icons/chats.svg'
import usersCircleIconSvg from '@/assets/icons/users-circle.svg'
import trophyIconSvg from '@/assets/icons/trophy.svg'
import collegeIconSvg from '@/assets/icons/college.svg'
import userCircleIconSvg from '@/assets/icons/user-circle.svg'
import gearIconSvg from '@/assets/icons/gear.svg'

import { INavigationItem } from './interfaces'

const navigationLinks: INavigationItem[] = [
    {
        id: 'chats',
        name: 'Chats',
        icon: <img src={chatsIconSvg} alt={'Chats'} />,
        path: '/dashboard/chats',
        subMenu: [
            {
                id: 'care_team_chats',
                name: 'Care Team Chats',
                subPath: '/dashboard/chats/care-team-chats',
            },
            {
                id: '1:1_chats',
                name: '1:1 Chats',
                subPath: '/dashboard/chats/own-chats',
            },
        ],
    },
    {
        id: 'patients',
        name: 'Patients',
        icon: <img src={usersCircleIconSvg} alt={'Patients'} />,
        path: '/dashboard/patients',
    },
    {
        id: 'action_items',
        name: 'Action Items',
        icon: <img src={trophyIconSvg} alt={'Action Items'} />,
        path: '/dashboard/todos',
    },
    {
        id: 'colleges_and_partners',
        name: 'Colleges & Partners',
        icon: <img src={collegeIconSvg} alt={'Colleges'} />,
        path: '/dashboard/colleges-and-partners',
    },
    {
        id: 'message_settings',
        name: 'Message Settings',
        icon: <img src={gearIconSvg} alt={'Message Settings'} />,
        path: '/dashboard/message-settings',
        isAdminOnly: true,
    },
    {
        id: 'chatbot',
        name: 'Chatbot',
        icon: <img src={chatsIconSvg} alt={'Chatbot'} />,
        path: '/dashboard/chatbot',
    },
    {
        id: 'profile',
        name: 'Profile',
        icon: <img src={userCircleIconSvg} alt={'Profile'} />,
        path: '/dashboard/profile',
    },
]

export default navigationLinks
