import format from 'date-fns/format'

const getDoB = (createdAt: number | string, dateFormat: string): string => {
    if (!createdAt) {
        return ''
    }

    const dt = new Date(createdAt)
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

    return format(Number(dtDateOnly), dateFormat)
}

export default getDoB
