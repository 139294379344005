import React from 'react'
import { TableBody } from '@mui/material'

import ListTableSingleRow from '@/components/CollegesAndPartners/components/ListTableSingleRow'

import { IListTablePagination } from '@/components/CollegesAndPartners/interfaces'

const ListTablePagination: React.FC<IListTablePagination> = ({
    listData,
    search,
    offset,
    limit,
    count,
    isPartners,
    isSubPartners,
    isColleges,
    isUnverifiedColleges,
}) => {
    return (
        <TableBody>
            {listData?.map((item: any) => (
                <ListTableSingleRow
                    key={item.id}
                    item={item}
                    search={search}
                    offset={offset}
                    limit={limit}
                    count={count}
                    isPartners={isPartners}
                    isSubPartners={isSubPartners}
                    isColleges={isColleges}
                    isUnverifiedColleges={isUnverifiedColleges}
                />
            ))}
        </TableBody>
    )
}

export default ListTablePagination
