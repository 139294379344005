import React from 'react'
import { Box, IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

import editMessageSvg from '@/assets/images/edit-message.svg'

import { IEditAlert } from './interfaces'

import { EditAlertWrapper } from './styles'

const EditAlert: React.FC<IEditAlert> = ({ resetEditing }) => {
    return (
        <EditAlertWrapper>
            <Box className='alert-text'>
                <img src={editMessageSvg} alt='Edit' />
                Edit Message
            </Box>
            <Box>
                <IconButton onClick={resetEditing}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
        </EditAlertWrapper>
    )
}

export default EditAlert
