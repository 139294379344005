import React from 'react'
import { Typography } from '@mui/material'

import { ILabTestName } from '@/components/ToDo/ToDoContent/ToDoLab/interfaces'
import { MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'

const LabTestName: React.FC<ILabTestName> = ({ isViewMode, medicine, handleInput }) => {
    return (
        <MainHeader>
            <Typography className='place-typography required-field w-130'>Lab test name</Typography>
            <ToDoFormText
                value={medicine}
                onChange={handleInput}
                disabled={isViewMode}
                className='todo-inputs'
                name='medicine'
                type='text'
                variant='standard'
                size='small'
                required
            />
        </MainHeader>
    )
}

export default LabTestName
