import React from 'react'
import { ApolloProvider } from '@apollo/client'

import AppRouter from '@/routes/AppRouter'
import AuthProvider from '@/context/AuthProvider'
import ExpiredTokenProvider from '@/context/ExpiredTokenProvider'

import { createApolloClient } from '@/graphql/config'

import { ExpiredTokenNotifier } from '@/components/UI'

import '@/App.css'

function App() {
    return (
        <ExpiredTokenProvider>
            <ExpiredTokenNotifier>
                {(showExpiredTokenNotifier: any) => (
                    <ApolloProvider client={createApolloClient(showExpiredTokenNotifier)}>
                        <AuthProvider>
                            <AppRouter />
                        </AuthProvider>
                    </ApolloProvider>
                )}
            </ExpiredTokenNotifier>
        </ExpiredTokenProvider>
    )
}

export default App
