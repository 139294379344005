// Set GQL playground link
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

export const createApolloClient = (showExpiredTokenNotifier: any) => {
    const BACKEND_ADDRESS = window.env.BACKEND_ADDRESS
    const httpLink = createHttpLink({ uri: BACKEND_ADDRESS })

    // Create authorization header with authenticated token
    const authLink = setContext(() => {
        const token = sessionStorage.getItem('accessToken')

        return {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        }
    })

    const errorLink = onError(({ graphQLErrors }) => {
        const isUnauthorizedMessage = 'Unauthorized'
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message }) => {
                if (message === isUnauthorizedMessage) {
                    showExpiredTokenNotifier()
                }
            })
        }
    })

    // Initialize Apollo Client
    return new ApolloClient({
        cache: new InMemoryCache({
            addTypename: false,
        }),
        link: ApolloLink.from([errorLink, authLink, httpLink]),
        credentials: 'same-origin',
    })
}
