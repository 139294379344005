import { gql } from '@apollo/client'

const MEDICATION_REQUEST_BY_ID = gql`
    query MedicationRequestById($medicationRequestByIdId: ID!) {
        medicationRequestById(id: $medicationRequestByIdId) {
            pharmacy
        }
    }
`

const MEDICATION_STATEMENT_BY_PATIENT_ID = gql`
    query MedicationStatementByPatientId($patientId: ID!) {
        medicationStatementByPatientId(patientId: $patientId) {
            medicine
            medicationRequestId
        }
    }
`

const DIAGNOSTIC_REPORT_BY_PATIENT_ID = gql`
    query DiagnosticReportByPatientId($patientId: ID!) {
        diagnosticReportByPatientId(patientId: $patientId) {
            labOrderName
        }
    }
`

export {
    MEDICATION_REQUEST_BY_ID,
    MEDICATION_STATEMENT_BY_PATIENT_ID,
    DIAGNOSTIC_REPORT_BY_PATIENT_ID,
}
