import React from 'react'
import classNames from 'classnames'
import { IconButton, Input } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { ICollegeListInput } from '@/components/UI/Dropdowns/CollegeListDropdown/interfaces'

import { CollegeListInputWrapper } from './styles'

const CollegeListInput: React.FC<ICollegeListInput> = ({
    searchByName,
    searchName,
    clearSearch,
    toggleDropdown,
    isOpenDropdown,
    isEditMode,
}) => {
    const isDisabledCloseBtn = !searchName || !isEditMode

    const handleInputFocus = () => {
        if (!isOpenDropdown) {
            toggleDropdown()
        }
    }

    return (
        <CollegeListInputWrapper
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <Input
                value={searchName}
                disableUnderline={!isEditMode}
                disabled={!isEditMode}
                onChange={searchByName}
                onFocus={handleInputFocus}
                endAdornment={
                    <IconButton onClick={clearSearch} disabled={isDisabledCloseBtn}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            {isEditMode && (
                <>
                    <IconButton
                        onClick={toggleDropdown}
                        className={classNames({
                            'open-dropdown': isOpenDropdown,
                        })}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>
                </>
            )}
        </CollegeListInputWrapper>
    )
}

export default CollegeListInput
