import React, { useContext } from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import ExpiredTokenContext from '@/context/ExpiredTokenContext'

import {
    ModalContent,
    ModalText,
    ModalWrapper,
} from '@/components/UI/Modals/ExpiredTokenNotifier/styles'

const ExpiredTokenNotifier: React.FC<any> = ({ children }) => {
    const { doShowError, setDoShowError } = useContext(ExpiredTokenContext)

    return (
        <>
            {doShowError && (
                <ModalWrapper open={doShowError} closeAfterTransition>
                    <ModalContent>
                        <ErrorOutlineIcon color='primary' />
                        <ModalText>
                            There has been an error. You will be transferred to the login page
                            automatically.
                        </ModalText>
                    </ModalContent>
                </ModalWrapper>
            )}
            {children(() => setDoShowError(true))}
        </>
    )
}

export default ExpiredTokenNotifier
