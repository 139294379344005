import React, { useContext } from 'react'
import Linkify from 'linkify-react'
import classNames from 'classnames'

import MessageMenu from './MessageMenu'
import { iconType } from '../iconTypes'

import { getTime } from '@/utils'
import { ChannelContext } from '@/context/ChannelContext'

import { IOwnMessage } from '../interfaces'

import {
    MessageDateAndSettings,
    MessageInfo,
    MessageMedia,
    MessageWrapper,
    OwnMessageWrapper,
    ReplyWrapper,
    MessageText,
} from '../styles'

const OwnMessage: React.FC<IOwnMessage> = ({
    chatMessage,
    createdAt,
    messageId,
    messageStatus,
    messageImgUrl,
    deleteMessage,
    updatedAt,
    nickname,
    children,
    parentMessage,
    messageType,
}) => {
    const {
        messageId: selectedMessageId,
        selectMessage,
        isEditMessage,
    } = useContext(ChannelContext)
    const messageContent: string | JSX.Element = chatMessage ? (
        chatMessage
    ) : (
        <MessageMedia component='img' height='194' image={messageImgUrl} />
    )

    return (
        <MessageWrapper className='wrapper__message-own'>
            <ReplyWrapper
                className={classNames({
                    'wrapper__message-edit': isEditMessage && selectedMessageId === messageId,
                })}
            >
                <Linkify options={{ target: '_blank' }}>{children}</Linkify>
                <OwnMessageWrapper>
                    <MessageInfo className='relative'>
                        <div className='message-status'>{iconType[messageStatus]}</div>
                        {Boolean(updatedAt) && (
                            <MessageDateAndSettings className='edited-msg'>
                                Edited
                            </MessageDateAndSettings>
                        )}
                        <MessageDateAndSettings>{getTime(createdAt)}</MessageDateAndSettings>
                        <MessageMenu
                            messageId={messageId}
                            chatMessage={chatMessage}
                            messageImgUrl={messageImgUrl}
                            nickname={nickname}
                            selectMessage={selectMessage}
                            parentMessage={parentMessage}
                            deleteMessage={deleteMessage}
                            messageType={messageType}
                        />
                    </MessageInfo>
                </OwnMessageWrapper>
                <MessageText>
                    <Linkify options={{ target: '_blank' }}>{messageContent}</Linkify>
                </MessageText>
            </ReplyWrapper>
        </MessageWrapper>
    )
}

export default OwnMessage
