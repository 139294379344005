import React from 'react'

import userIconSvg from '@/assets/icons/user.svg'
import infoIconSvg from '@/assets/icons/info.svg'
import mailIconSvg from '@/assets/icons/mail.svg'
import locationIconSvg from '@/assets/icons/location.svg'
import phoneIconSvg from '@/assets/icons/phone.svg'
import collegeHatIconSvg from '@/assets/icons/college-hat.svg'
import shieldIconSvg from '@/assets/icons/shield.svg'
import membershipCardIconSvg from '@/assets/icons/membership-card.svg'
import warningIconSvg from '@/assets/icons/warning.svg'

import virusIconSvg from '@/assets/icons/virus.svg'
import pillIconSvg from '@/assets/icons/pill.svg'
import stethoscopeIconSvg from '@/assets/icons/stethoscope.svg'
import mentalHealthIconSvg from '@/assets/icons/mental-health.svg'

import {
    IAccordionSections,
    IPersonalInformationFields,
    IMedicalHistoryFields,
} from '../../interfaces'

const iconStyle = { paddingTop: 1.5, paddingRight: 4 }

export const personalInformationFields: IPersonalInformationFields[] = [
    {
        id: 'preferredName',
        name: 'Preferred Name',
        icon: <img src={userIconSvg} style={iconStyle} alt={'Preferred Name'} />,
    },
    {
        id: 'preferredGender',
        name: 'Gender',
        icon: <img src={infoIconSvg} style={iconStyle} alt={'Gender'} />,
    },
    {
        id: 'sexAtBirth',
        name: 'Sex at Birth',
        icon: <img src={infoIconSvg} style={iconStyle} alt={'Sex at Birth'} />,
    },
    {
        id: 'pronouns',
        name: 'Pronouns',
        icon: <img src={infoIconSvg} style={iconStyle} alt={'Pronouns'} />,
    },
    {
        id: 'email',
        name: 'Email',
        icon: <img src={mailIconSvg} style={iconStyle} alt={'Email'} />,
    },
    {
        id: 'homeAddress',
        name: 'Address',
        icon: <img src={locationIconSvg} style={iconStyle} alt={'Home Address'} />,
    },
    {
        id: 'phone',
        name: 'Mobile',
        icon: <img src={phoneIconSvg} style={iconStyle} alt={'Mobile'} />,
    },
    {
        id: 'college',
        name: 'College',
        icon: <img src={collegeHatIconSvg} style={iconStyle} alt={'College'} />,
    },
    {
        id: 'emergencyContact',
        name: 'Emergency Contact',
        icon: <img src={shieldIconSvg} style={iconStyle} alt={'Emergency Contact'} />,
    },
    {
        id: 'membership',
        name: 'Membership',
        icon: <img src={membershipCardIconSvg} style={iconStyle} alt={'Membership'} />,
    },
    {
        id: 'planStart',
        name: 'Plan Start',
        icon: <img src={warningIconSvg} style={iconStyle} alt={'Plan Start'} />,
    },
    {
        id: 'planExpiration',
        name: 'Plan Expiration',
        icon: <img src={warningIconSvg} style={iconStyle} alt={'Plan Expiration'} />,
    },
]

export const medicalHistoryFields: IMedicalHistoryFields[] = [
    {
        id: 'allergyResponse',
        checkId: 'isAllergic',
        name: 'Allergies',
        icon: <img src={virusIconSvg} style={iconStyle} alt={'Allergies'} />,
    },
    {
        id: 'medicationResponse',
        checkId: 'isOnMedication',
        name: 'Medications',
        icon: <img src={pillIconSvg} style={iconStyle} alt={'Medications'} />,
    },
    {
        id: 'underCareResponse',
        checkId: 'isUnderCare',
        name: 'Health Condition',
        icon: <img src={stethoscopeIconSvg} style={iconStyle} alt={'Health Condition'} />,
    },
    {
        id: 'underMentalHealthCareResponse',
        checkId: 'isUnderMentalHealthCare',
        name: 'Mental Health',
        icon: <img src={mentalHealthIconSvg} style={iconStyle} alt={'Mental Health'} />,
    },
]

export const accordionSections: IAccordionSections[] = [
    {
        component: personalInformationFields,
        title: 'Personal Information',
    },
    {
        component: medicalHistoryFields,
        title: 'Medical History',
    },
    {
        title: 'My Path',
    },
    {
        title: 'Support Resources',
    },
]
