import React from 'react'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { OutgoingMessageStates } from '@/hooks/useOutgoingMessageStatus'

import { IIconType } from './interfaces'

import { MessageSpinner } from './styles'

export const iconType: IIconType = {
    [OutgoingMessageStates.SENT]: <DoneIcon />,
    [OutgoingMessageStates.PENDING]: <MessageSpinner />,
    [OutgoingMessageStates.DELIVERED]: <DoneIcon />,
    [OutgoingMessageStates.READ]: <DoneAllIcon />,
    [OutgoingMessageStates.FAILED]: <ErrorOutlineIcon />,
}
