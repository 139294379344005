import React from 'react'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'

import { dateToString } from '@/utils'
import { FETCH_SKILLSETS_BY_USER_ID } from '@/graphql/users/queries'
import { SkillsetChassisEnum } from '@/constants/myPathConstants'

import { isIntensityExist } from '@/components/Patient/PatientTabsPanel/PatientMyPath/PatientMyPath'
import { IMyPath, IMyPathSkillsets } from '../../interfaces'
import { MyPathSkillsetsContainer, MyPathSkillsetsEmptyState } from '../../styles'

const MyPath: React.FC<IMyPath> = ({ patientUserId }) => {
    const { data: myPathData, loading: myPathLoading } = useQuery(FETCH_SKILLSETS_BY_USER_ID, {
        variables: {
            userId: patientUserId,
        },
        fetchPolicy: 'network-only',
    })

    const patientSkillsets: IMyPathSkillsets[] = myPathData?.myPathSkillsetListByUserId || []
    const checkSkillsetEntryExists = patientSkillsets?.length

    const individualSkillsetLayout = (
        skillsetChassisType: string,
        startIntensity: number,
        endIntensity: number
    ) => {
        const endIntensityLabel =
            skillsetChassisType === SkillsetChassisEnum.LEARNING ? 'Learning Rating' : 'Final'

        const startIntensityBlock = (
            <Typography>Initial {isIntensityExist(startIntensity)}</Typography>
        )

        const endIntensityBlock = (
            <Typography>
                {endIntensityLabel} {isIntensityExist(endIntensity)}
            </Typography>
        )

        switch (skillsetChassisType) {
            case SkillsetChassisEnum.LEARNING:
                return endIntensityBlock
            case SkillsetChassisEnum.INTENSITY:
            case null:
            default:
                return (
                    <>
                        {startIntensityBlock}
                        {endIntensityBlock}
                    </>
                )
        }
    }

    return (
        <>
            {checkSkillsetEntryExists && !myPathLoading ? (
                patientSkillsets.map((skillset: IMyPathSkillsets, index: number) => (
                    <MyPathSkillsetsContainer key={index}>
                        <Typography className='my-path__name'>{skillset.skillsetName}</Typography>
                        <Typography className='my-path__date'>
                            Started {dateToString(skillset?.createdDatetime, 'MMM dd yyyy')}
                        </Typography>
                        <Box className='my-path__intensity-block'>
                            {individualSkillsetLayout(
                                skillset?.skillsetChassisType,
                                skillset?.startIntensity,
                                skillset?.endIntensity
                            )}
                        </Box>
                    </MyPathSkillsetsContainer>
                ))
            ) : (
                <MyPathSkillsetsEmptyState>No Modules Started</MyPathSkillsetsEmptyState>
            )}
        </>
    )
}

export default MyPath
