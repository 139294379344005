import { Box, IconButton, styled } from '@mui/material'

const header_height = '60px'

export const SendbirdAppWrapper = styled('div')(
    ({ theme }) => `
    background: ${theme.palette.primary.contrastText};
    box-shadow: 0px 4px 24px rgba(55, 43, 18, 0.05);
    height: calc(100% - ${header_height});

    header {
        height: ${header_height};
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        position: relative;
        display: flex;
        & div p {
            font-family: 'DM Sans', sans-serif;
            font-size: 18px;
            color: ${theme.palette.violet.main};
            padding-left: 38px;
        }
    }

    .sendbird-app__conversation-wrap {
        height: 100%;
        display: flex;
    }

    .sendbird-channel-list {
        position: relative;
        max-width: 332px;
        width: 100%;
    }

    .sendbird-channel-list_footer {
      position: absolute;
      bottom: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .sendbird-channel-list_footer__btn {
      color: ${theme.palette.primary.contrastText};
      background-color: ${theme.palette.violet.main};
      width: 245px;
      height: 48px;
      align-items: center;

      &:hover {
        background-color: ${theme.palette.violet.main};
      }
    }

    .sendbird-channel-list__header {
        height: auto;
    }

    .sendbird-channel-header {
        position: inherit;
        height: 100%;
        padding: 24px 16px 10px 22px;
        width: 100%;
    }

    .sendbird-channel-list__body,
    .sendbird-channel-list {
        position: relative;
        border-bottom-left-radius: 8px;
    }

    .sendbird-channel-header,
    .sendbird-channel-list {
        border: none;
        border-top-left-radius: 8px;
    }

    .sendbird-chat-box {
        width: 100%;
        display: flex;
    }

    .patient-info-box {
        max-width: 302px;
        background: ${theme.palette.primary.contrastText};
        width: 100%;
        height: 100%;
        padding: 15px 16px;
        border-left: 1px solid #c8c8c8;
        background-color: white;
    }

    .sendbird-channel-list {
        border-right: 1px solid #c8c8c8;
        border-bottom: none;

        .sendbird-place-holder__body {
            .sendbird-place-holder__body__icon,
            .sendbird-place-holder__body__text {
                display: none;
            }

            &::after {
                content: 'No chats found';
                width: 230px;
                font-size: 24px;
                text-transform: capitalize;
                color: rgba(43, 48, 115, 0.5);
                text-align: center;
                font-weight: 500;
            }
        }
    }

    .sendbird-channel-header__right-icon,
    .sendbird-notification {
        display: none;
    }

    .sendbird-conversation {
        position: relative;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: none;
        background-color: ${theme.palette.beige.light};

        .sendbird-conversation__footer {
            padding-bottom: 0;
        }

        .sendbird-conversation__messages-padding {
            padding: 16px 30px 0;
            overflow: hidden;
            overflow-y: auto;
        }

        .sendbird-place-holder__body__icon,
        .sendbird-place-holder__body__text {
            display: none;
        }

        .sendbird-place-holder__body::after {
            content: 'Please choose who you are going to chat with';
            width: 230px;
            font-size: 24px;
            text-transform: capitalize;
            color: rgba(43, 48, 115, 0.5);
            text-align: center;
            font-weight: 500;
        }
    }

    .sendbird-conversation__messages {
        .sendbird-msg--scroll-ref {
            .admin-message:first-of-type {
                margin-top: 35px;
            }

            .admin-message:last-of-type {
                margin-bottom: 35px;
            }
        }
    }
`
)

export const ArrowUpwardButton = styled(IconButton)`
    position: absolute;
    bottom: 104px;
    right: 32px;
    width: 40px;
    z-index: 10;
    height: 40px;
    box-shadow: 0px 4px 16px rgba(63, 75, 84, 0.1);
`

export const DashboardChatsWrapper = styled(Box)`
    position: relative;
    height: 100vh;
    min-height: 100vh;
`
