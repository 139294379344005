import requiredFields from '@/components/ToDo/ToDoForm/requiredFields'

const checkToDoValidation = (toDoData: any, type: string) => {
    if (!type) {
        return false
    }

    const validationFields = requiredFields[type]

    return validationFields.every((field: string) => {
        const isTitleField = field === 'title'
        const isDescriptionField = field === 'description'

        if (isTitleField && toDoData[field].length < 4) {
            return false
        }

        if (isDescriptionField) {
            if (toDoData[field]?.length === 0) {
                return true
            }
        }

        return !!toDoData[field]
    })
}

export default checkToDoValidation
