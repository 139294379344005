import React from 'react'
import { useQuery } from '@apollo/client'
import { Table, TableBody, TableRow } from '@mui/material'

import { AccordionFieldsContent } from '../../styles'
import { IMedicalHistory, IMedicalHistoryFields } from '../../interfaces'
import { FETCH_INTAKE_BY_USER_ID } from '@/graphql/intakes/queries'
import TableRowNames from './tableRowNames'

const MedicalHistory: React.FC<IMedicalHistory> = ({ patientUserId, section }) => {
    const { data: intakeData, loading: intakeLoading } = useQuery(FETCH_INTAKE_BY_USER_ID, {
        variables: { userId: patientUserId },
        fetchPolicy: 'network-only',
    })

    const patientIntake = intakeData?.intakeByUserId

    const checkResponses = (field: string, fieldResponse: string) => {
        if (patientIntake[field]) {
            return patientIntake[fieldResponse] || 'Yes, not specified'
        }

        return 'No'
    }

    return (
        <Table aria-label='simple table' sx={{ tableLayout: 'fixed' }}>
            <TableBody>
                {section.component.map((row: IMedicalHistoryFields) => (
                    <TableRow key={row.name} sx={{ '& > *': { border: 0, padding: 0 } }}>
                        <TableRowNames icon={row.icon} name={row.name} />
                        <AccordionFieldsContent align='left'>
                            {patientIntake && !intakeLoading
                                ? checkResponses(row.checkId, row.id)
                                : '--'}
                        </AccordionFieldsContent>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default MedicalHistory
