import React from 'react'
import { Avatar, Box, CardContent, Stack } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'

import CircularLoader from '@/components/Loader/CircularLoader'

import { useProviderAvatarInToDo } from '@/hooks'
import { dateToString, getTime, cropName, getFullName } from '@/utils'

import { IPatientToDoAppointment } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'
import {
    ChipIcon,
    DescriptionBlock,
    PatientToDoChip,
    TitleBlock,
} from '@/components/Patient/PatientTabsPanel/styles'

const PatientToDoAppointment: React.FC<IPatientToDoAppointment> = ({
    priorityClass,
    priorityIcon,
    visitDate,
    visitTime,
    duration,
    title,
    description,
    practitionerId,
    visitReason,
}) => {
    const { previewAvatar, providerData, loading } = useProviderAvatarInToDo(practitionerId)

    let practitionerContent: string | JSX.Element = ''

    if (loading) {
        practitionerContent = <CircularLoader size={20} />
    }

    if (!loading && practitionerId) {
        practitionerContent = (
            <TitleBlock>
                <Avatar src={previewAvatar} />
                {getFullName(providerData?.firstName, providerData?.lastName)}
            </TitleBlock>
        )
    }

    return (
        <CardContent>
            <Stack direction='row'>
                <PatientToDoChip className={priorityClass} label={<Avatar src={priorityIcon} />} />
                {(visitDate || visitTime) && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <CalendarMonthOutlinedIcon />
                                </ChipIcon>
                                {/*{repeated && <img src={repeatSvg} alt='Repeated' />}*/}
                                {`${visitDate && dateToString(visitDate, 'MM/dd/yyyy')} ${
                                    visitTime && getTime(visitTime)
                                }`}
                            </Box>
                        }
                    />
                )}
                {duration && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <TimerOutlinedIcon />
                                </ChipIcon>
                                {duration} min
                            </Box>
                        }
                    />
                )}
            </Stack>
            {practitionerContent}
            {title && <TitleBlock>{cropName(title, 20)}</TitleBlock>}
            {description && <DescriptionBlock>{cropName(description, 65)}</DescriptionBlock>}
            {visitReason && (
                <DescriptionBlock className='free-text'>{visitReason}</DescriptionBlock>
            )}
        </CardContent>
    )
}

export default PatientToDoAppointment
