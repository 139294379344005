import { gql } from '@apollo/client'

export const COLLEGE_LIST = gql`
    query CollegeList($offset: Int, $limit: Int, $search: String) {
        colleges(offset: $offset, limit: $limit, search: $search) {
            count
            collegeList {
                id
                name
                address
                city
                state
                zipCode
                trial {
                    id
                    startDate
                    endDate
                }
                users {
                    id
                }
                isArchived
                campusCrisisInfo
                createdDate
            }
        }
    }
`

export const PATIENT_COLLEGE_LIST = gql`
    query CollegeList($offset: Int, $limit: Int, $search: String) {
        colleges(offset: $offset, limit: $limit, search: $search) {
            count
            collegeList {
                id
                name
                address
                city
                state
            }
        }
    }
`

export const COLLEGE_BY_ID = gql`
    query CollegeById($collegeByIdId: ID!) {
        collegeById(id: $collegeByIdId) {
            id
            name
            address
            city
            state
        }
    }
`
