import { useMutation } from '@apollo/client'
import axios from 'axios'

import { UPLOAD_FILE_WITH_SIGNED_URL } from '@/graphql/files/mutations'

import { dateToString } from '@/utils'

const useUploadPhoto = () => {
    const [uploadFileSignedUrl] = useMutation(UPLOAD_FILE_WITH_SIGNED_URL)
    const currentDate = dateToString(Date.now(), "yyyy-MM-dd'T'HH:mm:ss")

    const uploadFile = async (file: File | null) => {
        const { data } = await uploadFileSignedUrl({
            variables: {
                fileInput: {
                    name: `${currentDate}_${file?.name}`,
                    fileType: file?.type,
                },
            },
        })

        if (data?.uploadFileWithSignedUrl) {
            await axios.put(data?.uploadFileWithSignedUrl?.url, file, {
                headers: { 'Content-Type': 'application/json' },
            })
        }

        return data?.uploadFileWithSignedUrl?.file?.id
    }

    return { uploadFile }
}

export default useUploadPhoto
