import { createContext } from 'react';

interface AuthContextValue {
  signIn: (authCode: string) => void;
  signOut: () => void;
  currentUser: any;
  setCurrentUser: (data: any) => void;
}

const AuthContext = createContext({} as AuthContextValue);

export default AuthContext;
