import React, { useState } from 'react'
import { IconButton, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

import { IFileLoaderMenuBtn } from '@/components/Patient/PatientTabsPanel/interfaces'
import { ReactComponent as EditMessageSvg } from '@/assets/images/edit-message.svg'

import { MessageMenuWrapper } from '@/components/Chat/ChatMessage/styles'

const FileLoaderMenuBtn: React.FC<IFileLoaderMenuBtn> = ({ downloadFile, toggleEditMode }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const fileDownloadHandler = () => {
        downloadFile()

        setAnchorEl(null)
    }

    const editingModeHandler = () => {
        handleClose()
        toggleEditMode()
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <MessageMenuWrapper
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='upload-file_wrapper'
            >
                <MenuItem onClick={fileDownloadHandler} className='download-btn'>
                    <IconButton disableRipple>
                        <FileDownloadOutlinedIcon />
                    </IconButton>
                    Download
                </MenuItem>
                <MenuItem onClick={editingModeHandler} className='edit-btn'>
                    <IconButton disabled>
                        <EditMessageSvg />
                    </IconButton>
                    Edit
                </MenuItem>
            </MessageMenuWrapper>
        </>
    )
}

export default FileLoaderMenuBtn
