export const getProviderFullName = (sessionUser: any) => {
    return `${sessionUser?.firstName} ${sessionUser?.lastName}`
}

export const getProviderRoleName = (sessionUser: any) => {
    return sessionUser?.role?.name
}

export const getProviderInAdminMessage = (sessionUser: any) => {
    return sessionUser?.firstName ?? 'Care Team Member'
}

export const getProviderSub = (sessionUser: any) => {
    return sessionUser?.sub
}

export default {
    getProviderFullName,
    getProviderRoleName,
    getProviderInAdminMessage,
    getProviderSub,
}
