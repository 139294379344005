import React, { useMemo } from 'react'
import { CardHeader } from '@mui/material'

import { PatientDatePicker, GenderDropdown, InputField } from '../../../UI'
import withUIComponent from '@/HOC/withUIComponent'
import CollegeListDropdown from '@/components/UI/Dropdowns/CollegeListDropdown'
import EditModeBtn from '@/components/UI/Buttons/EditModeBtn'

import { filterPatientsData } from '@/utils'

import { IPatientDataBlock } from '../../PatientContent/interfaces'
import { IUIComponents } from '../interfaces'

import {
    CardContentBox,
    ErrorField,
    PatientDataBlockWrapper,
    ProfileAddressBox,
} from '../../PatientContent/styles'

const UIComponents: IUIComponents = {
    inputField: InputField,
    datePicker: PatientDatePicker,
    dropdown: GenderDropdown,
    searchDropdown: CollegeListDropdown,
}

const PatientDataBlock: React.FC<IPatientDataBlock> = ({
    title,
    id,
    templateData,
    data,
    unverifiedCollege,
    initialState,
    enableEditModeForTab,
    setPatientData,
    isEditMode,
    isDisabled,
    formik,
    submitting,
}) => {
    const { filteredTemplateData } = filterPatientsData(templateData, data)

    const editPatientData = (name: string, value: any) => {
        setPatientData((prev: any) => ({
            ...prev,
            [name]: value,
        }))
    }

    const sendModifiedData = async () => {
        formik.handleSubmit()
    }

    const cancelEditingMode = () => {
        formik.setTouched({}, false)

        setPatientData(initialState)
        enableEditModeForTab('')
    }

    const convertToUIComponent = (label: string, value: any, type: string, key: string) => {
        const EnhancedWrapped = withUIComponent(UIComponents[type])

        return ({ value }: { value: string }) => (
            <EnhancedWrapped
                label={label}
                value={value}
                name={key}
                isEditMode={isEditMode}
                editDate={editPatientData}
                onChangeHandler={(e) => {
                    const { name, value: targetValue } = e.target

                    formik.handleChange(e)
                    editPatientData(name, targetValue)
                }}
            />
        )
    }

    const cardComponents = useMemo(() => {
        return filteredTemplateData?.map(({ label, value, type, key }: any) => ({
            EnhancedComponent: convertToUIComponent(label, value, type, key),
            key,
        }))
    }, [isEditMode])

    const enableEditMode = () => enableEditModeForTab(id)

    return (
        <PatientDataBlockWrapper>
            <CardHeader
                action={
                    <EditModeBtn
                        isEditMode={isEditMode}
                        cancelEditingMode={cancelEditingMode}
                        sendModifiedData={sendModifiedData}
                        enableEditMode={enableEditMode}
                        isLoading={submitting}
                        isDisabled={isDisabled}
                    />
                }
                title={title}
            />
            <ProfileAddressBox>
                {unverifiedCollege ? (
                    <div className='unverified-college-box'>
                        <div>
                            Unverified
                            <br />
                            College
                        </div>
                        <div>{unverifiedCollege ? unverifiedCollege : 'N/A'}</div>
                    </div>
                ) : (
                    ''
                )}
                {cardComponents?.map(({ EnhancedComponent, key }: any, index: number) => {
                    const fieldValue = filteredTemplateData[index]?.value
                    return (
                        <CardContentBox key={key + index}>
                            <EnhancedComponent value={fieldValue} />
                            {formik.touched[key] && formik.errors[key] ? (
                                <ErrorField
                                    className={formik.errors[key] ? 'error-color' : ''}
                                    bottom={-16}
                                >
                                    <>{formik.errors[key]}</>
                                </ErrorField>
                            ) : null}
                        </CardContentBox>
                    )
                })}
            </ProfileAddressBox>
        </PatientDataBlockWrapper>
    )
}

export default PatientDataBlock
