import React from 'react'
import { useQuery } from '@apollo/client'
import { TextField, Typography } from '@mui/material'

import { FETCH_STATES } from '@/graphql/states/queries'

import { ISearchDropdown } from '@/components/UI/interfaces'

import { CustomAutocomplete } from '@/components/ToDo/ToDoForm/styles'
import CircularLoader from '@/components/Loader/CircularLoader'

const StateListDropdown: React.FC<ISearchDropdown> = ({
    label,
    name,
    value,
    isEditMode,
    handleData,
    isDisabled,
}) => {
    const { data, loading } = useQuery(FETCH_STATES)

    const findStateByAbbreviation = (abbreviation?: string) => {
        return data?.states?.find((state: any) => state.abbreviation === abbreviation)
    }

    return (
        <>
            <Typography className='state-typography required-field'>{label}</Typography>
            {loading ? (
                <CircularLoader size={20} />
            ) : (
                <CustomAutocomplete
                    className='search-box'
                    value={findStateByAbbreviation(value) ?? null}
                    onChange={(_: any, data: any) => handleData(name, data?.abbreviation)}
                    options={data?.states}
                    getOptionLabel={(option: any) => {
                        return `${option.abbreviation}` ?? ''
                    }}
                    renderOption={(props, option: any) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={!isEditMode || isDisabled}
                    disableClearable
                    disablePortal
                />
            )}
        </>
    )
}

export default StateListDropdown
