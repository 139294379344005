import React from 'react'
import { CardContent } from '@mui/material'

import { dateToString } from '@/utils'
import { PatientDataBlockWrapper } from '@/components/Patient/PatientContent/styles'
import InfoField from './InfoField'
import { ISkillsetContainer } from '../../interfaces'

const SkillsetContainer: React.FC<ISkillsetContainer> = ({
    id,
    skillsetName,
    createdDatetime,
    startIntensityBlock,
    endIntensityBlock,
}) => {
    return (
        <PatientDataBlockWrapper key={id}>
            <CardContent>
                <InfoField name='skillset' label='Skillset: ' value={skillsetName} isHeader />
                <InfoField
                    name='dateStarted'
                    label='Date Started'
                    value={dateToString(createdDatetime, 'MM/dd/yyyy')}
                />
                {startIntensityBlock}
                {endIntensityBlock}
            </CardContent>
        </PatientDataBlockWrapper>
    )
}

export default SkillsetContainer
