import React from 'react'

import PanelHeader from './components/PanelHeader'
import PanelAccordion from './components/PanelAccordion'
import { IChatPatientPanel } from './interfaces'
import { PatientPanelContainer } from './styles'

const ChatPatientPanel: React.FC<IChatPatientPanel> = ({
    isPatientLoading,
    patientUserId,
    patientData,
}) => {
    return (
        <PatientPanelContainer className='patient-info-box'>
            {isPatientLoading ? null : (
                <>
                    <PanelHeader patientUserId={patientUserId} patientData={patientData} />
                    <PanelAccordion patientUserId={patientUserId} patientData={patientData} />
                </>
            )}
        </PatientPanelContainer>
    )
}

export default ChatPatientPanel
