import { getResourceIdBySlug } from './getResourceIdbySlug'
import { IPartnerResource } from '../interfaces'

export const getResourceChanges = (
    originalResources: string[],
    newResources: string[],
    partnerResources?: IPartnerResource[]
) => {
    const deleted: string[] = originalResources.filter((item: any) => !newResources.includes(item))
    const deletedIds = getResourceIdBySlug(deleted, partnerResources)
    const created = newResources.filter((item: any) => !originalResources.includes(item))

    return { deletedIds, created }
}
