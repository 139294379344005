import React, { useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import AttachFileIcon from '@mui/icons-material/AttachFile'

import { useUploadFile } from '@/hooks'

import { IAttachBtn } from './interfaces'
import { FileMessageCreateParams } from '@sendbird/chat/message'

const AttachBtn: React.FC<IAttachBtn> = ({ sendFileMessage, sdk, channel, userId, providerRoleName, firstName }) => {
    const attachBtnRef = useRef<HTMLInputElement | null>(null)

    const uploadFile = useUploadFile(userId)

    const handleCapture = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0]

        const params: FileMessageCreateParams = {}
        params.file = file
        params.data = JSON.stringify({firstName, role: providerRoleName})

        try {
            sendFileMessage(channel, params)
            await uploadFile(event)
        } catch (e) {
            console.error(e.message)
        }
    }

    const clickOnAttachBtn = () => {
        if (attachBtnRef.current) {
            attachBtnRef.current.click()
        }
    }

    return (
        <div className='wrapper-btn--attach'>
            <input
                ref={attachBtnRef}
                accept='image/*'
                style={{ display: 'none' }}
                multiple
                type='file'
                onChange={handleCapture}
            />
            <IconButton onClick={clickOnAttachBtn}>
                <AttachFileIcon />
            </IconButton>
        </div>
    )
}

export default AttachBtn;
