import React, { ReactElement, useState } from 'react'

import { Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import SearchBar from '@/components/UI/Inputs/SearchBar'
import { ToDoPagination } from '@/components'

import { menu } from '../styles'
import { HeaderMenu, ToDosListContainer, ToDosListHeader, ToDosListLayout } from './styles'
import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'

const ToDoList: React.FC = (): ReactElement => {
    const [searchName, setSearchName] = useState<string>('')
    const [isCreateMode, setIsCreateMode] = useState<boolean>(false)

    const searchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value

        setSearchName(text)
    }

    const clearSearch = () => {
        setSearchName('')
    }

    const openCreateModal = () => {
        setIsCreateMode(!isCreateMode)
    }

    return (
        <ToDosListContainer>
            <SearchBar
                placeholder='Search by Patient Name'
                searchName={searchName}
                searchByName={searchByName}
                clearSearch={clearSearch}
            />
            <ToDosListLayout>
                <ToDosListHeader>
                    Action Items
                    <AddNewChannelBtn
                        onClick={openCreateModal}
                        startIcon={<AddCircleOutlineIcon />}
                    >
                        Add New
                    </AddNewChannelBtn>
                </ToDosListHeader>
                <HeaderMenu>
                    {menu?.map((menuItem: string) => (
                        <Typography key={menuItem}>{menuItem}</Typography>
                    ))}
                </HeaderMenu>
                <ToDoPagination
                    searchName={searchName}
                    isCreateMode={isCreateMode}
                    openCreateModal={openCreateModal}
                />
            </ToDosListLayout>
        </ToDosListContainer>
    )
}

export default ToDoList
