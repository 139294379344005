import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { FETCH_USER_TODOS_BY_EMAIL } from '@/graphql/toDos/queries'

import PatientToDoBlock from './PatientToDoBlock'
import CircularLoader from '@/components/Loader/CircularLoader'
import ModalToDo from '@/components/ToDo/ModalToDo/ModalToDo'

import { IPatientPersonalInfo, IToDo } from '@/components/Patient/PatientTabsPanel/interfaces'

import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'
import { EmptyStatePatientBlock, PatientToDosWrapper } from '../styles'

const PatientToDos: React.FC<IPatientPersonalInfo> = ({ email, userId }) => {
    const {
        loading,
        data: userData,
        refetch,
    } = useQuery(FETCH_USER_TODOS_BY_EMAIL, {
        variables: { email },
    })

    useEffect(() => {
        const refreshToDos = async () => {
            await refetch({ email })
        }
        refreshToDos()
    }, [])

    const [isCreateMode, setIsCreateMode] = useState<boolean>(false)

    const openCreateModal = () => {
        setIsCreateMode(!isCreateMode)
    }

    if (loading) {
        return <CircularLoader size={60} />
    }

    return (
        <PatientToDosWrapper>
            <AddNewChannelBtn onClick={openCreateModal} startIcon={<AddCircleOutlineIcon />}>
                Add New
            </AddNewChannelBtn>

            {userData?.userByEmail?.todos?.length ? (
                userData?.userByEmail?.todos?.map((todo: IToDo) => (
                    <PatientToDoBlock
                        key={todo.id}
                        toDo={todo}
                        userId={userId}
                        patientEmail={email}
                        patientId={userData?.userByEmail?.patient?.id}
                        openCreateModal={openCreateModal}
                        isCreateMode={isCreateMode}
                    />
                ))
            ) : (
                <EmptyStatePatientBlock>
                    There are no assigned action items for this patient yet.
                </EmptyStatePatientBlock>
            )}
            {isCreateMode && (
                <ModalToDo
                    open={isCreateMode}
                    userId={userId}
                    patientEmail={email}
                    patientId={userData?.userByEmail?.patient?.id}
                    handleClose={openCreateModal}
                    refetch={refetch}
                    isExistingPatient
                    isCreateMode
                />
            )}
        </PatientToDosWrapper>
    )
}

export default PatientToDos
