import lowPrioritySvg from '@/assets/images/priority-low.svg'
import medPrioritySvg from '@/assets/images/priority-medium.svg'
import highPrioritySvg from '@/assets/images/priority-high.svg'

// ------------------- To Do Values (Menu, Categories, Priority) -------------------
export const menu: Array<string> = [
    '',
    'Action Items Title',
    'Type',
    'Start Date',
    'Due Date',
    'Patient',
    'Status',
]

export const categories: Array<string> = [
    'Free Text',
    'Activities',
    'Content',
    'Appointment',
    'Lab/Rx Order',
    'Lab Result',
    'After Visit Summary',
]

export const priorities: Array<string> = ['Low', 'Medium', 'High']

interface PriorityObj {
    [key: string]: any;
}

export const prioritiesTag: PriorityObj = {
    Low: {
        img: lowPrioritySvg,
        alt: 'Low priority',
    },
    Medium: {
        img: medPrioritySvg,
        alt: 'Medium priority',
    },
    High: {
        img: highPrioritySvg,
        alt: 'High priority',
    },
}
