import React from 'react'
import { TextField, Typography } from '@mui/material'

import { CustomAutocomplete, MainHeader } from '@/components/ToDo/ToDoForm/styles'

import { IToDoQuestionnaire } from './interfaces'
import { useQuery } from '@apollo/client'
import { FETCH_QUESTIONNAIRE_LIST } from '@/graphql/questionnaires/queries'
import CircularLoader from '@/components/Loader/CircularLoader'

const ToDoQuestionnaire: React.FC<IToDoQuestionnaire> = ({
    questionnaireValue,
    handleToDoData,
    isViewMode,
}) => {
    const { data, loading } = useQuery(FETCH_QUESTIONNAIRE_LIST)

    const findQuestionnaireById = (questionnaireId: string) => {
        return data?.questionnaireList?.find(({ id }: any) => questionnaireId === id)
    }

    return (
        <MainHeader className='content-body mt-0' alignItems='center'>
            <Typography className='activity-typography required-field w-130'>
                Choose activity
            </Typography>
            {loading ? (
                <CircularLoader size={20} />
            ) : (
                <CustomAutocomplete
                    className='search-box'
                    value={findQuestionnaireById(questionnaireValue) || null}
                    onChange={(_: any, data: unknown | any) => {
                        handleToDoData('intakeAssigned', data?.id)
                    }}
                    options={data?.questionnaireList || []}
                    getOptionLabel={(option: any) => option.name ?? ''}
                    renderOption={(props: any, option: any) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.name}
                            </li>
                        )
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    disabled={isViewMode}
                    disableClearable
                    disablePortal
                />
            )}
        </MainHeader>
    )
}

export default ToDoQuestionnaire
