import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        orange: Palette['primary'];
        violet: Palette['primary'];
        green: Palette['primary'];
        beige: Palette['primary'];
        purple: Palette['primary'];
        lightGray: Palette['primary'];
        gray: Palette['primary'];
    }

    interface PaletteOptions {
        orange: PaletteOptions['primary'];
        violet: PaletteOptions["primary"];
        green: PaletteOptions['primary'];
        beige: PaletteOptions['primary'];
        purple: PaletteOptions['primary'];
        lightGray: PaletteOptions['primary'];
        gray: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        orange: true;
        green: true;
        violet: true;
    }
}

// A custom theme for our app
const theme = createTheme({
    palette: {
        primary: {
            main: '#FC7960',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#b2cfe7', // light blue
        },
        orange: {
            main: '#FC7960',
            contrastText: '#fcf9f0',
        },
        violet: {
            main: "#401F52", // main purple - grape
            light: "#c5bbcb", // lighter grape
        },
        green: {
            main: '#dfefd8',
            contrastText: '#97C287',
        },
        beige: {
            main: '#fcf9f0',
            light: '#fdfcf7',
        },
        info: {
            main: '#201f58', // dark blue
            contrastText: '#769FC1' // blue
        },
        text: {
            primary: '#201f58', // dark blue
        },
        error: {
            main: '#FC7960',
        },
        background: {
            default: '#fbf8ef',
        },
        purple: {
            main: '#B871B5',
            dark:'#2e2333', // dark purple
            light: '#807685', // light gray-purple
        },
        lightGray: {
            main: '#bdbdbd',
        },
        gray: {
            main: '#a0aeba',
        },
    },
})

export default theme