export const getResourceIdBySlug = (slugsToFind: string[], originalResourceArray: any) => {
    const resultArray: any = []

    slugsToFind.forEach((slug) => {
        const deletedResource = originalResourceArray.find(
            (resource: any) => resource.slug === slug
        )

        if (!!deletedResource) {
            resultArray.push(deletedResource.id)
        }
    })

    return resultArray
}
