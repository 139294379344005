import { Box, FormControl, List, styled, TableCell, TableContainer } from '@mui/material'

export const SearchBarWrapper = styled(FormControl)`
    width: 30%;
    margin-bottom: 1rem;
    background-color: #f4f2e5;
    min-width: 240px;
`

export const ToDosListContainer = styled(Box)`
    width: 100%;
    padding: 25px;

    & .MuiInputLabel-root {
        font-weight: 700;
        font-size: 14px;
        opacity: 0.42;
    }
`

export const ToDosListLayout = styled(List)`
    padding: 0px;
    background-color: #fdfcf7;
    border-radius: 15px;
`

export const ToDosListHeader = styled('h4')`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 0;
    padding: 1.5rem;
    font-size: 18px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    box-shadow: 0 8px 10px -5px #e2e0d8;
    background-color: white;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    & .MuiButton-root {
        margin-bottom: 0;
    }

    &.colleges-list-header {
        color: rgba(43, 48, 115, 0.6);
        font-weight: 400;
        font-size: 16px;
        box-shadow: 0px 1px 0px rgba(200, 200, 200, 0.25), 0px 4px 24px rgba(55, 43, 18, 0.05);
    }
`

export const HeaderMenu = styled(Box)`
    width: 100%;
    display: grid;
    background-color: #fff;
    grid-template-columns: 30px 30% calc(15% + 9px) repeat(2, 2fr) 3fr 2fr 20px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);

    padding: 8px calc(1rem + 1rem + 24px) 8px 8px;

    box-shadow: 0 8px 10px -9px #e2e0d8;

    & .MuiTypography-root {
        font-weight: 700;
        font-size: 12px;
        opacity: 0.6;
    }
`

export const TableCellCustom = styled(TableCell)`
    width: 100%;
    padding: 0;
    border: none;
`

export const TableContainerWrapper = styled(TableContainer)`
    position: relative;
    min-height: 160px;
`
