import { gql } from '@apollo/client'

export const REGISTER_USER = gql`
    mutation register(
        $username: String!
        $email: String!
        $password: String!
        $confirmPassword: String!
    ) {
        register(
            RegisterUser: {
                username: $username
                email: $email
                password: $password
                confirmPassword: $confirmPassword
            }
        ) {
            id
            username
            email
            token
            createdAt
        }
    }
`

export const LOGIN_USER = gql`
    mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            id
            username
            email
            token
            createdAt
        }
    }
`
export const GET_SESSION_TOKEN = gql`
    mutation IssueSessionToken($userId: String!) {
        issueSessionToken(userId: $userId)
    }
`
