const mockPersonalInfo = [
    {
        label: 'Preferred Name',
        key: 'preferredName',
        type: 'inputField',
        value: '',
    },
    {
        label: 'First Name',
        key: 'firstName',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Last Name',
        key: 'lastName',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Sex (at birth)',
        key: 'gender',
        type: 'dropdown',
        value: 'Male',
    },
    {
        label: 'Gender (pref.)',
        key: 'preferredGender',
        type: 'dropdown',
        value: 'female',
    },
    {
        label: 'Pronouns',
        key: 'pronouns',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Date of Birth',
        key: 'birthDate',
        type: 'datePicker',
        value: '',
    },
    {
        label: 'Email',
        key: 'email',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Phone Mobile',
        key: 'phone',
        type: 'inputField',
        value: '',
    },
]
const mockPersonalInfoAddress = [
    {
        label: 'College',
        key: 'college',
        type: 'searchDropdown',
        value: '',
    },
    {
        label: 'Address',
        key: 'line',
        type: 'inputField',
        value: '',
    },
    {
        label: 'City',
        key: 'city',
        type: 'inputField',
        value: '',
    },
    {
        label: 'State',
        key: 'state',
        type: 'inputField',
        value: '',
    },
    {
        label: 'ZIP',
        key: 'postalCode',
        type: 'inputField',
        value: '',
    },
]

const mockPersonalInfoEmergencyContact = [
    {
        label: 'Name',
        key: 'emergencyContactName',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Relationship',
        key: 'emergencyContactRelationship',
        type: 'inputField',
        value: '',
    },
    {
        label: 'Phone',
        key: 'emergencyContactPhone',
        type: 'inputField',
        value: '',
    },
]

export { mockPersonalInfo, mockPersonalInfoAddress, mockPersonalInfoEmergencyContact }
