import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material'

import EllipsisLoader from '@/components/Loader/EllipsisLoader'
import CircularLoader from '@/components/Loader/CircularLoader'

import { useDebounce, useObserver } from '@/hooks'
import { PATIENT_COLLEGE_LIST } from '@/graphql/colleges/queries'

import { ICollegeListDropdownBody } from '@/components/UI/Dropdowns/CollegeListDropdown/interfaces'

import {
    CollegeListDropdownBodyWrapper,
    NoResultsText,
    CircularLoaderWrapper,
} from '@/components/UI/Dropdowns/CollegeListDropdown/styles'

const QUERY_LIMIT = 10

const CollegeListDropdownBody: React.FC<ICollegeListDropdownBody> = ({
    searchName,
    collegeList,
    setCollegeList,
    selectCollegeItem,
}) => {
    const [offset, setOffset] = useState<number>(0)
    const [collegesCount, setCollegesCount] = useState<number>(0)

    const lastElement = useRef<any>(null)
    let dropdownContent = null

    const [fetchCollegeList, { loading: collegesLoading }] = useLazyQuery(PATIENT_COLLEGE_LIST)

    useObserver(lastElement, offset, collegesCount, collegesLoading, () => {
        setOffset((prevState: number) => prevState + 10)
    })

    const searchCollegeList = useCallback(
        async (search: string, offset?: number) => {
            if (searchName) {
                setOffset(0)
            }

            try {
                const { data } = await fetchCollegeList({
                    variables: {
                        search,
                        offset,
                        limit: QUERY_LIMIT,
                    },
                })

                setCollegesCount(data?.colleges?.count)

                if (searchName) {
                    setCollegeList([...data?.colleges?.collegeList])
                    return
                }

                setCollegeList([...collegeList, ...data?.colleges?.collegeList])
            } catch (e) {
                console.error(e)
            }
        },
        [searchName, offset]
    )

    const debouncedSearch = useDebounce(searchCollegeList, 500)

    useEffect(() => {
        if (collegesLoading) {
            return
        }

        searchCollegeList(searchName, offset)
    }, [offset])

    useEffect(() => {
        debouncedSearch(searchName, 0)
    }, [searchName])

    if (collegesLoading && !collegeList.length) {
        dropdownContent = (
            <CircularLoaderWrapper>
                <CircularLoader size={20} />
            </CircularLoaderWrapper>
        )
    } else {
        dropdownContent = <NoResultsText>No results</NoResultsText>
    }

    return (
        <CollegeListDropdownBodyWrapper>
            {Boolean(collegeList.length)
                ? collegeList.map((college: any) => (
                    <ListItem key={college.id} disablePadding>
                        <ListItemButton onClick={() => selectCollegeItem(college)}>
                            <ListItemText primary={college.name} />
                        </ListItemButton>
                    </ListItem>
                ))
                : dropdownContent}

            {collegesLoading && Boolean(collegeList.length) && (
                <Box display='flex' justifyContent='center'>
                    <EllipsisLoader />
                </Box>
            )}
            {!collegesLoading && <Box ref={lastElement} height={2} />}
        </CollegeListDropdownBodyWrapper>
    )
}

export default CollegeListDropdownBody
