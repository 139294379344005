import { gql } from '@apollo/client'

export const FETCH_SINGLE_RESOURCE_BY_TYPE = gql`
    query PartnerSubPartnerSingleResource($name: String!, $partnerType: String!) {
        partnerSubPartnerSingleResource(name: $name, partnerType: $partnerType) {
            id
            slug
            entityId
            type {
                id
                name
            }
        }
    }
`
