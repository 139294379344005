import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import classNames from 'classnames'

import { CREATE_RESOURCES, DELETE_RESOURCES } from '@/graphql/resources/mutations'
import { UPDATE_PARTNER } from '@/graphql/partners/mutations'
import { GET_B2B_PARTNER_LIST } from '@/graphql/partners/queries'

import { IPartner, IPartnerSingleRow } from '@/components/CollegesAndPartners/interfaces'
import { ListTableBodyRow, ListTableCell } from '@/components/CollegesAndPartners/styles'
import ThreeDotBtn from '@/components/CollegesAndPartners/components/ThreeDotBtn'
import { ResourceTypesEnum } from '@/constants/resourceConstants'
import ModalPartner from '../modals/ModalPartner'
import { getReadableResources } from '../utils/getReadableResources'

const PartnerSingleRow: React.FC<IPartnerSingleRow> = ({ partner }) => {
    const [isPartnerMenuOpen, setIsPartnerMenuOpen] = useState<boolean>(false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)

    const [createResources] = useMutation(CREATE_RESOURCES)
    const [deleteResources] = useMutation(DELETE_RESOURCES)
    const [updatePartner] = useMutation(UPDATE_PARTNER)

    const readableResources = getReadableResources(partner?.resources)

    const openPartnerMenu = () => {
        setIsPartnerMenuOpen((prevState) => !prevState)
    }

    const enableEditMode = () => {
        setIsEditMode((prevState) => !prevState)
    }

    const getUpdateFunction = (cacheReplaceFn: (cachedPartner: IPartner) => any) => {
        return (cache: any) => {
            const cachedB2bPartnerList: any = cache.readQuery({
                query: GET_B2B_PARTNER_LIST,
            })
            const newCachedB2bPartnerList = cachedB2bPartnerList?.b2bPartnerList?.map(
                (cachedPartner: IPartner) => {
                    if (cachedPartner.id === partner.id) {
                        return {
                            ...cachedPartner,
                            ...cacheReplaceFn(cachedPartner),
                        }
                    }
                    return cachedPartner
                }
            )

            cache.writeQuery({
                query: GET_B2B_PARTNER_LIST,
                data: {
                    b2bPartnerList: newCachedB2bPartnerList,
                },
            })
        }
    }

    const handleEditPartner = async (
        deletedResourceIds: any,
        createdResources: any,
        partnerCode: string
    ) => {
        !!deletedResourceIds.length &&
            (await deleteResources({
                variables: {
                    resourceIds: deletedResourceIds,
                },
                update: getUpdateFunction((cachedPartner: IPartner) => {
                    return {
                        resources: cachedPartner?.resources?.filter(
                            (resource: any) => !deletedResourceIds.includes(resource.id)
                        ),
                    }
                }),
            }))

        !!createdResources.length &&
            (await createResources({
                variables: {
                    entityId: parseFloat(partner?.id),
                    partnerType: ResourceTypesEnum.B2B_PARTNER,
                    slugs: createdResources,
                },
                update: getUpdateFunction((cachedPartner: IPartner) => {
                    return { resources: cachedPartner?.resources?.concat(createdResources) }
                }),
            }))

        if (partnerCode != null && partnerCode !== partner.code) {
            await updatePartner({
                variables: {
                    b2bPartnerInput: {
                        id: partner.id,
                        code: partnerCode,
                    },
                },
                update: getUpdateFunction(() => {
                    return { code: partnerCode }
                }),
            })
        }
    }

    return (
        <ListTableBodyRow
            className={classNames({
                'open-menu': isPartnerMenuOpen,
            })}
        >
            <ListTableCell>{partner?.name}</ListTableCell>
            <ListTableCell>{partner?.code}</ListTableCell>
            <ListTableCell className='partner-resources'>{readableResources || '-'}</ListTableCell>
            <ListTableCell>
                <ThreeDotBtn openMenu={openPartnerMenu} enableEditMode={enableEditMode} />
            </ListTableCell>
            {isEditMode && (
                <ModalPartner
                    open={isEditMode}
                    handleClose={enableEditMode}
                    handleEditPartner={handleEditPartner}
                    partner={partner}
                />
            )}
        </ListTableBodyRow>
    )
}

export default PartnerSingleRow
