import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, Divider } from '@mui/material'
import classNames from 'classnames'

import PatientAvatar from '@/components/UI/PatientAvatar'
import ModalToDo from '@/components/ToDo/ModalToDo/ModalToDo'
import PatientMenuBtn from '@/components/UI/Buttons/PatientMenuBtn'
import { statusColors } from '@/components/Patient/PatientTabsPanel/PatientToDos/priorityIcons'
import { IToDo } from '@/components/Patient/PatientTabsPanel/interfaces'
import { IToDoSingle } from '@/components/ToDo/interfaces'

import { EDIT_TODO_MUTATION } from '@/graphql/toDos/mutations'
import { FETCH_TODOS_QUERY } from '@/graphql/toDos/queries'

import { useSignedUrl } from '@/hooks'
import { dateToString, getSubscriptionCurrentPlan } from '@/utils'

import { prioritiesTag } from '../styles'
import {
    PriorityTag,
    ToDoBox,
    ToDoCategory,
    ToDoContainer,
    ToDoListItem,
    ToDoText,
    ToDoTypography,
} from './styles'

/*
    Single row (i.e. a single action item) that appears in the Action Item tab's table
*/

const ToDoSingle: React.FC<IToDoSingle> = ({ toDo, search, offset, limit, count }) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [isViewMode, setIsViewMode] = useState<boolean>(false)

    const { previewAvatar, setPreviewAvatar, fetchSignedUrl } = useSignedUrl()
    const [editToDo] = useMutation(EDIT_TODO_MUTATION)

    const downloadSignedUrl = async () => {
        if (!toDo?.user?.avatar) {
            setPreviewAvatar('')
            return
        }

        await fetchSignedUrl(toDo?.user?.avatar)
    }

    useEffect(() => {
        downloadSignedUrl()
    }, [toDo?.user?.avatar])

    const priorityImg: any = prioritiesTag[toDo.priority]['img']
    const priorityAlt: any = prioritiesTag[toDo.priority]['alt']
    const statusClass = statusColors[toDo.status.toLowerCase()]
    const isCompleted =
        statusClass === statusColors.completed || statusClass === statusColors.canceled

    const isAppointment = toDo.type === 'Appointment'
    const subscriptionType = toDo.user?.activeSubscription?.subscriptionType

    const openMenuItems = () => {
        setIsOpenMenu((prev) => !prev)
    }

    const handleCloseModal = () => {
        setIsEditMode(false)
        setIsViewMode(false)
    }

    const enableViewMode = () => {
        setIsViewMode(!isViewMode)
    }

    const openEditModal = () => {
        setIsEditMode(!isEditMode)
    }

    const completeToDo = async (status: string) => {
        const completedTodo = {
            ...toDo,
            status,
            user: {
                id: toDo?.user?.id,
            },
        }
        delete completedTodo.id

        await editToDo({
            variables: { updateTodoId: toDo.id, todoInput: completedTodo },
            update(proxy) {
                const cacheToDosList: any = proxy.readQuery({
                    query: FETCH_TODOS_QUERY,
                    variables: {
                        search,
                        offset,
                        limit,
                    },
                })

                const toDosList = cacheToDosList?.todoList?.todoList?.map((cacheToDo: IToDo) => {
                    if (cacheToDo.id === toDo.id) {
                        return completedTodo
                    }

                    return cacheToDo
                })

                proxy.writeQuery({
                    query: FETCH_TODOS_QUERY,
                    variables: {
                        search,
                        offset,
                        limit,
                    },
                    data: {
                        todoList: {
                            count,
                            todoList: toDosList,
                        },
                    },
                })
            },
        })
    }

    return (
        <Box>
            <ToDoListItem
                className={classNames({
                    'open-menu': isOpenMenu,
                })}
            >
                <ToDoContainer>
                    <ToDoBox>
                        <PriorityTag src={priorityImg} alt={priorityAlt} />
                    </ToDoBox>
                    <ToDoText primary={toDo.title} secondary={toDo.description} />
                    <ToDoCategory>{toDo?.type}</ToDoCategory>
                    <ToDoTypography>
                        {dateToString(toDo?.startingDate, 'MM/dd/yyyy')}
                    </ToDoTypography>
                    {toDo.dueDate ? (
                        <ToDoTypography>{dateToString(toDo.dueDate, 'MM/dd/yyyy')}</ToDoTypography>
                    ) : (
                        <ToDoTypography>
                            {dateToString(toDo.visitDate, 'MM/dd/yyyy')}
                        </ToDoTypography>
                    )}

                    <ToDoBox className='patient-column'>
                        <PatientAvatar
                            src={previewAvatar}
                            currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                            fontSize={6}
                            wrapperWidth={40}
                            wrapperHeight={40}
                        />
                        <ToDoTypography className='patient-fullname'>
                            {toDo?.user?.firstName} {toDo?.user?.lastName}
                        </ToDoTypography>
                    </ToDoBox>

                    <ToDoTypography className={statusClass}>{toDo.status}</ToDoTypography>
                    <ToDoTypography textAlign='right'>
                        <PatientMenuBtn
                            openMenuItems={openMenuItems}
                            enableEditMode={openEditModal}
                            completeToDo={completeToDo}
                            isCompleted={isCompleted}
                            isAppointment={isAppointment}
                            enableViewMode={enableViewMode}
                            isToDoEdit
                        />
                    </ToDoTypography>
                </ToDoContainer>
            </ToDoListItem>
            {(isEditMode || isViewMode) && (
                <ModalToDo
                    open={isEditMode || isViewMode}
                    userId={toDo?.user?.id}
                    patientEmail={toDo?.user?.email}
                    patientId={toDo?.patientId}
                    toDoId={toDo?.id}
                    handleClose={handleCloseModal}
                    isViewMode={isViewMode}
                    limit={limit}
                    offset={offset}
                    search={search}
                    isExistingPatient
                    isEditMode
                />
            )}

            <Divider />
        </Box>
    )
}

export default ToDoSingle
