import React, { ChangeEvent } from 'react'
import { Typography } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import UploadToDoContentFile from './ToDoFreeText/UploadToDoContentFile'
import ToDoDateTime from '@/components/UI/DatePickers/ToDoDateTime'
import MedicalHistoryCheckbox from '@/components/UI/Buttons/MedicalHistoryCheckbox'

import { IToDoLabResult } from './interfaces'

import { MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'

const ToDoLabResult: React.FC<IToDoLabResult> = ({
    toDoData,
    handleToDoData,
    isViewMode,
    toDoContentFile,
    setToDoContentFile,
    isAddedToMedicalHistory,
    handleCheckbox,
}) => {
    const { labResultName, labResultDate, labResultExplanation } = toDoData

    const handleInput = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
        const { name, value } = e.target

        handleToDoData(name, value)
    }

    const changeLabResultExplanation = (e: any) => {
        const { name, value } = e.target
        const limit = 250

        if (value?.length > limit) {
            return
        }

        handleToDoData(name, value)
    }

    return (
        <>
            <MainHeader>
                <Typography className='lab-typography required-field w-130'>
                    Lab test name*
                </Typography>
                <ToDoFormText
                    value={labResultName}
                    onChange={handleInput}
                    disabled={isViewMode}
                    className='todo-inputs'
                    name='labResultName'
                    type='text'
                    variant='standard'
                    size='small'
                    required
                />
            </MainHeader>
            <ToDoDateTime
                handleToDoData={handleToDoData}
                existingDate={labResultDate}
                existingTime={''}
                dateFieldName='labResultDate'
                timeFieldName={''}
                rowTitle='Were received*'
                isDisabled={isViewMode}
                isLabResult
            />
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Lab result explanation*
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='labResultExplanation'
                    variant='outlined'
                    size='small'
                    value={labResultExplanation?.substring(0, 250)}
                    disabled={isViewMode}
                    onChange={changeLabResultExplanation}
                />
                <span className='content-limit'>{`(${
                    labResultExplanation?.length ?? 0
                }/250)`}</span>
            </MainHeader>
            <UploadToDoContentFile
                rowTitle='Lab result file*'
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
            <MedicalHistoryCheckbox
                isAddedToMedicalHistory={isAddedToMedicalHistory}
                isViewMode={isViewMode}
                handleCheckbox={handleCheckbox}
            />
        </>
    )
}

export default ToDoLabResult
