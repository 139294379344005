import React, { MouseEvent, useState } from 'react'
import { Tooltip } from '@mui/material'

import { IResponseTooltip } from '../../interfaces'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

const ResponseTooltip: React.FC<IResponseTooltip> = ({ response }) => {
    const [tooltipEnabled, setTooltipEnabled] = useState(false)

    const handleShouldShowTooltip = ({ currentTarget }: MouseEvent<Element>) => {
        if (currentTarget.scrollWidth > currentTarget.clientWidth) {
            setTooltipEnabled(true)
        }
    }

    const hideTooltip = () => setTooltipEnabled(false)

    return (
        <>
            {response && (
                <Tooltip
                    title={response}
                    onMouseEnter={handleShouldShowTooltip}
                    onMouseLeave={hideTooltip}
                    disableHoverListener={!tooltipEnabled}
                    arrow
                >
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {capitalizeFirstLetter(response)}
                    </div>
                </Tooltip>
            )}
        </>
    )
}

export default ResponseTooltip
