import React from 'react'

import { ICircularLoader } from './interfaces'
import { CircularLoaderWrapper } from './styles'
import classNames from 'classnames'

const CircularLoader: React.FC<ICircularLoader> = ({ size, color, relative }) => {
    return (
        <CircularLoaderWrapper
            className={classNames({
                relative,
            })}
            size={size}
            color={color}
        />
    )
}

export default CircularLoader
