import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'

import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

const useSignedUrl = () => {
    const [previewAvatar, setPreviewAvatar] = useState<string>('')
    const [fetchSignedUrlById, { loading }] = useLazyQuery(DOWNLOAD_SIGNED_URL)

    const fetchSignedUrl = async (avatar: string) => {
        await fetchSignedUrlById({
            variables: {
                downloadSignedUrlId: avatar,
            },
            onCompleted: ({ downloadSignedUrl }) => {
                setPreviewAvatar(downloadSignedUrl?.url)
            },
            onError: () => {
                setPreviewAvatar('')
            },
        })
    }

    return {
        previewAvatar,
        setPreviewAvatar,
        fetchSignedUrl,
        userAvatarLoading: loading,
    }
}

export default useSignedUrl
