import React from 'react'
import { Button, IconButton, Tooltip } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import editMessageSvg from '@/assets/images/edit-message.svg'
import editMessageDisabledSvg from '@/assets/images/edit-message-disabled.svg'

import { IEditModeBtn } from '../interfaces'

const EditModeBtn: React.FC<IEditModeBtn> = ({
    isEditMode,
    cancelEditingMode,
    sendModifiedData,
    enableEditMode,
    isLoading,
    isEmptyField,
    isDisabled,
}) => {
    return isEditMode ? (
        <>
            <Button
                variant='text'
                startIcon={<CloseOutlinedIcon />}
                disabled={isLoading}
                onClick={cancelEditingMode}
            >
                Cancel
            </Button>
            <Button
                variant='text'
                className='save-btn'
                startIcon={<SaveOutlinedIcon />}
                onClick={sendModifiedData}
                disabled={isLoading || isEmptyField}
            >
                Save
            </Button>
        </>
    ) : (
        <Tooltip title={isDisabled ? 'Save changes on other sections to edit.' : ''}>
            <span>
                <IconButton onClick={enableEditMode} className='edit-icon' disabled={isDisabled}>
                    <img src={isDisabled ? editMessageDisabledSvg : editMessageSvg} alt='Edit' />
                </IconButton>
            </span>
        </Tooltip>
    )
}

export default EditModeBtn
