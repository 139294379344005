import React from 'react'
import { Divider } from '@mui/material'

import { dateToString } from '@/utils'

import { IChatSeparator } from '../interfaces'

import { SeparatorWrapper } from '../styles'

const ChatSeparator: React.FC<IChatSeparator> = ({ createdAt }) => {
    return (
        <SeparatorWrapper>
            <Divider orientation='horizontal' flexItem>
                {dateToString(createdAt, 'MMMM dd, yyyy')}
            </Divider>
        </SeparatorWrapper>
    )
}

export default ChatSeparator
