import React from 'react'
import { Box } from '@mui/material'

import CircularLoader from '@/components/Loader/CircularLoader'
import PatientPreviewInfo from '@/components/Patient/PatientSidebar/PatientPreviewInfo'

import { IPatientEntryResource, IPatientList } from './interfaces'

import { EmptyStatePatientBlock } from '../PatientTabsPanel/styles'

const PatientsPreview: React.FC<IPatientList> = ({ patientPreviewData, loading }) => {
    if (loading && !patientPreviewData.length) {
        return <CircularLoader size={60} color='primary' />
    }

    if (!patientPreviewData?.length) {
        return <EmptyStatePatientBlock>No patients found</EmptyStatePatientBlock>
    }

    return (
        <Box>
            {patientPreviewData?.map((patient: IPatientEntryResource) => (
                <PatientPreviewInfo key={patient?.id} patient={patient} />
            ))}
        </Box>
    )
}

export default PatientsPreview
