import React, { ChangeEvent, useEffect, useRef } from 'react'
import { useQuery } from '@apollo/client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import CircularLoader from '@/components/Loader/CircularLoader'
import FileLoader from '@/components/Patient/PatientTabsPanel/PatientMedicalHistory/FileLoader'
import PatientMedicalBlock from '@/components/Patient/PatientTabsPanel/PatientMedicalHistory/PatientMedicalBlock'

import { FETCH_USER_TODOS_BY_EMAIL } from '@/graphql/toDos/queries'
import { FETCH_FILE_LIST_BY_USER_ID } from '@/graphql/files/queries'

import { useUploadFile } from '@/hooks'

import { IPatientPersonalInfo, IToDo } from '@/components/Patient/PatientTabsPanel/interfaces'

import { AddNewChannelBtn } from '@/components/Chat/ChannelHeader/styles'
import { FileLoaderWrapper, EmptyStatePatientBlock } from '../styles'

const acceptableTypes = ['Free Text', 'Lab/Rx Order', 'Lab Result']

const PatientMedicalHistory: React.FC<IPatientPersonalInfo> = ({ email, userId }) => {
    const inputRef = useRef<HTMLElement | any>(null)

    const {
        loading,
        data: userData,
        refetch: refetchUserTodos,
    } = useQuery(FETCH_USER_TODOS_BY_EMAIL, {
        variables: { email },
    })
    const { data: files, refetch: refetchFileList } = useQuery(FETCH_FILE_LIST_BY_USER_ID, {
        variables: {
            userId,
        },
    })

    const uploadFile = useUploadFile(userId)

    useEffect(() => {
        const refreshToDos = async () => {
            await refetchUserTodos({ email })
            await refetchFileList({ userId })
        }
        refreshToDos()
    }, [])

    if (loading) {
        return <CircularLoader size={60} />
    }

    const selectFile = () => {
        inputRef.current.click()
    }

    const uploadNewFile = async (e: ChangeEvent<HTMLInputElement>) => {
        await uploadFile(e)
    }

    const filteredTodos = userData?.userByEmail?.todos?.filter(
        (todo: IToDo) => todo.type === 'Appointment' || todo.type === 'After Visit Summary'
    )

    const filesWithoutToDoId = files?.fileListByUserId
        ?.filter((file: any) => {
            const isAcceptableType = acceptableTypes.includes(file?.todo?.type)

            return !file?.todo?.id || (isAcceptableType && file?.todo?.isAddedToMedicalHistory)
        })
        ?.slice()
        ?.sort((currentFile: any, nextFile: any) => nextFile.createdDate - currentFile.createdDate)

    const medicalHistoryWithoutToDos = !filesWithoutToDoId?.length && !filteredTodos?.length

    return (
        <>
            <input type='file' className='file-input' ref={inputRef} onChange={uploadNewFile} />
            <AddNewChannelBtn
                onClick={selectFile}
                startIcon={<AddCircleOutlineIcon />}
                className='file-btn'
            >
                Add New
            </AddNewChannelBtn>
            {medicalHistoryWithoutToDos ? (
                <EmptyStatePatientBlock>
                    There are no files for this patient yet.
                </EmptyStatePatientBlock>
            ) : (
                <>
                    {filesWithoutToDoId?.map((file: any) => (
                        <FileLoaderWrapper key={file.id}>
                            <FileLoader file={file} />
                        </FileLoaderWrapper>
                    ))}
                    {Boolean(filteredTodos?.length) &&
                        filteredTodos?.map((toDo: IToDo) => (
                            <PatientMedicalBlock
                                key={toDo.id}
                                toDo={toDo}
                                userId={userId}
                                todoId={toDo.id}
                            />
                        ))}
                </>
            )}
        </>
    )
}

export default PatientMedicalHistory
