import { gql } from '@apollo/client'

export const FETCH_FILE_LIST_BY_USER_ID = gql`
    query FileListByUserId($userId: ID!) {
        fileListByUserId(userId: $userId) {
            id
            name
            displayName
            fileType
            todo {
                id
                type
                isAddedToMedicalHistory
            }
            user {
                id
            }
        }
    }
`

export const FETCH_FILE_LIST_BY_TODO_ID = gql`
    query FileListByTodoId($todoId: ID!) {
        fileListByTodoId(todoId: $todoId) {
            name
            displayName
            id
            createdDate
        }
    }
`

export const FETCH_FILE_BY_ID = gql`
    query FileById($fileByIdId: ID!) {
        fileById(id: $fileByIdId) {
            id
            name
            displayName
            fileType
            createdDate
        }
    }
`

export const DOWNLOAD_SIGNED_URL = gql`
    query DownloadSignedUrl($downloadSignedUrlId: ID!) {
        downloadSignedUrl(id: $downloadSignedUrlId) {
            url
            name
            displayName
            fileType
            id
        }
    }
`
