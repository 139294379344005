import React from 'react'
import { IconButton, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

import { IChannelSearchBtn } from './interfaces'

import { SearchBtnWrapper } from './styles'

const ChannelSearchBtn: React.FC<IChannelSearchBtn> = ({
    searchByName,
    searchName,
    clearSearch,
}) => {
    return (
        <SearchBtnWrapper
            onSubmit={(e) => {
                e.preventDefault()
            }}
        >
            <InputBase
                onChange={searchByName}
                value={searchName}
                placeholder='Search by Name, DoB, State'
            />
            {searchName.length ? (
                <IconButton onClick={clearSearch}>
                    <CloseIcon />
                </IconButton>
            ) : (
                <IconButton type='submit' disabled>
                    <SearchIcon />
                </IconButton>
            )}
        </SearchBtnWrapper>
    )
}

export default ChannelSearchBtn
