import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Box } from '@mui/material'
import PatientAvatar from '@/components/UI/PatientAvatar'
import { getFullName, cropName, getDoBAndState, getSubscriptionCurrentPlan } from '@/utils'
import { IPatientPreviewInfo } from '@/components/Patient/PatientSidebar/interfaces'
import { CardHeaderPreview } from './styles'
import { ChannelPreviewWrapper } from '@/components/Chat/ChannelPreview/styles'

const PatientPreviewInfo: React.FC<IPatientPreviewInfo> = ({ patient }) => {
    const navigate = useNavigate()
    const { id: patientId } = useParams()

    const { id, firstName, lastName, birthDate, state, avatarLink, inactive, activeSubscription } =
        patient

    const subscriptionType: string = activeSubscription?.subscriptionType
    const b2bPartner: string = activeSubscription?.b2bPartner
    const b2bSubPartner: string = activeSubscription?.b2bSubPartner
    const readablePartner: string = b2bSubPartner ? b2bSubPartner : b2bPartner ? b2bPartner : ''

    const navigateToContent = (id: string) => {
        navigate(`/dashboard/patients/${id}`, { replace: true })
    }

    return (
        <ChannelPreviewWrapper
            key={id}
            onClick={() => navigateToContent(id)}
            className={classNames({
                active: patientId === id,
                inactive: inactive,
            })}
        >
            <CardHeaderPreview
                avatar={
                    <PatientAvatar
                        src={avatarLink}
                        currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                        fontSize={10}
                        wrapperWidth={72}
                        wrapperHeight={72}
                    />
                }
                title={cropName(getFullName(firstName, lastName), 12)}
                subheader={
                    <Box>
                        {getDoBAndState(birthDate, state)}
                        <div className='partner-info'>{readablePartner}</div>
                    </Box>
                }
                className='patients__patient-preview'
            />
        </ChannelPreviewWrapper>
    )
}

export default PatientPreviewInfo
