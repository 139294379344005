import isToday from 'date-fns/isToday'
import format from 'date-fns/format'

const getLastMessageCreatedAt = (createdAt: number | undefined): string => {
    if (!createdAt) {
        return ''
    }
    if (isToday(createdAt)) {
        return format(createdAt, 'p')
    }
    return format(createdAt, 'MMM dd')
}

export default getLastMessageCreatedAt
