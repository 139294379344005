import React, { useEffect, useState } from 'react'
import { Avatar, Box, CardContent, Stack, Typography } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'

import { contentTypes } from '@/components/ToDo/ToDoContent/storyblok-types'
import Storyblok from '@/core/storyblok.config'

import { dateToString, getTime, cropName } from '@/utils'

import { IPatientToDoContent } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'
import {
    ChipIcon,
    DescriptionBlock,
    PatientToDoChip,
    TitleBlock,
    ToDoTypeBlock,
} from '@/components/Patient/PatientTabsPanel/styles'

const PatientToDoContent: React.FC<IPatientToDoContent> = ({
    priorityClass,
    priorityIcon,
    dueDate,
    dueTime,
    duration,
    title,
    description,
    contentType,
    searchContent,
}) => {
    const [assignedContent, setAssignedContent] = useState<string>('')
    const contentName = contentTypes.find(({ content }) => contentType === content)?.name

    useEffect(() => {
        Storyblok.get('cdn/stories', {
            by_uuids: searchContent,
        })
            .then((response) => {
                setAssignedContent(response.data.stories[0].content.title)
            })
            .catch((error) => {
                console.error(error)
            })
    })

    return (
        <CardContent>
            <Stack direction='row'>
                <PatientToDoChip className={priorityClass} label={<Avatar src={priorityIcon} />} />
                {(dueDate || dueTime) && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <CalendarMonthOutlinedIcon />
                                </ChipIcon>
                                {/*{repeated && <img src={repeatSvg} alt='Repeated' />}*/}
                                {`${dueDate && dateToString(dueDate, 'MM/dd/yyyy')} ${
                                    dueTime && getTime(dueTime)
                                }`}
                            </Box>
                        }
                    />
                )}
                {duration && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <TimerOutlinedIcon />
                                </ChipIcon>
                                {duration} min
                            </Box>
                        }
                    />
                )}
            </Stack>
            {title && <TitleBlock>{cropName(title, 20)}</TitleBlock>}
            {description && <DescriptionBlock>{cropName(description, 65)}</DescriptionBlock>}
            <ToDoTypeBlock>
                <Typography>Content type</Typography>
                <span>{contentName}</span>
            </ToDoTypeBlock>
            <ToDoTypeBlock>
                <Typography>Assigned content:</Typography>
                <span>{assignedContent}</span>
            </ToDoTypeBlock>
        </CardContent>
    )
}

export default PatientToDoContent
