import { useMutation } from '@apollo/client'
import { UPDATE_COLLEGE } from '@/graphql/colleges/mutations'
import { COLLEGE_LIST } from '@/graphql/colleges/queries'

const useUpdateCollege = () => {
    const [updateCollege] = useMutation(UPDATE_COLLEGE)

    const handleUpdateCollege = async (
        collegeData: any,
        collegeInput: any,
        collegeId: string,
        search: string,
        offset: number,
        limit: number,
        count: number
    ) => {
        try {
            await updateCollege({
                variables: {
                    updateCollegeId: collegeId,
                    collegeInput,
                },
                update(proxy) {
                    const cachedCollegeList: any = proxy.readQuery({
                        query: COLLEGE_LIST,
                        variables: {
                            search,
                            offset,
                            limit,
                        },
                    })

                    const newCachedCollegeList = cachedCollegeList?.colleges?.collegeList?.map(
                        (cachedCollege: any) => {
                            if (cachedCollege.id === collegeData?.id) {
                                return {
                                    ...cachedCollege,
                                    ...collegeData,
                                }
                            }

                            return cachedCollege
                        }
                    )

                    proxy.writeQuery({
                        query: COLLEGE_LIST,
                        variables: {
                            search,
                            offset,
                            limit,
                        },
                        data: {
                            colleges: {
                                count,
                                collegeList: newCachedCollegeList,
                            },
                        },
                    })
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    return {
        handleUpdateCollege,
    }
}

export default useUpdateCollege
