import React from 'react'
import { useQuery } from '@apollo/client'
import { Avatar, Box, CardContent, Stack, Typography } from '@mui/material'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'

import { dateToString, getTime, cropName } from '@/utils'

import { QUESTIONNAIRE_BY_ID_QUERY } from '@/graphql/questionnaires/queries'

import { IPatientToDoActivities } from '@/components/Patient/PatientTabsPanel/PatientToDos/interfaces'
import {
    ChipIcon,
    DescriptionBlock,
    PatientToDoChip,
    TitleBlock,
    ToDoTypeBlock,
} from '@/components/Patient/PatientTabsPanel/styles'

const PatientToDoActivities: React.FC<IPatientToDoActivities> = ({
    priorityClass,
    priorityIcon,
    dueDate,
    dueTime,
    duration,
    title,
    description,
    intakeAssigned,
}) => {
    const { data } = useQuery(QUESTIONNAIRE_BY_ID_QUERY, {
        variables: {
            questionnaireByIdId: intakeAssigned,
        },
    })

    return (
        <CardContent>
            <Stack direction='row'>
                <PatientToDoChip className={priorityClass} label={<Avatar src={priorityIcon} />} />
                {(dueDate || dueTime) && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <CalendarMonthOutlinedIcon />
                                </ChipIcon>
                                {/*{repeated && <img src={repeatSvg} alt='Repeated' />}*/}
                                {`${dueDate && dateToString(dueDate, 'MM/dd/yyyy')} ${
                                    dueTime && getTime(dueTime)
                                }`}
                            </Box>
                        }
                    />
                )}
                {duration && (
                    <PatientToDoChip
                        className='due-date_chip'
                        label={
                            <Box display='flex' alignItems='center'>
                                <ChipIcon>
                                    <TimerOutlinedIcon />
                                </ChipIcon>
                                {duration} min
                            </Box>
                        }
                    />
                )}
            </Stack>
            {title && <TitleBlock>{cropName(title, 20)}</TitleBlock>}
            {description && <DescriptionBlock>{cropName(description, 65)}</DescriptionBlock>}

            <ToDoTypeBlock>
                <Typography>Intake name:</Typography>
                {data?.questionnaireById?.name && (
                    <span>{cropName(data?.questionnaireById?.name, 50)}</span>
                )}
            </ToDoTypeBlock>
        </CardContent>
    )
}

export default PatientToDoActivities
