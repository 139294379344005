import React from 'react'
import { IconButton, InputLabel, OutlinedInput } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'

import { ISearchBar } from '@/components/UI/interfaces'

import { SearchBarWrapper } from '@/components/ToDo/ToDoList/styles'

const SearchBar: React.FC<ISearchBar> = ({
    placeholder,
    searchName,
    searchByName,
    clearSearch,
}) => {
    return (
        <SearchBarWrapper variant='outlined' size='small'>
            <InputLabel>{placeholder}</InputLabel>
            <OutlinedInput
                data-testid='search-bar'
                value={searchName}
                onChange={searchByName}
                endAdornment={
                    searchName.length ? (
                        <IconButton onClick={clearSearch}>
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        <IconButton type='submit' disabled>
                            <SearchIcon />
                        </IconButton>
                    )
                }
                label='Search Action Items'
            />
        </SearchBarWrapper>
    )
}

export default SearchBar
