import React, { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { ChannelSearchBtn } from '@/components'
import PatientsTable from '@/components/Chat/CreateChatModal/PatientsTable'

import { ICreateChatModal } from './interfaces'

import { ConfirmButton, DialogTitleWrapper, ModalWrapper, PatientsList } from './styles'
import { getSessionUser } from '@/utils'
import { getProviderSub } from '../../../utils/providerHelper'
import { CREATE_GROUP_CHANNEL } from '../../../graphql/chat/mutations'

const CreateChatModal: React.FC<ICreateChatModal> = ({
    open,
    handleClose,
    setSelectedChannel,
    providerInAdminMessage,
    filteredUsers,
}) => {
    const [searchName, setSearchName] = useState<string>('')
    const [currentUser, setCurrentUser] = useState<any>(null)
    const searchByName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value
        setSearchName(text)
    }, [])
    const sessionUser = getSessionUser()
    const providerSub = getProviderSub(sessionUser)

    const [createGroupChannel, { error: createGroupChannelError }] =
        useMutation(CREATE_GROUP_CHANNEL)

    const clearSearch = () => {
        setSearchName('')
    }

    const closeAndMoveToExistChat = (channelUrl: string) => {
        setSelectedChannel(channelUrl)
        handleClose()
    }

    const handleCreateChat = async () => {
        const existingUserChats = filteredUsers.filter(({ id }) => id === currentUser?.id)
        if (existingUserChats.length && existingUserChats[0].groupChannel) {
            return closeAndMoveToExistChat(existingUserChats[0].groupChannel.channelUrl)
        }

        try {
            const patientId = currentUser.canvasPatientId
            const params: any = {}
            params.name = providerInAdminMessage
            params.customType = '1o1_clinician'
            params.data = JSON.stringify({
                patient_id: patientId,
                user_id: currentUser?.id,
            })
            params.usersWithInvitationStatus = [
                { userId: patientId, userType: 'patient', invitationStatus: 'joined' },
                {
                    userId: providerSub,
                    userType: 'provider',
                    invitationStatus: 'invited_by_non_friend',
                },
            ]
            params.isPublic = true
            params.isDiscoverable = true

            const {
                data: { createGroupChannel: groupChannel },
            } = await createGroupChannel({
                variables: {
                    createGroupChannelInput: params,
                },
            })

            if (createGroupChannelError) {
                throw createGroupChannelError
            }

            setSelectedChannel(groupChannel.channelUrl)
        } catch (e) {
            console.error(e)
        } finally {
            handleClose()
        }
    }

    return (
        <ModalWrapper open={open} onClose={handleClose}>
            <PatientsList>
                <DialogTitleWrapper>
                    <Box className='icon-box'>
                        <IconButton onClick={handleClose}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                    <Box className='search-box'>
                        <ChannelSearchBtn
                            searchName={searchName}
                            searchByName={searchByName}
                            clearSearch={clearSearch}
                        />
                    </Box>
                </DialogTitleWrapper>
                <Typography className='header'>Choose The Patient</Typography>
                <PatientsTable
                    searchName={searchName}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                />
                <ConfirmButton
                    disabled={!currentUser}
                    onClick={handleCreateChat}
                    variant='contained'
                    color='info'
                >
                    Done
                </ConfirmButton>
            </PatientsList>
        </ModalWrapper>
    )
}

export default CreateChatModal
