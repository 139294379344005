export const ADMIN = 'admin'
export const REPLY_TYPE = 'QUOTE_REPLY'
export const RED_ZONE = 'red'
export const BLUE_ZONE = 'blue'
export const GREEN_ZONE = 'green'
export const GRAY_ZONE = 'gray'
export const MY_CHANNELS_ZONE = 'myChannels'
export const FREEMIUM = 'freemium'
export const IS_JOINED = 'isJoined'
export const TODAY = 'today'
export const LAST_THIRTY_DAYS = 'lastThirtyDays'
export const OPEN = 'open'
export const CLOSED = 'closed'
export const WAITING_FOR_FOLLOW_UP_FLAG = 'waitingForFollowUpFlag'

export { default as TWO_MINUTES_IN_MILLISECONDS } from './time'
export { default as PATIENT_INFO_TAB_KEYS } from './patientInfoSections'
