import { IGenderAbbreviations } from '@/components/Patient/PatientTabsPanel/interfaces'

const genderAbbreviations: IGenderAbbreviations = {
    male: 'M',
    female: 'F',
    other: 'O',
    unknown: 'UNK',
}

export default genderAbbreviations
