import { gql } from '@apollo/client'

export const CREATE_TODO_MUTATION = gql`
    mutation CreateTodo($todoInput: CreateTodoInput) {
        createTodo(todoInput: $todoInput) {
            id
            type
            title
            user {
                id
            }
            isAddedToMedicalHistory
        }
    }
`

export const DELETE_TODO_MUTATION = gql`
    mutation DeleteTodo($deleteTodoId: ID!) {
        deleteTodo(id: $deleteTodoId) {
            msg
        }
    }
`

export const EDIT_TODO_MUTATION = gql`
    mutation UpdateTodo($updateTodoId: ID!, $todoInput: UpdateTodoInput) {
        updateTodo(id: $updateTodoId, todoInput: $todoInput) {
            id
            type
            dueTime
            user {
                id
            }
        }
    }
`
