import React from 'react'
import { Typography } from '@mui/material'

import UploadToDoContentFile from './ToDoFreeText/UploadToDoContentFile'
import ToDoDateTime from '@/components/UI/DatePickers/ToDoDateTime'

import { IToDoAfterVisitSummary } from './interfaces'

import { MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'

const ToDoAfterVisitSummary: React.FC<IToDoAfterVisitSummary> = ({
    toDoData,
    handleToDoData,
    isViewMode,
    toDoContentFile,
    setToDoContentFile,
}) => {
    const {
        afterVisitDate,
        afterVisitReason,
        afterAction,
        afterMedicationList,
        afterEducation,
        afterTimeline,
    } = toDoData

    const changeFreeText = (e: any) => {
        const { name, value } = e.target
        const limit = 500
        const visitReasonLimit = 25

        if (name === 'afterVisitReason' && value?.length > visitReasonLimit) {
            return
        }

        if (value?.length > limit) {
            return
        }

        handleToDoData(name, value)
    }

    return (
        <>
            <ToDoDateTime
                handleToDoData={handleToDoData}
                existingDate={afterVisitDate}
                existingTime={''}
                dateFieldName='afterVisitDate'
                timeFieldName={''}
                rowTitle='Visit Date*'
                isDisabled={isViewMode}
                isAfterVisitSummary
            />
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Reason for Visit*
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={1}
                    className='todo-inputs'
                    type='text'
                    name='afterVisitReason'
                    variant='outlined'
                    size='small'
                    value={afterVisitReason?.substring(0, 25)}
                    disabled={isViewMode}
                    onChange={changeFreeText}
                />
                <span className='content-limit'>{`(${afterVisitReason?.length ?? 0}/25)`}</span>
            </MainHeader>
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Action Items
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='afterAction'
                    variant='outlined'
                    size='small'
                    value={afterAction?.substring(0, 500)}
                    disabled={isViewMode}
                    onChange={changeFreeText}
                />
                <span className='content-limit'>{`(${afterAction?.length ?? 0}/500)`}</span>
            </MainHeader>
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Medications List
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='afterMedicationList'
                    variant='outlined'
                    size='small'
                    value={afterMedicationList?.substring(0, 500)}
                    disabled={isViewMode}
                    onChange={changeFreeText}
                />
                <span className='content-limit'>{`(${afterMedicationList?.length ?? 0}/500)`}</span>
            </MainHeader>
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Educational Materials
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='afterEducation'
                    variant='outlined'
                    size='small'
                    value={afterEducation?.substring(0, 500)}
                    disabled={isViewMode}
                    onChange={changeFreeText}
                />
                <span className='content-limit'>{`(${afterEducation?.length ?? 0}/500)`}</span>
            </MainHeader>
            <MainHeader className='content-body'>
                <Typography className='text-typography required-field w-130'>
                    Follow Up Timeline
                </Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs'
                    type='text'
                    name='afterTimeline'
                    variant='outlined'
                    size='small'
                    value={afterTimeline?.substring(0, 500)}
                    disabled={isViewMode}
                    onChange={changeFreeText}
                />
                <span className='content-limit'>{`(${afterTimeline?.length ?? 0}/500)`}</span>
            </MainHeader>
            <UploadToDoContentFile
                rowTitle='Lab result file'
                toDoContentFile={toDoContentFile}
                setToDoContentFile={setToDoContentFile}
            />
        </>
    )
}

export default ToDoAfterVisitSummary
