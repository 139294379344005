import React from 'react'
import { Typography } from '@mui/material'

import { IInstructionField } from '../interfaces'

import { MainHeader, ToDoFormText } from '@/components/ToDo/ToDoForm/styles'

const LAB_INSTRUCTION = 'Laboratory testing instruction or advice'
const RX_INSTRUCTION = 'Instructions'

const InstructionField: React.FC<IInstructionField> = ({
    isViewMode,
    instructions,
    handleToDoData,
    isLabOrder,
}) => {
    const instructionsPlaceholder = isLabOrder ? LAB_INSTRUCTION : RX_INSTRUCTION

    const changeInstructions = (e: any) => {
        const { name, value } = e.target
        const limit = 250

        if (value?.length > limit) {
            return
        }

        handleToDoData(name, value)
    }

    return (
        <MainHeader className='content-body'>
            <Typography className='text-typography required-field w-130'>Instruction</Typography>
            <ToDoFormText
                required
                multiline
                rows={4}
                className='todo-inputs'
                type='text'
                name='instructions'
                variant='outlined'
                size='small'
                placeholder={instructionsPlaceholder}
                value={instructions?.substring(0, 250)}
                disabled={isViewMode}
                onChange={changeInstructions}
            />
            <span className='content-limit'>{`(${instructions?.length ?? 0}/250)`}</span>
        </MainHeader>
    )
}

export default InstructionField
