import React, { useState } from 'react'
import { Typography } from '@mui/material'

import CollegeListInput from '@/components/UI/Dropdowns/CollegeListDropdown/CollegeListInput'
import CollegeListDropdownBody from '@/components/UI/Dropdowns/CollegeListDropdown/CollegeListDropdownBody'

import { useOutside } from '@/hooks'
import { IInputField } from '@/components/UI/interfaces'

import { CollegeListDropdownWrapper, CollegeListWrapper } from './styles'

const CollegeListDropdown: React.FC<IInputField> = ({
    label,
    editDate,
    value,
    isEditMode,
    name,
}) => {
    const [searchName, setSearchName] = useState<string>(() => value?.name)
    const [collegeList, setCollegeList] = useState<any>([])
    const [selectedCollegeItem, setSelectedCollegeItem] = useState(() => value)

    const { ref, isOpen, setIsOpen } = useOutside(false, () => {
        setSearchName(selectedCollegeItem.name)
    })

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const selectCollegeItem = (college: any) => {
        if (editDate) {
            editDate(name, college)
        }

        setSearchName(college.name)
        setSelectedCollegeItem(college)
        setIsOpen(false)
    }

    const searchByName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value

        if (!text) {
            setCollegeList([])
        }

        setSearchName(text)
    }

    const clearSearch = () => {
        setSearchName('')
        setCollegeList([])
    }

    return (
        <CollegeListDropdownWrapper ref={ref}>
            <Typography>{label}</Typography>
            <CollegeListWrapper>
                <CollegeListInput
                    searchName={searchName}
                    searchByName={searchByName}
                    isEditMode={isEditMode}
                    clearSearch={clearSearch}
                    toggleDropdown={toggleDropdown}
                    isOpenDropdown={isOpen}
                />
                {isOpen && (
                    <CollegeListDropdownBody
                        searchName={searchName}
                        collegeList={collegeList}
                        setCollegeList={setCollegeList}
                        selectCollegeItem={selectCollegeItem}
                    />
                )}
            </CollegeListWrapper>
        </CollegeListDropdownWrapper>
    )
}

export default CollegeListDropdown
