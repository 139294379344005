import React from 'react'
import { render } from 'storyblok-rich-text-react-renderer'

import { Box, CardContent, CardMedia, Typography } from '@mui/material'

import { cropName } from '@/utils'
import AudioContent from '@/components/ToDo/ToDoContent/components/AudioContent'

import articleSvg from '@/assets/images/article-preview.svg'
import audioSvg from '@/assets/images/audio-preview.svg'

import { IToDoComponents } from './interfaces'
import { PreviewContent } from './styles'

const StoryblokContent: React.FC<IToDoComponents> = ({ selectedContent, contentType }) => {
    const isArticleContent = contentType === 'Post-V1-article'
    const isAudioContent = contentType === 'Post-V1-audio'
    let mediaContent = null

    if (isAudioContent) {
        mediaContent = selectedContent?.audio?.filename ? (
            <AudioContent audio={selectedContent?.audio?.filename} />
        ) : (
            <Box className='article-bg'>
                <img className='audio-img' src={audioSvg} alt={selectedContent?.title} />
            </Box>
        )
    } else if (isArticleContent || !selectedContent?.video) {
        mediaContent = selectedContent?.image ? (
            <Box className='article-bg'>
                <img
                    className='article-heart'
                    src={selectedContent?.image}
                    alt={selectedContent?.image}
                />
            </Box>
        ) : (
            <Box className='article-bg'>
                <img className='article-heart' src={articleSvg} alt={selectedContent?.image} />
            </Box>
        )
    } else {
        mediaContent = (
            <CardMedia component='video' src={selectedContent?.video?.filename} controls />
        )
    }

    return (
        <PreviewContent>
            {mediaContent}
            <CardContent>
                <Typography className='content-title'>{selectedContent?.title}</Typography>
                <Typography className='content-author'>By {selectedContent?.author}</Typography>
                {isArticleContent ? (
                    <Typography className='content-intro'>
                        {cropName(render(selectedContent?.intro), 128)}
                    </Typography>
                ) : (
                    <Typography className='content-description'>
                        {cropName(render(selectedContent?.description), 128)}
                    </Typography>
                )}
            </CardContent>
        </PreviewContent>
    )
}

export default StoryblokContent
