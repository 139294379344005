import { useEffect, useRef } from 'react'

export const useObserver = (
    ref: any,
    offset: number,
    count: number,
    isLoading: boolean,
    callback: any,
    allowObserve = true
) => {
    const observer = useRef<any>(null)

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (observer.current) {
            observer.current.disconnect()
        }

        const cbObserver = function (entries: any) {
            const isEqualToZero = offset === 0 && count === 0

            if (entries[0].isIntersecting && (offset < count || isEqualToZero)) {
                if (!allowObserve) {
                    return
                }
                callback()
            }
        }

        observer.current = new IntersectionObserver(cbObserver)
        observer.current.observe(ref.current)
    }, [isLoading, allowObserve])
}

export default useObserver
