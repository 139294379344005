import React, { useState } from 'react'

import { CallbackType, IUseShiftEnterKey, TypeKeyboardEvent } from './interfaces'

const EventKey = {
    SHIFT: 'Shift',
    ENTER: 'Enter',
}

export const useShiftEnterKey = (callback: CallbackType): IUseShiftEnterKey => {
    const [isShiftPressed, setIsShiftPressed] = useState(false)

    const keyDownHandler = (e: TypeKeyboardEvent) => {
        if (e.key === EventKey.SHIFT) {
            setIsShiftPressed(true)
        }
        if (!isShiftPressed && e.key === EventKey.ENTER) {
            e.preventDefault()
            callback()
        }
    }

    const keyUpHandler = (e: TypeKeyboardEvent) => {
        if (e.key === EventKey.SHIFT) {
            setIsShiftPressed(false)
        }
    }

    return { keyDownHandler, keyUpHandler }
}

export default useShiftEnterKey
