import format from 'date-fns/format'

const getTime = (createdAt: number | string): string => {
    if (!createdAt) {
        return ''
    }

    return format(Number(createdAt), 'p')
}

export default getTime
