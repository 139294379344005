import { styled, Autocomplete, TableCell, TableRow } from '@mui/material'

export const ListTableCell = styled(TableCell)`
    padding: 22px 16px;
    font-size: 14px;
    font-weight: 400;

    &.colleges-date {
        font-size: 12px;
    }

    &.colleges-students {
        max-width: 100px;
        font-weight: 700;
        opacity: 0.6;
    }

    &.colleges-articles,
    &.partner-resources {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const ListTableBodyRow = styled(TableRow)`
    position: relative;
    transition: all 0.3s;

    & td:first-of-type {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            background-color: transparent;
            transition: all 0.3s;
        }
    }

    &.open-menu {
        background-color: #f3f0ea;

        & td:first-of-type {
            &::before {
                background-color: #fc7960;
            }
        }
    }
`

export const PartnerFormChipField = styled(Autocomplete)`
    width: 100%;
`
