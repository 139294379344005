import { Box, Button, Drawer, List, ListItem, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'

const drawerWidth = 270

export const DrawerWrapper = styled(Drawer)(
    ({ theme }) => `
    width: ${drawerWidth}px;
    flex-shrink: 0;

    & .MuiDrawer-paper {
        width: ${drawerWidth}px;
        box-sizing: border-box;
        border-top-right-radius: 30px;
        background-color: ${theme.palette.violet.main}
    }
`
)

export const LogoImg = styled('img')`
    padding: 36px 70px;
    margin-bottom: 36px;
`

export const NavLinkItem = styled(NavLink)`
    text-decoration: none;

    &.active > li {
        background-color: #ffffff33;
        border-radius: 0 0.5em 0.5em 0;
        opacity: 1;

        & .MuiListItemIcon-root {
            opacity: 1;
        }

        & .MuiListItemText-root > span {
            opacity: 1;
        }
    }
`

export const SubNavLinkItem = styled(NavLink)`
    text-decoration: none;

    &.active > li {
        & .MuiListItemText-root > span {
            opacity: 1;
        }
    }
`

export const ListWrapper = styled(List)`
    position: relative;
    height: 100%;
    padding: 0;
`

export const SubListWrapper = styled(List)`
    position: relative;
    padding: 0;
`

export const ListSubItemWrapper = styled(ListItem)(({ theme }) => ({
    padding: '0 0 3px 80px',

    '& .MuiListItemText-root > span': {
        fontWeight: '700',
        color: theme.palette.primary.contrastText,
        opacity: '0.65',
    },
}))

export const ListItemWrapper = styled(ListItem)(({ theme }) => ({
    paddingLeft: '35px',
    paddingTop: '10px',
    paddingBottom: '10px',

    '& .MuiListItemIcon-root': {
        minWidth: '30px',
        marginRight: '15px',
        color: theme.palette.primary.contrastText,
        opacity: '0.65',
    },

    '& .MuiListItemText-root > span': {
        fontWeight: '700',
        fontFamily: 'DM Sans',
        color: theme.palette.primary.contrastText,
        opacity: '0.65',
    },
}))

export const LogoutButton = styled(Button)`
    border: none;
    padding: 8px 0 8px 55px;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
    justify-content: flex-start;
    margin-bottom: 48px;
    width: 100%;

    &:hover {
        border: none;
    }
`

export const DashboardContentWrapper = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    width: '100%',
    overflowX: 'hidden',
}))
