import { ChangeEvent } from 'react'
import { useMutation } from '@apollo/client'
import axios from 'axios'

import { UPLOAD_FILE_WITH_SIGNED_URL } from '@/graphql/files/mutations'
import { FETCH_FILE_LIST_BY_USER_ID, FETCH_FILE_LIST_BY_TODO_ID } from '@/graphql/files/queries'
import { fileToBlob, setUniqueFileName } from '@/utils'

const useUploadFile = (userId?: string, todoId: any = '') => {
    const [uploadFileSignedUrl] = useMutation(UPLOAD_FILE_WITH_SIGNED_URL);

    const uploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const { name, type: fileType } = file

        const fileName = setUniqueFileName(name)

        const fileInput: any = {
            name: fileName,
            displayName: name,
            fileType,
            user: {
                id: userId
            }
        }

        if (todoId) {
            fileInput.todo = {
                id: todoId
            }
        }

        const { data } = await uploadFileSignedUrl({
            variables: {
                fileInput,
            },
            update(proxy, result) {
                const newFile = result.data.uploadFileWithSignedUrl
                let filesByTodoId = []
                let filesByUserId = []

                if (todoId) {
                    const cacheFilesByTodoId: any = proxy.readQuery({
                        query: FETCH_FILE_LIST_BY_TODO_ID,
                        variables: {
                            todoId
                        }
                    })

                    if (cacheFilesByTodoId?.fileListByTodoId.length) {
                        filesByTodoId = [newFile, ...cacheFilesByTodoId?.fileListByTodoId]
                    } else {
                        filesByTodoId = [newFile]
                    }

                    proxy.writeQuery({
                        query: FETCH_FILE_LIST_BY_TODO_ID,
                        variables: {
                            todoId
                        },
                        data: {
                            fileListByTodoId: filesByTodoId
                        }
                    })
                } else {
                    const cacheFilesByUserId: any = proxy.readQuery({
                        query: FETCH_FILE_LIST_BY_USER_ID,
                        variables: {
                            userId
                        }
                    })

                    if (cacheFilesByUserId?.fileListByUserId.length) {
                        filesByUserId = [newFile, ...cacheFilesByUserId?.fileListByUserId]
                    } else {
                        filesByUserId = [newFile]
                    }

                    proxy.writeQuery({
                        query: FETCH_FILE_LIST_BY_USER_ID,
                        variables: {
                            userId
                        },
                        data: {
                            fileListByUserId: filesByUserId
                        }
                    })
                }
            },
        })

        const blobFile = await fileToBlob(file)

        if (data?.uploadFileWithSignedUrl) {
            await axios.put(data?.uploadFileWithSignedUrl?.url, blobFile, {
                headers: { 'Content-Type': 'application/octet-stream' },
            })
        }

        e.target.value = ''

        return data?.uploadFileWithSignedUrl?.file?.id
    }

    return uploadFile
}

export default useUploadFile