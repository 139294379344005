import React, { useMemo } from 'react'
import { Checkbox, Typography } from '@mui/material'

import ToDoRepeat from '@/components/ToDo/ToDoSettings/ToDoRepeat'
import ToDoDateTime from '@/components/UI/DatePickers/ToDoDateTime'

import { IToDoSettings } from '../ToDoContent/interfaces'

import { FormSection, ToDoOptionalCheck } from '@/components/ToDo/ToDoForm/styles'

const regex = /[^A-Za-z0-9]+/

const ToDoSettings: React.FC<IToDoSettings> = ({
    handleToDoData,
    isViewMode,
    type,
    toDoData,
    isEditMode,
}) => {
    const isAppointment = type === 'Appointment'
    const isLabResult = type === 'Lab Result'
    const isAfterVisitSummary = type === 'After Visit Summary'
    const typeAsClassName = useMemo(() => {
        return type?.toLowerCase().split(regex).join('-')
    }, [type])

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target

        handleToDoData(name, value)
    }

    return (
        <FormSection className={`settings ${typeAsClassName}`}>
            <Typography className='section-title' gutterBottom>
                Settings
            </Typography>
            <ToDoDateTime
                handleToDoData={handleToDoData}
                existingDate={toDoData.startingDate}
                existingTime={toDoData.startingTime}
                dateFieldName='startingDate'
                timeFieldName='startingTime'
                rowTitle='Starting date'
                isDisabled={
                    isEditMode || isViewMode || isAppointment || isLabResult || isAfterVisitSummary
                }
            />
            <ToDoDateTime
                handleToDoData={handleToDoData}
                existingDate={toDoData.dueDate}
                existingTime={toDoData.dueTime}
                dateFieldName='dueDate'
                timeFieldName='dueTime'
                rowTitle='Due date'
                isDisabled={isViewMode || isAppointment || isLabResult || isAfterVisitSummary}
            />
            <ToDoRepeat
                isViewMode={isViewMode}
                handleToDoData={handleToDoData}
                repeat={toDoData.repeat}
            />
            <ToDoOptionalCheck
                label='Praise'
                name='praise'
                labelPlacement='end'
                control={
                    <Checkbox
                        checked={toDoData.praise}
                        disabled={isViewMode}
                        onChange={handleCheckbox}
                        color='info'
                    />
                }
            />
        </FormSection>
    )
}

export default React.memo(ToDoSettings)
