import React, { useEffect, useState } from 'react'
import { Avatar, TextField, Typography } from '@mui/material'
import { useLazyQuery, useQuery } from '@apollo/client'

import CircularLoader from '@/components/Loader/CircularLoader'
import ToDoDateTime from '@/components/UI/DatePickers/ToDoDateTime'

import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'
import { FETCH_PROVIDERS_LIST } from '@/graphql/providers/queries'

import { getFullName } from '@/utils'

import { IToDoAppointment } from '@/components/ToDo/ToDoContent/interfaces'

import {
    CustomAutocomplete,
    MainHeader,
    ToDoFormDropdown,
    ToDoFormText,
} from '@/components/ToDo/ToDoForm/styles'

const ToDoAppointment: React.FC<IToDoAppointment> = ({
    changeTaskDesc,
    handleToDoData,
    toDoData,
    isViewMode,
}) => {
    const [previewAvatar, setPreviewAvatar] = useState<string>('')
    const [practitionerData, setPractitionerData] = useState<string>('')

    const { data, loading: providerListLoading } = useQuery(FETCH_PROVIDERS_LIST)
    const [fetchSignedUrlById, { loading: urlLoading }] = useLazyQuery(DOWNLOAD_SIGNED_URL)

    const { visitDate, visitTime, practitionerId, visitReason } = toDoData

    useEffect(() => {
        const getPractitionerData = async () => {
            const practitionerData = data?.providersList?.find(
                ({ id }: any) => practitionerId === id
            )

            await fetchSignedUrlById({
                variables: {
                    downloadSignedUrlId: practitionerData?.avatar,
                },
                onCompleted: ({ downloadSignedUrl }) => {
                    setPreviewAvatar(downloadSignedUrl?.url)
                    setPractitionerData(practitionerData)
                },
                onError: () => {
                    setPreviewAvatar('')
                    setPractitionerData(practitionerData)
                },
            })
        }

        getPractitionerData()
    }, [practitionerId, providerListLoading])

    return (
        <>
            <MainHeader className='content-body'>
                <ToDoFormDropdown
                    variant='standard'
                    size='small'
                    className='todo-inputs select-doctor'
                >
                    <Typography className='doctor-typography required-field w-130'>
                        Doctor
                    </Typography>
                    {providerListLoading ? (
                        <CircularLoader size={20} />
                    ) : (
                        <>
                            {urlLoading ? (
                                <CircularLoader size={40} relative />
                            ) : (
                                <Avatar src={previewAvatar} />
                            )}
                            <CustomAutocomplete
                                className='search-box'
                                style={{ paddingLeft: 7 }}
                                value={practitionerData || null}
                                onChange={(_: any, data: unknown | any) => {
                                    handleToDoData('practitionerId', data?.id)
                                }}
                                options={data?.providersList}
                                getOptionLabel={(option: any) =>
                                    getFullName(option?.firstName, option?.lastName) ?? ''
                                }
                                renderOption={(props, option: any) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.firstName} {option.lastName}
                                        </li>
                                    )
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                disabled={isViewMode}
                                disableClearable
                                disablePortal
                            />
                        </>
                    )}
                </ToDoFormDropdown>
            </MainHeader>
            <ToDoDateTime
                handleToDoData={handleToDoData}
                existingDate={visitDate}
                existingTime={visitTime}
                dateFieldName='visitDate'
                timeFieldName='visitTime'
                rowTitle='Visit date & time'
                isDisabled={isViewMode}
                isAppointment
            />
            <MainHeader marginTop='8px' className='content-body'>
                <Typography className='w-130'>Reason for visit</Typography>
                <ToDoFormText
                    required
                    multiline
                    rows={5}
                    className='todo-inputs reason-for-visit'
                    type='text'
                    name='visitReason'
                    variant='outlined'
                    size='small'
                    value={visitReason?.substring(0, 250)}
                    onChange={changeTaskDesc}
                    disabled={isViewMode}
                />
                <span className='content-limit'>{`(${visitReason?.length ?? 0}/250)`}</span>
            </MainHeader>
        </>
    )
}

export default ToDoAppointment
