import React, { useContext, useEffect, useState } from 'react'
import { sendbirdSelectors, withSendBird } from '@sendbird/uikit-react'
import { SendBirdState } from '@sendbird/uikit-react/types/lib/types';
import classNames from 'classnames'
import { InputBase, Paper, Box, Typography } from '@mui/material'
import { UserMessageCreateParams } from '@sendbird/chat/message'

import { useChannelContext } from '@sendbird/uikit-react/Channel/context'
import { GroupChannel } from '@sendbird/chat/groupChannel'

import { useShiftEnterKey } from '@/hooks'
import { getSessionUser, getChannelUserId } from '@/utils'
import { ChannelContext } from '@/context/ChannelContext'

import { AttachBtn, EditAlert, SendBtn } from './'

import { IMessageInput } from './interfaces'

import { MessageInputWrapper } from './styles'

const MessageInput: React.FC<IMessageInput> = ({
    sdk,
    sendUserMessage,
    sendFileMessage,
    updateUserMessage,
    providerRoleName,
    setRefetchChannelsToggle
}) => {
    const {currentGroupChannel} = useChannelContext() as any
    const [messageText, setMessageText] = useState('')
    const [isAlreadyRefetched, setAlreadyRefetched] = useState<boolean>(false)
    const {isJoined} = useContext(ChannelContext)

    const sessionUser: any = getSessionUser()

    const userId = getChannelUserId(currentGroupChannel)

    const {
        isEditMessage,
        isReplyMessage,
        message,
        selectMessage,
        messageId,
    } = useContext(ChannelContext)
    const { keyDownHandler, keyUpHandler } = useShiftEnterKey(sendMessage)

    useEffect(() => {
        setMessageText(message)
    }, [isEditMessage])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setMessageText(value)

        if (value.length > 0) {
            currentGroupChannel?.startTyping()
        }
        else {
            currentGroupChannel?.endTyping()
        }
    }

    function sendMessage() {
        if (!messageText.trim()) {
            return
        }

        const params = {} as UserMessageCreateParams
        params.message = messageText
        params.data = JSON.stringify({firstName: sessionUser.firstName, role: providerRoleName})
        try {
            if (isEditMessage) {
                updateUserMessage(currentGroupChannel, messageId, params)
                resetEditing()
                return
            }

            if (isReplyMessage) {
                params.isReplyToChannel = true
                params.parentMessageId = messageId
                sendUserMessage(currentGroupChannel, params)

                resetEditing()
                return
            }

            sendUserMessage(currentGroupChannel, params)
            currentGroupChannel?.endTyping()
            if(!isAlreadyRefetched) {
                setRefetchChannelsToggle(true)
                setAlreadyRefetched(true)
            }
            setMessageText('')
        } catch (e) {
            console.error(e.message)
        }
    }

    const resetEditing = () => {
        selectMessage({
            isEditMessage: false,
            isReplyMessage: false,
            message: '',
            messageImgUrl: '',
            messageId: 0,
            nickname: '',
        })

        setMessageText('')
    }

    if (isJoined) {
        return (
            <MessageInputWrapper
                className={classNames({
                    'message-input-edit': isEditMessage,
                })}
            >
                {isEditMessage ?
                    <EditAlert resetEditing={resetEditing}/> :
                    <Typography variant='body1' component='span' className='sender-msg'>Reply</Typography>
                }
                <Paper component='form'>
                    <InputBase
                        type='submit'
                        value={messageText}
                        onChange={handleChange}
                        onKeyDown={keyDownHandler}
                        onKeyUp={keyUpHandler}
                        multiline
                        placeholder='Type message here'
                        onBlur={() => currentGroupChannel.endTyping()}
                    />
                    <Box className='wrapper-action_btn'>
                        {!isEditMessage ? (
                            <AttachBtn
                                sendFileMessage={sendFileMessage}
                                sdk={sdk}
                                channel={currentGroupChannel as GroupChannel}
                                userId={userId}
                                firstName={sessionUser.firstName}
                                providerRoleName={providerRoleName}
                            />
                        ): <Box/>}
                        <SendBtn sendMessage={sendMessage}/>
                    </Box>
                </Paper>
            </MessageInputWrapper>
        )
    }
    else {
        return null
    }

}

const mapStoreToProps = (store: SendBirdState) => {
    const sdk = sendbirdSelectors.getSdk(store)

    const sendUserMessage = sendbirdSelectors.getSendUserMessage(store)
    const sendFileMessage = sendbirdSelectors.getSendFileMessage(store)
    const updateUserMessage = sendbirdSelectors.getUpdateUserMessage(store)

    return {
        sdk,
        sendUserMessage,
        sendFileMessage,
        updateUserMessage,
    }
}

export default withSendBird(MessageInput, mapStoreToProps) as any;
