import React, { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'

import { ToDoForm } from '@/components'
import todoStatuses from '@/components/ToDo/ModalToDo/todoStatuses'

import { CREATE_TODO_MUTATION, EDIT_TODO_MUTATION } from '@/graphql/toDos/mutations'
import {
    FETCH_TODO_BY_ID,
    FETCH_TODOS_QUERY,
    FETCH_USER_TODOS_BY_EMAIL,
} from '@/graphql/toDos/queries'
import { FETCH_TODOS_TITLE_BY_USER_ID } from '@/graphql/users/queries'

import CircularLoader from '@/components/Loader/CircularLoader'
import { IModalToDo, IToDo } from '@/components/Patient/PatientTabsPanel/interfaces'

import { ModalWrapper } from '../../Patient/PatientTabsPanel/styles'

const ModalToDo: React.FC<IModalToDo> = ({
    open,
    handleClose,
    isExistingPatient,
    userId,
    patientEmail,
    patientId,
    toDoId,
    isEditMode,
    isViewMode,
    isCreateMode,
    refetch,
    limit = 10,
    offset = 0,
    search = '',
}) => {
    const [isExistingTitle, setIsExistingTitle] = useState<boolean>(false)
    const [refetchToDoById, { data, loading }] = useLazyQuery(FETCH_TODO_BY_ID)
    const [fetchToDosTitleByUserId, { loading: fetchToDosLoading }] = useLazyQuery(
        FETCH_TODOS_TITLE_BY_USER_ID,
        {
            fetchPolicy: 'network-only',
        }
    )

    const [createToDo, { loading: createToDoLoading }] = useMutation(CREATE_TODO_MUTATION)
    const [editToDo, { loading: editToDoLoading }] = useMutation(EDIT_TODO_MUTATION)

    const modalTitle = isEditMode ? 'Editor' : 'Creating'
    const isQueryLoading = fetchToDosLoading || createToDoLoading || editToDoLoading

    useEffect(() => {
        if (isEditMode && toDoId) {
            try {
                refetchToDoById({
                    variables: {
                        todoByIdId: toDoId,
                    },
                    fetchPolicy: 'network-only',
                })
            } catch (e) {
                console.error(e)
            }
        }
    }, [])

    const submitData = async (
        e: React.MouseEvent<HTMLButtonElement>,
        toDoData: any,
        toDoId?: string
    ) => {
        e.preventDefault()
        let toDo: any = {}

        const { data: fetchedTodos } = await fetchToDosTitleByUserId({
            variables: {
                userId: toDoData.user.id,
            },
        })

        const titleAndStatusArray = fetchedTodos?.userById?.todos

        const arrayIncludesTitle = titleAndStatusArray.some(
            ({ title, status }: any) =>
                title === toDoData.title &&
                !toDoData.id &&
                (status === todoStatuses.IN_PROGRESS || status === todoStatuses.NOT_STARTED)
        )

        if (arrayIncludesTitle) {
            setIsExistingTitle(true)
            return
        }

        delete toDoData.id

        const variablesForSearch = {
            limit,
            offset,
            search,
        }

        if (isCreateMode) {
            toDo = await createToDo({
                variables: { todoInput: toDoData },
                update(proxy) {
                    if (patientEmail) {
                        refetch({ email: patientEmail })
                    }

                    const cacheToDosList: any = proxy.readQuery({
                        query: FETCH_TODOS_QUERY,
                        variables: variablesForSearch,
                    })

                    if (cacheToDosList?.todoList?.todoList?.length) {
                        const toDosList = [...cacheToDosList?.todoList?.todoList, toDoData]

                        proxy.writeQuery({
                            query: FETCH_TODOS_QUERY,
                            data: {
                                todoList: {
                                    count: cacheToDosList?.todoList?.count + 1,
                                    todoList: toDosList,
                                },
                            },
                            variables: variablesForSearch,
                        })
                    }
                },
            })
        }

        if (isEditMode) {
            toDo = await editToDo({
                variables: { updateTodoId: toDoId, todoInput: toDoData },
                update(proxy) {
                    const cacheToDosByEmail: any = proxy.readQuery({
                        query: FETCH_USER_TODOS_BY_EMAIL,
                        variables: { email: patientEmail },
                    })

                    const cacheToDosList: any = proxy.readQuery({
                        query: FETCH_TODOS_QUERY,
                        variables: variablesForSearch,
                    })

                    const toDosByEmail = cacheToDosByEmail?.userByEmail.todos.map((toDo: IToDo) => {
                        if (toDo.id === toDoId) {
                            return {
                                ...toDo,
                                ...toDoData,
                            }
                        }

                        return toDo
                    })

                    const toDosList = cacheToDosList?.todoList?.todoList.map((toDo: IToDo) => {
                        if (toDo.id === toDoId) {
                            return {
                                ...toDo,
                                ...toDoData,
                            }
                        }

                        return toDo
                    })

                    proxy.writeQuery({
                        query: FETCH_USER_TODOS_BY_EMAIL,
                        variables: { email: patientEmail },
                        data: {
                            userByEmail: {
                                todos: toDosByEmail,
                            },
                        },
                    })
                    proxy.writeQuery({
                        query: FETCH_TODOS_QUERY,
                        data: {
                            todoList: {
                                count: cacheToDosList?.todoList?.count,
                                todoList: toDosList,
                            },
                        },
                        variables: variablesForSearch,
                    })
                },
            })
        }

        if (handleClose) {
            handleClose()
        }

        return toDo
    }

    return (
        <ModalWrapper open={open} onClose={handleClose}>
            {loading ? (
                <CircularLoader size={60} />
            ) : (
                <ToDoForm
                    userId={userId}
                    isEditMode={isEditMode}
                    toDo={data?.todoById}
                    patientId={patientId}
                    version={'edit'}
                    isExistingPatient={isExistingPatient}
                    submitData={submitData}
                    modalTitle={modalTitle}
                    handleClose={handleClose}
                    isCreateMode={isCreateMode}
                    isViewMode={isViewMode}
                    isExistingTitle={isExistingTitle}
                    setIsExistingTitle={setIsExistingTitle}
                    isQueryLoading={isQueryLoading}
                />
            )}
        </ModalWrapper>
    )
}

export default ModalToDo
