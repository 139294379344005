import { gql } from '@apollo/client'

export const UPDATE_SUBSCRIPTION_END_DATE = gql`
    mutation Mutation($userId: String!, $endDate: DateTime!) {
        updateSubscriptionEndDate(userId: $userId, endDate: $endDate) {
            updatedDate
            endDate
        }
    }
`
