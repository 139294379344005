import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'

import { Checkbox, TextField, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import StoryblokContent from '@/components/ToDo/ToDoContent/components/StoryblokContent'

import { useStoryblokContent } from '@/hooks'
import { contentTypes } from './storyblok-types'

import { IToDoArticle } from './interfaces'

import { CustomAutocomplete, MainHeader } from '@/components/ToDo/ToDoForm/styles'

type ListBoxProps = React.HTMLAttributes<HTMLUListElement>
type NullableUlElement = HTMLUListElement | null

const ListBox = forwardRef(function ListBoxBase(
    props: ListBoxProps,
    ref: ForwardedRef<HTMLUListElement>
) {
    const { children, ...rest } = props

    const innerRef = useRef<HTMLUListElement>(null)

    useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current)

    return (
        <ul {...rest} ref={innerRef} role='list-box'>
            {children}
        </ul>
    )
})

const ToDoArticle: React.FC<IToDoArticle> = ({ handleToDoData, isViewMode, toDoData }) => {
    const { contentType, searchByTag, searchContent } = toDoData
    const contentTypeForToDo = contentType || null
    const { content, tags, fetchData, isFullData } = useStoryblokContent(
        contentTypeForToDo,
        searchByTag,
        ''
    )

    useEffect(() => {
        handleToDoData('searchContent', '')
    }, [contentType])

    useEffect(() => {
        if (selectedContent && searchByTag.length) {
            const checkTags = []

            for (const tag of selectedContent.tag_list) {
                checkTags.push(searchByTag.includes(tag))
            }

            if (!checkTags.some(Boolean)) {
                handleToDoData('searchContent', '')
            }
        }
    }, [searchByTag])

    useEffect(() => {
        handleToDoData('searchContent', searchContent)
    }, [searchContent])

    const handleContent = (type: string | unknown, name: string) => {
        handleToDoData(name, type)
    }

    const findContentById = (contentId: string) => {
        return content?.find(({ uuid }: any) => contentId === uuid)
    }

    const findNameByContent = (type: string) => {
        return contentTypes.find((contentItem) => contentItem.content === type)
    }

    const selectedContent = findContentById(searchContent)

    return (
        <>
            <MainHeader className='content-body mt-0'>
                <Typography className='content-type-typography required-field w-130'>
                    Content type
                </Typography>
                <CustomAutocomplete
                    className='search-box'
                    value={findNameByContent(contentType) || null}
                    onChange={(_: any, type: unknown | any) => {
                        handleContent(type.content, 'contentType')
                    }}
                    options={contentTypes}
                    getOptionLabel={(option: any) => option.name ?? ''}
                    renderOption={(props: any, option: any) => {
                        return (
                            <li {...props} key={option.name}>
                                {option.name}
                            </li>
                        )
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    disabled={isViewMode}
                    disableClearable
                    disablePortal
                />
            </MainHeader>
            <MainHeader className='content-body mt-0'>
                <Typography className='w-130'>Search by tag</Typography>
                <CustomAutocomplete
                    className='search-box'
                    value={searchByTag || []}
                    onChange={(e, tag) => {
                        handleContent(tag, 'searchByTag')
                    }}
                    options={tags}
                    getOptionLabel={(option: any) => option ?? ''}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <>
                                <Checkbox
                                    icon={
                                        <CheckBoxOutlineBlankIcon color='info' fontSize='small' />
                                    }
                                    checkedIcon={<CheckBoxIcon color='info' fontSize='small' />}
                                    checked={selected}
                                    color='info'
                                />
                                {option}
                            </>
                        </li>
                    )}
                    renderInput={(params: any) => <TextField {...params} />}
                    disabled={isViewMode || !contentType}
                    disableCloseOnSelect
                    multiple
                />
            </MainHeader>
            <MainHeader className='content-body mt-0'>
                <Typography className='search-content-typography required-field w-130'>
                    Search content
                </Typography>
                <CustomAutocomplete
                    className='search-box'
                    value={findContentById(searchContent) || null}
                    onChange={(_: any, data: unknown | any) => {
                        handleContent(data?.uuid, 'searchContent')
                    }}
                    options={content}
                    getOptionLabel={(option: any) => option.name ?? ''}
                    ListboxComponent={ListBox}
                    ListboxProps={{
                        onScroll: (event: React.SyntheticEvent) => {
                            const listboxNode = event.currentTarget
                            if (
                                listboxNode.scrollTop + listboxNode.clientHeight >=
                                listboxNode.scrollHeight
                            ) {
                                if (!isFullData) {
                                    fetchData()
                                }
                            }
                        },
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={isViewMode || !contentType}
                    isOptionEqualToValue={(option: any, value: any) => option?.name === value.name}
                    disablePortal
                />
            </MainHeader>
            {selectedContent && (
                <StoryblokContent
                    selectedContent={selectedContent?.content}
                    contentType={contentType}
                />
            )}
        </>
    )
}

export default ToDoArticle
