import React from 'react'
import { useCallback, useRef } from 'react'
import { TextField, IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { IChatInputProps } from '@/pages/Dashboard/DashboardChatbotPage/interfaces'

export const ChatInput = ({
    disabled,
    onSubmit,
    placeholder,
    customSubmitIcon,
}: IChatInputProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const handleSubmit = useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault()
            const textArea = textAreaRef?.current
            if (textArea && textArea.value.trim().length > 0) {
                if (onSubmit) {
                    onSubmit(textArea.value)
                }
                textArea.value = ''
            }
        },
        [onSubmit]
    )

    return (
        <>
            <TextField
                inputRef={textAreaRef}
                multiline
                rows={4}
                variant='outlined'
                fullWidth
                placeholder={placeholder || 'Type here to chat'}
                disabled={disabled}
                sx={{ flexGrow: 1, width: '100%', maxWidth: 'none', background: '#ffffff' }}
            />
            <IconButton aria-label='submit' onClick={handleSubmit} disabled={disabled}>
                {customSubmitIcon || <SendIcon />}
            </IconButton>
        </>
    )
}
