import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Box, TextField } from '@mui/material'

import CircularLoader from '@/components/Loader/CircularLoader'
import PatientAvatar from '@/components/UI/PatientAvatar'
import { IToDoFormUserList } from '@/components/ToDo/interfaces'
import { CustomAutocomplete } from '@/components/ToDo/ToDoForm/styles'

import { useDebounce } from '@/hooks'
import { FETCH_PATIENT_DATA_BY_USER_ID, FETCH_USERS } from '@/graphql/users/queries'
import { getSubscriptionCurrentPlan } from '@/utils'

/*
    The patient dropdown in the action item modal (modal is used for both creating and editing action items)
*/

const ToDoFormUserList: React.FC<IToDoFormUserList> = ({
    isViewMode,
    isExistingPatient,
    urlLoading,
    profileAvatar,
    userId,
    getPatientAvatar,
    downloadAvatarUrl,
    setProfileAvatar,
    handleToDoData,
}) => {
    const [selectedUser, setSelectedUser] = useState<any>(null)
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false)
    const [search, setSearch] = useState<string>('')
    const [userList, setUserList] = useState<any>([])
    const inputRef = useRef<HTMLElement | any>(null)

    const [fetchUserList, { loading: usersLoading }] = useLazyQuery(FETCH_USERS)
    const [fetchUserDataById] = useLazyQuery(FETCH_PATIENT_DATA_BY_USER_ID)

    const debouncedSearch = useDebounce(searchUsers, 500)

    const { activeSubscription } = selectedUser || {}
    const subscriptionType: string = activeSubscription?.subscriptionType

    useEffect(() => {
        const requestUserList = async () => {
            try {
                await fetchUserList({
                    variables: {
                        search,
                        limit: 100,
                        offset: 0,
                    },
                    onCompleted: ({ users }) => {
                        const user = users?.userList[0]
                        const fullName = user ? `${user?.firstName} ${user?.lastName}` : ''
                        setUserList(users?.userList)
                        if (search.trim() && search.trim() !== fullName.trim()) {
                            setIsOpenDropdown(true)
                        }

                        inputRef?.current?.focus()
                    },
                })
            } catch (e) {
                console.error(e)
            }
        }

        requestUserList()
    }, [search])

    useEffect(() => {
        if (userId) {
            const getUserAvatar = async () => {
                const { data } = await findUserById(userId)
                const email = data?.userById?.patient?.email

                setSelectedUser(data?.userById)

                try {
                    await getPatientAvatar({
                        variables: {
                            email,
                        },
                        onCompleted: async ({ userByEmail }: any) => {
                            if (!userByEmail?.avatar) {
                                setProfileAvatar('')
                                return
                            }

                            await downloadAvatarUrl({
                                variables: {
                                    downloadSignedUrlId: userByEmail?.avatar,
                                },
                                onCompleted: ({ downloadSignedUrl }: any) => {
                                    setProfileAvatar(downloadSignedUrl?.url)
                                },
                                onError: () => {
                                    setProfileAvatar('')
                                },
                            })
                        },
                    })
                } catch (e) {
                    console.error(e)
                }
            }
            getUserAvatar()
        }
    }, [userId])

    const findUserById = async (id?: string) => {
        return await fetchUserDataById({
            variables: {
                userId: id,
            },
        })
    }

    function searchUsers(value: string) {
        setUserList([])
        setSearch(value)
    }

    return (
        <Box className='patient-autocomplete'>
            {urlLoading ? (
                <CircularLoader size={40} relative />
            ) : (
                <PatientAvatar
                    src={profileAvatar}
                    currentPlan={getSubscriptionCurrentPlan(subscriptionType)}
                    fontSize={5}
                    wrapperWidth={35}
                    wrapperHeight={35}
                    isBorder={selectedUser}
                />
            )}
            <CustomAutocomplete
                className='search-box'
                style={{ paddingLeft: 7 }}
                value={selectedUser ?? null}
                onChange={(_: any, data: unknown) => {
                    handleToDoData('patientId', data)
                    setIsOpenDropdown(false)
                }}
                options={userList || []}
                getOptionLabel={(option: any) => {
                    return `${option.firstName} ${option.lastName}` ?? ''
                }}
                onOpen={() => setIsOpenDropdown(true)}
                open={isOpenDropdown && !isExistingPatient}
                clearOnBlur={false}
                renderOption={(props, option: any) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.firstName} {option.lastName}
                        </li>
                    )
                }}
                onInputChange={(event, newInputValue) => {
                    debouncedSearch(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField {...params} inputRef={inputRef} value={search} />
                )}
                disabled={isExistingPatient || usersLoading || isViewMode}
                disableClearable
                disablePortal
            />
        </Box>
    )
}

export default ToDoFormUserList
