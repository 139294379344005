import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'

import { FETCH_ROLE_BY_ID } from '@/graphql/roles/queries'
import { DOWNLOAD_SIGNED_URL } from '@/graphql/files/queries'

import { IProviderCard } from '@/components/Provider/interfaces'

import { AvatarWrapper, ProviderCardWrapper, ProviderInfo } from '@/components/Provider/styles'

const ProviderCard: React.FC<IProviderCard> = ({ provider }) => {
    const [roleName, setRoleName] = useState<string>('')
    const [profileAvatar, setProfileAvatar] = useState<string>('')
    const { firstName, lastName, role, avatar } = provider || {}

    useQuery(DOWNLOAD_SIGNED_URL, {
        variables: {
            downloadSignedUrlId: avatar,
        },
        onCompleted: ({ downloadSignedUrl }) => {
            setProfileAvatar(downloadSignedUrl?.url)
        },
    })

    useQuery(FETCH_ROLE_BY_ID, {
        variables: {
            roleId: role?.id,
        },
        onCompleted: ({ role }) => {
            setRoleName(role.name)
        },
    })

    return (
        <ProviderCardWrapper>
            <AvatarWrapper src={profileAvatar} variant='circular' />
            <ProviderInfo>
                <Typography className='provider-name'>{`${firstName} ${lastName}`}</Typography>
                <Typography className='provider-position'>{roleName}</Typography>
            </ProviderInfo>
        </ProviderCardWrapper>
    )
}

export default ProviderCard
