import React from 'react'
import { format, parseISO } from 'date-fns'
import { TextField, Typography } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { IInputField } from '@/components/UI/interfaces'

const PatientDatePicker: React.FC<IInputField> = ({ label, value, isEditMode, editDate, name }) => {
    const isStringDate = typeof value === 'string'

    const handleChange = (newDate: Date | null) => {
        if (editDate) {
            editDate(name, newDate)
        }
    }

    if (isStringDate) {
        value = format(parseISO(value), 'MM/dd/yyyy')
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Typography>{label}</Typography>
            <DatePicker
                inputFormat='MM/dd/yyyy'
                disabled={!isEditMode}
                value={value}
                onChange={handleChange}
                renderInput={({ onKeyDown, ...params }) => (
                    <TextField
                        onKeyDown={(e) => {
                            e.preventDefault()
                            return
                        }}
                        {...params}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default PatientDatePicker
