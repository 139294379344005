import * as Yup from 'yup'

// const AGE_18_DATE = 18
const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
const stateRegExp =
    /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/
const zipCodeRegExp = /^\d{5}$/

export const BasicInfoSchema = Yup.object().shape({
    firstName: Yup.string().nullable().required('First Name is required'),
    lastName: Yup.string().nullable().required('Last Name is required'),
    preferredName: Yup.string().nullable(),
    gender: Yup.string().nullable(),
    preferredGender: Yup.string().nullable(),
    pronouns: Yup.string().nullable(),
    email: Yup.string().nullable().email('Email is not valid').required('Email is required'),
    phone: Yup.string().nullable().matches(phoneRegExp, 'Phone Number is not valid'),
    birthDate: Yup.date().nullable().required('Date of Birth is required'),
})

export const AddressSchema = Yup.object().shape({
    line: Yup.string().nullable().required('Address is required'),
    city: Yup.string().nullable().required('City is required'),
    state: Yup.string()
        .nullable()
        .required('State is required')
        .matches(stateRegExp, 'State must be a valid, two-letter abbreviation'),
    postalCode: Yup.string()
        .nullable()
        .required('Zipcode is required')
        .matches(zipCodeRegExp, 'Zipcode is not valid'),
    college: Yup.object().nullable(),
})

export const EmergencyContactSchema = Yup.object().shape({
    emergencyContactName: Yup.string().nullable().required('Name is required'),
    emergencyContactRelationship: Yup.string().nullable().required('Contact is required'),
    emergencyContactPhone: Yup.string()
        .nullable()
        .required('Phone Number is required')
        .matches(phoneRegExp, 'Phone Number is not valid'),
})
