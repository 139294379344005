import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'

import { CardHeader } from '@mui/material'

import ModalToDo from '../../../ToDo/ModalToDo/ModalToDo'
import PatientMenuBtn from '@/components/UI/Buttons/PatientMenuBtn'
import PatientToDoActivities from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientToDoActivities'
import PatientToDoFreeText from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientToDoFreeText'
import PatientToDoAppointment from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientToDoAppointment'
import PatientToDoContent from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientToDoContent'
import PatientToDoLabResult from './PatientToDoLabResult'
import PatientLabRxToDo from '@/components/Patient/PatientTabsPanel/PatientToDos/PatientLabRxToDo'
import PatientToDoAfterVisitSummary from './PatientToDoAfterVisitSummary'
import { priorityIcons, statusColors } from './priorityIcons'

import { FETCH_USER_TODOS_BY_EMAIL } from '@/graphql/toDos/queries'
import { EDIT_TODO_MUTATION } from '@/graphql/toDos/mutations'

import { IPatientToDos, IToDo } from '../interfaces'
import { IAssignedContentComponents } from '@/components/ToDo/ToDoContent/interfaces'

import lowPrioritySvg from '@/assets/images/priority-low.svg'

import { PatientToDoBlockWrapper, PatientToDoChip } from '../styles'

const PatientToDoBlock: React.FC<IPatientToDos> = ({ toDo, patientEmail, userId, patientId }) => {
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [isViewMode, setIsViewMode] = useState<boolean>(false)

    const [editToDo] = useMutation(EDIT_TODO_MUTATION)

    const {
        type,
        title,
        description,
        priority,
        status,
        dueTime,
        dueDate,
        duration,
        taskDescription,
        practitionerId,
        visitReason,
        visitDate,
        visitTime,
        contentType,
        searchContent,
        laboratory,
    } = toDo

    const priorityIcon = priorityIcons[priority]?.icon || lowPrioritySvg
    const priorityClass = priorityIcons[priority]?.classname || 'low-priority'
    const statusClass = statusColors[status.toLowerCase()]

    const isCompleted =
        statusClass === statusColors.completed || statusClass === statusColors.canceled
    const isAppointment = type === 'Appointment'
    const isLabRx = type === 'Lab/Rx Order'

    const openMenuItems = () => {
        setIsOpenMenu((prev) => !prev)
    }

    const handleCloseModal = () => {
        setIsEditMode(false)
        setIsViewMode(false)
    }

    const enableEditMode = () => {
        setIsEditMode(!isEditMode)
    }

    const enableViewMode = () => {
        setIsViewMode(!isViewMode)
    }

    const completeToDo = async (status: string) => {
        const completedTodo = {
            ...toDo,
            status,
            user: {
                id: userId,
            },
        }
        delete completedTodo.id

        await editToDo({
            variables: { updateTodoId: toDo.id, todoInput: completedTodo },
            update(proxy) {
                const cacheToDosByEmail: any = proxy.readQuery({
                    query: FETCH_USER_TODOS_BY_EMAIL,
                    variables: { email: patientEmail },
                })

                const toDosByEmail = cacheToDosByEmail?.userByEmail.todos.map(
                    (cacheToDo: IToDo) => {
                        if (cacheToDo.id === toDo.id) {
                            return completedTodo
                        }

                        return cacheToDo
                    }
                )

                proxy.writeQuery({
                    query: FETCH_USER_TODOS_BY_EMAIL,
                    variables: { email: patientEmail },
                    data: {
                        userByEmail: {
                            todos: toDosByEmail,
                        },
                    },
                })
            },
        })
    }

    const assignedContentComponents: IAssignedContentComponents = {
        Activities: (
            <PatientToDoActivities
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                dueDate={dueDate}
                dueTime={dueTime}
                duration={duration}
                title={title}
                description={description}
                intakeAssigned={toDo.intakeAssigned}
            />
        ),
        'Free Text': (
            <PatientToDoFreeText
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                dueDate={dueDate}
                dueTime={dueTime}
                duration={duration}
                title={title}
                description={description}
                taskDescription={taskDescription}
            />
        ),
        Content: (
            <PatientToDoContent
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                dueDate={dueDate}
                dueTime={dueTime}
                duration={duration}
                title={title}
                description={description}
                contentType={contentType}
                searchContent={searchContent}
            />
        ),
        Appointment: (
            <PatientToDoAppointment
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                visitDate={visitDate}
                visitTime={visitTime}
                duration={duration}
                title={title}
                description={description}
                practitionerId={practitionerId}
                visitReason={visitReason}
            />
        ),
        'Lab/Rx Order': (
            <PatientLabRxToDo
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                dueDate={dueDate}
                dueTime={dueTime}
                duration={duration}
                title={title}
                description={description}
            />
        ),
        'Lab Result': (
            <PatientToDoLabResult
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                duration={duration}
                title={title}
                description={description}
            />
        ),
        'After Visit Summary': (
            <PatientToDoAfterVisitSummary
                priorityClass={priorityClass}
                priorityIcon={priorityIcon}
                duration={duration}
                title={title}
                description={description}
            />
        ),
    }

    return (
        <PatientToDoBlockWrapper
            className={classNames({
                'open-menu': isOpenMenu,
            })}
        >
            <CardHeader
                action={
                    <>
                        <PatientToDoChip className={statusClass} label={status} />
                        <PatientMenuBtn
                            openMenuItems={openMenuItems}
                            enableEditMode={enableEditMode}
                            completeToDo={completeToDo}
                            isCompleted={isCompleted}
                            isAppointment={isAppointment}
                            enableViewMode={enableViewMode}
                            isToDoEdit
                        />
                    </>
                }
                title={isLabRx ? laboratory : type}
            />

            {assignedContentComponents[type]}

            {(isEditMode || isViewMode) && (
                <ModalToDo
                    toDoId={toDo?.id}
                    open={isEditMode || isViewMode}
                    patientEmail={patientEmail}
                    handleClose={handleCloseModal}
                    patientId={patientId}
                    userId={userId}
                    isViewMode={isViewMode}
                    isExistingPatient
                    isEditMode
                />
            )}
        </PatientToDoBlockWrapper>
    )
}

export default PatientToDoBlock
