import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Channel } from '@sendbird/uikit-react'
import { Box } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { useSearchSendbirdUsers } from '@/hooks'
import { ChannelProvider } from '@/context/ChannelContext'
import { ICriteriaFilter } from '@/components/Chat/ChannelHeader/interfaces'
import { ChannelHeader, ChannelPreview, ChatHeader, ChatMessage, MessageInput } from '@/components'
import CreateChatModal from '@/components/Chat/CreateChatModal/CreateChatModal'
import {
    criteriaForCareTeam,
    criteriaFor1o1Chat,
    defaultStateOfCriteria,
} from '@/components/Chat/ChannelHeader/criteria'
import { CustomButton } from '@/components/Chat/ChannelHeader/styles'
import CircularLoader from '@/components/Loader/CircularLoader'
import EllipsisLoader from '@/components/Loader/EllipsisLoader'
import ChatsHeader from '@/components/Chat/ChatsHeader/ChatsHeader'
import ChatPatientPanel from '@/components/Chat/ChatPatientPanel/ChatPatientPanel'

import { ISendbirdApp } from '@/pages/Dashboard/DashboardChatsPage/interfaces'
import { ArrowUpwardButton, SendbirdAppWrapper } from '@/pages/Dashboard/DashboardChatsPage/styles'

import { REPLY_TYPE } from '@/core/constants'

const MAX_LIMIT_FOR_USERS = 100

const SendbirdApp: React.FC<ISendbirdApp> = ({
    providerId,
    providerRoleName,
    providerInAdminMessage,
    isCareTeamTab,
}) => {
    const [searchName, setSearchName] = useState<string>('')
    const [selectedChannel, setSelectedChannel] = useState<string>('')
    const [filterCriteries, setFilterCriteres] = useState(defaultStateOfCriteria)
    const [currentFilterCriteries, setCurrentFilterCriteres] = useState(defaultStateOfCriteria)
    const [isCreateChatModal, setIsCreateChatModal] = useState<boolean>(false)
    const [isPatientLoading, setIsPatientLoading] = useState<boolean>(true)
    const [patientUserId, setPatientUserId] = useState<any>(null)
    const [patient, setPatient] = useState<any>({})
    const [isPreviewsHeightMoreThanListHeight, setPreviewsHeightMoreThanListHeight] =
        useState<boolean>(false)
    const [refetchChannelsToggle, setRefetchChannelsToggle] = useState<boolean>(false)

    const location = useLocation()
    const state: any = location.state
    const channelPreviewWrapper = useRef<HTMLDivElement>(null)
    const channelListBody = useRef<HTMLDivElement>(null)
    const selectedCustomType = isCareTeamTab ? 'care_team' : '1o1_clinician'

    const {
        usersWithChannels: filteredUsers,
        loading,
        lastElement,
        handleSetFilter,
        inviteProviderToGroupChannel,
    } = useSearchSendbirdUsers(
        searchName,
        MAX_LIMIT_FOR_USERS,
        selectedCustomType,
        providerId,
        filterCriteries,
        isPreviewsHeightMoreThanListHeight,
        selectedChannel,
        refetchChannelsToggle,
        setRefetchChannelsToggle,
        setCurrentFilterCriteres
    )

    const makeFilterByDefaultRedAndBlue = (filterByDefaultObj: ICriteriaFilter[]) => {
        const tempArrayOfFilter = [...filterByDefaultObj]
        const zoneCheckBoxes = tempArrayOfFilter.shift()?.checkboxes
        const checkboxes = zoneCheckBoxes?.map((checkbox) => {
            if (checkbox.name === 'red' || checkbox.name === 'blue') {
                return { ...checkbox, checked: true }
            }
            return checkbox
        })
        return [{ title: 'zone', checkboxes }, ...tempArrayOfFilter]
    }

    useEffect(() => {
        let mounted = true
        if (mounted) {
            const defaultFilterByRedAndBlueZones =
                makeFilterByDefaultRedAndBlue(criteriaForCareTeam)
            const filterOptionsOnTab =
                selectedCustomType === 'care_team'
                    ? defaultFilterByRedAndBlueZones
                    : criteriaFor1o1Chat
            const savedFilterInLocalStorage = sessionStorage.getItem(`${selectedCustomType}_filter`)
            const currentFilterOptions = savedFilterInLocalStorage
                ? JSON.parse(savedFilterInLocalStorage)
                : filterOptionsOnTab

            setFilterCriteres(currentFilterOptions)
            setCurrentFilterCriteres(currentFilterOptions)
        }
        return () => {
            mounted = false
        }
    }, [selectedCustomType])

    useEffect(() => {
        let mounted = true
        if (mounted) {
            setPreviewsHeightMoreThanListHeight(
                (channelPreviewWrapper.current?.clientHeight || 0) >
                    (channelListBody.current?.clientHeight || 0)
            )
        }
        return () => {
            mounted = false
        }
    }, [filteredUsers])

    const handleOpenCreateChatModal = () => {
        setIsCreateChatModal(!isCreateChatModal)
    }

    useEffect(() => {
        let mounted = true

        if (state && mounted) {
            setSearchName(state.name)
        }
        if (mounted) {
            setSelectedChannel('')
        }
        return () => {
            mounted = false
        }
    }, [location])

    const searchByName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value

        setSearchName(text)
    }, [])

    const clearSearch = () => {
        setSearchName('')
    }

    const clearFilter = useCallback(() => {
        setFilterCriteres(currentFilterCriteries)
    }, [currentFilterCriteries])

    const handleResetFilterState = useCallback(() => {
        const filterOptionsOnTab =
            selectedCustomType === 'care_team' ? criteriaForCareTeam : criteriaFor1o1Chat
        setFilterCriteres(filterOptionsOnTab)
    }, [selectedCustomType])

    useEffect(() => {
        let mounted = true
        if (searchName.trim() && mounted) {
            filteredUsers?.map((user: any) => {
                if (state && user.groupChannel.channelUrl && state.id === user.id && mounted) {
                    inviteProviderToGroupChannel(user.groupChannel.channelUrl)
                        .then(() => {
                            setSelectedChannel(user.groupChannel.channelUrl)
                        })
                        .catch((error) => {
                            console.error('Error loading channel from search: ', error)
                        })
                }
            })
        }
        return () => {
            mounted = false
        }
    }, [filteredUsers])

    const filterCriteria = (event: React.ChangeEvent<HTMLInputElement>, title: string) => {
        const { name, checked } = event.target

        const mappedCheckboxes = filterCriteries.map((objOfCheckboxGroup) => {
            if (objOfCheckboxGroup.title === title) {
                const checkboxes = objOfCheckboxGroup.checkboxes.map((checkboxesInGroup) => {
                    if (checkboxesInGroup.name === name) {
                        return {
                            ...checkboxesInGroup,
                            checked,
                        }
                    }
                    return checkboxesInGroup
                })
                return { ...objOfCheckboxGroup, checkboxes }
            }
            return objOfCheckboxGroup
        })
        setFilterCriteres(mappedCheckboxes)
    }

    return (
        <SendbirdAppWrapper>
            <ChatsHeader isMyChannelsTab={!isCareTeamTab} />
            <div className='sendbird-app__conversation-wrap'>
                <div className='sendbird-channel-list'>
                    <div className='sendbird-channel-list__header'>
                        <div className='sendbird-channel-header'>
                            <ChannelHeader
                                searchByName={searchByName}
                                searchName={searchName}
                                filterCheckboxes={filterCriteries}
                                filterCriteria={filterCriteria}
                                clearSearch={clearSearch}
                                handleSetFilter={handleSetFilter}
                                clearFilter={clearFilter}
                                handleResetFilterState={handleResetFilterState}
                            />
                        </div>

                        {(channelPreviewWrapper.current?.clientHeight || 0) >
                            (channelListBody.current?.clientHeight || 0) && (
                            <ArrowUpwardButton
                                onClick={() => {
                                    const channelListBody = document.querySelector(
                                        '.sendbird-channel-list__body'
                                    )
                                    channelListBody?.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            >
                                <ArrowUpwardIcon />
                            </ArrowUpwardButton>
                        )}
                    </div>

                    <div className='sendbird-channel-list__body' ref={channelListBody}>
                        {loading && !filteredUsers.length && (
                            <CircularLoader size={60} color='primary' />
                        )}
                        <div ref={channelPreviewWrapper}>
                            {filteredUsers?.map((item: any) => (
                                <ChannelPreview
                                    key={item.id}
                                    data={item}
                                    selectedChannel={selectedChannel}
                                    selectedCustomType={selectedCustomType}
                                    setSelectedChannel={setSelectedChannel}
                                    inviteProviderToGroupChannel={inviteProviderToGroupChannel}
                                />
                            ))}
                            {loading && Boolean(filteredUsers.length) && (
                                <Box display='flex' justifyContent='center'>
                                    <EllipsisLoader />
                                </Box>
                            )}
                            {!loading && <div ref={lastElement} style={{ height: '20px' }} />}
                        </div>
                    </div>
                    {!isCareTeamTab && (
                        <div className='sendbird-channel-list_footer'>
                            <CustomButton
                                onClick={handleOpenCreateChatModal}
                                className='sendbird-channel-list_footer__btn'
                            >
                                Start New Chat
                            </CustomButton>
                        </div>
                    )}
                </div>

                <ChannelProvider>
                    <div className='sendbird-chat-box'>
                        <Channel
                            channelUrl={selectedChannel}
                            replyType={REPLY_TYPE}
                            renderChannelHeader={() => (
                                <ChatHeader
                                    selectedCustomType={selectedCustomType}
                                    providerId={providerId}
                                    providerInAdminMessage={providerInAdminMessage}
                                    isCareTeamTab={isCareTeamTab}
                                    providerRoleName={providerRoleName}
                                    setRefetchChannelsToggle={setRefetchChannelsToggle}
                                    setIsPatientLoading={setIsPatientLoading}
                                    setPatientUserId={setPatientUserId}
                                    setPatient={setPatient}
                                    setSelectedChannel={setSelectedChannel}
                                />
                            )}
                            renderMessage={({ message }) => (
                                <ChatMessage message={message} myOwnId={providerId} />
                            )}
                            renderMessageInput={() => (
                                <MessageInput
                                    providerRoleName={providerRoleName}
                                    setRefetchChannelsToggle={setRefetchChannelsToggle}
                                />
                            )}
                        />

                        {selectedChannel && (
                            <ChatPatientPanel
                                isPatientLoading={isPatientLoading}
                                patientUserId={patientUserId}
                                patientData={patient}
                            />
                        )}
                    </div>
                </ChannelProvider>
                {isCreateChatModal && (
                    <CreateChatModal
                        open={isCreateChatModal}
                        handleClose={handleOpenCreateChatModal}
                        setSelectedChannel={setSelectedChannel}
                        providerInAdminMessage={providerInAdminMessage}
                        filteredUsers={filteredUsers}
                    />
                )}
            </div>
        </SendbirdAppWrapper>
    )
}

export default SendbirdApp
