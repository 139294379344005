import { Box, Modal, styled, Typography } from '@mui/material'

export const ModalWrapper = styled(Modal)`
    background-color: rgba(43, 48, 115, 0.6);
`

export const ModalContent = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 708px;
    background: #edebe5;
    padding: 32px 16px;
    box-shadow: 0px 2px 5px -1px rgba(64, 72, 100, 0.1), 0px 1px 1px rgba(64, 72, 100, 0.14),
        0px 1px 3px rgba(64, 72, 100, 0.12);
    border-radius: 6px;
    display: flex;
    align-items: center;

    &.column-content {
        flex-direction: column;
    }

    & .modal-btns {
        margin-top: 16px;
        width: 100%;
        justify-content: flex-end;

        button {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0.02em;
            width: 120px;
        }

        .quit-btn {
            color: #fff;
        }
    }
`

export const ModalText = styled(Typography)`
    margin-left: 30px;
`
