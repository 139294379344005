import { gql } from '@apollo/client'

export const FETCH_PROVIDER_BY_EMAIL = gql`
    query providerByEmail($email: String!) {
        providerByEmail(email: $email) {
            id
            sub
            email
            firstName
            lastName
            phoneNumber
            smsNotification
            role {
                id
                name
            }
            avatar
            state
            isAdmin
            credentials
            licensedStates
            education
            background
        }
    }
`

export const FETCH_PROVIDER_BY_ID = gql`
    query ProviderById($providerByIdId: ID!) {
        providerById(id: $providerByIdId) {
            firstName
            lastName
            avatar
        }
    }
`

export const FETCH_PROVIDER_BY_SUB = gql`
    query ProviderBySub($sub: String!) {
        providerBySub(sub: $sub) {
            firstName
            lastName
            avatar
        }
    }
`

export const FETCH_PROVIDERS_LIST = gql`
    {
        providersList {
            id
            firstName
            lastName
            avatar
        }
    }
`
