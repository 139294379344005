import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { FETCH_SKILLSETS_BY_USER_ID } from '@/graphql/users/queries'
import { convertFloatToPercent } from '@/utils'
import { SkillsetChassisEnum } from '@/constants/myPathConstants'
import CircularLoader from '@/components/Loader/CircularLoader'
import InfoField from './components/InfoField'
import SkillsetContainer from './components/SkillsetContainer'
import { EmptyStatePatientBlock } from '../styles'

type ISkillset = {
    id: number,
    skillsetName: string,
    skillsetChassisType: string,
    createdDatetime: number,
    startIntensity: number,
    endIntensity: number,
}

export const isIntensityExist = (intensity: number) => {
    if (intensity === null || intensity === undefined) {
        return '--'
    } else {
        return convertFloatToPercent(intensity)
    }
}

const PatientMyPath: React.FC = () => {
    const { id: userId } = useParams()

    const { loading, data } = useQuery(FETCH_SKILLSETS_BY_USER_ID, {
        variables: {
            userId: userId,
        },
        fetchPolicy: 'network-only',
    })

    const skillsets: ISkillset[] = data?.myPathSkillsetListByUserId || []
    const checkSkillsetEntryExists = skillsets?.length

    if (loading) {
        return <CircularLoader size={60} />
    }

    const individualSkillsetLayout = (skillset: ISkillset) => {
        const {
            id,
            skillsetName,
            skillsetChassisType,
            createdDatetime,
            startIntensity,
            endIntensity,
        } = skillset
        const endLabel =
            skillsetChassisType === SkillsetChassisEnum.LEARNING
                ? 'Learning Rating'
                : 'Final Intensity'

        const startIntensityBlock = (
            <InfoField
                name='startIntensity'
                label='Initial Intensity'
                value={isIntensityExist(startIntensity)}
            />
        )

        const endIntensityBlock = (
            <InfoField
                name='endIntensity'
                label={endLabel}
                value={isIntensityExist(endIntensity)}
            />
        )

        switch (skillsetChassisType) {
            case SkillsetChassisEnum.LEARNING:
                return (
                    <SkillsetContainer
                        id={id}
                        skillsetName={skillsetName}
                        createdDatetime={createdDatetime}
                        endIntensityBlock={endIntensityBlock}
                    />
                )
            case SkillsetChassisEnum.INTENSITY:
            default:
                return (
                    <SkillsetContainer
                        id={id}
                        skillsetName={skillsetName}
                        createdDatetime={createdDatetime}
                        startIntensityBlock={startIntensityBlock}
                        endIntensityBlock={endIntensityBlock}
                    />
                )
        }
    }

    return (
        <>
            {checkSkillsetEntryExists ? (
                skillsets.map((skillset: ISkillset) => {
                    return individualSkillsetLayout(skillset)
                })
            ) : (
                <EmptyStatePatientBlock>
                    There is no My Path activity for this user yet.
                </EmptyStatePatientBlock>
            )}
        </>
    )
}

export default PatientMyPath
