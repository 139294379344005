import { createContext } from 'react';

interface ExpiredTokenContextValue {
    doShowError: any;
    setDoShowError: any;
}

const ExpiredTokenContext = createContext({} as ExpiredTokenContextValue);

export default ExpiredTokenContext;
