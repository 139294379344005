import React from 'react'
import { useCallback, useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { Box } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { getSessionUser } from '@/utils'
import { getProviderFullName } from '@/utils/providerHelper'
import '@sendbird/uikit-react/dist/index.css'
import { MessageRole, Conversations } from './interfaces'
import { ChatUI } from '@/components/Chatbot/ChatUI'
import {
    ChatbotSidebarWrapper,
    ChatbotHeader,
    ChatUIWrapper,
    FooterWrapper,
    ChatbotPreviewWrapper,
    CustomButton,
} from './styles'
import ChatbotPreview from '@/components/Chatbot/ChatbotPreview'

export const DashboardChatbotPage: React.FC = () => {
    const BACKEND_ADDRESS_GENAI = window.env.BACKEND_ADDRESS_GENAI
    const default_start_message = {
        chatId: null,
        messageId: 1,
        role: MessageRole.ASSISTANT,
        message: 'Hello! I am Cara AI, your health assistant. Ask me anything!',
    }

    const [chatList, setChatList] = useState<any>([])
    const sessionUser: any = getSessionUser()
    const lastElement = useRef<any>(null)
    const [chatId, setChatId] = useState<any>(null)
    const providerfullName = getProviderFullName(sessionUser)
    const token = sessionStorage.getItem('accessToken')
    const [isQuerying, setIsQuerying] = useState<boolean>(false)
    const [chatConversations, setChatConversations] = useState<Conversations>([
        default_start_message,
    ])

    useEffect(() => {
        getChatList()
    }, [])

    const handleNewChat = () => {
        setChatId(null)
        setChatConversations([default_start_message])
    }

    const getChatList = async () => {
        try {
            const url = BACKEND_ADDRESS_GENAI + `/chatbot/chats`
            const body = { userId: sessionUser.id }
            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                },
            })
            const responseChatList = response.data.items.map((msg: any) => ({
                chatId: msg.chat_id,
                lastMessageSent: msg.last_message_sent,
            }))
            response.data.items = responseChatList
            setChatList(response.data)
        } catch (error) {
            console.error('Error fetching chats for user:', error)
            return 'An error occurred while fetching chats.'
        }
    }

    const fetchChatBotResponse = async (message: string) => {
        let url
        let body
        try {
            if (chatId !== null) {
                body = { text: message, userId: sessionUser.id, chatId: chatId }
                url = BACKEND_ADDRESS_GENAI + `/chatbot/new_message`
            } else {
                body = { text: message, userId: sessionUser.id }
                url = BACKEND_ADDRESS_GENAI + `/chatbot/new_chat`
            }

            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                },
            })
            if (chatId == null) {
                setChatId(response.data.chat_id)
            }
            return response.data
        } catch (error) {
            console.error('Error fetching chat bot response:', error)
            return 'An error occurred while fetching the response.'
        }
    }

    const handleSubmit = useCallback(async (value: string) => {
        setIsQuerying(true)
        setChatConversations((conversations) => [
            ...conversations,
            {
                userInfo: { name: providerfullName },
                messageId: conversations.length + 1,
                chatId: chatId,
                role: MessageRole.USER,
                message: value,
            },
        ])
        const response = await fetchChatBotResponse(value)

        setIsQuerying(false)
        setChatConversations((conversations) => [
            ...conversations,
            {
                chatId: response.chat_id,
                messageId: response.message_id,
                role: MessageRole.ASSISTANT,
                message: response.text,
                comment: response.comment,
                approval: response.approval,
            },
        ])
    }, [])

    const onChatClick = async (selectedChatId: string) => {
        setChatId(selectedChatId)
        try {
            const url = BACKEND_ADDRESS_GENAI + `/chatbot/messages`
            const body = { userId: sessionUser.id, chatId: selectedChatId }
            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token ? `Bearer ${token}` : '',
                },
            })

            const chartMessages = response.data.items.map((msg: any) => ({
                chatId: msg.chat_id,
                messageId: msg.message_id,
                role: msg.sender === 'chatbot' ? MessageRole.ASSISTANT : MessageRole.USER,
                message: msg.text,
                comment: msg.comment,
                approval: msg.approval,
                userInfo: { name: providerfullName },
            }))
            setChatId(response.data.items[0].chat_id)
            setChatConversations(chartMessages)
        } catch (error) {
            console.error('Error fetching chats for user:', error)
            return 'An error occurred while fetching chats.'
        }
    }

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <ChatbotHeader>Chats: Cara AI</ChatbotHeader>
            <div style={{ flex: '0 0 25%', display: 'flex' }}>
                <ChatbotSidebarWrapper>
                    <ChatbotPreviewWrapper>
                        <ChatbotPreview chatPreviewData={chatList} onChatClick={onChatClick} />
                        <Box ref={lastElement} height={20} />
                    </ChatbotPreviewWrapper>
                    <FooterWrapper>
                        <CustomButton onClick={handleNewChat}>Start New Chat</CustomButton>
                    </FooterWrapper>
                </ChatbotSidebarWrapper>
            </div>

            <ChatUIWrapper>
                <ChatUI
                    userId={sessionUser.id}
                    isQuerying={isQuerying}
                    onSubmit={handleSubmit}
                    placeholder='Type here to interact with Cara AI'
                    disabled={isQuerying}
                    conversations={chatConversations}
                    customSubmitIcon={<SendIcon />}
                    token={token}
                />
            </ChatUIWrapper>
        </div>
    )
}
